import { REPORTS } from "../../constants/actionTypes";
import axios from 'axios';

export const getAppUserStep1 = (data, token) => {
    return (dispatch) => {
        dispatch({ type: REPORTS.APP_USER_STEP1_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/get-user-step-1", inputJSON)
            .then(function (response) {
                dispatch(appUsersStep1Success(response.data));
            })
            .catch(function (error) {
                dispatch(appUsersStep1Error(error.message));
            });
    };
};

const appUsersStep1Success= (data) => ({
    type: REPORTS.APP_USER_STEP1_SUCCESS,
    payload: data
});

const appUsersStep1Error = errorMessage => ({
    type: REPORTS.APP_USER_STEP1_FAIL,
    payload: errorMessage
});

export const getAppUserStep2 = (data, token) => {
    return (dispatch) => {
        dispatch({ type: REPORTS.APP_USER_STEP2_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/get-user-step-2", inputJSON)
            .then(function (response) {
                dispatch(appUsersStep2Success(response.data));
            })
            .catch(function (error) {
                dispatch(appUsersStep2Error(error.message));
            });
    };
};

const appUsersStep2Success= (data) => ({
    type: REPORTS.APP_USER_STEP2_SUCCESS,
    payload: data
});

const appUsersStep2Error = errorMessage => ({
    type: REPORTS.APP_USER_STEP3_FAIL,
    payload: errorMessage
});

export const getAppUserStep3 = (data, token) => {
    return (dispatch) => {
        dispatch({ type: REPORTS.APP_USER_STEP3_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/get-user-step-3", inputJSON)
            .then(function (response) {
                dispatch(appUsersStep3Success(response.data));
            })
            .catch(function (error) {
                dispatch(appUsersStep3Error(error.message));
            });
    };
};

const appUsersStep3Success= (data) => ({
    type: REPORTS.APP_USER_STEP3_SUCCESS,
    payload: data
});

const appUsersStep3Error = errorMessage => ({
    type: REPORTS.APP_USER_STEP3_FAIL,
    payload: errorMessage
});