import { COUPONS } from "../../constants/actionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  messageId: null,
  lists: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COUPONS.RESET_COUPONS: {
      return {
        ...state,
        loader: false,
        alertMessage: "",
        messageId: null,
      };
    }

    case COUPONS.ADD_COUPONS_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case COUPONS.ADD_COUPONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
      };
    }

    case COUPONS.ADD_COUPONS_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case COUPONS.LIST_COUPONS_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case COUPONS.LIST_COUPONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload,
      };
    }

    case COUPONS.LIST_COUPONS_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case COUPONS.DELETE_COUPONS_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case COUPONS.DELETE_COUPONS_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data,
      };
    }

    case COUPONS.DELETE_COUPONS_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    default:
      return state;
  }
};
