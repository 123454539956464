import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import About from "../../../components/Profile/About/index";
import Availability from "../../../components/Profile/Availability/index";
import ProfileHeader from "../../../components/Profile/ProfileHeader/index";
import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import {
  getUser,
  resetUser
} from "./../../../appRedux/actions/users";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {
      let inputJSON = {
        _id: authUser.user.userId
      }
      this.props.getUser(authUser.token, inputJSON);
    }
  }

  render() {
    const { userData } = this.props;
    return (
      <Auxiliary>
        {!userData ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <ProfileHeader userData={userData} mode="edit" />
            <div className="gx-profile-content">
              <Row>
                <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                  <About userData={userData} mode="edit" />
                </Col>

                <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                  <Availability />
                </Col>
              </Row>
            </div>
          </>
        }
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userData: state.users?.userData,
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ getUser, resetUser }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(Profile);
