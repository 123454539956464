import React from "react";
import {
  Divider,
  Table,
  Modal,
  Tag,
  Breadcrumb,
  Button,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-bootstrap";
import { FaRegEdit, FaRegImage } from "react-icons/fa";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";
import ToolTip from "components/ToolTip";

import {
  listSlides,
  resetSlide,
} from "../../../../appRedux/actions/marketing/slides";

const getRandomuserParams = (params) => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return (
      <Button className="ant-paginatio-button">
        <MdNavigateBefore className="icon-size" /> Previous
      </Button>
    );
  }
  if (type === "next") {
    return (
      <Button className="ant-paginatio-button">
        Next <MdNavigateNext className="icon-size" />
      </Button>
    );
  }
  return originalElement;
};

class ListMarketingSlides extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slidesList: [],
      currentInstance: this,
      actionType: null,
      record: null,
      previewCoverVisible: false,
      previewImage: null,
      previewImageTitle: null,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description - b.description,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
      },
      {
        title: "Updated By",
        dataIndex: "modifiedBy",
        key: "modifiedBy",
        render: (modifiedBy) => {
          return (
            <span>
              {modifiedBy?.firstName} {modifiedBy?.lastName}
            </span>
          );
        },
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        width: 150,
        sorter: (a, b) =>
          moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
        sortDirections: ["descend", "ascend"],
        render: (updatedAt) => {
          return <span>{moment(updatedAt).format("MM-DD-YYYY hh:mm a")}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status - b.status,
        sortDirections: ["descend", "ascend"],
        render: (status) => {
          return (
            <Tag color={status ? "processing" : "warning"}>
              {status ? "RELEASED" : "DRAFT"}
            </Tag>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        width: 200,
        render: (text, record) => (
          <span>
            <ToolTip
              text="Edit"
              child={
                <span className="gx-link">
                  <Link to={`/marketing/edit-slide/${record._id}`}>
                    <FaRegEdit className="icon-size gx-link" />
                  </Link>
                </span>
              }
            />
            <Divider type="vertical" />
            <ToolTip
              text="Slider Image"
              child={
                <span
                  className="gx-link"
                  onClick={(e) => {
                    this.onPreviewCover(record.sliderImg);
                  }}
                >
                  <FaRegImage className="icon-size gx-link" />
                </span>
              }
            />
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.slidesData) {
      if (nextProps.slidesData.messageId === 204) {
        return {
          slidesList: nextProps.slidesData.lists.data,
          pagination: {
            current: nextProps.slidesData.lists.current,
            pageSize: nextProps.slidesData.lists.pageSize,
            total: nextProps.slidesData.lists.totalCount,
          },
        };
      } else if (nextProps.slidesData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.slidesData.alertMessage,
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetSlide();
      } else if (nextProps.slidesData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.slidesData.alertMessage,
        });
        nextProps.resetSlide();
      }
      return true;
    }
    return null;
  }

  onPreviewCover = (image) => {
    this.setState({
      previewImage: image.location,
      previewCoverVisible: true,
      previewImageTitle: image.originalName,
    });
  };

  handleCancel = () =>
    this.setState({
      previewCoverVisible: false,
    });

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize,
      };
      this.props.listSlides(authUser.token, inputJson);
    }
  };

  render() {
    const { isLoading } = this.props.slidesData;
    const {
      pagination,
      slidesList,
      previewCoverVisible,
      previewImage,
      previewImageTitle,
    } = this.state;

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Marketing Slides</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader
          title="Marketing Slides"
          navigateTo="/marketing/add-slide"
          linkName="Add Marketing Slide"
        />
        <Row>
          <Col>
            <Table
              style={{ width: "100%" }}
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={slidesList}
              rowKey={(record) => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: "small",
              }}
              loading={isLoading}
              size={"middle"}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Modal
          visible={previewCoverVisible}
          title={previewImageTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            alt={previewImageTitle}
            style={{ width: "100%" }}
            src={previewImage}
          />
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    slidesData: state.slidesData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      listSlides,
      resetSlide,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(ListMarketingSlides);
