import { FAQ } from "../../constants/actionTypes";
import axios from 'axios';

export const addFaq = (token, data) => {
    return (dispatch) => {
        dispatch({ type: FAQ.ADD_FAQ_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("faqs/create", inputJSON)
            .then(function (response) {
                dispatch(addFaqSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addFaqError(error.message));
            });
    };
};

const addFaqSuccess = (data) => ({
    type: FAQ.ADD_FAQ_SUCCESS,
    payload: data
});

const addFaqError = errorMessage => ({
    type: FAQ.ADD_FAQ_FAIL,
    payload: errorMessage
});

export const listFaq = (token, data) => {
  return (dispatch) => {
    dispatch({ type: FAQ.LIST_FAQ_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("faqs/lists", inputJSON)
      .then(function (response) {
        dispatch(listFaqSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listFaqError(error.message));
      });
  };
};

const listFaqSuccess = (data) => ({
    type: FAQ.LIST_FAQ_SUCCESS,
    payload: data
});

const listFaqError = errorMessage => ({
    type: FAQ.LIST_FAQ_FAIL,
    payload: errorMessage
});

export const deleteFaq = (token, data) => {
    return (dispatch) => {
        dispatch({ type: FAQ.DELETE_FAQ_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("faqs/delete", inputJSON)
            .then(function (response) {
                dispatch(deleteFaqSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(deleteFaqError(error.message));
            });
    };
};

const deleteFaqSuccess = (data) => ({
    type: FAQ.DELETE_FAQ_SUCCESS,
    payload: data
});

const deleteFaqError = errorMessage => ({
    type: FAQ.DELETE_FAQ_FAIL,
    payload: errorMessage
});

export const updateFaq = (token, data) => {
    return (dispatch) => {
        dispatch({ type: FAQ.UPDATE_FAQ_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("faqs/update", inputJSON)
            .then(function (response) {
                dispatch(updateFaqSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateFaqError(error.message));
            });
    };
};

const updateFaqSuccess = (data) => ({
    type: FAQ.UPDATE_FAQ_SUCCESS,
    payload: data
});

const updateFaqError = errorMessage => ({
    type: FAQ.UPDATE_FAQ_FAIL,
    payload: errorMessage
});

export const resetFaq = () => {
    return (dispatch) => {
        dispatch({ type: FAQ.RESET_FAQ})
    }
}

export const searchFaq = (data, token) => {
  return (dispatch) => {
    dispatch({ type: FAQ.SEARCH_FAQ_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("faqs/search", inputJSON)
      .then(function (response) {
        dispatch(searchFaqSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchFaqError(error.message));
      });
  };
};

const searchFaqSuccess = (data) => ({
  type: FAQ.SEARCH_FAQ_SUCCESS,
  payload: data
});

const searchFaqError = errorMessage => ({
  type: FAQ.SEARCH_FAQ_FAIL,
  payload: errorMessage
});
