import React from "react";
import { connect } from 'react-redux';
import { Input, Modal, Form, Button, notification } from "antd";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
const FormItem = Form.Item;

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    }
    this.formRef = React.createRef();
  }

  onFinish = values => {
    this.setState({
      isLoading: true
    }, () => {
      const { authUser } = this.props.auth;
      const inputJSON = {
        email: authUser.user.email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      };

      fetch(`${process.env.REACT_APP_API_URL}users/update-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        },
        body: JSON.stringify(inputJSON)
      }).then(res => res.json()).then((json) => {
        if (json.messageId === 200) {
          this.setState({
            isLoading: false
          })
          notification['success']({
            message: 'Success',
            description: json.message,
          });
          this.formRef.current.setFieldsValue({
            currentPassword: null,
            newPassword: null,
            confirmPassword: null
          });
          this.props.onClose();
        } else {
          this.setState({
            isLoading: false
          })
          notification['error']({
            message: 'Alert!!',
            description: json.message,
          });
        }
      });
    })
  }

  render() {
    const { onClose, open } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal onCancel={onClose} visible={open}
        title="Change Password"
        closable={true}
        style={{ zIndex: 2600 }}
        footer={null}
      >
        <Form
          layout={'vertical'}
          onFinish={this.onFinish}
          className="gx-signin-form gx-form-row0"
          ref={this.formRef}
          autoComplete="off"
          >
          <FormItem
            label="Current Password"
            rules={[{ required: true, message: 'Please enter current password.' }]} name="currentPassword">
            <Input
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Current Password" />
          </FormItem>
          <FormItem
            label="New Password"
            rules={[{ required: true, message: 'Please enter new password.' }, { min: 6, message: 'Password must be minimum 6 characters.' }]} name="newPassword">
            <Input
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="New Password" />
          </FormItem>
          <FormItem
            label="Confirm New Password"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: 'Please enter confirm new password.'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('New password and confirm password does not match.'));
                },
              }),
            ]} name="confirmPassword">
            <Input
              type="password"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Confirm New Password" />
          </FormItem>
          <FormItem>
            <Button loading={isLoading} className="gx-mb-0" type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(ChangePassword);
