import React from "react";
import { Modal, notification } from "antd";
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import RequiredField from "../../RequiredField/index";


class AddSprite extends React.Component {
  constructor() {
    super();
    this.state = {
      assetType: '',
      sprites: [{
        fileName: ''
      }]
    }
  }
  onFileDelete = (index) => {
    const { sprites } = this.state;
    sprites.splice(index, 1);
    this.setState({ sprites: sprites });
  }
  onResolutionChange = (asset, event) => {
    const { sprites } = this.state;
    asset.resolution = event.target.value;
    this.setState({ sprites: sprites });
  }
  onAssetNameChange = (asset, event) => {
    const { sprites } = this.state;
    asset.assetName = event.target.value;
    this.setState({ sprites: sprites });
  }

  onFileChange = (asset, event) => {
    if (asset.resolution === undefined || asset.resolution === null || asset.resolution === '') {
      notification['error']({
        message: 'Alert!!',
        description: "Please add file resolution",
      });
      return false;
    }
    const { sprites } = this.state;
    let files = event.target.files;
    if (files[0]) {
      if (["image/png", "image/jpeg", "image/jpg"].includes(files[0].type)) {

        var img = new Image();
        img.src = window.URL.createObjectURL(files[0]);

        img.onload = () => {
          var width = img.naturalWidth, height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          const resolution = asset.resolution.split("*")
          if (width >= parseInt(resolution[0]) && height >= parseInt(resolution[1])) {
            asset.assetFile = files[0];
            asset.fileName = files[0].name;
            asset.fileSize = files[0].size;
            asset.fileType = files[0].type;
            this.setState({ sprites: sprites });
          } else {
            if (width > parseInt(resolution[0])) {
              notification['error']({
                message: 'Alert!!',
                description: "The asset you added is too big.  Please re-submit",
              });
            }
            else {
              notification['error']({
                message: 'Alert!!',
                description: "The asset you added is too small.  Please re-submit",
              });
            }
          }
        }
      }
      else {
        notification['error']({
          message: 'Alert!!',
          description: "The asset you added is the wrong file type.  Please re-submit.",
        });
      }
    }
    return false;
  }

  onNewSprite = () => {
    const { sprites } = this.state;
    sprites.push({ fileName: '' });
    this.setState({ sprites: sprites });
  }

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onFinish = () => {
    const { assetType, sprites } = this.state;

    if (assetType === null || assetType === '') {
      notification['error']({
        message: 'Alert!!',
        description: "Asset Type is required!",
      });
      return;
    }

    let isValid = true;
    sprites.forEach(asset => {
      asset.assetType = assetType;
      if (asset.assetName === undefined  || asset.assetName === null || asset.assetName === '') {
        notification['error']({
          message: 'Alert!!',
          description: "Asset Name is required!",
        });
        isValid = false;
      }
      if (asset.resolution === undefined || asset.resolution === null || asset.resolution === '') {
        notification['error']({
          message: 'Alert!!',
          description: "Resolution is required!",
        });
        isValid = false;
      }
      if (asset.assetFile === undefined ||  asset.assetFile === null || asset.assetFile === '') {
        notification['error']({
          message: 'Alert!!',
          description: "Asset File is required!",
        });
        isValid = false;
      }
    });


    if (!isValid || sprites.length === 0) {
      notification['error']({
        message: 'Alert!!',
        description: "Sprite fields are required!",
      });
      return;
    }

    this.props.onAddSprite(sprites);
    this.props.onClose();
  };

  render() {
    const { onClose, assetType } = this.props;
    const { sprites } = this.state;
    return (
      <Modal onCancel={onClose} visible={this.props.open}
        title={"Add Sprite Group"}
        closable={false}
        footer={null}
        width={1000}
        style={{ zIndex: 2600 }}
      >
        <Row>
          <Col>
            <Form.Row className="formrow">
              <Col>
                <Form.Label>Sprite Group Name<RequiredField /></Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="assetType"
                  value={assetType}
                  onChange={(e) => {
                    this.setState({ ...this.state, assetType: e.target.value })
                  }}
                />
              </Col>
            </Form.Row>

            <Form.Row className="btnrow">
              <Col>
                <Button type="button" onClick={() => { this.onNewSprite() }}>
                  Add Sprite
                        </Button>
              </Col>
            </Form.Row>
            <Form.Row className="btnrow">
              <Col>
                <Table responsive bordered>
                  <thead>
                    <tr className="asset-table-title">
                      <td>Asset Name<RequiredField /></td>
                      <td>Resolution<RequiredField /></td>
                      <td>Type of File</td>
                      <td>Size of File</td>
                      <td>Asset File<RequiredField /></td>
                      <td>Action</td>
                    </tr>
                  </thead>

                  <tbody>
                    {sprites ? sprites.map((asset, index) => (
                      <tr key={index}>
                        <td> <Form.Control value={asset.assetName} onChange={this.onAssetNameChange.bind(this, asset)} /></td>
                        <td><Form.Control placeholder="1024*500" value={asset.resolution} onChange={this.onResolutionChange.bind(this, asset)} /></td>
                        <td>{asset.fileType}</td>
                        <td>{this.convertFileSize(asset.fileSize)}</td>
                        <td>
                          <Form.File
                            className="ant-btn"
                            label={asset.fileName}
                            custom
                            data-browse="Upload"
                            onChange={this.onFileChange.bind(this, asset)}
                          />
                        </td>
                        <td>
                          <Button title="Delete" variant="outline-danger" onClick={this.onFileDelete.bind(this, index)}> Delete</Button></td>
                      </tr>
                    )) : null}
                  </tbody>
                </Table>
              </Col>
            </Form.Row>
            <Form.Row className="btnrow">
              <Col>
                <Button type="button" variant="outline-danger" onClick={() => { this.setState({ assetType: '', sprites: [] }); onClose() }}>
                  Cancel
                        </Button>
                <Button type="button" onClick={() => { this.onFinish() }}> Submit </Button>
              </Col>
            </Form.Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default AddSprite;
