import React from "react";
import { Divider, Table, Modal, Tag, Breadcrumb, Menu, Dropdown, Button, notification } from "antd";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { FaRegEdit, FaVideo, FaRegImage, FaEye } from 'react-icons/fa';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { ExclamationCircleOutlined, DownOutlined, DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";
import ToolTip from "components/ToolTip";

import {
  listGameInventory,
  updateSelectedGameInventory,
  resetGameInventory
} from "../../../../appRedux/actions/gameCatalog/gameInventory";

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListGameInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      gameCatalogLists: [],
      currentInstance: this,
      actionType: null,
      record: null,
      previewCoverVisible: false,
      previewImage: null,
      previewImageTitle: null,
      previewVideoVisible: false,
      previewVideo: null,
      previewVideoTitle: null,
      previewDetails: false,
      previewDetailsTitle: null,
      previewDetailsData: null,
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Name',
      dataIndex: 'gameName',
      key: 'gameName',
      sorter: (a, b) => a.gameName.length - b.gameName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Registered User',
      dataIndex: 'registeredUserPrice',
      key: 'registeredUserPrice',
      sorter: (a, b) => a.registeredUserPrice - b.registeredUserPrice,
      sortDirections: ['descend', 'ascend'],
      render: (registeredUserPrice) => { return (<span>${registeredUserPrice}</span>) }
    }, {
      title: 'Subscriber',
      dataIndex: 'subscriberPrice',
      key: 'subscriberPrice',
      sorter: (a, b) => a.subscriberPrice - b.subscriberPrice,
      sortDirections: ['descend', 'ascend'],
      render: (subscriberPrice) => { return (<span>${subscriberPrice}</span>) }
    }, {
      title: 'Game Category',
      dataIndex: 'gameCategory',
      key: 'gameCategory',
      sorter: (a, b) => a.gameCategory?.name.length - b.gameCategory?.name.length,
      sortDirections: ['descend', 'ascend'],
      render: (gameCategory) => {
        return (<span>{gameCategory?.name}</span>)
      }
    }, {
      title: 'Inventory #',
      dataIndex: 'inventoryId',
      key: 'inventoryId'
    }, {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: (a, b) => a.isActive - b.isActive,
      sortDirections: ['descend', 'ascend'],
      render: (isActive) => {
        return (<Tag color={isActive ? "processing" : 'warning'}>
          {isActive ? 'Enabled' : 'Disabled'}
        </Tag>)
      },
      filters: [
        {
          text: 'Enabled',
          value: true,
        },
        {
          text: 'Disabled',
          value: false,
        }
      ],
      onFilter: (value, record) => record.isActive === value
    }, {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <span>
          <ToolTip
            text="Edit"
            child={
              <span className="gx-link">
                <Link to={`/game-catalog/edit-inventory/${record._id}`}><FaRegEdit className="icon-size gx-link" /></Link>
              </span>
            }
          />
          <Divider type="vertical" />
          <ToolTip
            text="See Details"
            child={
              <span className="gx-link" onClick={(e) => { this.onPreviewDetails(record); }}>
                <FaEye className="icon-size gx-link" />
              </span>
            }
          />
          <Divider type="vertical" />
          <ToolTip
            text="Cover Image"
            child={
              <span className="gx-link" onClick={(e) => { this.onPreviewCover(record.coverImage); }}>
                <FaRegImage className="icon-size gx-link" />
              </span>
            }
          />
          <Divider type="vertical" />
          <ToolTip
            text="Game Logo"
            child={
              <span className="gx-link" onClick={(e) => { this.onPreviewCover(record.gameLogo); }}>
                <FaRegImage className="icon-size gx-link" />
              </span>
            }
          />
          {record.teaserVideo ?
            <>
              <Divider type="vertical" />
              <ToolTip
                text="Teaser Video"
                child={
                  <span className="gx-link" onClick={(e) => { this.onPreviewTeaser(record.teaserVideo); }}>
                    <FaVideo className="icon-size gx-link" />
                  </span>
                }
              />
            </>
            : null
          }
        </span>
      )
    }];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameInventoryData) {
      if (nextProps.gameInventoryData.messageId === 204) {
        return {
          gameCatalogLists: nextProps.gameInventoryData.lists.data,
          pagination: {
            current: nextProps.gameInventoryData.lists.current,
            pageSize: nextProps.gameInventoryData.lists.pageSize,
            total: nextProps.gameInventoryData.lists.totalCount
          }
        }
      } else if (nextProps.gameInventoryData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameInventoryData.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetGameInventory();
      } else if (nextProps.gameInventoryData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameInventoryData.alertMessage
        });
        nextProps.resetGameInventory();
      }
      return true;
    }
    return null;
  }

  onPreviewCover = (image) => {
    this.setState({
      previewImage: image.location,
      previewCoverVisible: true,
      previewImageTitle: image.originalName
    });
  }

  onPreviewTeaser = (video) => {
    this.setState({
      previewVideo: video.location,
      previewVideoVisible: true,
      previewVideoTitle: video.originalName
    });
  }

  onPreviewDetails = (record) => {
    this.setState({
      previewDetailsData: record,
      previewDetails: true,
      previewDetailsTitle: record.gameName
    });
  }

  handleCancel = () => this.setState({
    previewCoverVisible: false,
    previewVideoVisible: false,
    previewDetails: false
  });

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      this.props.listGameInventory(authUser.token, inputJson);
    }
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleMenuClick = (e) => {
    this.setState({
      actionType: e.key
    }, () => {
      Modal.confirm({
        title: e.key,
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to ' + e.key + ' selected records?',
        okText: e.key,
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onConfirmAction
      });
    })
  }

  onConfirmAction = () => {
    const { selectedRowKeys, actionType } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      ids: selectedRowKeys
    }
    if (actionType === 'Enable') {
      data.isActive = true;
    } else if (actionType === 'Disable') {
      data.isActive = false;
    } else {
      data.isActive = false;
      data.isDeleted = true;
    }
    this.props.updateSelectedGameInventory(authUser.token, data);
  }

  render() {
    const { isLoading } = this.props.gameInventoryData;
    const { pagination, selectedRowKeys, gameCatalogLists, previewCoverVisible, previewImage, previewImageTitle, previewVideoVisible, previewVideo, previewVideoTitle, previewDetails, previewDetailsTitle, previewDetailsData } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="Enable" icon={<UnlockOutlined />}>
          Enable
        </Menu.Item>
        <Menu.Item key="Disable" icon={<LockOutlined />}>
          Disable
        </Menu.Item>
        <Menu.Item key="Delete" icon={<DeleteOutlined />}>
          Delete
        </Menu.Item>
      </Menu>
    );

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Game Inventory</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Game Inventory" navigateTo="/game-catalog/add-inventory" linkName="Add Game" />
        <Row className="row-margin2">
          <Col style={{ textAlign: 'right' }}>
            <span style={{ marginRight: 15, fontWeight: 'bold' }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} records` : ''}
            </span>
            <Dropdown overlay={menu} disabled={!hasSelected}>
              <Button danger>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              style={{ width: '100%' }}
              className="gx-table-responsive"
              rowSelection={rowSelection}
              columns={this.columns}
              dataSource={gameCatalogLists}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={isLoading}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Modal
          visible={previewCoverVisible}
          title={previewImageTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt={previewImageTitle} style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Modal
          visible={previewVideoVisible}
          title={previewVideoTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <video
            width={"100%"}
            controls
            src={previewVideo}
          />
        </Modal>
        <Modal
          visible={previewDetails}
          title={previewDetailsTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <Row>
            <Col><span className="lable-style">Inventory #: </span>
              {previewDetailsData ? previewDetailsData.inventoryId : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col><span className="lable-style">Server URL: </span>
              {previewDetailsData ? previewDetailsData.serverURL : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col><span className="lable-style">Game Skin: </span>
              {previewDetailsData ? previewDetailsData.gameSkin.title : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Registered User Special: </span>
            </Col>
            <Col>
              <span className="lable-style">Subscriber Special: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              {previewDetailsData && previewDetailsData.registeredUserSpecial ?
                <>
                  <p>Name: <Tag color={previewDetailsData.registeredUserSpecial.color}>{previewDetailsData.registeredUserSpecial.name}</Tag></p>
                  <p>Price: ${previewDetailsData.registeredUserSpecial.price}</p>
                  <p>Date: {moment(previewDetailsData.registeredUserSpecial.startDate).format('MM-DD-YYYY')} To {moment(previewDetailsData.registeredUserSpecial.endDate).format('MM-DD-YYYY')}</p>
                </>
                : 'No Special Price'}
            </Col>
            <Col>
              {previewDetailsData && previewDetailsData.subscriberSpecial ?
                <>
                  <p>Name: <Tag color={previewDetailsData.subscriberSpecial.color}>{previewDetailsData.subscriberSpecial.name}</Tag></p>
                  <p>Price: ${previewDetailsData.subscriberSpecial.price}</p>
                  <p>Date: {moment(previewDetailsData.subscriberSpecial.startDate).format('MM-DD-YYYY')} To {moment(previewDetailsData.subscriberSpecial.endDate).format('MM-DD-YYYY')}</p>
                </>
                : 'No Special Price'}
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="lable-style">Info Tags: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              {previewDetailsData && previewDetailsData.infoTags.map(item => (
                <Tag color="#cd201f" key={item._id}>
                  {item.name}
                </Tag>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="lable-style">Game Sub Categories: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              {previewDetailsData && previewDetailsData.searchTags.map(item => (
                <Tag color="#108ee9" key={item._id}>
                  {item.name}
                </Tag>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="lable-style">Game Description: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              {previewDetailsData ? previewDetailsData.description : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="lable-style">SEO Keywords: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              {previewDetailsData?.seoKeywords ? previewDetailsData.seoKeywords : "Not Available"}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col><span className="lable-style">Created By: </span>
              {previewDetailsData ? (previewDetailsData.createdBy.firstName + " " + previewDetailsData.createdBy.lastName) : null}
            </Col>
            <Col><span className="lable-style">Modified By: </span>
              {previewDetailsData ? (previewDetailsData.modifiedBy.firstName + " " + previewDetailsData.modifiedBy.lastName) : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col><span className="lable-style">Created At: </span>
              {previewDetailsData ? moment(previewDetailsData.createdAt).format('MM-DD-YYYY hh:mm a') : null}
            </Col>
            <Col><span className="lable-style">Updated At: </span>
              {previewDetailsData ? moment(previewDetailsData.updatedAt).format('MM-DD-YYYY hh:mm a') : null}
            </Col>
          </Row>
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameInventoryData: state.gameInventoryData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listGameInventory, updateSelectedGameInventory, resetGameInventory }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListGameInventory);
