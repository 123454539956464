import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Avatar, Popover, Modal } from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  logOutUser
} from "./../../appRedux/actions/auth";
import ChangePassword from "components/Profile/ChangePassword/index";

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false
    }
  };

  changeState(value) {
    this.setState({ changePassword: value })
  }

  onConfirmModal() {
    Modal.confirm({
      title: 'Confirm',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure, you want to logout?',
      okText: 'Logout',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: this.onLogoutOk
    });
  }

  onLogoutOk = () => {
    this.props.logOutUser();
  }

  render() {
    const { authUser } = this.props.auth;

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>
          <Link to={`/home/profile`}>My Account</Link>
        </li>
        <li onClick={() => { this.changeState(true); }}>Change Password</li>
        <li onClick={() => this.onConfirmModal()}>Logout</li>
      </ul>
    );

    return (
      <div>
        <ChangePassword open={this.state.changePassword} onClose={() => { this.changeState(false); }} />
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
          {authUser.user && authUser.user.profilePic ?
            <Avatar size={'small'} src={authUser.user.profilePic.url} className="gx-avatar gx-pointer" alt={authUser.user ? authUser.user.firstName : ""}/>
            :
            <Avatar size={'small'} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} className="gx-avatar gx-pointer" alt={authUser.user ? authUser.user.firstName : ""}>{authUser.user ? authUser.user.firstName.charAt(0) + "" + authUser.user.lastName.charAt(0) : ""}</Avatar>
          }
        </Popover>
      </div>
    )

  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}
function mapDispathToProps(dispatch) {
  return bindActionCreators({ logOutUser }, dispatch);
}
export default connect(mapStateToProps, mapDispathToProps)(UserInfo);

