import React from "react";
import { Avatar, Popover, Upload, notification, Modal } from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getUserProfile } from "../../../appRedux/actions/auth";

const ProfileHeader = (props) => {
  const authUser = useSelector(({ auth }) => auth.authUser);
  const dispatch = useDispatch();

  const onChange = ({ file }) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("path", "users/");

    const INSTANCE = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ".concat(authUser.token),
      },
    });

    INSTANCE.post("upload-file/upload", formData)
      .then((response) => {
        if (response.data.messageId === 200) {
          INSTANCE.post("users/update-profile-pic", {
            _id: authUser.user.userId,
            profilePic: {
              key: response.data.data.key,
              url: response.data.data.location,
            },
          })
            .then((resp) => {
              notification["success"]({
                message: "Success",
                description: resp.data.message,
              });
              dispatch(
                getUserProfile(authUser.token, {
                  _id: authUser.user.userId,
                })
              );
            })
            .catch((error) => {
              notification["error"]({
                message: "Alert!!",
                description: error,
              });
            });
        } else {
          notification["error"]({
            message: "Alert!!",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        notification["error"]({
          message: "Alert!!",
          description: error,
        });
      });
  };

  const removePorfilePic = () => {
    axios
      .delete("users/delete-profile-pic", {
        data: {
          _id: authUser.user.userId,
          key: authUser.user?.profilePic?.key,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ".concat(authUser.token),
        },
      })
      .then((response) => {
        notification["success"]({
          message: "Success",
          description: response.data.message,
        });
        dispatch(
          getUserProfile(authUser.token, {
            _id: authUser.user.userId,
          })
        );
      })
      .catch((error) => {
        notification["error"]({
          message: "Alert!!",
          description: error,
        });
      });
  };

  const onConfirmRemoveModal = () => {
    Modal.confirm({
      title: "Confirm",
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to remove profile image?",
      okText: "Remove",
      okType: "danger",
      cancelText: "Cancel",
      onOk: removePorfilePic,
    });
  };

  const content = (
    <div>
      <p>
        <ImgCrop>
          <Upload
            className="imgCropAntD"
            multiple={false}
            customRequest={onChange}
            maxCount={1}
            fileList={[]}
          >
            <span className="changeImageMenu">Change Picture</span>
          </Upload>
        </ImgCrop>
      </p>
      <p onClick={onConfirmRemoveModal} className="mb-0 removeImageMenu delete-color">
        Remove Picture
      </p>
    </div>
  );

  if (props.userData) {
    return (
      <div
        className={
          props.mode === "preview"
            ? "gx-profile-banner btnrow"
            : "gx-profile-banner"
        }
      >
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              <div className="gx-profile-banner-avatar uploadAvtar">
                {props.userData.profilePic?.url ? (
                  props.mode === "edit" ? (
                    <>
                      <div className="profilePicture">
                        <img src={props.userData.profilePic?.url} alt="profilePicture" />
                      </div>
                      <Popover
                        placement="bottomLeft"
                        content={content}
                        trigger="click"
                      >
                        <span className="gx-link profile-camera profileCameraModalIcon">
                          <i className="icon icon-camera gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
                        </span>
                      </Popover>
                    </>
                  ) : (
                    <div className="profilePicture">
                      <img src={authUser.user?.profilePic?.url} alt={props.userData.firstName} />
                    </div>
                  )
                ) : (
                  <>
                    <Avatar
                      size={"large"}
                      style={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                      }}
                      className="gx-size-90"
                    >
                      {props.userData.firstName.charAt(0) +
                        "" +
                        props.userData.lastName.charAt(0)}
                    </Avatar>
                    {props.mode === "edit" ? (
                      <ImgCrop>
                        <Upload
                          className="imgCropAntD"
                          multiple={false}
                          customRequest={onChange}
                          maxCount={1}
                          fileList={[]}
                        >
                          <span className="gx-link profile-camera firstUpload">
                            <i className="icon icon-camera gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
                          </span>
                        </Upload>
                      </ImgCrop>
                    ) : null}
                  </>
                )}
              </div>
              <div className="gx-profile-banner-avatar-info">
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {props.userData.firstName} {props.userData.lastName}
                </h2>
                <p className="gx-mb-0 gx-fs-lg">
                  {props.userData.userRole[0].name}
                </p>
                {props.userData.city ? (
                  <p className="gx-mb-0 gx-fs-lg" style={{ marginTop: "5px" }}>
                    {props.userData.city}, {props.userData.state},{" "}
                    {props.userData.country}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="gx-profile-banner-top-right"></div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ProfileHeader;
