import React, { Component } from "react";
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, notification, Modal, Breadcrumb } from "antd";
import * as Yup from 'yup';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress/index";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField/index";
import AddAsset from "components/GameTemplate/AddAsset/index";
import AddSprite from "components/GameTemplate/AddSprite/index";
import Auxiliary from "util/auxiliary";

import {
  addGameTemplateSkin,
  editGameTemplateSkin,
  resetGameTemplate
} from "../../../appRedux/actions/gameTemplate";

const GameTemplateSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  gameType: Yup.string().required('Required'),
  shortDescription: Yup.string().required('Required'),
  videoUrl: Yup.string(),
  coverImage: Yup.string().nullable(),
  description: Yup.string().required('Required'),
  assets: Yup.object().shape({
    assetType: Yup.string(),
    resolution: Yup.string(),
    fileType: Yup.string(),
    fileSize: Yup.string(),
    fileName: Yup.string(),
    assetFile: Yup.string(),
  })
})

function MinHeightPlugin(editor) {
  this.editor = editor;
}

MinHeightPlugin.prototype.init = function () {
  this.editor.ui.view.editable.extendTemplate({
    attributes: {
      style: {
        minHeight: '8em'
      }
    }
  });
};

ClassicEditor.builtinPlugins.push(MinHeightPlugin);

class AddGameSkin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      s3Folder: 'game-template',
      editId: null,
      copyId: null,
      templateId: null,
      templateName: null,
      tempId: null,
      skinId: null,
      file: null,
      fileName: 'Browse Image',
      addAsset: false,
      addSprite: false,
      gameTemplate: {
        assets: [],
        sprites: [],
        audios: []
      },
      title: '',
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      index: null,
      showDeleteModal: false,
      isLoading: false
    }
  };

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onFileChange = event => {
    let files = event.target.files;
    if (files[0]) {
      this.setState(state => ({
        file: files[0],
        fileName: files[0].name
      }));
    }
    return false;
  }

  componentDidMount() {
    if (this.props.match.params.edit) {
      this.setState({
        isLoading: true
      })
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      INSTANCE.get(`game-template/get-skin-by-id/${this.props.match.params.edit}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              templateId: this.props.match.params.id,
              templateName: this.props.match.params.name,
              tempId: this.props.match.params.tempId,
              editId: this.props.match.params.edit,
              skinId: response.data.data.skinId,
              isLoading: false
            })
          }
        })
        .catch((error) => {
        })
    } else if (this.props.match.params.copy) {
      this.setState({
        isLoading: true
      })
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      INSTANCE.get(`game-template/get-skin-by-id/${this.props.match.params.copy}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              templateId: this.props.match.params.id,
              templateName: this.props.match.params.name,
              tempId: this.props.match.params.tempId,
              skinId: this.generateRandomId(5),
              copyId: this.props.match.params.copy,
              isLoading: false
            })
          }
        })
        .catch((error) => {
        })
    } else {
      this.setState({
        templateId: this.props.match.params.id,
        templateName: this.props.match.params.name,
        tempId: this.props.match.params.tempId,
        skinId: this.generateRandomId(5)
      });
    }
  }

  // generateRandomId
  generateRandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameTemplate.alertMessage
        });
        nextProps.resetGameTemplate();
        nextProps.history.goBack();
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
        nextProps.resetGameTemplate();
      }
    }
    return null;
  }

  onFinish = values => {
    const { file, gameTemplate, editId, s3Folder, templateId, templateName, tempId, skinId } = this.state;
    const { authUser } = this.props.auth;
    gameTemplate.title = values.title;
    gameTemplate.gameType = values.gameType;
    gameTemplate.shortDescription = values.shortDescription;
    gameTemplate.description = values.description;
    gameTemplate.videoUrl = values.videoUrl;
    gameTemplate.templateId = templateId;
    gameTemplate.templateName = templateName;
    if (this.props.match.path.includes("copy-template-skin")) {
      gameTemplate._id = null;
      gameTemplate.isAssetsAndSprites = false;
      gameTemplate.isAudios = false;
      gameTemplate.isGameTeasers = false;
      gameTemplate.createdAt = null;
      gameTemplate.updatedAt = null;
    }
    if (!editId) {
      gameTemplate.skinId = skinId;
    }
    if (file) {
      const formData = new FormData();
      formData.append('path', `${s3Folder}/${tempId}/${skinId}/`);
      formData.append('image', file);
      formData.append('gameTemplate', JSON.stringify(gameTemplate));
      if (editId) {
        this.props.editGameTemplateSkin(authUser.token, formData);
      } else {
        this.props.addGameTemplateSkin(authUser.token, formData);
      }
    } else {
      if (editId) {
        this.props.editGameTemplateSkin(authUser.token, JSON.stringify(gameTemplate));
      } else {
        this.props.addGameTemplateSkin(authUser.token, JSON.stringify(gameTemplate));
      }
    }

  }

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }

  onDeleteClick = (index, type, isDelete) => {
    this.setState({
      index: index,
      title: type,
      showDeleteModal: isDelete
    });
  }

  onAssetDelete = (index, type) => {
    const { gameTemplate } = this.state;
    if (type === "Asset")
      gameTemplate.assets.splice(index, 1);
    if (type === "Sprite") {
      gameTemplate.sprites = gameTemplate.sprites.filter(x => x.assetKey !== index);
    }
    if (type === "Audio")
      gameTemplate.audios.splice(index, 1);
    this.setState({ gameTemplate: gameTemplate, showDeleteModal: false });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  changeState(value, title) {
    this.setState({ ...this.state, addAsset: value, title: title })
  }

  changeAddSpriteState(value, title) {
    this.setState({ ...this.state, addSprite: value, title: title })
  }

  addNewSprite(sprites) {
    const { gameTemplate, tempId, skinId, s3Folder } = this.state;
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    let i = 0;
    sprites.forEach(values => {
      const formData = new FormData();
      formData.append('path', `${s3Folder}/${tempId}/${skinId}/`);
      formData.append('image', values.assetFile);
      INSTANCE.post('game-template/upload-asset', formData)
        .then((response) => {
          if (response.data.messageId === 200) {
            values.assetFile = response.data.data.location;
            values.assetKey = response.data.data.key;
            gameTemplate.sprites.push(values);
            this.setState({ gameTemplate: gameTemplate });
            i++;
            if (sprites.length === i) {
            }
          }
        })
        .catch((error) => {
          notification['error']({
            message: 'Alert!!',
            description: error
          });
        });
    });
  }

  addNewAsset(values) {
    const { gameTemplate, title, tempId, skinId, s3Folder } = this.state;
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const formData = new FormData();
    formData.append('path', `${s3Folder}/${tempId}/${skinId}/`);
    formData.append('image', values.assetFile);
    INSTANCE.post('game-template/upload-asset', formData)
      .then((response) => {
        if (response.data.messageId === 200) {
          values.assetFile = response.data.data.location;
          values.assetKey = response.data.data.key;
          if (title === "Asset") {
            gameTemplate.assets.push(values);
          } else if (title === "Sprite") {
            gameTemplate.sprites.push(values);
          } else if (title === "Audio") {
            gameTemplate.audios.push(values);
          }
          this.setState({ gameTemplate: gameTemplate });
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'Alert!!',
          description: error
        });
      });
  }

  renderAssetRow() {
    return (
      <Table responsive bordered>
        <thead>
          <tr className="asset-table-title">
            <td>Type of Asset</td>
            <td>Asset Name</td>
            <td>Resolution</td>
            <td>Type of File</td>
            <td>Size of File</td>
            <td>Asset Example</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {this.state.gameTemplate.assets?.map((asset, index) => (
            <tr key={index}>
              <td>{asset.assetType}</td>
              <td>{asset.assetName}</td>
              <td>{asset.resolution}</td>
              <td>{asset.fileType}</td>
              <td>{this.convertFileSize(asset.fileSize)}</td>
              <td>{asset.fileName}</td>
              <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Asset")}>Preview</Button>
                <Button title="Delete" variant="outline-danger" onClick={this.onDeleteClick.bind(this, index, "Asset", true)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderSpriteRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Table responsive bordered key={spriteGroup}>
          <thead>
            <tr className="asset-table-title">
              <td>Sprite Group</td>
              <td>Asset Name</td>
              <td>Resolution</td>
              <td>Type of File</td>
              <td>Size of File</td>
              <td>Asset Example</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {sprites?.map((asset, index) => (
              <tr key={index}>
                <td>{asset.assetType}</td>
                <td>{asset.assetName}</td>
                <td>{asset.resolution}</td>
                <td>{asset.fileType}</td>
                <td>{this.convertFileSize(asset.fileSize)}</td>
                <td>{asset.fileName}</td>
                <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Sprite")}>Preview</Button>
                  <Button title="Delete" variant="outline-danger" onClick={this.onDeleteClick.bind(this, asset.assetKey, "Sprite", true)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    })
  }

  renderAudioRow() {
    return (
      <Table responsive bordered>
        <thead>
          <tr className="asset-table-title">
            <td>Type of Asset</td>
            <td>Asset Name</td>
            <td>Resolution</td>
            <td>Type of File</td>
            <td>Size of File</td>
            <td>Asset Example</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {this.state.gameTemplate.audios?.map((asset, index) => (
            <tr key={index}>
              <td>{asset.assetType}</td>
              <td>{asset.assetName}</td>
              <td>{asset.resolution}</td>
              <td>{asset.fileType}</td>
              <td>{this.convertFileSize(asset.fileSize)}</td>
              <td>{asset.fileName}</td>
              <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}>Preview</Button>
                <Button title="Delete" variant="outline-danger" onClick={this.onDeleteClick.bind(this, index, "Audio", true)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  render() {
    const { loader } = this.props.gameTemplate;
    const { isLoading, editId, copyId, templateId, templateName, tempId, fileName, gameTemplate, previewVisible, previewImage, previewTitle } = this.state;

    return (
      <Auxiliary>
        {loader || isLoading ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/game-template/list" className="gx-link">Game Templates</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/game-template/template-skins/${templateId}/${templateName}/${tempId}`} className="gx-link">{templateName}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? gameTemplate.title : (copyId ? gameTemplate.title : "Add Game Template Skin")}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title={editId ? "Edit Game Template Skin" : (copyId ? "Copy Game Template Skin" : "Add Game Template Skin")} />
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Formik
                    validationSchema={GameTemplateSchema}
                    onSubmit={values => {
                      this.onFinish(values);
                    }}
                    enableReinitialize={true}
                    initialValues={{
                      title: gameTemplate.title,
                      gameType: gameTemplate.gameType,
                      shortDescription: gameTemplate.shortDescription,
                      videoUrl: gameTemplate.videoUrl,
                      description: gameTemplate.description,
                      coverImage: null
                    }}>
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row className="formrow">
                          <Col>
                            <Form.Label>Title<RequiredField /></Form.Label>
                            <Form.Control
                              type="text"
                              required
                              name="title"
                              value={values.title || ''}
                              onChange={handleChange}
                              isInvalid={!!errors.title}
                            />
                            {errors.title && touched.title ? (
                              <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Form.Label>Game Type<RequiredField /></Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              name="gameType"
                              required
                              value={values.gameType || ''}
                              onChange={e => {
                                const { value } = e.target;
                                setFieldValue("gameType", value);
                              }}
                              isInvalid={!!errors.gameType}>
                              <option value={null}>Select Game Type</option>
                              <option value="2D">2D</option>
                              <option value="3D">3D</option>
                              <option value="AR">AR</option>
                              <option value="VR">VR</option>
                            </Form.Control>
                            {errors.gameType && touched.gameType ? (
                              <Form.Control.Feedback type="invalid">{errors.gameType}</Form.Control.Feedback>
                            ) : null}
                          </Col>
                        </Form.Row>

                        <Form.Row className="formrow">
                          <Col>
                            <Form.Label>Short Description<RequiredField /></Form.Label>
                            <Form.Control
                              type="text"
                              required
                              name="shortDescription"
                              value={values.shortDescription || ''}
                              onChange={handleChange}
                              isInvalid={!!errors.shortDescription}
                            />
                            {errors.shortDescription && touched.shortDescription ? (
                              <Form.Control.Feedback type="invalid">{errors.shortDescription}</Form.Control.Feedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Form.Label>Video URL</Form.Label>
                            <Form.Control
                              type="text"
                              name="videoUrl"
                              value={values.videoUrl || ''}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Row className="formrow">
                          <Col>
                            <Form.Label>Cover Image</Form.Label>
                            <Form.File
                              id="custom-file"
                              label={fileName}
                              custom
                              onChange={this.onFileChange}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Row className="formrow">
                          <Col>
                            <Form.Group>
                              <Form.Label>Game Story<RequiredField /></Form.Label>
                              <CKEditor
                                name="description"
                                isInvalid={!!errors.description}
                                editor={ClassicEditor}
                                data={values.description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue("description", data);
                                }}
                              />
                              {errors.description && touched.description ? (
                                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Row className="btnrow">
                          <Col>
                            <Button type="button" onClick={() => { this.changeState(true, "Asset"); }}>
                              Add Asset
                            </Button>
                          </Col>
                        </Form.Row>
                        <Form.Row className="btnrow">
                          <Col>
                            {this.renderAssetRow()}
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Button type="button" onClick={() => { this.changeAddSpriteState(true, "Sprite"); }}>
                              Add Sprite Group
                            </Button>
                          </Col>
                        </Form.Row>
                        <Form.Row className="btnrow">
                          <Col>
                            {this.renderSpriteRow()}
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Button type="button" onClick={() => { this.changeState(true, "Audio"); }}>
                              Add Audio
                            </Button>
                          </Col>
                        </Form.Row>
                        <Form.Row className="btnrow">
                          <Col>
                            {this.renderAudioRow()}
                          </Col>
                        </Form.Row>
                        <Form.Row className="btnrow">
                          <Col>
                            <Button type="submit">
                              {editId ? "Update" : "Submit"}
                            </Button>
                          </Col>
                        </Form.Row>
                      </Form>
                    )}
                  </Formik>
                </Card>
              </Col>
            </Row>
          </>
        }
        {this.state.addAsset &&
          <AddAsset title={this.state.title} open={this.state.addAsset} onClose={() => { this.changeState(false); }}
            onAddAsset={this.addNewAsset.bind(this)} />}
        {this.state.addSprite &&
          <AddSprite title={this.state.title} open={this.state.addSprite} onClose={() => { this.changeAddSpriteState(false); }}
            onAddSprite={this.addNewSprite.bind(this)} />}
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >

          {this.state.title === "Audio" ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
        <Modal
          visible={this.state.showDeleteModal}
          title={"Delete " + this.state.title}
          okText="Delete"
          okType="danger"
          closable={false}
          onOk={() => {
            this.onAssetDelete(this.state.index, this.state.title)
          }}
          onCancel={() => this.onDeleteClick(this.state.index, this.state.type, false)}
          style={{ zIndex: 2600 }}
        >
          <h3>Are you sure you want to delete?</h3>
        </Modal>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addGameTemplateSkin, editGameTemplateSkin, resetGameTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(withRouter(AddGameSkin));

