import React, { Component } from "react";
import { Card, notification, Breadcrumb } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField";
import Auxiliary from "util/auxiliary";

import {
  addFaq,
  updateFaq,
  resetFaq
} from "./../../../appRedux/actions/faq";

const FaqSchema = Yup.object().shape({
  question: Yup.string().required('Required'),
  answer: Yup.string().required('Required')
})

class AddFaq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      question: "",
      answer: ""
    }
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("faqs/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              editId: this.props.match.params.id,
              question: response.data.data.question,
              answer: response.data.data.answer
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.faqs) {
      if (nextProps.faqs.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.faqs.alertMessage
        });
        nextProps.resetFaq();
      } else if (nextProps.faqs.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.faqs.alertMessage
        });
        nextProps.resetFaq();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = values;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateFaq(authUser.token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addFaq(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.faqs;
    const { editId, question, answer } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/faqs/list" className="gx-link">FAQs</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit FAQs" : "Add FAQs"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit FAQs" : "Add FAQs"} />
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Formik
                  validationSchema={FaqSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={{
                    question: question,
                    answer: answer
                  }}>
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Question<RequiredField /></Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="question"
                            value={values.question}
                            onChange={handleChange}
                            isInvalid={!!errors.question}
                          />
                          {errors.question && touched.question ? (
                            <Form.Control.Feedback type="invalid">{errors.question}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Answer<RequiredField /></Form.Label>
                            <CKEditor
                              name="answer"
                              isInvalid={!!errors.answer}
                              editor={ClassicEditor}
                              data={values.answer}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("answer", data);
                              }}
                            />
                            {errors.answer && touched.answer ? (
                              <Form.Control.Feedback type="invalid">{errors.answer}</Form.Control.Feedback>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Form.Row>

                      <Form.Row>
                        <Button type="submit">
                          {editId ? "Update" : "Submit"}
                        </Button>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    faqs: state.faqs
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addFaq, updateFaq, resetFaq }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddFaq);
