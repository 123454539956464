import React from "react";
import { Tag, notification, Divider, Breadcrumb, Input, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import { FaArchive, FaRedo, FaCopy } from 'react-icons/fa';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import copy from 'copy-to-clipboard';

import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";
import { gameDemoToken } from "../../../util/config";

import {
  getRecipientList,
  searchRecipient,
  resetSendDemo,
  updateDemoStatus
} from "../../../appRedux/actions/sendDemo";

const { Search } = Input;

let fields = {
  createdAt: {
    header: "Sent At",
    formatter: (_fieldValue, record) => {
      return moment(record?.createdAt).format('MM-DD-YYYY hh:mm a');
    },
  },
  firstName: {
    header: "First Name",
    formatter: (_fieldValue, record) => {
      return record?.firstName;
    },
  },
  lastName: {
    header: "Last Name",
    formatter: (_fieldValue, record) => {
      return record?.lastName;
    },
  },
  email: {
    header: "Email",
    formatter: (_fieldValue, record) => {
      return record?.email;
    },
  },
  mobileNumber: {
    header: "Mobile Number",
    formatter: (_fieldValue, record) => {
      return record?.mobileNumber;
    },
  },
  sender: {
    header: "Sender",
    formatter: (_fieldValue, record) => {
      return record?.sender.firstName + " " + record?.sender.lastName;
    },
  },
  gameName: {
    header: "Game Name",
    formatter: (_fieldValue, record) => {
      return record?.gameInventory.gameName;
    },
  },
  greetingPackage: {
    header: "Greeting Package",
    formatter: (_fieldValue, record) => {
      return record?.greetingPackage.name;
    },
  },
  cashGift: {
    header: "Cash Gift",
    formatter: (_fieldValue, record) => {
      return "$" + record?.cashGift;
    },
  },
  gameNumber: {
    header: "Game#",
    formatter: (_fieldValue, record) => {
      return record?.gameNumber;
    },
  },
  status: {
    header: "Status",
    formatter: (_fieldValue, record) => {
      return renderStatusText(record?.status);
    },
  }
};;

function renderStatusText(status) {
  if (status === 1) {
    return "In Progress";
  } else if (status === 2) {
    return "Paused";
  } else if (status === 3) {
    return "Completed";
  } else if (status === 4) {
    return "Tokkenz.shop";
  } else if (status === 5) {
    return "Archive";
  } else {
    return "Not Started";
  }
}

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class RecipientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      warning: false,
      actionType: null,
      record: null,
      recipientList: [],
      copiedGameLink: "",
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Sent At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => {
        return (<span>{moment(createdAt).format('MM-DD-YYYY hh:mm a')}</span>)
      }
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        return (this.renderStatus(status))
      },
      filters: [
        {
          text: 'Not Started',
          value: 0,
        },
        {
          text: 'In Progress',
          value: 1,
        },
        {
          text: 'Paused',
          value: 2,
        },
        {
          text: 'Completed',
          value: 3,
        },
        {
          text: 'Tokkenz.shop',
          value: 4,
        },
        {
          text: 'Archive',
          value: 5,
        }
      ],
      onFilter: (value, record) => record.status === value
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    }, {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber'
    }, {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      width: 150,
      sorter: (a, b) => a.sender.firstName?.length - b.sender.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (sender) => {
        return (<span>{sender?.firstName} {sender?.lastName}</span>)
      }
    }, {
      title: 'Game Name',
      dataIndex: 'gameInventory',
      key: 'gameInventory',
      width: 150,
      render: (gameInventory) => {
        return (<span>{gameInventory?.gameName}</span>)
      }
    }, {
      title: 'Greeting Package',
      dataIndex: 'greetingPackage',
      key: 'greetingPackage',
      width: 200,
      render: (greetingPackage) => {
        return (<span>{greetingPackage?.name}</span>)
      }
    }, {
      title: 'Cash Gift',
      dataIndex: 'cashGift',
      key: 'cashGift',
      width: 100,
      render: (cashGift) => {
        return (<span>${cashGift}</span>)
      }
    }, {
      title: 'Game#',
      dataIndex: 'gameNumber',
      key: 'gameNumber'
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (record) => (
        <span>
          <span title="Copy Game Link"
            className="gx-link"
            onClick={(e) => { this.onCopyGameLink(record, e) }}>
            <FaCopy className="icon-size" />
          </span>
          <Divider type="vertical" />
          <span title="Archive"
            className="gx-link delete-color"
            onClick={(e) => { this.onArchive('Archive', record, e); }}>
            <FaArchive className="icon-size" />
          </span>
          <Divider type="vertical" />
          <span title="Reset"
            className="gx-link"
            onClick={(e) => { this.onArchive('Reset', record, e); }}>
            <FaRedo className="icon-size" />
          </span>
        </span>
      ),
    }];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      this.props.getRecipientList(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: pagination.current,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchRecipient(inputJson, authUser.token);
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.sendDemoData) {
      if (nextProps.sendDemoData.messageId === 204) {
        return {
          recipientList: nextProps.sendDemoData.lists.data,
          pagination: {
            current: nextProps.sendDemoData.lists.current,
            pageSize: nextProps.sendDemoData.lists.pageSize,
            total: nextProps.sendDemoData.lists.totalCount
          }
        }
      } else if (nextProps.sendDemoData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.sendDemoData.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetSendDemo();
      } else if (nextProps.sendDemoData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.sendDemoData.alertMessage
        });
        nextProps.resetSendDemo();
      }
      return true;
    }
    return null;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  onCopyGameLink = (record, e) => {
    e.preventDefault();
    let gameLink = record.gameInventory.serverURL + '?id=' + record._id + '&&gn=' + record.gameNumber + '&&token=' + gameDemoToken;
    copy(gameLink);
    notification['success']({
      message: 'Success',
      description: "Game link copied to clipboard."
    });
  }

  onArchive = (type, record, e) => {
    e.preventDefault();
    this.setState({
      warning: true,
      record: record,
      actionType: type
    })
  }

  archiveRecord(record, type) {
    this.setState({
      warning: false
    }, () => {
      let data = {
        _id: record._id,
        status: (type === 'Archive' ? 5 : 0)
      }
      this.props.updateDemoStatus(data);
    })
  };

  onCancelArchive = () => {
    this.setState({
      warning: false
    })
  };

  renderArchivePopup(type) {
    const { warning, record } = this.state;
    return (
      <SweetAlert
        show={warning}
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={type}
        onConfirm={() => this.archiveRecord(record, type)}
        onCancel={this.onCancelArchive}
      >
        Are you sure you want to {type} game demo ({record.gameName}: {record.gameNumber}) sent to {record.firstName} {record.lastName}?
      </SweetAlert>
    )
  }

  renderStatus(status) {
    if (status === 1) {
      return (
        <Tag color="processing">In Progress</Tag>
      )
    } else if (status === 2) {
      return (
        <Tag color="warning">Paused</Tag>
      )
    } else if (status === 3) {
      return (
        <Tag color="success">Completed</Tag>
      )
    } else if (status === 4) {
      return (
        <Tag color="geekblue">Tokkenz.shop</Tag>
      )
    } else if (status === 5) {
      return (
        <Tag color="gold">Archive</Tag>
      )
    } else {
      return (
        <Tag color="default">Not Started</Tag>
      )
    }
  }

  render() {
    const { isLoading } = this.props.sendDemoData;
    const { warning, actionType, pagination, recipientList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/send-demo" className="gx-link">Send Demo</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Demo Recipients</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Demo Recipients" />
        <Row className="row-margin2">
          <Col>
            <Search
              placeholder="Search by First Name or Last Name or Email or Mobile Phone"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={this.onSearch}
            />
          </Col>
        </Row>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={recipientList}
              rowKey={record => record._id}
              exportableProps={{ fields, fileName: "investor-demo-users", showColumnPicker: true }}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={isLoading}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        {warning ? this.renderArchivePopup(actionType) : null}
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    sendDemoData: state.sendDemoData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ getRecipientList, searchRecipient, updateDemoStatus, resetSendDemo }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(RecipientList);
