import {
    DEMO
} from "../../constants/actionTypes";
  
  const INIT_STATE = {
    isLoading: false,
    alertMessage: '',
    messageId: null,
    lists: []
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case DEMO.RESET_DEMO: {
            return {
                ...state,
                isLoading: false,
                alertMessage: '',
                messageId: null
            }
        }
  
        case DEMO.SEND_DEMO_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case DEMO.SEND_DEMO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 200
            }
        }
  
        case DEMO.SEND_DEMO_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case DEMO.LIST_RECIPIENT_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case DEMO.LIST_RECIPIENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 204,
                lists: action.payload
            }
        }
  
        case DEMO.LIST_RECIPIENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case DEMO.UPDATE_DEMO_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case DEMO.UPDATE_DEMO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 200
            }
        }
  
        case DEMO.UPDATE_DEMO_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case DEMO.SEARCH_RECIPIENT_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case DEMO.SEARCH_RECIPIENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 204,
                lists: action.payload
            }
        }
  
        case DEMO.SEARCH_RECIPIENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        default:
            return state;
    }
  }
  