import React from "react";
import { notification, Divider, Modal, Tag, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FaEye, FaFileDownload, FaCcMastercard, FaCreditCard, FaCcDinersClub } from 'react-icons/fa';
import { RiVisaFill } from "react-icons/ri";
import { SiDiscover, SiJcb } from "react-icons/si";
import { GrAmex } from "react-icons/gr";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToolTip from "components/ToolTip";
import Auxiliary from "util/auxiliary";

import {
  listCustomerOrders,
  searchOrderById,
  resetCustomerOrders
} from "./../../../appRedux/actions/customerOrders";

let fields = {
  createdAt: {
    header: "Created At",
    formatter: (_fieldValue, record) => {
      return moment(record?.createdAt).format('MM-DD-YYYY hh:mm a');
    },
  },
  orderId: {
    header: "Order#",
    formatter: (_fieldValue, record) => {
      return record?.orderId
    },
  },
  user: {
    header: "User",
    formatter: (_fieldValue, record) => {
      return (
        record?.user.firstName + " " + record?.user.lastName + "\n" + record?.user?.email + "\n" + record?.user?.dialCode + " " + record?.user?.phone
      );
    },
  },
  items: {
    header: "Games",
    formatter: (_fieldValue, record) => {
      return record?.items.length;
    },
  },
  chargeDetails: {
    header: "Payment Method",
    formatter: (_fieldValue, record) => {
      return ".... " + record?.chargeDetails?.card?.last4 + " (" + (record?.chargeDetails?.card?.brand) + ")";
    },
  },
  total: {
    header: "Total Amount",
    formatter: (_fieldValue, record) => {
      return "$" + record?.total;
    },
  },
  receiptUrl: {
    header: "Receipt",
    formatter: (_fieldValue, record) => {
      return record?.receiptUrl;
    },
  }
};

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

const renderCard = (type) => {
  let avatar = null;
  switch (type) {
    case 'mastercard':
      avatar = <FaCcMastercard size={'28px'} />
      break;
    case 'visa':
      avatar = <RiVisaFill size={'28px'} />
      break;
    case 'amex':
      avatar = <GrAmex size={'28px'} />
      break;
    case 'diners':
      avatar = <FaCcDinersClub size={'28px'} />;
      break;
    case 'jcb':
      avatar = <SiJcb size={'28px'} />
      break;
    case 'discover':
      avatar = <SiDiscover size={'28px'} />
      break;
    default:
      avatar = <FaCreditCard size={'28px'} />
      break;
  }
  return avatar;
}

const gameStatus = (status) => {
  let statusTag;
  switch (status) {
    case 0:
      statusTag = <Tag color="#2db7f5">Personalize</Tag>
      break;
    case 1:
      statusTag = <Tag color="#108ee9">Scheduled</Tag>
      break;
    case 2:
      statusTag = <Tag color="#87d068">Sent</Tag>
      break;
    case 3:
      statusTag = <Tag color="#2db7f5">Personalize</Tag>
      break;
    default:
      statusTag = <Tag color="#2db7f5">Personalize</Tag>
      break;
  }
  return statusTag;
}

class OrdersTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      ordersList: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      previewDetails: false,
      previewDetailsTitle: null,
      previewDetailsData: null,
    }

    this.columns = [{
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => {
        return (<span>{moment(createdAt).format('MM-DD-YYYY hh:mm a')}</span>)
      }
    }, {
      title: 'Order#',
      dataIndex: 'orderId',
      key: 'orderId',
      sorter: (a, b) => a.orderId - b.orderId,
      sortDirections: ['descend', 'ascend'],
    }, {
      title: 'Games',
      dataIndex: 'items',
      key: 'items',
      width: 150,
      sorter: (a, b) => a.items?.length - b.items?.length,
      sortDirections: ['descend', 'ascend'],
      render: (items) => {
        return (<span>{items?.length}</span>)
      }
    }, {
      title: 'Payment Method',
      dataIndex: 'chargeDetails',
      key: 'chargeDetails',
      width: 150,
      render: (chargeDetails) => {
        return (
          <>
            {renderCard(chargeDetails?.card?.brand)}
            <span style={{ marginLeft: '10px' }}>&#183;&#183;&#183;&#183; {chargeDetails?.card?.last4}</span>
          </>
        )
      }
    }, {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      sorter: (a, b) => a.total - b.total,
      render: (total) => {
        return (<span>${total}</span>)
      }
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (record) => (
        <>
          <ToolTip
            text="Receipt"
            child={
              <span className="gx-link" onClick={(e) => { this.onDownloadReceipt(record, e) }}>
                <FaFileDownload className="icon-size" />
              </span>
            }
          />
          <Divider type="vertical" />
          <ToolTip
            text="See Details"
            child={
              <span className="gx-link" onClick={(e) => { this.onPreviewDetails(record); }}>
                <FaEye className="icon-size gx-link" />
              </span>
            }
          />
        </>
      ),
    }];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        user: this.props.userId,
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      this.props.listCustomerOrders(authUser.token, inputJson);
    }
  }

  // onSearch = value => {
  //   if (value) {
  //     const { pagination } = this.state;
  //     let inputJson = {
  //       user: this.props.userId,
  //       searchString: value,
  //       current: pagination.current,
  //       pageSize: pagination.pageSize
  //     }
  //     const { authUser } = this.props.auth;
  //     this.props.searchOrderById(inputJson, authUser.token);
  //   }
  // };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.customerOrdersData) {
      if (nextProps.customerOrdersData.messageId === 200) {
        nextProps.resetCustomerOrders();
        return {
          ordersList: nextProps.customerOrdersData.lists.data,
          pagination: {
            current: nextProps.customerOrdersData.lists.current,
            pageSize: nextProps.customerOrdersData.lists.pageSize,
            total: nextProps.customerOrdersData.lists.totalCount
          }
        }
      } else if (nextProps.customerOrdersData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.customerOrdersData.alertMessage
        });
        nextProps.resetCustomerOrders();
      }
      return true;
    }
    return null;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  onPreviewDetails = (record) => {
    this.setState({
      previewDetailsData: record,
      previewDetails: true,
      previewDetailsTitle: record.orderId
    });
  }

  onDownloadReceipt = (record, e) => {
    e.preventDefault();
    window.open(record.receiptUrl, '_blank');
  }

  handleCancel = () => this.setState({
    previewDetails: false
  });

  render() {
    const { isLoading } = this.props.customerOrdersData;
    const { pagination, ordersList, previewDetails, previewDetailsTitle, previewDetailsData } = this.state;
    return (
      <Auxiliary>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={ordersList}
              rowKey={record => record._id}
              exportableProps={{ fields, fileName: "orders-placed", showColumnPicker: true }}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={isLoading}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Modal
          visible={previewDetails}
          title={"Order # " + previewDetailsTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Total Amount: </span>
              {previewDetailsData ? "$" + previewDetailsData.total : null}
            </Col>
          </Row>
          <Row>
            {previewDetailsData ?
              <Col>
                <span className="lable-style">Payment Method: </span>
                {renderCard(previewDetailsData.chargeDetails?.card?.brand)}
                <span style={{ marginLeft: '10px' }}>&#183;&#183;&#183;&#183; {previewDetailsData.chargeDetails?.card?.last4}</span>
              </Col>
              : null}
            <Col>
              <span className="lable-style">Created At: </span>
              {previewDetailsData ? moment(previewDetailsData.createdAt).format('MM-DD-YYYY hh:mm a') : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Games: </span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <table className="table align-middle">
                <thead className="table-light">
                  <tr>
                    <th>Game#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Cash Gift</th>
                    <th>Status</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {previewDetailsData && previewDetailsData.items.map(item => (
                    <tr className="align-middle" key={item.gameNumber}>
                      <td>{item.gameNumber}</td>
                      <td>{item.gameInventory.gameName}</td>
                      <td>${item.price}</td>
                      <td>${item.cashGift}</td>
                      <td>{gameStatus(item.status)}</td>
                      <td>${item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Receipt: </span>
              {previewDetailsData ? previewDetailsData.receiptUrl : null}
            </Col>
          </Row>
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customerOrdersData: state.customerOrdersData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    listCustomerOrders,
    searchOrderById,
    resetCustomerOrders
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(OrdersTab);
