import React from "react";
import { Divider, notification, Tag, Button, Modal, Breadcrumb } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { FaUserEdit, FaTrashAlt, FaEye, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  listUsers,
  deleteUser,
  resetUser
} from "./../../../appRedux/actions/users";

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      userList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120,
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 120,
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Mobile',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      width: 120
    }, {
      title: 'Notifications',
      dataIndex: 'gameCompleteNotification',
      key: 'gameCompleteNotification',
      sorter: (a, b) => a.gameCompleteNotification - b.gameCompleteNotification,
      sortDirections: ['descend', 'ascend'],
      render: (gameCompleteNotification) => {
        return (<Tag color={gameCompleteNotification ? "processing" : 'warning'}>
          {gameCompleteNotification ? 'Yes' : 'No'}
        </Tag>)
      }
    }, {
      title: 'User Type',
      dataIndex: 'userRole',
      key: 'userRole',
      sorter: (a, b) => a.userRole.length - b.userRole.length,
      sortDirections: ['descend', 'ascend'],
      render: (userRoles) => {
        return (<Tag color="processing">
          {userRoles.map(x => x.name)}
        </Tag>)
      }
    }, {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Deleted' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Deleted',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (record) => (
        <span>
          <span title="Preview" className="gx-link">
            <Link to={`/users/preview/${record._id}`}>
              <FaEye className="icon-size" />
            </Link>
          </span>
          <Divider type="vertical" />
          <span title="Edit" className="gx-link">
            <Link to={`/users/edit/${record._id}`}>
              <FaUserEdit className="icon-size gx-link" />
            </Link>
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.users) {
      if (nextProps.users.messageId === 204) {
        return {
          userList: nextProps.users.lists.data,
          pagination: {
            current: nextProps.users.lists.current,
            pageSize: nextProps.users.lists.pageSize,
            total: nextProps.users.lists.totalCount
          }
        }
      } else if (nextProps.users.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.users.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetUser();
      } else if (nextProps.users.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.users.alertMessage
        });
        nextProps.resetUser();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete ' + record.firstName + ' ' + record.lastName + '?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteUser(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.firstName + ' ' + record.lastName + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteUser(authUser.token, data);
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listUsers(authUser.token, inputJson);
    }
  }

  render() {
    const { loader } = this.props.users;
    const { pagination, userList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Users</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Users" navigateTo="/users/add" linkName="Add User" />
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={userList}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={loader}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.users
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listUsers, deleteUser, resetUser }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListUser);
