import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import TermsPage from './view/terms';
import AddTerms from "./terms";
import PrivacyPolicy from './view/policy';
import AddPrivacyPolicy from "./privacyPolicy";
import CookiePolicy from './view/cookie';
import AddCookiePolicy from "./cookiePolicy";
import AcceptableUsePolicy from './view/acceptable';
import AddAcceptableUsePolicy from "./acceptablePolicy";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/terms`} component={TermsPage} />
    <Route path={`${match.url}/add-terms`} component={AddTerms} />
    <Route path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
    <Route path={`${match.url}/add-privacy-policy`} component={AddPrivacyPolicy} />
    <Route path={`${match.url}/cookie-policy`} component={CookiePolicy} />
    <Route path={`${match.url}/add-cookie-policy`} component={AddCookiePolicy} />
    <Route path={`${match.url}/acceptable-use-policy`} component={AcceptableUsePolicy} />
    <Route path={`${match.url}/add-acceptable-use-policy`} component={AddAcceptableUsePolicy} />
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/terms`} />
    </Route>
  </Switch>
);

export default Main;
