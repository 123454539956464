import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Breadcrumb, Card } from "antd";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

class TermsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            termsData: {},
            errorMessage: null
        }
    }
    componentDidMount() {
        this.setState({
            isLoading: true
        }, () => {
            const AuthStr = 'Bearer '.concat(process.env.REACT_APP_API_TOKEN);
            const INSTANCE = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': AuthStr
                }
            });

            let inputJSON = JSON.stringify({
                type: 'Terms'
            });

            INSTANCE.post("legal/get-legal", inputJSON)
                .then(response => {
                    if (response.data.messageId === 200) {
                        this.setState({ isLoading: false, termsData: response.data });
                    } else {
                        this.setState({ isLoading: false, errorMessage: response.data.message });
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false, errorMessage: error.message });
                });
        })
    }

    render() {
        const { isLoading, termsData } = this.state;
        return (
            <Auxiliary>
                <Row className="margin-bottom">
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Terms & Conditions</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <PageHeader title="Terms & Conditions" navigateTo="/legal/add-terms" linkName="Add/Edit"/>
                {isLoading ?
                    <div className="gx-loader-view">
                        <CircularProgress />
                    </div> :
                    <Card>
                        <Row>
                            <Col md="12">
                                {
                                    termsData.data ?
                                        <div dangerouslySetInnerHTML={{ __html: termsData.data.description }} />
                                        : null
                                }
                            </Col>
                        </Row>
                    </Card>
                }
            </Auxiliary >
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}


export default connect(mapStateToProps, null)(TermsPage);