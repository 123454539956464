import React, { Component } from "react";
import { Card, Breadcrumb, notification, Select, Modal, Upload } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from "moment";
import { Formik } from 'formik';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField";

import {
  addGameInventory,
  updateGameInventory,
  resetGameInventory
} from "../../../../appRedux/actions/gameCatalog/gameInventory";
import {
  getAllGameSubCategory
} from "../../../../appRedux/actions/gameCatalog/gameSubCategory";
import {
  getALLGameCategoryTags
} from "../../../../appRedux/actions/gameCatalog/gameCategoryTag";
import {
  getAllSpecialTags
} from "../../../../appRedux/actions/gameCatalog/specialTag";
import {
  getAllGameInfoTags
} from "../../../../appRedux/actions/gameCatalog/infoTag";
import {
  getAllGameSkins
} from "../../../../appRedux/actions/gameTemplate";

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const GameCatalogSchema = Yup.object().shape({
  gameName: Yup.string().required('Required').max(50, 'Game name must be at most 50 characters'),
  registeredUserPrice: Yup.number().positive('Price must be a positive number.').required('Required'),
  subscriberPrice: Yup.number().positive('Price must be a positive number.').required('Required'),
  description: Yup.string().required('Required').max(500, 'Description must be at most 500 characters'),
  seoKeywords: Yup.string().required('Required').max(160, 'Description must be at most 160 characters'),
  gameCategory: Yup.string().required('Required'),
  gameSkin: Yup.string().required('Required'),
  serverURL: Yup.string().required('Required'),
  registeredUserSpecial: Yup.string(),
  subscriberSpecial: Yup.string(),
  gameCover: Yup.mixed()
    .test("required", "Required", (file) => {
      if (file) return true;
      return false;
    })
    .test("fileSize", "Game thumbnail must be less than 1 MB.", (file) => {
      return file !== 'Cover' ? file && file.size <= 1000000 : true;
    })
    .test("fileFormat", "Game thumbnail has unsupported format.", (file) => {
      // return file && SUPPORTED_FORMATS.includes(file.type);
      return file !== 'Cover' ? file && SUPPORTED_FORMATS.includes(file.type) : true;
    }),
  gameLogoImage: Yup.mixed()
    .test("required", "Required", (file) => {
      if (file) return true;
      return false;
    })
    .test("fileSize", "Game logo must be less than 1 MB.", (file) => {
      // return file && file.size <= 1000000;
      return file !== 'Logo' ? file && file.size <= 1000000 : true;
    })
    .test("fileFormat", "Game logo has unsupported format.", (file) => {
      // return file && SUPPORTED_FORMATS.includes(file.type);
      return file !== 'Logo' ? file && SUPPORTED_FORMATS.includes(file.type) : true;
    }),
  teaserVideo: Yup.mixed()
    .nullable()
    .test("fileSize", "Teaser video size exceeds 25 MB.", (file) => {
      return file ? file.size <= 25000000 : true;
    }),
  teaserThumbnailImage: Yup.mixed()
    .test("required", "Required", (file) => {
      if (file) return true;
      return false;
    })
    .test("fileSize", "Video thumbnail must be less than 1 MB.", (file) => {
      return file !== 'Teaser' ? file && file.size <= 1000000 : true;
    })
    .test("fileFormat", "Video thumbnail has unsupported format.", (file) => {
      // return file && SUPPORTED_FORMATS.includes(file.type);
      return file !== 'Teaser' ? file && SUPPORTED_FORMATS.includes(file.type) : true;
    }),
})

const { Option } = Select;

let self;

class AddGameInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialGameData: {
        gameName: '',
        registeredUserPrice: '',
        subscriberPrice: '',
        description: '',
        seoKeywords: '',
        gameCategory: '',
        gameSkin: '',
        serverURL: '',
        registeredUserSpecial: '',
        subscriberSpecial: '',
        gameCover: null,
        gameLogoImage: null,
        teaserVideo: null,
        teaserThumbnailImage: null,
      },
      editId: null,
      selectedGameSubCategory: [],
      selectedInfoTags: [],
      coverFile: null,
      gameLogoFile: null,
      videoFile: null,
      teaserThumbnailFile: null,
      editGameCover: null,
      editTeaserVideo: null,
      editTeaserThumbnail: null,
      editGameLogo: null,
      uploadingFile: false,
      coverFileError: null,
      gameLogoError: null,
      teaserThumbnailError: null,
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: []
    }
  };

  componentDidMount() {
    self = this;
    const { authUser } = this.props.auth;
    this.props.getAllGameSubCategory(authUser.token);
    this.props.getALLGameCategoryTags(authUser.token);
    this.props.getAllSpecialTags(authUser.token);
    this.props.getAllGameInfoTags(authUser.token);
    this.props.getAllGameSkins(authUser.token);
    if (this.props.match.params.id) {
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("game-inventory/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              editId: this.props.match.params.id,
              initialGameData: {
                gameName: response.data.data.gameName,
                description: response.data.data.description,
                seoKeywords: response.data.data.seoKeywords ? response.data.data.seoKeywords : "",
                gameCategory: response.data.data.gameCategory,
                gameSkin: response.data.data.gameSkin,
                serverURL: response.data.data.serverURL,
                registeredUserPrice: response.data.data.registeredUserPrice,
                subscriberPrice: response.data.data.subscriberPrice,
                registeredUserSpecial: response.data.data.registeredUserSpecial ? response.data.data.registeredUserSpecial : '',
                subscriberSpecial: response.data.data.subscriberSpecial ? response.data.data.subscriberSpecial : '',
                gameCover: 'Cover',
                gameLogoImage: 'Logo',
                teaserThumbnailImage: 'Teaser',
              },
              fileList: response.data.data.gameScreens,
              selectedGameSubCategory: response.data.data.searchTags,
              selectedInfoTags: response.data.data.infoTags,
              editGameCover: response.data.data.coverImage,
              editGameLogo: response.data.data.gameLogo ? response.data.data.gameLogo : null,
              editTeaserVideo: response.data.data.teaserVideo ? response.data.data.teaserVideo : null,
              editTeaserThumbnail: response.data.data.teaserThumbnail ? response.data.data.teaserThumbnail : null
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameInventoryData) {
      if (nextProps.gameInventoryData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameInventoryData.alertMessage
        });
        nextProps.resetGameInventory();
        nextProps.history.goBack();
      } else if (nextProps.gameInventoryData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameInventoryData.alertMessage
        });
        nextProps.resetGameInventory();
      }
      return true;
    }
    return null;
  }

  onSearchTagChange = (value) => {
    this.setState({
      selectedGameSubCategory: value
    })
  }

  onInfoTagChange = (value) => {
    this.setState({
      selectedInfoTags: value
    })
  }

  onFinish = values => {
    const { fileList, selectedGameSubCategory, selectedInfoTags, coverFileError, gameLogoError, teaserThumbnailError } = this.state;
    if (!coverFileError && !gameLogoError && !teaserThumbnailError) {
      // Process Form
      let inputJSON = values;
      inputJSON.fileList = fileList;
      inputJSON.searchTags = selectedGameSubCategory;
      inputJSON.infoTags = selectedInfoTags;
      inputJSON.registeredUserSpecial = values.registeredUserSpecial === "" ? null : values.registeredUserSpecial;
      inputJSON.subscriberSpecial = values.subscriberSpecial === "" ? null : values.subscriberSpecial;
      this.onUploadFile(inputJSON);
      this.setState({ uploadingFile: true });
    } else {
      let newErrors = [];
      if (coverFileError) {
        newErrors.push(coverFileError);
      }
      if (gameLogoError) {
        newErrors.push(gameLogoError);
      }
      if (teaserThumbnailError) {
        newErrors.push(teaserThumbnailError);
      }
      Modal.error({
        title: 'Required Fields',
        centered: true,
        content: (
          <Row>
            <Col sm={12} className="error-message">
              {newErrors.map(function (item, i) {
                return (
                  <p key={i} className="error-message">{i + 1 + ") " + item}</p>
                )
              })}
            </Col>
          </Row>
        )
      });
    }
  };

  async onUploadFile(inputJSON) {
    const { coverFile, gameLogoFile, teaserThumbnailFile, videoFile } = this.state;
    let pathName = inputJSON.gameName.replace(/ /g, "");
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    if (coverFile) {
      let formData = new FormData();
      formData.append('path', 'game-catalog/' + pathName + '/');
      formData.append('image', coverFile);
      const resp = await INSTANCE.post('upload-file/upload', formData);
      if (resp.data.messageId === 200) {
        inputJSON.coverImage = {
          originalName: resp.data.data.originalname,
          mimeType: resp.data.data.mimetype,
          location: resp.data.data.location,
          key: resp.data.data.key
        }
      } else {
        notification['error']({
          message: 'Alert!!',
          description: "Error uploading cover image. Please try again."
        });
        this.setState({ uploadingFile: false });
        return;
      }
    }
    if (gameLogoFile) {
      let formData = new FormData();
      formData.append('path', 'game-catalog/' + pathName + '/');
      formData.append('image', gameLogoFile);
      const resp = await INSTANCE.post('upload-file/upload', formData);
      if (resp.data.messageId === 200) {
        inputJSON.gameLogo = {
          originalName: resp.data.data.originalname,
          mimeType: resp.data.data.mimetype,
          location: resp.data.data.location,
          key: resp.data.data.key
        }
      } else {
        notification['error']({
          message: 'Alert!!',
          description: "Error uploading game logo. Please try again."
        });
        this.setState({ uploadingFile: false });
        return;
      }
    }
    if (teaserThumbnailFile) {
      let formData = new FormData();
      formData.append('path', 'game-catalog/' + pathName + '/');
      formData.append('image', teaserThumbnailFile);
      const resp = await INSTANCE.post('upload-file/upload', formData);
      if (resp.data.messageId === 200) {
        inputJSON.teaserThumbnail = {
          originalName: resp.data.data.originalname,
          mimeType: resp.data.data.mimetype,
          location: resp.data.data.location,
          key: resp.data.data.key
        }
      } else {
        notification['error']({
          message: 'Alert!!',
          description: "Error uploading teaser thumbnail. Please try again."
        });
        this.setState({ uploadingFile: false });
        return;
      }
    }
    if (videoFile) {
      let formData = new FormData();
      formData.append('path', 'game-catalog/' + pathName + '/');
      formData.append('image', videoFile);
      const resp = await INSTANCE.post('upload-file/upload', formData);
      if (resp.data.messageId === 200) {
        inputJSON.teaserVideo = {
          originalName: resp.data.data.originalname,
          mimeType: resp.data.data.mimetype,
          location: resp.data.data.location,
          key: resp.data.data.key
        }
      } else {
        notification['error']({
          message: 'Alert!!',
          description: "Error uploading teaser video. Please try again."
        });
        this.setState({ uploadingFile: false });
        return;
      }
    }
    let game_screens = [];
    this.loopEachGameScreen(authUser.token, inputJSON, game_screens, 0);
  }

  loopEachGameScreen(token, inputJSON, game_screens, gsIdx) {
    if (gsIdx < inputJSON.fileList.length) {
      if (inputJSON.fileList[gsIdx].key) {
        game_screens.push(inputJSON.fileList[gsIdx]);
        gsIdx++;
        this.loopEachGameScreen(token, inputJSON, game_screens, gsIdx);
      } else {
        // New Game Screen
        let formData = new FormData();
        formData.append('image', inputJSON.fileList[gsIdx]);
        let pathName = inputJSON.gameName.replace(/ /g, "");
        formData.append('path', 'game-catalog/' + pathName + '/screens/');
        const { authUser } = this.props.auth;
        const INSTANCE = axios.create({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '.concat(authUser.token)
          }
        });
        INSTANCE.post('upload-file/upload', formData)
          .then((response) => {
            if (response.data.messageId === 200) {
              game_screens.push({
                name: response.data.data.originalname,
                url: response.data.data.location,
                key: response.data.data.key,
                uid: inputJSON.fileList[gsIdx].uid
              })
              gsIdx++;
              this.loopEachGameScreen(token, inputJSON, game_screens, gsIdx);
            } else {
              gsIdx++;
              this.loopEachGameScreen(token, inputJSON, game_screens, gsIdx);
            }
          }).catch((error) => {
            gsIdx++;
            this.loopEachGameScreen(token, inputJSON, game_screens, gsIdx);
          })
      }
    } else {
      inputJSON.gameScreens = game_screens;
      this.onFinishCatalog(token, inputJSON);
    }
  }

  onFinishCatalog(token, inputJSON) {
    const gameSkins = this.props.gameTemplateData.skinLists;
    for (let i = 0; i < gameSkins.length; i++) {
      if (gameSkins[i]._id === inputJSON.gameSkin) {
        inputJSON.inventoryId = gameSkins[i].templateId.templateId + "-" + gameSkins[i].skinId;
      }
      if (i === gameSkins.length - 1) {
        const { editId } = this.state;
        const { authUser } = this.props.auth;
        delete inputJSON.fileList;
        delete inputJSON.gameCover;
        delete inputJSON.gameLogoImage;
        delete inputJSON.teaserThumbnailImage;
        if (editId) {
          inputJSON._id = editId;
          inputJSON.modifiedBy = authUser.user.userId;
          this.props.updateGameInventory(token, inputJSON);
        } else {
          inputJSON.createdBy = authUser.user.userId;
          inputJSON.modifiedBy = authUser.user.userId;
          this.props.addGameInventory(token, inputJSON);
        }
      }
    }
  }

  handleUpload = () => {
    const { fileList, image_types } = this.state;
    if (image_types) {
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('image', file);
      });
      formData.append('path', 'event-images/');
      this.setState({
        uploading: true,
      });

      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      INSTANCE.post('upload-file/upload', formData)
        .then((response) => {
          if (response.data.messageId === 200) {
            let inputJSON = JSON.stringify({
              'image_types': image_types,
              'image': {
                originalName: response.data.data.originalname,
                mimeType: response.data.data.mimetype,
                location: response.data.data.location,
                key: response.data.data.key
              }
            })
            const INSTANCE1 = axios.create({
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(authUser.token)
              }
            });
            INSTANCE1.post('event-images/upload-image-data', inputJSON)
              .then((resp) => {
                if (resp.data.messageId === 200) {
                  this.setState({
                    fileList: [],
                    uploading: false,
                    image_types: null
                  }, () => {
                    notification['success']({
                      message: 'Success',
                      description: resp.data.message
                    });
                  });
                } else {
                  this.setState({
                    uploading: false
                  }, () => {
                    notification['error']({
                      message: 'Alert!!',
                      description: response.data.message
                    });
                  });
                }
              })
              .catch((error) => {
                notification['error']({
                  message: 'Alert!!',
                  description: error
                });
              })
          } else {
            this.setState({
              uploading: false
            }, () => {
              notification['error']({
                message: 'Alert!!',
                description: response.data.message
              });
            });
          }
        })
        .catch((error) => {
          notification['error']({
            message: 'Alert!!',
            description: error
          });
        })
    } else {
      notification['error']({
        message: 'Alert!!',
        description: 'Please select event image type.'
      });
    }
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file]
    }));
    return false;
  }

  onRemove = file => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { isLoading } = this.props.gameInventoryData;
    const searchTags = this.props.gameSubCategoryData.lists;
    let searchTagsList = [];
    if (searchTags) {
      if (searchTags.data) {
        searchTagsList = searchTags.data;
      }
    }
    let gameCategoryTagsList = [];
    const gameCategoryTags = this.props.gameCategoryTagData.lists;
    if (gameCategoryTags) {
      if (gameCategoryTags.data) {
        gameCategoryTagsList = gameCategoryTags.data;
      }
    }
    const specialTags = this.props.gameSpecialTagData.lists;
    let specialTagsList = [];
    if (specialTags) {
      if (specialTags.data) {
        specialTagsList = specialTags.data;
      }
    }
    const infoTags = this.props.gameInfoTagData.lists;
    let infoTagsList = [];
    if (infoTags) {
      if (infoTags.data) {
        infoTagsList = infoTags.data;
      }
    }

    let getSkinList = [];
    const gameSkins = this.props.gameTemplateData;
    if (gameSkins) {
      if (gameSkins.skinLists) {
        getSkinList = gameSkins.skinLists;
      }
    }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const { editId, initialGameData, fileList, previewImage, previewTitle, previewVisible, uploadingFile, selectedGameSubCategory, selectedInfoTags, coverFile, videoFile, gameLogoFile, teaserThumbnailFile, editGameCover, editGameLogo, editTeaserVideo, editTeaserThumbnail } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/game-catalog/inventory" className="gx-link">Game Inventory</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit Game" : "Add Game"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Game" : "Add Game"} />
        {isLoading || uploadingFile ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Row className="row-margin2">
            <Col>
              <Card>
                <Formik
                  validationSchema={GameCatalogSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={initialGameData}>
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Game Name<RequiredField /></Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="gameName"
                            value={values.gameName}
                            onChange={handleChange}
                            isInvalid={!!errors.gameName}
                          />
                          {errors.gameName && touched.gameName ? (
                            <Form.Control.Feedback type="invalid">{errors.gameName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Game Category<RequiredField /></Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="gameCategory"
                            required
                            value={values.gameCategory}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("gameCategory", value);
                            }}
                            isInvalid={!!errors.gameCategory}>
                            <option value={null}>Select Game Category</option>
                            {gameCategoryTagsList.map(item => (
                              <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.gameName && touched.gameName ? (
                            <Form.Control.Feedback type="invalid">{errors.gameName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Registered User Price<RequiredField /></Form.Label>
                          <Form.Control
                            type="number"
                            required
                            name="registeredUserPrice"
                            value={values.registeredUserPrice}
                            onChange={handleChange}
                            isInvalid={!!errors.registeredUserPrice}
                          />
                          {errors.registeredUserPrice && touched.registeredUserPrice ? (
                            <Form.Control.Feedback type="invalid">{errors.registeredUserPrice}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Subscriber Price<RequiredField /></Form.Label>
                          <Form.Control
                            type="number"
                            required
                            name="subscriberPrice"
                            value={values.subscriberPrice}
                            onChange={handleChange}
                            isInvalid={!!errors.subscriberPrice}
                          />
                          {errors.subscriberPrice && touched.subscriberPrice ? (
                            <Form.Control.Feedback type="invalid">{errors.subscriberPrice}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Game Skin<RequiredField /></Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="gameSkin"
                            required
                            value={values.gameSkin}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("gameSkin", value);
                            }}
                            isInvalid={!!errors.gameSkin}>
                            <option value={null}>Select Game Skin</option>
                            {getSkinList.map(item => (
                              <option key={item._id} value={item._id}>{item.title}</option>
                            ))}
                          </Form.Control>
                          {errors.gameSkin && touched.gameSkin ? (
                            <Form.Control.Feedback type="invalid">{errors.gameSkin}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Server URL<RequiredField /></Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="serverURL"
                            value={values.serverURL}
                            onChange={handleChange}
                            isInvalid={!!errors.serverURL}
                          />
                          {errors.serverURL && touched.serverURL ? (
                            <Form.Control.Feedback type="invalid">{errors.serverURL}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Registered User Special</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="registeredUserSpecial"
                            required
                            value={values.registeredUserSpecial}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("registeredUserSpecial", value);
                            }}>
                            <option value={""}>--- No Registered User Special ---</option>
                            {specialTagsList.map(item => (
                              <option key={item._id} value={item._id}>{item.name} ${item.price} ({moment(item.startDate).format('MM-DD-YYYY')} To {moment(item.endDate).format('MM-DD-YYYY')})</option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Label>Subscriber Special</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="subscriberSpecial"
                            required
                            value={values.subscriberSpecial}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("subscriberSpecial", value);
                            }}>
                            <option value={""}>--- No Subscriber Special ---</option>
                            {specialTagsList.map(item => (
                              <option key={item._id} value={item._id}>{item.name} ${item.price} ({moment(item.startDate).format('MM-DD-YYYY')} To {moment(item.endDate).format('MM-DD-YYYY')})</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Description<RequiredField /> <small>(Max 500 characters)</small></Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                            rows="5"
                          />
                          {errors.description && touched.description ? (
                            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Game Sub Category <small>(You can select multiple values.)</small></Form.Label>
                          <Select
                            onChange={this.onSearchTagChange}
                            mode="multiple"
                            allowClear
                            value={selectedGameSubCategory}
                            style={{ width: '100%' }}
                          >
                            {searchTagsList.map(item => (
                              <Option key={item._id} value={item._id}>{item.name}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Form.Label>Info Tags <small>(You can select multiple values.)</small></Form.Label>
                          <Select
                            onChange={this.onInfoTagChange}
                            mode="multiple"
                            allowClear
                            value={selectedInfoTags}
                            style={{ width: '100%' }}
                          >
                            {infoTagsList.map(item => (
                              <Option key={item._id} value={item._id}>{item.name}</Option>
                            ))}
                          </Select>
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>SEO Keywords<RequiredField /> <small>(Max 160 characters)</small></Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            name="seoKeywords"
                            value={values.seoKeywords}
                            onChange={handleChange}
                            isInvalid={!!errors.seoKeywords}
                            rows="5"
                          />
                          {errors.seoKeywords && touched.seoKeywords ? (
                            <Form.Control.Feedback type="invalid">{errors.seoKeywords}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Game Thumbnail<RequiredField /> <small>(Only jpg, jpeg, png up to 1 MB & width 340px & height 192px allowed.)</small></Form.Label>
                          <Form.Control
                            type="file"
                            name="gameCover"
                            accept=".jpg,.jpeg,.png"
                            isInvalid={!!errors.gameCover}
                            onChange={(event) => {
                              let image = event.currentTarget.files[0];
                              setFieldValue("gameCover", image);
                              if (image) {
                                let reader = new FileReader();
                                reader.readAsDataURL(image);
                                reader.onload = function (e) {
                                  let imageObj = new Image();
                                  imageObj.src = e.target.result;
                                  imageObj.onload = function () {
                                    if (this.height > 192 || this.width > 350) {
                                      let newElement = "Game thumbnail width and height are invalid. Allowed dimensions are width 340px & height 192px";
                                      self.setState({
                                        coverFileError: newElement,
                                        coverFile: image
                                      })
                                    } else {
                                      self.setState({
                                        coverFile: image,
                                        coverFileError: null
                                      });
                                    }
                                  };
                                };
                              }
                            }}
                          />
                          {errors.gameCover && touched.gameCover ? (
                            <Form.Control.Feedback type="invalid">{errors.gameCover}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Game Logo<RequiredField /> <small>(Only jpg, jpeg, png up to 1 MB & width 340px & height 192px allowed.)</small></Form.Label>
                          <Form.Control
                            type="file"
                            name="gameLogoImage"
                            accept=".jpg,.jpeg,.png"
                            isInvalid={!!errors.gameLogoImage}
                            onChange={(event) => {
                              let logo = event.currentTarget.files[0];
                              setFieldValue("gameLogoImage", logo);
                              if (logo) {
                                let reader = new FileReader();
                                reader.readAsDataURL(logo);
                                reader.onload = function (e) {
                                  let imageObj = new Image();
                                  imageObj.src = e.target.result;
                                  imageObj.onload = function () {
                                    if (this.height > 192 || this.width > 340) {
                                      let newElement = "Game logo width and height are invalid. Allowed dimensions are width 340px & height 192px";
                                      self.setState({
                                        gameLogoError: newElement,
                                        gameLogoFile: logo
                                      })
                                    } else {
                                      self.setState({
                                        gameLogoFile: logo,
                                        gameLogoError: null
                                      });
                                    }
                                  };
                                };
                              }
                            }}
                          />
                          {errors.gameLogoImage && touched.gameLogoImage ? (
                            <Form.Control.Feedback type="invalid">{errors.gameLogoImage}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          {coverFile && (
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={URL.createObjectURL(coverFile)}
                              alt="Cover"
                            />
                          )}
                          {!coverFile && editGameCover ?
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={editGameCover.location}
                              alt="Cover"
                            /> : null
                          }
                        </Col>
                        <Col>
                          {gameLogoFile && (
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={URL.createObjectURL(gameLogoFile)}
                              alt="Cover"
                            />
                          )}
                          {!gameLogoFile && editGameLogo ?
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={editGameLogo.location}
                              alt="Cover"
                            /> : null
                          }
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Teaser Video <small>(Only mov, mkv, mp4 up to 25 MB allowed.)</small></Form.Label>
                          <Form.Control
                            type="file"
                            accept=".mov,.mkv,.mp4"
                            isInvalid={!!errors.teaserVideo}
                            onChange={(event) => {
                              let video = event.currentTarget.files[0];
                              setFieldValue("teaserVideo", video);
                              if (video) {
                                self.setState({
                                  videoFile: video
                                });
                              }
                            }}
                          />
                          {errors.teaserVideo && touched.teaserVideo ? (
                            <Form.Control.Feedback type="invalid">{errors.teaserVideo}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Video Thumbnail <small>(Only jpg, jpeg, png up to 1 MB & width 340px & height 192px allowed.)</small></Form.Label>
                          <Form.Control
                            type="file"
                            name="teaserThumbnailImage"
                            accept=".jpg,.jpeg,.png"
                            isInvalid={!!errors.teaserThumbnailImage}
                            onChange={(event) => {
                              let image = event.currentTarget.files[0];
                              setFieldValue("teaserThumbnailImage", image);
                              if (image) {
                                let reader = new FileReader();
                                reader.readAsDataURL(image);
                                reader.onload = function (e) {
                                  let imageObj = new Image();
                                  imageObj.src = e.target.result;
                                  imageObj.onload = function () {
                                    if (this.height > 192 || this.width > 350) {
                                      let newElement = "Video thumbnail width and height are invalid. Allowed dimensions are width 340px & height 192px";
                                      self.setState({
                                        teaserThumbnailError: newElement,
                                        teaserThumbnailFile: image
                                      })
                                    } else {
                                      self.setState({
                                        teaserThumbnailFile: image,
                                        teaserThumbnailError: null
                                      });
                                    }
                                  };
                                };
                              }
                            }}
                          />
                          {errors.teaserThumbnailImage && touched.teaserThumbnailImage ? (
                            <Form.Control.Feedback type="invalid">{errors.teaserThumbnailImage}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          {videoFile && (
                            <video
                              width={"340px"}
                              height={'192px'}
                              controls
                              src={URL.createObjectURL(videoFile)}
                            />
                          )}
                          {!videoFile && editTeaserVideo ?
                            <video
                              width={"340px"}
                              height={'192px'}
                              controls
                              src={editTeaserVideo.location}
                            /> : null
                          }
                        </Col>
                        <Col>
                          {teaserThumbnailFile && (
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={URL.createObjectURL(teaserThumbnailFile)}
                              alt="TeaserThumbnail"
                            />
                          )}
                          {!teaserThumbnailFile && editTeaserThumbnail ?
                            <img
                              width={"340px"}
                              height={'192px'}
                              src={editTeaserThumbnail.location}
                              alt="TeaserThumbnail"
                            /> : null
                          }
                        </Col>
                      </Form.Row>
                      <Form.Row className="my-2">
                        <Col>
                          <Form.Label>Game Screens <small>(Only jpg, jpeg, png of size 1 MB each up to 6 images.)</small></Form.Label>
                          <Upload
                            listType="picture-card"
                            onPreview={this.handlePreview}
                            beforeUpload={this.beforeUpload}
                            onRemove={this.onRemove}
                            fileList={fileList}
                            accept="image/png, image/jpeg, image/jpg"
                            multiple
                            maxCount={6}
                          >
                            {uploadButton}
                          </Upload>
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <Button type="submit">
                            {editId ? "Update" : "Submit"}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        }
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameInventoryData: state.gameInventoryData,
    gameSubCategoryData: state.gameSubCategoryData,
    gameCategoryTagData: state.gameCategoryTagData,
    gameSpecialTagData: state.gameSpecialTagData,
    gameInfoTagData: state.gameInfoTagData,
    gameTemplateData: state.gameTemplate
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    addGameInventory,
    updateGameInventory,
    resetGameInventory,
    getAllGameSubCategory,
    getALLGameCategoryTags,
    getAllSpecialTags,
    getAllGameInfoTags,
    getAllGameSkins
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddGameInventory);
