import React, { Component } from "react";
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Card, Modal, Breadcrumb, Divider } from "antd";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "util/auxiliary";

import { FaCloudDownloadAlt, FaEye } from "react-icons/fa";

function MinHeightPlugin(editor) {
  this.editor = editor;
}

MinHeightPlugin.prototype.init = function () {
  this.editor.ui.view.editable.extendTemplate({
    attributes: {
      style: {
        minHeight: '8em'
      }
    }
  });
};

ClassicEditor.builtinPlugins.push(MinHeightPlugin);

class PreviewGameSkin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editId: null,
      templateId: null,
      templateName: null,
      tempId: null,
      gameTemplate: {
        assets: [],
        sprites: [],
        audios: []
      },
      title: '',
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      index: null,
      isLoading: false,
      previewCoverImage: null,
      previewCoverVisible: false,
      previewCoverTitle: null
    }
  };

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  componentDidMount() {
    if (this.props.match.params.edit) {
      this.setState({
        isLoading: true
      })
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      INSTANCE.get(`game-template/get-skin-by-id/${this.props.match.params.edit}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              templateId: this.props.match.params.id,
              templateName: this.props.match.params.name,
              tempId: this.props.match.params.tempId,
              editId: this.props.match.params.edit,
              isLoading: false
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }

  onClickCoverImage = (image) => {
    this.setState({
      previewCoverImage: image.url,
      previewCoverVisible: true,
      previewCoverTitle: image.name
    });
  }

  onDownloadClick = (image, e) => {
    window.open(image.assetFile, '_self', false);
  }

  renderAssetRow() {
    return (
      <Table responsive bordered>
        <thead>
          <tr className="asset-table-title">
            <td>Type of Asset</td>
            <td>Asset Name</td>
            <td>Resolution</td>
            <td>Type of File</td>
            <td>Size of File</td>
            <td>Asset Example</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {this.state.gameTemplate.assets?.map((asset, index) => (
            <tr key={index}>
              <td>{asset.assetType}</td>
              <td>{asset.assetName}</td>
              <td>{asset.resolution}</td>
              <td>{asset.fileType}</td>
              <td>{this.convertFileSize(asset.fileSize)}</td>
              <td>{asset.fileName}</td>
              <td>
                <FaEye className="icon-size gx-link" onClick={this.onPreviewClick.bind(this, asset, "Asset")} />
                <Divider type="vertical" />
                <FaCloudDownloadAlt className="icon-size gx-link" onClick={this.onDownloadClick.bind(this, asset)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderSpriteRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Table responsive bordered key={spriteGroup}>
          <thead>
            <tr className="asset-table-title">
              <td>Sprite Group</td>
              <td>Asset Name</td>
              <td>Resolution</td>
              <td>Type of File</td>
              <td>Size of File</td>
              <td>Asset Example</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {sprites?.map((asset, index) => (
              <tr key={index}>
                <td>{asset.assetType}</td>
                <td>{asset.assetName}</td>
                <td>{asset.resolution}</td>
                <td>{asset.fileType}</td>
                <td>{this.convertFileSize(asset.fileSize)}</td>
                <td>{asset.fileName}</td>
                <td>
                  <Button onClick={this.onPreviewClick.bind(this, asset, "Sprite")}>Preview</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    })
  }

  renderAudioRow() {
    return (
      <Table responsive bordered>
        <thead>
          <tr className="asset-table-title">
            <td>Type of Asset</td>
            <td>Asset Name</td>
            <td>Resolution</td>
            <td>Type of File</td>
            <td>Size of File</td>
            <td>Asset Example</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {this.state.gameTemplate.audios?.map((asset, index) => (
            <tr key={index}>
              <td>{asset.assetType}</td>
              <td>{asset.assetName}</td>
              <td>{asset.resolution}</td>
              <td>{asset.fileType}</td>
              <td>{this.convertFileSize(asset.fileSize)}</td>
              <td>{asset.fileName}</td>
              <td>
                <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}>Preview</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  render() {
    const { isLoading, previewCoverVisible, previewCoverTitle, previewCoverImage, editId, templateId, templateName, tempId, gameTemplate, previewVisible, previewImage, previewTitle } = this.state;
    return (
      <Auxiliary>
        {isLoading ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/game-template/list" className="gx-link">Game Templates</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/game-template/template-skins/${templateId}/${templateName}/${tempId}`} className="gx-link">{templateName}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? gameTemplate.title : "Preview Game Template Skin"}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className="text-right" xs="3">
                <a href={`/game-template/template-skins/${templateId}/${templateName}/${tempId}`}>{"< Back"}</a>
              </Col>
            </Row>
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      title: gameTemplate.title,
                      gameType: gameTemplate.gameType,
                      shortDescription: gameTemplate.shortDescription,
                      description: gameTemplate.description
                    }}>
                    {({
                      handleChange,
                      setFieldValue,
                      values,
                    }) => (
                      <Form noValidate>
                        <Form.Row className="formrow">
                          <Col>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              disabled
                              name="title"
                              value={values.title || ''}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col>
                            <Form.Label>Game Type</Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              disabled
                              name="gameType"
                              required
                              value={values.gameType || ''}
                              onChange={e => {
                                const { value } = e.target;
                                setFieldValue("gameType", value);
                              }}>
                              <option value={null}>Select Game Type</option>
                              <option value="2D">2D</option>
                              <option value="3D">3D</option>
                              <option value="AR">AR</option>
                              <option value="VR">VR</option>
                            </Form.Control>
                          </Col>
                        </Form.Row>

                        <Form.Row className="formrow">
                          <Col>
                            <Form.Label>Short Description</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              disabled
                              name="shortDescription"
                              value={values.shortDescription || ''}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Form.Label>Cover Image</Form.Label>
                            {gameTemplate && gameTemplate.coverImage ? <p className="form-control gx-link" title="Click to Preview" onClick={() => this.onClickCoverImage(gameTemplate.coverImage)} style={{backgroundColor: '#e9ecef'}}>{gameTemplate.coverImage.name}</p> : null }
                          </Col>
                        </Form.Row>
                        <Form.Row className="formrow">
                          <Col>
                            <Form.Group>
                              <Form.Label>Game Story</Form.Label>
                              <CKEditor
                                name="description"
                                disabled
                                editor={ClassicEditor}
                                data={values.description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue("description", data);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Form.Label>Assets</Form.Label>
                            {this.renderAssetRow()}
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Form.Label>Sprites</Form.Label>
                            {this.renderSpriteRow()}
                          </Col>
                        </Form.Row>

                        <Form.Row className="btnrow">
                          <Col>
                            <Form.Label>Audio</Form.Label>
                            {this.renderAudioRow()}
                          </Col>
                        </Form.Row>
                      </Form>
                    )}
                  </Formik>
                </Card>
              </Col>
            </Row>
          </>
        }
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => this.setState({ previewVisible: false })}
        >

          {this.state.title === "Audio" ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
        <Modal
          visible={previewCoverVisible}
          title={previewCoverTitle}
          footer={null}
          onCancel={() => this.setState({ previewCoverVisible: false })}
        >
          <img alt={previewCoverTitle} style={{ width: '100%' }} src={previewCoverImage} />
        </Modal>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(withRouter(PreviewGameSkin));