import React, { Component } from "react";
import {
  Breadcrumb,
  notification,
  Modal,
  Tag,
} from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-bootstrap";
import { FaTrashAlt, FaTrashRestoreAlt } from "react-icons/fa";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  listCoupon,
  deleteCoupon,
  resetCoupon
} from "../../../appRedux/actions/coupons";

class ListCoupons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      couponsDataList: []
    };

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Coupon",
        dataIndex: "id",
        key: "id",
        width: 100,
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Max Redemptions",
        dataIndex: "max_redemptions",
        key: "max_redemptions",
        width: 100,
        sorter: (a, b) => a.max_redemptions.length - b.max_redemptions.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amount Off",
        dataIndex: "amount_off",
        key: "amount_off",
        sorter: (a, b) => a.amount_off.length - b.amount_off.length,
        sortDirections: ["descend", "ascend"],
        render: (amount_off) => {
          if (amount_off) {
            return (
              <span>$ {(amount_off/100).toFixed(2)}</span>
            )
          } else {
            return null;
          }
        }
      },
      {
        title: "Percent Off",
        dataIndex: "percent_off",
        key: "percent_off",
        sorter: (a, b) => a.percent_off.length - b.percent_off.length,
        sortDirections: ["descend", "ascend"],
        render: (percent_off) => {
          if (percent_off) {
            return (
              <span>{percent_off} %</span>
            )
          } else {
            return null;
          }
        }
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
        sorter: (a, b) => a.duration.length - b.duration.length,
        sortDirections: ["descend", "ascend"],
        render: (duration) => {
          return <span>{duration}</span>;
        },
      },
      {
        title: "Duration Months",
        dataIndex: "duration_in_months",
        key: "duration_in_months",
        sorter: (a, b) => a.duration_in_months.length - b.duration_in_months.length,
        sortDirections: ["descend", "ascend"],
        render: (duration_in_months) => {
          if (duration_in_months) {
            return (
              <span>{duration_in_months} months</span>
            )
          } else {
            return null;
          }
        }
      },
      {
        title: "Valid",
        dataIndex: "valid",
        key: "valid",
        sorter: (a, b) => a.valid - b.valid,
        sortDirections: ["descend", "ascend"],
        render: (valid) => {
          return (
            <Tag color={!valid ? "warning" : "processing"}>
              {!valid ? "Not Valid" : "Valid"}
            </Tag>
          );
        }
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
        sorter: (a, b) => moment(a.created * 1000).unix() - moment(b.created * 1000).unix(),
        sortDirections: ["descend", "ascend"],
        render: (created) => {
          return <span>{moment(created * 1000).format("MM-DD-YYYY")}</span>;
        },
      },
      {
        title: "Action",
        key: "action",
        width: 100,
        render: (text, record) => (
          <span>
            {!record.isDeleted ? (
              <span
                title="Delete"
                className="gx-link delete-color"
                onClick={(e) => {
                  this.onConfirmDelete(record);
                }}
              >
                <FaTrashAlt className="icon-size" />
              </span>
            ) : (
              <span
                title="Restore"
                className="gx-link delete-color"
                onClick={(e) => {
                  this.onConfirmRestore(record);
                }}
              >
                <FaTrashRestoreAlt className="icon-size" />
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.fetch();
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.couponsData) {
      if (nextProps.couponsData.messageId === 204) {
        return {
          couponsDataList: nextProps.couponsData.lists.data
        };
      } else if (nextProps.couponsData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.couponsData.alertMessage,
        });
        state.currentInstance.fetch();
        nextProps.resetCoupon();
      } else if (nextProps.couponsData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.couponsData.alertMessage,
        });
        nextProps.resetCoupon();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState(
      {
        record: record,
      },
      () => {
        Modal.confirm({
          title: "Delete",
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: "Are you sure you want to delete " + record.id + "?",
          okText: "Delete",
          okType: "danger",
          cancelText: "Cancel",
          onOk: this.onDeleteRecord,
        });
      }
    );
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      couponId: record.id
    };
    this.props.deleteCoupon(authUser.token, data);
  };

  onConfirmRestore(record) {
    this.setState(
      {
        record: record,
      },
      () => {
        Modal.confirm({
          title: "Restore",
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: "Are you sure you want to restore " + record.name + "?",
          okText: "Restore",
          okType: "danger",
          cancelText: "Cancel",
          onOk: this.onRestoreRecord,
        });
      }
    );
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false,
    };
    this.props.deleteCoupon(authUser.token, data);
  };

  handleTableChange = (filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = () => {
    const { authUser } = this.props.auth;
    if (authUser) {
      this.props.listCoupon(authUser.token);
    }
  };

  onChange = (e) => {
    if (e.type === "click") {
      this.fetch();
    }
  };

  render() {
    const { loader } = this.props.couponsData;
    const { couponsDataList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Coupons</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader
          title="Coupons"
          navigateTo="/coupons/add"
          linkName="Add Coupon"
        />
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={couponsDataList}
              rowKey={(record) => record.id}
              loading={loader}
              size={"middle"}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    couponsData: state.couponsData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      listCoupon,
      deleteCoupon,
      resetCoupon
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(ListCoupons);
