import React from "react";

import Widget from "components/Widget/index";
import EventItem from "./EventItem";

const Events = (props) => {
  if (props.userData) {
  const {gameTemplates} = props.userData;
  return (
    <Widget styleName="gx-card-profile">
      <div className="ant-card-head">
        <span className="ant-card-head-title gx-mb-1">Tokkenz MoneyHunt Games</span>
      </div>
      <div className="gx-pt-md-3">
        {gameTemplates.map((data, index) =>
          <EventItem key={index} data={data}/>
        )}
      </div>
    </Widget>
    );
  }
  return null;
}

export default Events;
