import React, { Component } from "react";
import { Button, Card, notification, Modal } from "antd";
import { Row, Col, Form, Table } from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";

import PageHeader from "components/PageHeader";

class GigApproveForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      gameTemplate: {},
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      rejectReason: '',
      managerName: '',
      approvedBy: '',
      approvedDate: '',
      managingContributor: '',
      title: '',
      showApproveConfirm: false,
      showRejectConfirm: false
    }

    this.history = this.props.history;

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      INSTANCE.get(`game-template/get-gig-history-by-id/${this.props.match.params.id}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  onFinish = values => { };

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }
  handleCancel = () => this.setState({ previewVisible: false });



  approveGig = () => {
    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: gameTemplate._id, adminId: authUser.user.userId };
    INSTANCE.post(`game-template/admin-Approved-gig`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          this.history.goBack();
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
      })
  }

  OnApproveGig = () => {
    this.setState({ ...this.state, showApproveConfirm: !this.state.showApproveConfirm });
  }

  rejectGig = () => {
    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: gameTemplate._id, adminId: authUser.user.userId, rejectReason: this.state.rejectReason };
    INSTANCE.post(`game-template/admin-Rejected-gig`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          this.history.goBack();
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
      })
  }
  onRejectGig = () => {
    this.setState({ ...this.state, showRejectConfirm: !this.state.showRejectConfirm });
  }


  renderAssetRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.assets ? this.state.gameTemplate.assets.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Asset")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  renderSpritesRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Row className="row-margin2">
          <Col>
            <Table responsive bordered key={spriteGroup}>
            <thead>
              <tr className="asset-table-title">
                <td>Sprite Group</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {sprites ? sprites.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Sprite")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
      )
    })
  }

  renderAudiosRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.audios ? this.state.gameTemplate.audios.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  render() {
    const { gameTemplate, previewVisible, previewImage, previewTitle, managerName, managingContributor, approvedBy, approvedDate } = this.state;
    return (
      <Card>
        <PageHeader title="Templates GIG Acceptance" />
        <Form name="gameTemplateForm">
          <Row className="row-margin2">
            <Col xs lg="6">
              <Table borderless>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Form.Label>Game Template Name</Form.Label>
                      <Form.Control placeholder="Game Template Name" readOnly value={gameTemplate.title || ''} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Label>Template ID#</Form.Label>
                      <Form.Control placeholder="Template ID#" readOnly value={gameTemplate._id || ''} />
                    </td>
                    <td>
                      <Form.Label>New Skin ID#</Form.Label>
                      <Form.Control placeholder="New Skin ID#" />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Form.Label>Managing Contributor Name</Form.Label>
                      <Form.Control
                        placeholder='Managing Contributor Name'
                        value={managingContributor || ''}
                        onChange={(e) => { this.setState({ managingContributor: e.target.value }) }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <Form.Label>Manager's Name</Form.Label>
                      <Form.Control
                        as="select"
                        value={managerName || ''}
                        onChange={(e) => { this.setState({ managerName: e.target.value }) }}
                      >
                        <option value="">Manager Name</option>
                        <option value="Sherry">Sherry Carani</option>
                        <option value="David">David</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Label>Approved By</Form.Label>
                      <Form.Control
                        placeholder='Approved By'
                        value={approvedBy || ''}
                        onChange={(e) => { this.setState({ approvedBy: e.target.value }) }}
                      />
                    </td>
                    <td>
                      <Form.Label>Approved Date</Form.Label>
                      <Form.Control
                        placeholder='Approved Date'
                        value={approvedDate || ''}
                        onChange={(e) => { this.setState({ approvedDate: e.target.value }) }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">ASSETS</span>
            </Col>
          </Row>
          {this.renderAssetRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">SPRITES</span>
            </Col>
          </Row>
          {this.renderSpritesRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">AUDIO (Optional)</span>
            </Col>
          </Row>
          {this.renderAudiosRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">Game Graphic Teasers (Optional)</span>
              <span style={{ 'marginLeft': 20 }}>
                <Form.Check name="game_teaser1" inline type="radio" label="Yes" />
                <Form.Check name="game_teaser1" inline type="radio" label="No" />
              </span>
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="game-teaser-div" key={index}>Game Teaser {index + 1}</div>
              ))}
            </Col>
          </Row>
          <Row className="row-margin2" style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Modal
              visible={this.state.showRejectConfirm}
              title="Reject Approval"
              okText="Reject"
              okType="danger"
              onOk={() => {
                if(this.state.rejectReason === '') {
                  notification['error']({
                    message: 'Alert!!',
                    description: "Please Enter The Reason To Reject"
                  });
                  return;
                }
                this.rejectGig();
                this.onRejectGig();
              }}
              onCancel={() => { this.onRejectGig() }}
              style={{ zIndex: 2600 }}
            >
              <Form>
                <Form.Control
                  as="textarea"
                  rows="6"
                  placeholder="Reason To Reject The Gig"
                  value={this.state.rejectReason}
                  onChange={e => this.setState({
                    ...this.state,
                    rejectReason: e.target.value
                  })}
                />
              </Form>
            </Modal>
            <Modal
              visible={this.state.showApproveConfirm}
              title="Accept Approval"
              okText="Approve"
              okType="primary"
              closable={false}
              onOk={() => {
                this.approveGig();
                this.OnApproveGig()
              }}
              onCancel={() => this.OnApproveGig()}
              style={{ zIndex: 2600 }}
            >
              <h4>Are You Sure You Want To Approve?</h4>
            </Modal>
            <Col xs lg="2">
              <Button className="reject-button" onClick={() => { this.onRejectGig() }}>
                Reject
              </Button>
            </Col>
            <Col xs lg="2">
              <Button className="submit-button" onClick={() => { this.OnApproveGig() }}>
                Approve
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          {this.state.title === "Audio" ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(GigApproveForm));
