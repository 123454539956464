import React from "react";
import { Select, DatePicker, Modal, Table } from "antd";
import { Row, Col } from 'react-bootstrap';
import { FaUserAlt, FaEye } from 'react-icons/fa';
import moment from 'moment';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";
import StatsCard from "components/Widgets/StatsCard";

import {
  getAppUserStep1, getAppUserStep2, getAppUserStep3
} from "./../../../appRedux/actions/reports";

const { Option } = Select;
const { RangePicker } = DatePicker;

class AppUsersReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resultShowing: "Showing all recods for App Users.",
      showDateRange: false,
      showUsersData: false,
      lists: null,
      type: null
    }

    this.columns = [{
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address) => { return (<span>{address && address.line1 ? address.line1 : null}</span>) }
    }, {
      title: 'City',
      dataIndex: 'address',
      key: 'city',
      render: (address) => { return (<span>{address && address.city ? address.city : null}</span>) }
    }, {
      title: 'State',
      dataIndex: 'address',
      key: 'state',
      render: (address) => { return (<span>{address && address.state ? address.state : null}</span>) }
    }, {
      title: 'Zip Code',
      dataIndex: 'address',
      key: 'zipCode',
      render: (address) => { return (<span>{address && address.zipCode ? address.zipCode : null}</span>) }
    }, {
      title: 'Country',
      dataIndex: 'address',
      key: 'country',
      render: (address) => { return (<span>{address && address.country ? address.country : null}</span>) }
    }, {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => { return (<span>{moment(createdAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      width: 50,
      render: (text, record) => (
        <span>
          <span title="Preview" className="gx-link">
            <Link to={`/customers/preview/${record._id}`}>
              <FaEye className="icon-size" />
            </Link>
          </span>
        </span>
      ),
    }]

  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {
      let inputJSON = {};
      this.callApis(inputJSON);
    }
  }

  callApis(inputJSON) {
    const { authUser } = this.props.auth;
    if (authUser) {
      this.props.getAppUserStep1(inputJSON, authUser.token);
      this.props.getAppUserStep2(inputJSON, authUser.token);
      this.props.getAppUserStep3(inputJSON, authUser.token);
    }
  }

  onTypeSelect(value) {
    let date = new Date();
    if (value === 'All') {
      let inputJSON = {};
      this.callApis(inputJSON);
      this.setState({
        resultShowing: "Showing all recods for App Users."
      })
    }

    if (value === 'Today') {
      let inputJSON = {
        startDate: date,
        endDate: date
      };
      this.callApis(inputJSON);
      this.setState({
        showUsersData: false,
        resultShowing: "Showing result for Today: " + moment(date).format("MMM-DD-YYYY")
      })
    }

    if (value === 'Yesterday') {
      let yesterday = date.setDate(date.getDate() - 1);
      let inputJSON = {
        startDate: yesterday,
        endDate: yesterday
      };
      this.setState({
        showUsersData: false,
        resultShowing: "Showing result for Yesterday: " + moment(yesterday).format("MMM-DD-YYYY")
      })
      this.callApis(inputJSON);
    }

    if (value === 'Past 7 Days') {
      let pastSevenDay = date.setDate(date.getDate() - 7);
      let inputJSON = {
        startDate: pastSevenDay,
        endDate: new Date()
      };
      this.setState({
        showUsersData: false,
        resultShowing: "Showing result for Past 7 Days: " + moment(pastSevenDay).format("MMM-DD-YYYY") + " - " + moment(new Date()).format("MMM-DD-YYYY")
      })
      this.callApis(inputJSON);
    }

    if(value === 'Past Month'){
      let prevMonthLastDate = new Date(date.getFullYear(), date.getMonth(), 0);
      let prevMonthFirstDate = new Date(date.getFullYear() - (date.getMonth() > 0 ? 0 : 1), (date.getMonth() - 1 + 12) % 12, 1);
      let inputJSON = {
        startDate: prevMonthFirstDate,
        endDate: prevMonthLastDate
      };
      this.setState({
        showUsersData: false,
        resultShowing: "Showing result for Past 7 Days: " + moment(prevMonthFirstDate).format("MMM-DD-YYYY") + " - " + moment(prevMonthLastDate).format("MMM-DD-YYYY")
      })
      this.callApis(inputJSON);
    }

    if(value === 'Custom'){
      this.setState({
        showUsersData: false,
        showDateRange: true
      })
    }
  }

  handleCancel = () => this.setState({ showDateRange: false });

  onCustomDateSelect(dates){
    let inputJSON = {
      startDate: dates[0]._d,
      endDate: dates[1]._d
    };
    this.setState({
      showUsersData: false,
      showDateRange: false,
      resultShowing: "Showing result for Custom Dates: " + moment(dates[0]._d).format("MMM-DD-YYYY") + " - " + moment(dates[1]._d).format("MMM-DD-YYYY")
    })
    this.callApis(inputJSON);
  }

  renderDateRangePicker(){
    return(
      <RangePicker style={{ width: '100%' }} format={'MM-DD-YYYY'} onChange={(value) => this.onCustomDateSelect(value)}/>
    )
  }

  showUserList(type){
    if(type === 'Step1'){
      const { appUserStep1 } = this.props.reports;
      this.setState({
        type: 'Step1',
        showUsersData: true,
        lists: appUserStep1.data
      })
    }
    if(type === 'Step2'){
      const { appUserStep2 } = this.props.reports;
      this.setState({
        type: 'Step2',
        showUsersData: true,
        lists: appUserStep2.data
      })
    }
    if(type === 'Step3'){
      const { appUserStep3 } = this.props.reports;
      this.setState({
        type: 'Step3',
        showUsersData: true,
        lists: appUserStep3.data
      })
    }
  }

  renderPeopleCompleteStep1() {
    const { appUserStep1 } = this.props.reports;
    return (
      <Col xl={3} lg={3} md={12} sm={3} xs={3} className="gx-col-full">
        <span className="gx-link" onClick={() => this.showUserList('Step1')}>
          <StatsCard>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-lg-4 gx-mr-3">
                <FaUserAlt style={{ fontSize: "45px", color: '#880061' }} />
              </div>

              <div className="gx-media-body">
                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{appUserStep1.data.length}</h1>
                <p className="gx-text-grey gx-mb-0">Completed Step 1</p>
              </div>
            </div>
          </StatsCard>
        </span>
      </Col>
    )
  }

  renderPeopleCompleteStep2() {
    const { appUserStep2 } = this.props.reports;
    return (
      <Col xl={3} lg={3} md={12} sm={3} xs={3} className="gx-col-full">
        <span className="gx-link" onClick={() => this.showUserList('Step2')}>
          <StatsCard>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-lg-4 gx-mr-3">
                <FaUserAlt style={{ fontSize: "45px", color: '#b1006a' }} />
              </div>

              <div className="gx-media-body">
                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{appUserStep2.data.length}</h1>
                <p className="gx-text-grey gx-mb-0">Completed Step 2</p>
              </div>
            </div>
          </StatsCard>
        </span>
      </Col>
    )
  }

  renderPeopleCompleteStep3() {
    const { appUserStep3 } = this.props.reports;
    return (
      <Col xl={3} lg={3} md={12} sm={3} xs={3} className="gx-col-full">
        <span className="gx-link" onClick={() => this.showUserList('Step3')}>
          <StatsCard>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-lg-4 gx-mr-3">
                <FaUserAlt style={{ fontSize: "45px", color: '#536DFE' }} />
              </div>

              <div className="gx-media-body">
                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{appUserStep3.data.length}</h1>
                <p className="gx-text-grey gx-mb-0">Completed Step 3</p>
              </div>
            </div>
          </StatsCard>
        </span>
      </Col>
    )
  }

  render() {
    const { loader, appUserStep1, appUserStep2, appUserStep3 } = this.props.reports;
    const { resultShowing, showDateRange, showUsersData, lists, type } = this.state;
    return (
      <Auxiliary>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <Row className="row-margin2" >
              <Col xs={12}>
                <Select style={{ width: 200 }} placeholder="Filter By Date" onSelect={(value) => this.onTypeSelect(value)}>
                  <Option value="Today">Today</Option>
                  <Option value="Yesterday">Yesterday</Option>
                  <Option value="Past 7 Days">Past 7 Days</Option>
                  <Option value="Past Month">Past Month</Option>
                  <Option value="Custom">Custom</Option>
                  <Option value={'All'}>Show All Records</Option>
                </Select>
              </Col>
            </Row>
            <Row className="row-margin2" >
              <Col xs={12}>
                <h5>{resultShowing ? resultShowing : null}</h5>
              </Col>
            </Row>
            <Row>
              {appUserStep1 ? this.renderPeopleCompleteStep1() : null}
              {appUserStep2 ? this.renderPeopleCompleteStep2() : null}
              {appUserStep3 ? this.renderPeopleCompleteStep3() : null}
            </Row>
            {showUsersData ? 
              <> 
              <PageHeader title={"Users Completed " + type}/>
              <Row className="row-margin2">
                <Col>
                  <Table
                    className="gx-table-responsive"
                    columns={this.columns}
                    dataSource={lists}
                    rowKey={record => record._id}
                  />
                </Col>
              </Row>
              </> : null
            }
          </>
        }
        <Modal
          visible={showDateRange}
          title={"Select Custom Date"}
          footer={null}
          onCancel={this.handleCancel}
        >
          {this.renderDateRangePicker()}
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    dashboard: state.dashboard,
    reports: state.reports
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ getAppUserStep1, getAppUserStep2, getAppUserStep3 }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AppUsersReport);
