import React from "react";
import {Layout} from "antd";
import {useRouteMatch} from "react-router-dom";

import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import {footerText} from "util/config";
import App from "routes/index";

const {Content, Footer} = Layout;

const MainApp = () => {

  const match = useRouteMatch();
  const getNavStyles = () => {
    return <Topbar/>;
  };

  const getYear = () => {
    const d = new Date();
    let year = d.getFullYear();
    return year;
  }

  return (
    <Layout className="gx-app-layout">
      <Sidebar/>
      <Layout>
        {getNavStyles()}
        <Content className="gx-layout-content">
          <App match={match}/>
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText} {getYear()}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

