import { MARKETING } from "../../../constants/actionTypes";

const INIT_STATE = {
  isLoading: false,
  alertMessage: "",
  messageId: null,
  lists: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MARKETING.RESET_SLIDE: {
      return {
        ...state,
        isLoading: false,
        alertMessage: "",
        messageId: null,
      };
    }

    case MARKETING.ADD_SLIDE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MARKETING.ADD_SLIDE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: action.payload.messageId,
      };
    }

    case MARKETING.ADD_SLIDE_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case MARKETING.LIST_SLIDE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MARKETING.LIST_SLIDE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageId: 204,
        lists: action.payload,
      };
    }

    case MARKETING.LIST_SLIDE_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case MARKETING.GET_ALL_SLIDE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MARKETING.GET_ALL_SLIDE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageId: 204,
        lists: action.payload,
      };
    }

    case MARKETING.GET_ALL_SLIDE_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case MARKETING.UPDATE_SLIDE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case MARKETING.UPDATE_SLIDE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data,
      };
    }

    case MARKETING.UPDATE_SLIDE_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    default:
      return state;
  }
};
