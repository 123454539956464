import {
    GREETING_PACKAGE
  } from "../../constants/actionTypes";
  
  const INIT_STATE = {
    loader: false,
    alertMessage: '',
    messageId: null,
    lists: []
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case GREETING_PACKAGE.RESET_GREETING_PACKAGE: {
        return {
          ...state,
          loader: false,
          alertMessage: '',
          messageId: null
        }
      }
  
      case GREETING_PACKAGE.ADD_GREETING_PACKAGE_REQUEST: {
        return {
          ...state,
          loader: true
        }
      }
  
      case GREETING_PACKAGE.ADD_GREETING_PACKAGE_SUCCESS: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 200
        }
      }
  
      case GREETING_PACKAGE.ADD_GREETING_PACKAGE_FAIL: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }
  
      case GREETING_PACKAGE.LIST_GREETING_PACKAGE_REQUEST: {
        return {
          ...state,
          loader: true
        }
      }
  
      case GREETING_PACKAGE.LIST_GREETING_PACKAGE_SUCCESS: {
        return {
          ...state,
          loader: false,
          messageId: 204,
          lists: action.payload
        }
      }
  
      case GREETING_PACKAGE.LIST_GREETING_PACKAGE_FAIL: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }

      case GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_REQUEST: {
        return {
          ...state,
          loader: true
        }
      }

      case GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_SUCCESS: {
        return {
          ...state,
          loader: false,
          messageId: 204,
          lists: action.payload
        }
      }

      case GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_FAIL: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }
  
      case GREETING_PACKAGE.DELETE_GREETING_PACKAGE_REQUEST: {
        return {
          ...state,
          loader: true
        }
      }
  
      case GREETING_PACKAGE.DELETE_GREETING_PACKAGE_SUCCESS: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 200,
          lists: action.payload.data
        }
      }
  
      case GREETING_PACKAGE.DELETE_GREETING_PACKAGE_FAIL: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }
  
      case GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_REQUEST: {
        return {
          ...state,
          loader: true
        }
      }
  
      case GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_SUCCESS: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 200,
          lists: action.payload.data
        }
      }
  
      case GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_FAIL: {
        return {
          ...state,
          loader: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }
  
      default:
        return state;
    }
  }
