import {
    CUSTOMER_GAME_RECEIVED
} from "../../constants/actionTypes";

const INIT_STATE = {
    isLoading: false,
    alertMessage: '',
    messageId: null,
    lists: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CUSTOMER_GAME_RECEIVED.CUSTOMER_GAME_RECEIVED_RESET: {
            return {
                ...state,
                isLoading: false,
                alertMessage: '',
                messageId: null
            }
        }

        case CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 200,
                lists: action.payload
            }
        }

        case CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_REQUEST: {
            return {
              ...state,
              loader: true
            }
          }
      
          case CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_SUCCESS: {
            return {
              ...state,
              loader: false,
              messageId: 200,
              lists: action.payload
            }
          }
      
          case CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_FAIL: {
            return {
              ...state,
              loader: false,
              alertMessage: action.payload.message,
              messageId: 203
            }
          }

        default:
            return state;
    }
}
