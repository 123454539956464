import { DASHBOARD } from "../../constants/actionTypes";
import axios from 'axios';

export const getUsersCount = (token) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD.USERS_COUNT_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        
        INSTANCE.get("users/get-users-count")
            .then(function (response) {
                dispatch(usersCountSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(usersCountError(error.message));
            });
    };
};

const usersCountSuccess= (data) => ({
    type: DASHBOARD.USERS_COUNT_SUCCESS,
    payload: data
});

const usersCountError = errorMessage => ({
    type: DASHBOARD.USERS_COUNT_FAIL,
    payload: errorMessage
});

export const getAppUsersCount = (token, data) => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD.APP_USER_COUNT_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/get-count", inputJSON)
            .then(function (response) {
                dispatch(appUsersCountSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(appUsersCountError(error.message));
            });
    };
};

const appUsersCountSuccess= (data) => ({
    type: DASHBOARD.APP_USER_COUNT_SUCCESS,
    payload: data
});

const appUsersCountError = errorMessage => ({
    type: DASHBOARD.APP_USER_COUNT_FAIL,
    payload: errorMessage
});

export const resetDashboard = () => {
    return (dispatch) => {
        dispatch({ type: DASHBOARD.RESET_DASHBOARD})
    }
}