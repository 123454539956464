import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "antd";

import AddTokkenz from "./../components/AddTokkenz";

const TokkenzTab = (props) => {
  const { profileData, giftCalculation } = props;
  const [showAddTokken, setShowAddTokken] = useState(false);

  if (!profileData) {
    return;
  }

  return (
    <>
      {giftCalculation && (
        <>
          <Row>
            <Col>
              <span className="lable-style">Total Cash Gift Received: </span>
              ${giftCalculation?.data?.totalCashGiftReceived} (USD)
            </Col>
            <Col>
              <span className="lable-style">Total Cash Gift Available: </span>
              ${giftCalculation?.data?.totalCashGiftAvailable} (USD)
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <span className="lable-style">Total Game Items: </span>
              {giftCalculation?.data?.totalGiftItems} Tokkenz
            </Col>
            <Col>
              <span className="lable-style">Total Gift Cards: </span>
              {giftCalculation?.data?.totalGiftCards} Tokkenz
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <span className="lable-style">Total VISA Cards: </span>
              {giftCalculation?.data?.totalPrepaidCards} Tokkenz
            </Col>
          </Row>
        </>
      )}
      <Button style={{ marginTop: '50px' }} className="nav-btn" onClick={() => setShowAddTokken(true)}>
        Add Tokken
      </Button>
      <AddTokkenz
        open={showAddTokken}
        ecommerceUserId={profileData?._id}
        onClose={() => {
          setShowAddTokken(false);
        }}
      />
    </>
  );
};

export default TokkenzTab;
