import React, { Component } from "react";
import { Divider, Breadcrumb, Table, Button, Image as ImageTag, notification, Select, Modal } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col, Form } from 'react-bootstrap';
import { MenuOutlined, DeleteFilled } from '@ant-design/icons';
import { FaTrashAlt, FaRegEdit, FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa';
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  getAllGreetingCategoryTag
} from "../../../appRedux/actions/greetingCategoryTag";
import {
  addGreetingPackage,
  updateGreetingPackage,
  resetGreetingPackage
} from "../../../appRedux/actions/greetingPackage";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const { Option } = Select;

class AddGreetingPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      record: null,
      selectedTags: [],
      showAddDataModal: false,
      greetingMessage: '',
      greetingImages: [],
      greetingCoverImage: { fileName: '' },
      greetingImage: { fileName: '' },
      editId: null,
      editGreetingId: null,
      data: [],
      count: 1,
      name: '',
      description: '',
      holdRecord: {},
      uploadingImages: false,
      previewImage: '',
      previewGreetingMode: false,
      isLoading: false,
      previewCoverImage: null,
      previewCoverVisible: false,
      previewCoverTitle: null
    }

    this.columns = [{
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    }, {
      title: 'Greeting Order',
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
      render: (order) => { return (<span>{order}</span>) }
    }, {
      title: 'Greeting Message',
      dataIndex: 'greetingMessage',
      key: 'greetingMessage',
      sorter: (a, b) => a.greetingMessage.localeCompare(b.greetingMessage),
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Greeting Images',
      dataIndex: 'greetingImages',
      key: 'greetingImages',
      render: greetingImages => (
        <span className="gx-link" title="Click to Preview" onClick={() => this.onPreviewClick(greetingImages)}>Show Images ({greetingImages.length})<br /></span>
      )
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          <span
            className="gx-link"
            title="Edit"
            onClick={() => this.onEditGreeting(record)}
          >
            <FaRegEdit className="icon-size gx-link" />
          </span>
          <Divider type="vertical" />
          {record.isActive === false
            ? <span className="gx-link"
              title="Resume"
              onClick={() => { this.renderHoldPopup(record); }}
            >
              <FaRegPlayCircle className="icon-size" />
            </span>
            : <span className="gx-link"
              title="Hold"
              onClick={() => { this.renderHoldPopup(record); }}
            >
              <FaRegPauseCircle className="icon-size" />
            </span>
          }
          <Divider type="vertical" />
          <span
            className="gx-link delete-color"
            title="Delete"
            onClick={(e) => { this.onConfirmDelete(record); }}
          >
            <FaTrashAlt className="icon-size" />
          </span>
        </span>
      ),
    }];

    this.previewColumns = [{
      title: 'Greeting Order',
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
      render: (order) => { return (<span>{order}</span>) }
    }, {
      title: 'Greeting Message',
      dataIndex: 'greetingMessage',
      key: 'greetingMessage',
      sorter: (a, b) => a.greetingMessage.localeCompare(b.greetingMessage),
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Greeting Images',
      dataIndex: 'greetingImages',
      key: 'greetingImages',
      render: greetingImages => (
        <span className="gx-link" title="Click to Preview" onClick={() => this.onPreviewClick(greetingImages)}>Show Images ({greetingImages.length})<br /></span>
      )
    }]
  }

  componentDidMount() {
    const { authUser } = this.props.auth;
    this.props.getAllGreetingCategoryTag(authUser.token);
    if (this.props.match.params.id) {
      this.setState({
        isLoading: true
      })
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("greeting-package/get-by-id", inputJSON).then((response) => {
        if (response.data.messageId === 200) {
          this.setState({
            isLoading: false,
            editId: this.props.match.params.id,
            name: response.data.data.name,
            description: response.data.data.description ? response.data.data.description : "",
            data: response.data.data.greetingMessages,
            selectedTags: response.data.data.greetingCategoryTags,
            greetingCoverImage: response.data.data.greetingCoverImage
          })
        }
      }).catch((error) => {
      })
    }

    if (this.props.match.params.name) {
      this.setState({
        previewGreetingMode: true
      })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.greetingPackage) {
      if (nextProps.greetingPackage.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.greetingPackage.alertMessage
        });
        nextProps.resetGreetingPackage();
        nextProps.history.goBack();
      } else if (nextProps.greetingPackage.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.greetingPackage.alertMessage
        });
        nextProps.resetGreetingPackage();
      }
      return true;
    }
    return null;
  }

  onSelectChange = (value) => {
    this.setState({
      selectedTags: value
    })
  }

  onPreviewClick = (greetingImages) => {
    this.setState({
      previewImage: greetingImages,
      visible: true
    });
  }

  onCoverFileChange = (event) => {
    const { greetingCoverImage, name } = this.state;
    let files = event.target.files;
    if (files[0] && name !== '') {
      if (["image/png", "image/jpeg", "image/jpg"].includes(files[0].type)) {
        greetingCoverImage.fileName = files[0].name;
        greetingCoverImage.fileSize = files[0].size;
        greetingCoverImage.fileType = files[0].type;
        const { authUser } = this.props.auth;
        const INSTANCE = axios.create({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '.concat(authUser.token)
          }
        });
        let pathName = name.replace(/ /g, "");
        const formData = new FormData();
        formData.append('image', files[0]);
        formData.append('path', 'greeting-package/' + pathName + '/');
        INSTANCE.post('upload-file/upload', formData)
          .then((response) => {
            if (response.data.messageId === 200) {
              greetingCoverImage.file = response.data.data.location;
              greetingCoverImage.key = response.data.data.key;
              this.setState({ greetingCoverImage: greetingCoverImage });
            }
          }).catch((error) => {
            notification['error']({
              message: 'Alert!!',
              description: error
            });
          });
      } else {
        notification['error']({
          message: 'Alert!!',
          description: "The asset you added is the wrong file type.",
        });
      }
    }
    return false;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to delete Greeting Message '" + record.greetingMessage + "' ?",
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const newData = this.state.data.filter(row => row._id !== record._id);
    this.setState({
      ...this.state,
      data: newData
    });
  }

  renderPreviewPopup() {
    const { visible, previewImage } = this.state;
    return (
      <>
        <ImageTag.PreviewGroup
          preview={{
            visible,
            onVisibleChange: value => {
              this.setState({
                visible: value
              });
            },
          }}>
          {previewImage
            ? previewImage.map(greetingImage => (
              <ImageTag
                key={greetingImage.key}
                src={greetingImage.file}
                alt={greetingImage.fileName}
              />
            ))
            : null
          }
        </ImageTag.PreviewGroup>
      </>
    )
  }

  renderHoldPopup(record) {
    this.setState({
      holdRecord: record
    }, () => {
      let type = record.isActive ? "Hold" : "Resume";
      Modal.confirm({
        title: record.isActive ? "Hold" : "Resume",
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to " + type + " greeting message '" + record.greetingMessage + "' ?",
        okText: record.isActive ? "Hold" : "Resume",
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onHoldResume
      });
    })
  }

  onHoldResume = () => {
    const { holdRecord } = this.state;
    const newData = this.state.data.map(row => row._id === holdRecord._id ? { ...row, isActive: !holdRecord.isActive } : row);
    this.setState({
      ...this.state,
      data: newData,
      holdRecord: {}
    });
  }

  handleModalOk = () => {
    const { greetingMessage, greetingImages, name } = this.state;
    if (name === '' || greetingMessage === '' || greetingImages.length < 1) {
      return;
    }
    this.setState({
      uploadingImages: true
    }, () => {
      let uploadedImages = [];
      this.uploadEachImage(greetingImages, uploadedImages, 0, name);
    })
  }

  uploadEachImage(imagesList, uploadedImages, idx, name) {
    if (idx < imagesList.length) {
      if (imagesList[idx].fileName) {
        // Image Idx Already Uploaded
        uploadedImages.push(imagesList[idx]);
        idx++;
        this.uploadEachImage(imagesList, uploadedImages, idx, name);
      } else {
        // Upload New Image
        let file = {
          fileName: imagesList[idx].name,
          fileSize: imagesList[idx].size,
          fileType: imagesList[idx].type
        }
        const { authUser } = this.props.auth;
        const INSTANCE = axios.create({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '.concat(authUser.token)
          }
        });
        let pathName = name.replace(/ /g, "");
        const formData = new FormData();
        formData.append('image', imagesList[idx]);
        formData.append('path', 'greeting-package/' + pathName + '/');
        INSTANCE.post('upload-file/upload', formData)
          .then((response) => {
            if (response.data.messageId === 200) {
              file.file = response.data.data.location;
              file.key = response.data.data.key;
              file.thumbUrl = response.data.data.location;
              uploadedImages.push(file);
              idx++;
              this.uploadEachImage(imagesList, uploadedImages, idx, name);
            }
          })
          .catch((error) => {
            notification['error']({
              message: 'Alert!!',
              description: error
            });
          });
      }
    } else {
      const { editGreetingId, greetingMessage } = this.state;
      if (editGreetingId) {
        const newData = {
          greetingMessage: greetingMessage,
          greetingImages: uploadedImages
        };
        this.setState({
          ...this.state,
          data: this.state.data.map(row => row._id === editGreetingId ? { ...row, ...newData } : row),
          greetingMessage: '',
          greetingImages: [],
          showAddDataModal: false,
          uploadingImages: false
        });
      } else {
        const newData = {
          order: this.state.data.length + 1,
          greetingMessage: greetingMessage,
          greetingImages: uploadedImages,
          isActive: true
        };
        this.setState({
          ...this.state,
          data: [...this.state.data, newData],
          greetingMessage: '',
          greetingImages: [],
          showAddDataModal: false,
          uploadingImages: false,
          count: this.state.count + 1
        });
      }
    }
  }

  handleModalCancel = () => {
    this.setState({
      ...this.state,
      showAddDataModal: false,
      greetingMessage: '',
      greetingImages: [],
      editGreetingId: null
    });
  }

  onAddGreeting = () => {
    this.setState({
      greetingMessage: '',
      greetingImages: [],
      editGreetingId: null,
      showAddDataModal: true
    });
  }

  onEditGreeting = (record) => {
    this.setState({
      greetingMessage: record.greetingMessage,
      greetingImages: record.greetingImages,
      editGreetingId: record._id,
      showAddDataModal: true
    });
  }

  handleSave = () => {
    const { selectedTags, name, description, data, editId, greetingCoverImage, } = this.state;
    if (greetingCoverImage?.fileName === "" || name === "") {
      return;
    }
    let jsonData = {
      name: name,
      description: description,
      greetingCategoryTags: selectedTags,
      greetingMessages: data,
      greetingCoverImage: greetingCoverImage,
    };

    const { authUser } = this.props.auth;
    if (authUser.token) {
      if (editId) {
        jsonData._id = editId;
        jsonData.modifiedBy = authUser.user.userId;
        this.props.updateGreetingPackage(authUser.token, jsonData);
      } else {
        jsonData.createdBy = authUser.user.userId;
        jsonData.modifiedBy = authUser.user.userId;
        this.props.addGreetingPackage(authUser.token, jsonData);
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(el => !!el);
      newData.map((key, index) => {
        key.order = index + 1;
        return key;
      });
      this.setState({ data: newData });
    }
  };

  draggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  draggableBodyRow = ({ className, style, ...restProps }) => {
    const { data } = this.state;
    const index = data.findIndex(x => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  beforeUpload = file => {
    this.setState(state => ({
      greetingImages: [...state.greetingImages, file],
    }));
    return false;
  }

  onRemove(index) {
    this.setState(state => {
      const newFileList = state.greetingImages.slice();
      newFileList.splice(index, 1);
      return {
        greetingImages: newFileList,
      };
    });
  }

  onClickCoverImage = (image) => {
    this.setState({
      previewCoverImage: image.file,
      previewCoverVisible: true,
      previewCoverTitle: image.fileName
    });
  }

  onGreetingImageChange = (event) => {
    const { greetingImages } = this.state;
    let files = event.target.files;
    this.setState({
      greetingImages: [...greetingImages, files[0]]
    })
  }

  render() {
    const { loader } = this.props.greetingPackage;
    const { isLoading, visible, previewGreetingMode, greetingCoverImage, greetingImage, uploadingImages, selectedTags, data, editId, editGreetingId, greetingMessage, greetingImages, previewCoverVisible, previewCoverImage, previewCoverTitle } = this.state;
    const tagList = this.props.greetingCategoryTag;

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/greeting-package/list" className="gx-link">Greeting Packages</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Greeting Package" : "Add Greeting Package"} />
        {loader || isLoading ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <Row className="bg-white formrow mx-auto py-3">
              <Col sm="4">
                <Form.Label>Name of the Greeting Package</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="name"
                  value={this.state.name}
                  isInvalid={this.state.name === '' ? true : false}
                  onChange={(e) => { this.setState({ name: e.target.value }); }}
                  readOnly={previewGreetingMode}
                />
                {this.state.name === '' ? <Form.Control.Feedback type="invalid">Greeting package name is required.</Form.Control.Feedback> : null}
              </Col>
              <Col sm="4">
                <Form.Label>Cover Image</Form.Label>
                {previewGreetingMode ?
                  <p className="form-control gx-link" title="Click to Preview" onClick={() => this.onClickCoverImage(greetingCoverImage)} style={{ backgroundColor: '#e9ecef' }}>{greetingCoverImage.fileName}</p>
                  :
                  <>
                    <Form.File
                      type="file"
                      className="upload-Image"
                      label={greetingCoverImage.fileName}
                      custom
                      data-browse="Upload"
                      name="coverimage"
                      isInvalid={greetingCoverImage.fileName === '' ? true : false}
                      onChange={this.onCoverFileChange.bind(greetingCoverImage)}
                      required
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    {greetingCoverImage.fileName === '' ?
                      <span className="invalid-feedback" style={{ display: 'block' }}>Please upload greeting cover image.</span>
                      : null
                    }
                  </>
                }
              </Col>
              <Col sm="4">
                <Form.Label>Select Tags</Form.Label>
                <Select
                  onChange={this.onSelectChange}
                  mode="multiple"
                  allowClear
                  value={selectedTags}
                  style={{ width: '100%' }}
                  disabled={previewGreetingMode}
                >
                  {tagList && tagList.map(item => (
                    <Option key={item._id} value={item._id}>{item.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col sm="12">
                <Form.Label>Greeting Package Description <small>(Max. 80 Characters)</small></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  required
                  value={this.state.description}
                  isInvalid={this.state.description === '' ? true : false}
                  onChange={(e) => { this.setState({ description: e.target.value }); }}
                  readOnly={previewGreetingMode}
                  maxLength={80}
                />
                {this.state.description === '' ? <Form.Control.Feedback type="invalid">Greeting package description is required.</Form.Control.Feedback> : null}
              </Col>
              {!previewGreetingMode ?
                <Col sm="12" className="add-btn">
                  <button type="button" onClick={() => this.onAddGreeting()} className="btn btn-primary">Add a Greeting</button>
                </Col>
                : null}
            </Row>
            <Row className="row-margin2">
              <Col md="12">
                {!previewGreetingMode ?
                  <Table
                    className="gx-table-responsive"
                    columns={this.columns}
                    dataSource={data}
                    rowKey={record => record._id}
                    components={{
                      body: {
                        wrapper: this.draggableContainer,
                        row: this.draggableBodyRow,
                      }
                    }}
                  />
                  :
                  <Table
                    className="gx-table-responsive"
                    columns={this.previewColumns}
                    dataSource={data}
                    rowKey={record => record._id}
                    components={{
                      body: {
                        wrapper: this.draggableContainer,
                        row: this.draggableBodyRow,
                      }
                    }}
                  />
                }
              </Col>
              {!previewGreetingMode ?
                <Col md="12" className="d-flex justify-content-end my-3">
                  <button type="button" onClick={this.handleSave} className="btn btn-primary" style={{ width: '128px' }}>{editId ? 'Update' : 'Submit'}</button>
                </Col>
                : null}
            </Row>
          </>
        }
        {visible ? this.renderPreviewPopup() : null}
        <Modal title={editGreetingId ? "Edit Greeting" : "Add a Greeting"} okText={editGreetingId ? "Update" : "Submit"} maskClosable={false} visible={this.state.showAddDataModal} onOk={this.handleModalOk} onCancel={this.handleModalCancel} confirmLoading={uploadingImages}>
          <Form className="px-2">
            <Form.Row className="my-2">
              <Col>
                <Form.Label>Enter Greeting Message</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="message"
                  value={greetingMessage}
                  isInvalid={greetingMessage === '' ? true : false}
                  onChange={(e) => {
                    this.setState({
                      greetingMessage: e.target.value
                    });
                  }}
                />
                {greetingMessage === '' ? <Form.Control.Feedback type="invalid">Please enter greeting message.</Form.Control.Feedback> : null}
              </Col>
            </Form.Row>
            <Form.Row className="my-2">
              <Col>
                <Form.Label>Upload Greeting Images <small>(You can upload up to 10 images.)</small></Form.Label>
                <Form.File
                  type="file"
                  className="upload-Image"
                  label={"Choose Greeting Image"}
                  custom
                  data-browse="Upload"
                  name="greetingImage"
                  isInvalid={greetingImages.length === 0 ? true : false}
                  onChange={this.onGreetingImageChange.bind(greetingImage)}
                  required
                  accept="image/png, image/jpeg, image/jpg"
                />
                {greetingImages.length === 0 ? <Form.Control.Feedback type="invalid">Please select greeting image.</Form.Control.Feedback> : null}
              </Col>
            </Form.Row>
            <Form.Row>
              {greetingImages && greetingImages.map((selectedImage, index) => (
                selectedImage.fileName ?
                  <Col md={3} className="my-2">
                    <img
                      key={index}
                      width={"100px"}
                      height={'80px'}
                      src={selectedImage.file}
                      alt="Greeting"
                    />
                    <Button size="small" danger type="link" onClick={() => this.onRemove(index)} icon={<DeleteFilled />} className="delete-button">Delete</Button>
                  </Col>
                  :
                  <Col md={3} className="my-2">
                    <img
                      key={index}
                      width={"100px"}
                      height={'80px'}
                      src={URL.createObjectURL(selectedImage)}
                      alt="Greeting"
                    />
                    <Button size="small" danger type="link" onClick={() => this.onRemove(index)} icon={<DeleteFilled />} className="delete-button">Delete</Button>
                  </Col>
              ))}
            </Form.Row>
          </Form>
        </Modal>
        <Modal
          visible={previewCoverVisible}
          title={previewCoverTitle}
          footer={null}
          onCancel={() => this.setState({ previewCoverVisible: false })}
        >
          <img alt={previewCoverTitle} style={{ width: '100%' }} src={previewCoverImage} />
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    greetingCategoryTag: state.greetingCategoryTag?.lists?.data,
    greetingPackage: state.greetingPackage
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllGreetingCategoryTag,
    addGreetingPackage,
    updateGreetingPackage,
    resetGreetingPackage
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGreetingPackage);
