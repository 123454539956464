import {
  GAME_SEARCH_TAG
} from "../../../constants/actionTypes";

const INIT_STATE = {
  isLoading: false,
  alertMessage: '',
  messageId: null,
  lists: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GAME_SEARCH_TAG.RESET_GAME_SEARCH_TAG: {
      return {
        ...state,
        isLoading: false,
        alertMessage: '',
        messageId: null
      }
    }

    case GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: action.payload.messageId
      }
    }

    case GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data
      }
    }

    case GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data
      }
    }

    case GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_FAIL: {
      return {
        ...state,
        isLoading: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    default:
      return state;
  }
}
