import { GAME_SPECIAL_TAG } from "../../../constants/actionTypes";
import axios from 'axios';

export const addSpecialTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("game-special-tag/create", inputJSON)
      .then(function (response) {
        dispatch(addSpecialTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addSpecialTagError(error.message));
      });
  };
};

const addSpecialTagSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const addSpecialTagError = errorMessage => ({
  type: GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const listSpecialTags = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("game-special-tag/lists", inputJSON)
      .then(function (response) {
        dispatch(listSpecialTagsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listSpecialTagsError(error.message));
      });
  };
};

const listSpecialTagsSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const listSpecialTagsError = errorMessage => ({
  type: GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const getAllSpecialTags = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-special-tag/get-all")
      .then(function (response) {
        dispatch(getAllSpecialTagsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllSpecialTagsError(error.message));
      });
  };
};

const getAllSpecialTagsSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const getAllSpecialTagsError = errorMessage => ({
  type: GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const deleteSpecialTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("game-special-tag/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteSpecialTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteSpecialTagError(error.message));
      });
  };
};

const deleteSpecialTagSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const deleteSpecialTagError = errorMessage => ({
  type: GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const updateSpecialTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("game-special-tag/update", inputJSON)
      .then(function (response) {
        dispatch(updateSpecialTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateSpecialTagError(error.message));
      });
  };
};

const updateSpecialTagSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const updateSpecialTagError = errorMessage => ({
  type: GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const searchSpecialTag = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("game-special-tag/search-special-tag", inputJSON)
      .then(function (response) {
        dispatch(searchSpecialTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchSpecialTagError(error.message));
      });
  };
};

const searchSpecialTagSuccess = (data) => ({
  type: GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_SUCCESS,
  payload: data
});

const searchSpecialTagError = errorMessage => ({
  type: GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_FAIL,
  payload: errorMessage
});

export const resetSpecialTag = () => {
  return (dispatch) => {
    dispatch({ type: GAME_SPECIAL_TAG.RESET_GAME_SPECIAL_TAG })
  }
}
