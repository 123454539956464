import React from "react";
import {Tag} from "antd";
import {Link} from "react-router-dom";
const EventItem = ({data}) => {
  if(!data) { return null}
  const {_id, title, gameType, approvedDate, createdAt, shortDescription, coverImage, status} = data;
  let Created = null, Approved = 'NA';
  let color = '#3700B3';
  if(status === 'Submit') {
    color = 'blue';
  } else if(status === 'Completed') {
    color = 'green';
  } else if(status === 'Rejected') {
    color = 'red';
  }
  if(createdAt) Created = createdAt.slice(0, 10);
  if(approvedDate) Approved = approvedDate.slice(0, 10);
  return (
    <div className="gx-media gx-featured-item">
      <div className="gx-featured-thumb">
        <img className="gx-rounded-lg" src={coverImage ? coverImage.url : 'https://via.placeholder.com/576X330'} alt="..."/>
      </div>
      <div className="gx-media-body gx-featured-content">
       
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div className="gx-featured-content-left">
          <Tag className="gx-rounded-xs gx-text-uppercase" color={color}>Game Type {gameType}</Tag>
          <h3 className="gx-mb-2">{title}</h3>
          <div className="ant-row-flex">
            <div className="gx-media gx-text-grey gx-mb-1">
              <span className="gx-media-body">{shortDescription}</span>
            </div>
          </div>
          </div>
          <div style={{ marginLeft: '10px' }}><span className="gx-media-body">{status}</span></div>
        </div>
       
        <div className="gx-featured-content-right ">
          <div style={{display:'flex'}}>
            <div style={{marginRight:'17px', marginTop:'5px'}} className="gx-media gx-text-grey gx-mb-1">
              <span className="gx-media-body">Created At</span>
            </div>
            <h2 className="gx-text-primary gx-mb-1">
              <i className={`icon icon-calendar gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/> <span
              className="gx-d-inline-flex gx-vertical-align-middle">{Created}</span>
            </h2>
          </div>
          <div style={{display:'flex'}}>
            <div style={{marginRight:'7px', marginTop:'5px'}} className="gx-media gx-text-grey gx-mb-1">
              <span className="gx-media-body">Approved At</span>
            </div>
            <h2 className="gx-text-primary gx-mb-1">
              <i className={`icon icon-calendar gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/> <span
              className="gx-d-inline-flex gx-vertical-align-middle">{Approved}</span>
            </h2>
          </div>
          <br />
          <Link to={`/game-template/details/${_id}`} className="gx-text-primary gx-text-truncate gx-mt-sm-auto gx-mb-0 gx-pointer">Check in detail <i
            className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-1 gx-ml-sm-2 gx-d-inline-flex gx-vertical-align-middle`}/>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EventItem;
