import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import ListFeedback from "./list";

const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/list`} component={ListFeedback}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
