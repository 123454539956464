import React from "react";
import { Divider, notification, Button, Modal, Tag, Breadcrumb } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { Row, Col } from 'react-bootstrap';
import { FaTrashAlt, FaArchive, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  listMessage,
  deleteMessage,
  updateMessage,
  resetMessage
} from "../../../appRedux/actions/message";

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListMessages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      messagList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: 250
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        return (<Tag color={status ? "success" : 'warning'}>
          {status ? 'Done' : 'Needs Reply'}
        </Tag>)
      },
    },
    {
      title: 'Deleted',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Yes' : 'No'}
        </Tag>)
      },
      filters: [
        {
          text: 'No',
          value: false
        },
        {
          text: 'Yes',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: (a, b) => a.modifiedBy?.firstName?.length - b.modifiedBy?.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (modifiedBy) => {
        return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
      }
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <span title="Update Status"
            className="gx-link"
            onClick={(e) => { this.onUpdateStatus(record)}}>
            <FaArchive className="icon-size" />
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.messages) {
      if (nextProps.messages.messageId === 204) {
        return {
          messagList: nextProps.messages.lists.data,
          pagination: {
            current: nextProps.messages.lists.current,
            pageSize: nextProps.messages.lists.pageSize,
            total: nextProps.messages.lists.totalCount
          }
        }
      } else if (nextProps.messages.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.messages.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetMessage();
      } else if (nextProps.messages.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.messages.alertMessage
        });
        nextProps.resetMessage();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete "' + record.name + '"?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteMessage(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.name + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteMessage(authUser.token, data);
  }

  onUpdateStatus(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to update status to Done for ' + record.name + '?',
        okText: 'Update',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onUpdateRecord
      });
    })
  }

  onUpdateRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      status: 1,
      modifiedBy: authUser.user.userId
    }
    this.props.updateMessage(authUser.token, data);
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listMessage(authUser.token, inputJson);
    }
  }

  onChange = e => {
    if (e.type === 'click') {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.messages;
    const { pagination, messagList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Messages</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Messages" />
        <>
          <Row className="row-margin2">
            <Col>
              <Table
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={messagList}
                rowKey={record => record._id}
                pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  itemRender: itemRender,
                  size: 'small'
                }}
                loading={loader}
                size={'middle'}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    messages: state.messages
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    listMessage,
    deleteMessage,
    updateMessage,
    resetMessage
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListMessages);
