import React from "react";
import { notification, Modal, Space, Select, Upload, Tag, Breadcrumb, Input, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import { UploadOutlined } from "@ant-design/icons";
import { FaGift } from "react-icons/fa";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToolTip from "components/ToolTip";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  listGamesComplete,
  searchGamesComplete,
  resetGamesComplete
} from "../../../appRedux/actions/gamesComplete";

const { Search } = Input;

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

const gameStatus = (status) => {
  let statusTag;
  switch (status) {
    case "Sent":
      statusTag = <Tag color="#87d068">Sent</Tag>
      break;
    default:
      statusTag = <Tag color="#ff1d25">Not Sent</Tag>
      break;
  }
  return statusTag;
}

class GamesComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      fileList: [],
      cardType: null,
      currentInstance: this,
      record: null,
      gamesCompleteList: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      openModal: false,
      modalData: null,
    }

    this.columns = [{
      title: 'Date Complete',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => {
        return (
          <>
            <span>{moment(createdAt).format('MM-DD-YYYY')}</span>
            <br />
            <span>{moment(createdAt).format('hh:mm a')}</span>
          </>
        )
      }
    }, {
      title: 'Game ID#',
      dataIndex: 'gameNumber',
      key: 'gameNumber',
      sorter: (a, b) => a.gameNumber - b.gameNumber,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      width: 150,
      sorter: (a, b) => a.sender.firstName.length - b.sender.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (sender) => {
        return (
          <>
            <span>{sender?.firstName} {sender?.lastName}</span>
            <br />
            <span>{sender?.email}</span>
            <br />
            <span>{sender?.dialCode} {sender?.phone}</span>
          </>
        )
      }
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient',
      width: 150,
      sorter: (a, b) => a.recipient.firstName.length - b.recipient.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (recipient) => {
        return (
          <>
            <span>{recipient?.firstName} {recipient?.lastName}</span>
            <br />
            <span>{recipient?.email}</span>
            <br />
            <span>{recipient?.dialCode} {recipient?.phone}</span>
          </>
        )
      }
    },
    {
      title: 'Event',
      dataIndex: 'personalization',
      key: 'personalization',
      width: 150,
      render: (personalization) => {
        return (
          <span>{personalization?.event?.name}</span>
        )
      }
    },
    {
      title: 'Gift Money',
      dataIndex: 'giftMoney',
      key: 'giftMoney',
      sorter: (a, b) => a.giftMoney - b.giftMoney,
      sortDirections: ['descend', 'ascend'],
      render: (giftMoney) => {
        return (
          <>
            <span>${giftMoney}</span>
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        return (gameStatus(status))
      },
      filters: [
        {
          text: 'Not Sent',
          value: 'Not Sent',
        },
        {
          text: 'Sent',
          value: 'Sent',
        }
      ],
      onFilter: (value, record) => record.status === value
    },
    {
      title: 'Card Type',
      dataIndex: 'cardType',
      key: 'cardType',
      width: 100,
      sorter: (a, b) => a.cardType - b.cardType,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Card Sent Date',
      dataIndex: 'cardSentDate',
      key: 'cardSentDate',
      width: 150,
      sorter: (a, b) => moment(a.cardSentDate).unix() - moment(b.cardSentDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (cardSentDate) => {
        return (
          cardSentDate ?
            <>
              <span>{moment(cardSentDate).format('MM-DD-YYYY')}</span >
              <br />
              <span>{moment(cardSentDate).format('hh:mm a')}</span>
            </> : null
        )
      }
    }, {
      title: 'Action',
      key: 'action',
      width: 50,
      render: (record) => (
        <>
          {record.status === 'Not Sent' ?
            <ToolTip
              text="Update Gift Money Status"
              child={
                <span className="gx-link" onClick={(e) => { this.onSendGiftMoney(record); }}>
                  <FaGift className="icon-size gx-link" />
                </span>
              }
            /> : null}
        </>
      ),
    }];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      this.props.listGamesComplete(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: pagination.current,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchGamesComplete(inputJson, authUser.token);
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gamesCompleteData) {
      if (nextProps.gamesCompleteData.messageId === 200) {
        nextProps.resetGamesComplete();
        return {
          gamesCompleteList: nextProps.gamesCompleteData.lists.data,
          pagination: {
            current: nextProps.gamesCompleteData.lists.current,
            pageSize: nextProps.gamesCompleteData.lists.pageSize,
            total: nextProps.gamesCompleteData.lists.totalCount
          }
        }
      } else if (nextProps.gamesCompleteData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gamesCompleteData.alertMessage
        });
        nextProps.resetGamesComplete();
      }
      return true;
    }
    return null;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  onSendGiftMoney = (record) => {
    this.setState({
      modalData: record,
      cardType: null,
      openModal: true,
      fileList: []
    });
  }

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  handleUpload = () => {
    const { fileList, cardType, modalData, pagination } = this.state;
    if (cardType) {
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('image', file);
      });
      formData.append('path', 'gift-money-receipt/');
      this.setState({
        isUpdating: true,
      });

      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      INSTANCE.post('upload-file/upload', formData)
        .then((response) => {
          if (response.data.messageId === 200) {
            let inputJSON = JSON.stringify({
              '_id': modalData._id,
              'cardType': cardType,
              'status': 'Sent',
              'cardSentDate': new Date(),
              'createdBy': authUser.user.userId,
              'giftCardReceipt': {
                originalName: response.data.data.originalname,
                mimeType: response.data.data.mimetype,
                location: response.data.data.location,
                key: response.data.data.key
              }
            })
            const INSTANCE1 = axios.create({
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(authUser.token)
              }
            });
            INSTANCE1.post('gift-card/update', inputJSON)
              .then((resp) => {
                if (resp.data.messageId === 200) {
                  this.setState({
                    fileList: [],
                    isUpdating: false,
                    openModal: false,
                    modalData: null,
                  }, () => {
                    notification['success']({
                      message: 'Success',
                      description: resp.data.message
                    });
                    this.fetch({ pagination });
                  });
                } else {
                  this.setState({
                    isUpdating: false
                  }, () => {
                    notification['error']({
                      message: 'Alert!!',
                      description: response.data.message
                    });
                  });
                }
              })
              .catch((error) => {
                notification['error']({
                  message: 'Alert!!',
                  description: error
                });
              })
          } else {
            this.setState({
              isUpdating: false
            }, () => {
              notification['error']({
                message: 'Alert!!',
                description: response.data.message
              });
            });
          }
        })
        .catch((error) => {
          notification['error']({
            message: 'Alert!!',
            description: error
          });
        })
    } else {
      notification['error']({
        message: 'Alert!!',
        description: 'Please select gift card type.'
      });
    }
  };

  onRemove = file => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  }

  onSelectChange = (value) => {
    this.setState({
      cardType: value
    })
  }

  handleCancel = () => {
    this.setState({
      openModal: false,
      modalData: null
    });
  }

  render() {
    const { isLoading } = this.props.gamesCompleteData;
    const { isUpdating, cardType, fileList, pagination, gamesCompleteList, openModal, modalData } = this.state;
    const uploadButton = (
      <Button icon={<UploadOutlined />}>Upload Receipt</Button>
    );

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Games Complete</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Games Complete" />
        <Row className="row-margin2">
          <Col>
            <Search
              placeholder="Search by Game#"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={this.onSearch}
            />
          </Col>
        </Row>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={gamesCompleteList}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={isLoading}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Modal onCancel={this.handleCancel} visible={openModal}
          title={modalData ? "Update Gift Money Status For Game ID# : " + modalData.gameNumber : null}
          closable={true}
          style={{ zIndex: 2600 }}
          footer={null}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              placeholder="Select Gift Card Type"
              style={{ width: '100%', marginBottom: '10px', borderRadius: '5px' }}
              onChange={this.onSelectChange}
              value={cardType}
            >
              <Select.Option value="Amazon">Amazon</Select.Option>
              <Select.Option value="Walmart">Walmart</Select.Option>
              <Select.Option value="Pre-Paid Debit">Pre-Paid Debit</Select.Option>
            </Select>
            <Upload
              listType="picture"
              beforeUpload={this.beforeUpload}
              onRemove={this.onRemove}
              fileList={fileList}
              accept="application/pdf"
            >
              {fileList.length > 0 ? null : uploadButton}
            </Upload>
            <Button onClick={this.handleUpload} style={{ marginTop: '20px' }} disabled={fileList.length === 0 || !cardType} type="primary" loading={isUpdating}>
              {isUpdating ? 'Updating' : 'Update'}
            </Button>
          </Space>
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gamesCompleteData: state.gamesCompleteData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    listGamesComplete,
    searchGamesComplete,
    resetGamesComplete
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(GamesComplete);
