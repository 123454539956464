import React, { Component } from "react";
import moment from "moment";
import Widget from "components/Widget";
import { Tag, Row, Col, Modal } from "antd";
import AddSkill from "components/Skill/AddSkill";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  IoIosMail,
  IoIosLink,
  IoIosPhonePortrait,
  IoMdClose,
} from "react-icons/io";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { addSkill, removeSkill } from "../../../appRedux/actions/auth";
import { getUser, resetUser } from "../../../appRedux/actions/users";

let badgeColors = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "blue",
  "cyan",
  "purple",
];

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addSkill: false,
      skillName: null
    };

    this.formRef = React.createRef();
  }

  addSkill(values) {
    const { authUser } = this.props.auth;
    values.email = authUser.user.email;
    values.color = badgeColors[Math.floor(Math.random() * badgeColors.length)];
    this.props.addSkill(values);
  }

  changeState(value) {
    this.setState({ addSkill: value });
  }

  removeSkillOk = () => {
    const { authUser } = this.props.auth;
    const { skillName }  = this.state;
    this.props.removeSkill({
      _id: authUser.user.userId,
      skillName
    });
  }

  onConfirmRemoveModal(skillName){
    this.setState({
      skillName: skillName
    }, () => {
      Modal.confirm({
        title: "Confirm",
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure want to delete skill " + skillName + "?",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk: this.removeSkillOk
      });
    })
  };

  render() {
    return this.props.userData ? (
      <Widget
        title={
          this.props.userData.userRole[0].name +
          " - Since " +
          moment(this.props.userData.createdAt).format("MMM-DD-YYYY")
        }
        styleName="gx-card-profile-sm"
      >
        <Row>
          <Col span={12}>
            <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              <div className="gx-mr-3">
                <IoIosMail className="icon-size gx-text-grey" />
              </div>
              <div className="gx-media-body">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">Email</span>
                <p className="gx-mb-0">{this.props.userData.email}</p>
              </div>
            </div>
            <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              <div className="gx-mr-3">
                <IoIosLink className="icon-size gx-text-grey" />
              </div>
              <div className="gx-media-body">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">Web page</span>
                <p className="gx-mb-0">
                  {this.props.userData.website
                    ? this.props.userData.website
                    : "NA"}
                </p>
              </div>
            </div>
            <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              <div className="gx-mr-3">
                <IoIosPhonePortrait className="icon-size gx-text-grey" />
              </div>
              <div className="gx-media-body">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">Phone</span>
                <p className="gx-mb-0">
                  {this.props.userData.dialCode} {this.props.userData.mobilePhone}
                </p>
              </div>
            </div>
          </Col>
          <Col span={12}>
            {this.props.mode === "edit" ? (
              <Row style={{ textAlign: "right" }}>
                <a
                  role="button"
                  href="#0"
                  className="ant-menu-item"
                  onClick={() => {
                    this.changeState(true);
                  }}
                >
                  Add Skill
                </a>
                <AddSkill
                  open={this.state.addSkill}
                  onClose={() => {
                    this.changeState(false);
                  }}
                  onAddSkill={this.addSkill.bind(this)}
                />
              </Row>
            ) : null}

            <Row style={{ marginTop: 25 }}>
              <Col span={24}>
                {this.props.userData.skill?.map((data, index) => (
                  <Tag key={index} color={data.color} style={{cursor: 'pointer'}}>
                    {data.name}{" "}
                    {this.props.mode === "edit" ? <IoMdClose
                      onClick={() => this.onConfirmRemoveModal(data.name)}
                      size={16}
                    /> :  null }
                  </Tag>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Widget>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userData: state.users?.userData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    { addSkill, getUser, resetUser, removeSkill },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(About);
