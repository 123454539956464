import React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import GameOrders from "./list";
import { Roles } from "../../shared/enums/roles";
import PrivateRoute from "../../shared/routePermission/privateRoute";

const Main = ({ match }) => (
  <Switch>
    <PrivateRoute path={`${match.url}/list`} component={GameOrders} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
