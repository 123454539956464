export const Roles = {
    SuperAdmin: 1,
    DevOpsSuperAdmin: 2,
    ContentAdmin: 3,
    DevOpsAdmin: 4, 
    GameyFactoryAdmin: 5,
    MarketingAdmin: 6,
    CorporateAdmin: 7,
    Contributor: 8,
    Developer: 9,
    UnityDeveloper: 10,
    AppUser: 11
}
