import React from "react";
import axios from 'axios';
import { Col, Row, notification } from "antd";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaUserAlt, FaCopy, FaBoxes, FaCartArrowDown } from 'react-icons/fa';
import { IoGameController, IoCheckmarkDoneSharp } from 'react-icons/io5';

import Auxiliary from "util/auxiliary";
import CircularProgress from "components/CircularProgress";
import StatsCard from "components/Widgets/StatsCard";
import PageHeader from "components/PageHeader";

import {
  getUsersCount, getAppUsersCount
} from "./../../../appRedux/actions/dashboard";

import {
  getGameTemplateCount
} from "./../../../appRedux/actions/gameTemplate";

import {
  getGameInventoryCount
} from "./../../../appRedux/actions/gameCatalog/gameInventory";

import {
  getGameOrdersCount
} from "./../../../appRedux/actions/gameOrders";

import {
  getPersonalizationsCount
} from "./../../../appRedux/actions/personalization";

import {
  getGamesCompleteCount
} from "./../../../appRedux/actions/gamesComplete";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  };
  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {
      if (authUser.user.userRoles[0].userRole === 1 || authUser.user.userRoles[0].userRole === 2 || authUser.user.userRoles[0].userRole === 4) {
        this.props.getUsersCount(authUser.token);
        let data = {}
        this.props.getAppUsersCount(authUser.token, data);
        this.props.getGameTemplateCount(authUser.token);
        this.props.getGameInventoryCount(authUser.token);
        this.props.getGameOrdersCount(authUser.token);
        this.props.getPersonalizationsCount(authUser.token);
        this.props.getGamesCompleteCount(authUser.token);
      }
    }
  }

  onAddBankAccount() {
    this.setState({
      isLoading: true
    })
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });

    INSTANCE.post("payment/create-connect-account", {})
      .then((response) => {
        if (response.data.messageId === 200) {
          window.open(response.data.data, '_blank');
          this.setState({
            isLoading: false
          })
        } else {
          this.setState({
            isLoading: false
          })
          notification['error']({
            message: 'Alert!!',
            description: response.data.message
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        notification['error']({
          message: 'Alert!!',
          description: error.message
        });
      })
  }

  render() {
    const { loader, usersData, appUsers } = this.props.dashboard;
    const { gameInventoryCount } = this.props.gameInventory;
    const { gameTemplateCount } = this.props.gameTemplate;
    const { gameOrdersCount } = this.props.gameOrders;
    const { personalizationsCount } = this.props.personalizationData;
    const { gamesCompleteCount } = this.props.gamesCompleteData;
    const { isLoading } = this.state;
    return (
      <Auxiliary>
        {loader || isLoading ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <PageHeader title="Dashboard" />
            <Row>
              {usersData ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/users/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <FaUserAlt style={{ fontSize: "45px", color: '#82B1FF' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{usersData.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Intranet Users</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col>
                : null}

              {appUsers ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/customers/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <FaUserAlt style={{ fontSize: "45px", color: '#F57F17' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{appUsers.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Customer Accounts</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}
              {gameTemplateCount ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/game-template/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <FaCopy style={{ fontSize: "45px", color: '#4185F3' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{gameTemplateCount.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Game Template</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}

              {gameInventoryCount ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/game-catalog/inventory`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <FaBoxes style={{ fontSize: "45px", color: '#311B92' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{gameInventoryCount.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Game Inventory</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}

              {gameOrdersCount ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/game-orders/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <FaCartArrowDown style={{ fontSize: "45px", color: '#1B5E20' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{gameOrdersCount.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Game Orders</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}

              {personalizationsCount ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/game-tracking/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <IoGameController style={{ fontSize: "45px", color: '#00695C' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{personalizationsCount.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Game Tracking</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}

              {gamesCompleteCount ?
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                  <Link to={`/games-complete/list`}>
                    <StatsCard>
                      <div className="gx-media gx-align-items-center gx-flex-nowrap">
                        <div className="gx-mr-lg-4 gx-mr-3">
                          <IoCheckmarkDoneSharp style={{ fontSize: "45px", color: '#00C853' }} />
                        </div>

                        <div className="gx-media-body">
                          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{gamesCompleteCount.data}</h1>
                          <p className="gx-text-grey gx-mb-0">Games Complete</p>
                        </div>
                      </div>
                    </StatsCard>
                  </Link>
                </Col> : null}
            </Row>
            {/* <Row>
              <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                <Button type="button" onClick={() => this.onAddBankAccount()}>
                  Add Bank Account
                </Button>
              </Col>
            </Row> */}
          </>
        }
      </Auxiliary>
    );
  };
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    dashboard: state.dashboard,
    gameTemplate: state.gameTemplate,
    gameInventory: state.gameInventoryData,
    gameOrders: state.gameOrders,
    personalizationData: state.personalizationData,
    gamesCompleteData: state.gamesCompleteData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    getUsersCount,
    getAppUsersCount,
    getGameInventoryCount,
    getGameTemplateCount,
    getGameOrdersCount,
    getPersonalizationsCount,
    getGamesCompleteCount
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(Dashboard);
