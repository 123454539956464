import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddCoupon from "./add";
import ListCoupons from "./list";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/add`} component={AddCoupon} />
    <Route path={`${match.url}/list`} component={ListCoupons} />
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
