import React from 'react';
import { Breadcrumb } from 'antd';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';

const checkId = (last) => {
    if(!last) return false;
    var regex = /\d/g;
    return regex.test(last);
}

const CustomBreadCrumb = (props) => {
    const {location : {pathname}} = props;
    const {home} = props;
    const pathnames = pathname.split('/').filter(x=>x);
    const hasId = checkId(pathnames[pathnames.length - 1]);
    return (
        <div className="margin-bottom">
            <Breadcrumb>
                {home === true?
                <Breadcrumb.Item className="gx-text-capitalize">
                    <Link className="gx-link" to="/home/dashboard">Dashboard</Link>
                </Breadcrumb.Item>
                :null}
                {pathnames.map((name, index) => {
                    const route = `/${pathnames.slice(0, index+1).join("/")}`
                    const active = index === pathnames.length - 1;
                    name = name.replaceAll('-', ' ');
                    if(hasId) {
                        if(index === pathnames.length - 2)
                            return <Breadcrumb.Item key={index} className="gx-text-capitalize">{name}</Breadcrumb.Item>
                        if(index === pathnames.length - 1)
                            return null;
                    }
                    return active ? 
                    <Breadcrumb.Item key={index} className="gx-text-capitalize">{name}</Breadcrumb.Item> :
                    <Breadcrumb.Item key={index} className="gx-text-capitalize">
                        <Link className="gx-link" to={route}>{name}</Link>
                    </Breadcrumb.Item>
                })}
            </Breadcrumb>
        </div>
    );
}

export default withRouter(CustomBreadCrumb);