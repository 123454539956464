import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AddGameInventory from "./inventory/add";
import ListGameInventory from "./inventory/list";
import AddGameSubCategory from "./gameSubCategory/add";
import ListGameSubCategory from "./gameSubCategory/list";
import AddGameCategoryTag from "./gameCategoryTags/add";
import ListGameCategoryTag from "./gameCategoryTags/list";
import AddSpecialTag from "./specialTags/add";
import ListSpecialTag from "./specialTags/list";
import AddInfoTag from "./infoTags/add";
import ListInfoTag from "./infoTags/list";

const Main = ({ match }) => (
    <>
        <Switch>
            <Route path={`${match.url}/inventory`} component={ListGameInventory} />
            <Route path={`${match.url}/add-inventory`} component={AddGameInventory} />
            <Route path={`${match.url}/edit-inventory/:id`} component={AddGameInventory} />
            <Route path={`${match.url}/add-game-sub-category`} component={AddGameSubCategory} />
            <Route path={`${match.url}/edit-game-sub-category/:id`} component={AddGameSubCategory} />
            <Route path={`${match.url}/game-sub-categories`} component={ListGameSubCategory} />
            <Route path={`${match.url}/add-game-category-tag`} component={AddGameCategoryTag} />
            <Route path={`${match.url}/edit-game-category-tag/:id`} component={AddGameCategoryTag} />
            <Route path={`${match.url}/game-category-tags`} component={ListGameCategoryTag} />
            <Route path={`${match.url}/add-special-tag`} component={AddSpecialTag} />
            <Route path={`${match.url}/edit-special-tag/:id`} component={AddSpecialTag} />
            <Route path={`${match.url}/special-tags`} component={ListSpecialTag} />
            <Route path={`${match.url}/add-info-tag`} component={AddInfoTag} />
            <Route path={`${match.url}/edit-info-tag/:id`} component={AddInfoTag} />
            <Route path={`${match.url}/info-tags`} component={ListInfoTag} />
            <Route exact path={`${match.url}/`}>
                <Redirect to={`${match.url}/inventory`} />
            </Route>
        </Switch>
    </>
);

export default Main;
