import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddUser from "./add";
import ListUser from "./list";
import PreviewUser from "./preview";
import PrivateRoute from "../../shared/routePermission/privateRoute";
import { Roles } from "../../shared/enums/roles";

const Main = ({ match }) => (
  <Switch>
    <PrivateRoute path={`${match.url}/add`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={AddUser} />
    <PrivateRoute path={`${match.url}/edit/:id`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={AddUser} />
    <PrivateRoute path={`${match.url}/preview/:id`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={PreviewUser} />
    <PrivateRoute path={`${match.url}/list`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={ListUser} />
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
