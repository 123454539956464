import React, { Component } from "react";
import { Button, Card, Form, Input, InputNumber, Breadcrumb, DatePicker, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";
import ColorPicker from "components/ColorPicker";

import {
  addSpecialTag,
  updateSpecialTag,
  resetSpecialTag
} from "../../../../appRedux/actions/gameCatalog/specialTag";

const FormItem = Form.Item;

class AddSpecialTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      defaultColor: ''
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("game-special-tag/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              price: response.data.data.price,
              specialColor: {
                color: response.data.data.color ? response.data.data.color : '#371cb3',
                alpha: 100
              },
              specialDate: [moment(response.data.data.startDate), moment(response.data.data.endDate)],
              description: response.data.data.description ? response.data.data.description : ''
            });
            this.setState({
              editId: this.props.match.params.id,
              defaultColor: response.data.data.color ? response.data.data.color : '#371cb3'
            })
          }
        })
        .catch((error) => {
        })
    } else {
      this.setState({
        defaultColor: '#371cb3'
      })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameSpecialTagData) {
      if (nextProps.gameSpecialTagData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameSpecialTagData.alertMessage
        });
        nextProps.resetSpecialTag();
        nextProps.history.goBack();
      } else if (nextProps.gameSpecialTagData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameSpecialTagData.alertMessage
        });
        nextProps.resetSpecialTag();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId, defaultColor } = this.state;
    let inputJSON = values;
    inputJSON.startDate = values.specialDate[0]._d;
    inputJSON.endDate = values.specialDate[1]._d;
    inputJSON.color = values.specialColor ? values.specialColor.color : defaultColor;
    delete inputJSON.specialColor;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateSpecialTag(authUser.token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addSpecialTag(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.gameSpecialTagData;
    const { editId, defaultColor } = this.state;
    return (
      <>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Auxiliary>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/game-catalog/special-tags" className="gx-link">Game Special Tags</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title={editId ? "Edit Special Tag" : "Add Special Tag"} />
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Form
                    name="gameTagForm"
                    onFinish={this.onFinish}
                    layout="vertical"
                    ref={this.formRef}
                  >
                    <FormItem label="Tag Name" rules={[{ required: true, message: 'Please enter tag name.' }]} name="name">
                      <Input placeholder="Tag Name" />
                    </FormItem>

                    <FormItem label="Price" rules={[{ required: true, message: 'Please enter price.' }]} name="price">
                      <InputNumber placeholder="Price" min={0} style={{ width: '100%' }} />
                    </FormItem>

                    <FormItem label="Start and End Date" rules={[{ required: true, message: 'Please select event start & end date.' }]} name="specialDate">
                      <DatePicker.RangePicker style={{ width: '100%' }} format={'MM-DD-YYYY'} />
                    </FormItem>

                    {defaultColor !== '' ?
                      <FormItem label="Special Color" name="specialColor">
                        <ColorPicker color={defaultColor} />
                      </FormItem> : null}

                    <FormItem label="Description" name="description">
                      <Input.TextArea placeholder="Description" rows={4} />
                    </FormItem>

                    <FormItem>
                      <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                        {editId ? "Update" : "Submit"}
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Auxiliary>
        }
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameSpecialTagData: state.gameSpecialTagData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    addSpecialTag,
    updateSpecialTag,
    resetSpecialTag
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddSpecialTag);
