import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ListGameInventory from "./slides/list";
import AddMarketingSlide from "./slides/add";

const Main = ({ match }) => (
    <>
        <Switch>
            <Route path={`${match.url}/slides`} component={ListGameInventory} />
            <Route path={`${match.url}/add-slide`} component={AddMarketingSlide} />
            <Route path={`${match.url}/edit-slide/:id`} component={AddMarketingSlide} />
            <Route exact path={`${match.url}/`}>
                <Redirect to={`${match.url}/slides`} />
            </Route>
        </Switch>
    </>
);

export default Main;
