import React from "react";
import { Row, Col } from "react-bootstrap";

const FatKatInsider = (props) => {
  const { profileData } = props;

  if(!profileData){
    return;
  }

  return (
    <>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">FatKat INSIDER: </span>
          {profileData.isGameyPass ? "Yes" : "No"}
        </Col>
        <Col md="6">
          <span className="lable-style">Subscription Type: </span>
          {profileData.isGameyPass && profileData?.subscriptionType ? profileData.subscriptionType : 'N/A'}
        </Col>
      </Row>
    </>
  );
};

export default FatKatInsider;
