import { GAME_ORDER } from "../../constants/actionTypes";
import axios from 'axios';

export const listGameOrders = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_ORDER.LIST_GAME_ORDER_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("orders/lists", inputJSON)
      .then(function (response) {
        dispatch(listGameOrdersSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGameOrdersError(error.message));
      });
  };
};

const listGameOrdersSuccess = (data) => ({
  type: GAME_ORDER.LIST_GAME_ORDER_SUCCESS,
  payload: data
});

const listGameOrdersError = errorMessage => ({
  type: GAME_ORDER.LIST_GAME_ORDER_FAIL,
  payload: errorMessage
});

export const getGameOrdersCount = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_ORDER.GAME_ORDER_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("orders/get-count")
      .then(function (response) {
        dispatch(gameOrdersCountSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(gameOrdersCountError(error.message));
      });
  };
};

const gameOrdersCountSuccess = (data) => ({
  type: GAME_ORDER.GAME_ORDER_COUNT_SUCCESS,
  payload: data
});

const gameOrdersCountError = errorMessage => ({
  type: GAME_ORDER.GAME_ORDER_COUNT_FAIL,
  payload: errorMessage
});

export const searchOrderById = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAME_ORDER.SEARCH_GAME_ORDER_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("orders/search-order", inputJSON)
      .then(function (response) {
        dispatch(searchOrderSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchOrderError(error.message));
      });
  };
};

const searchOrderSuccess = (data) => ({
  type: GAME_ORDER.SEARCH_GAME_ORDER_SUCCESS,
  payload: data
});

const searchOrderError = errorMessage => ({
  type: GAME_ORDER.SEARCH_GAME_ORDER_FAIL,
  payload: errorMessage
});

export const resetGameOrders = () => {
  return (dispatch) => {
    dispatch({ type: GAME_ORDER.GAME_ORDER_RESET })
  }
}
