import React, { Component } from "react";
import { Button, Card, Form, Input, Select, Breadcrumb, notification, Modal, Upload } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  addGameCategoryTag,
  updateGameCategoryTag,
  resetGameCategoryTag
} from "../../../../appRedux/actions/gameCatalog/gameCategoryTag";

const FormItem = Form.Item;
const { Option } = Select;

class AddGameCategoryTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      fileList: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      uploading: false,
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("game-category-tag/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              color: response.data.data.color ? response.data.data.color : 'green',
              description: response.data.data.description ? response.data.data.description : ''
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameCategoryTagData) {
      if (nextProps.gameCategoryTagData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameCategoryTagData.alertMessage
        });
        nextProps.resetGameCategoryTag();
        nextProps.history.goBack();
      } else if (nextProps.gameCategoryTagData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameCategoryTagData.alertMessage
        });
        nextProps.resetGameCategoryTag();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { fileList, editId } = this.state;
    if (fileList.length > 0) {
      this.setState({uploading: true});
      const { authUser } = this.props.auth;
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('image', file);
      });
      formData.append('path', 'game-types/');
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      INSTANCE.post('upload-file/upload', formData)
        .then((response) => {
          this.setState({uploading: false});
          if (response.data.messageId === 200) {
            let inputJSON = values;
            inputJSON.icon = response.data.data.location;
            if (editId) {
              inputJSON._id = editId;
              inputJSON.modifiedBy = authUser.user.userId;
              this.props.updateGameCategoryTag(authUser.token, inputJSON);
            } else {
              inputJSON.createdBy = authUser.user.userId;
              inputJSON.modifiedBy = authUser.user.userId;
              this.props.addGameCategoryTag(authUser.token, inputJSON);
            }
          }
        })
        .catch((error) => {
          this.setState({uploading: false});
          notification['error']({
            message: 'Alert!!',
            description: error
          });
        })
    } else {
      notification['error']({
        message: 'Alert!!',
        description: 'Please select game category icon.'
      });
    }
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  onRemove = file => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { loader } = this.props.gameCategoryTagData;
    const { editId, uploading, previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload Icon</div>
      </div>
    );
    return (
      <>
        {loader || uploading?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Auxiliary>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/game-catalog/game-category-tags" className="gx-link">Game Category Tags</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title={editId ? "Edit Category Tag" : "Add Category Tag"} />
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Form
                    name="gameTagForm"
                    onFinish={this.onFinish}
                    layout="vertical"
                    ref={this.formRef}
                  >
                    <FormItem label="Tag Name" rules={[{ required: true, message: 'Please enter tag name.' }]} name="name">
                      <Input placeholder="Tag Name" />
                    </FormItem>

                    <Form.Item name="color" label="Color" rules={[{ required: true }]}>
                      <Select
                        placeholder="Select Color"
                        allowClear
                      >
                        <Option value="blue">Blue</Option>
                        <Option value="gold">Gold</Option>
                        <Option value="green">Green</Option>
                        <Option value="orange">Orange</Option>
                        <Option value="purple">Purple</Option>
                        <Option value="pink">Pink</Option>
                        <Option value="red">Red</Option>
                        <Option value="white">White</Option>
                      </Select>
                    </Form.Item>

                    <FormItem label="Description" name="description">
                      <Input.TextArea placeholder="Description" rows={4} />
                    </FormItem>

                    <Upload
                      listType="picture-card"
                      onPreview={this.handlePreview}
                      beforeUpload={this.beforeUpload}
                      onRemove={this.onRemove}
                      accept="image/png, image/jpeg, image/jpg"
                    >
                      {fileList.length > 0 ? null : uploadButton}
                    </Upload>

                    <FormItem>
                      <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                        {editId ? "Update" : "Submit"}
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
              </Modal>
          </Auxiliary>
        }
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameCategoryTagData: state.gameCategoryTagData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({  
    addGameCategoryTag,
    updateGameCategoryTag,
    resetGameCategoryTag 
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddGameCategoryTag);
