import { CUSTOMER_ORDER } from "../../constants/actionTypes";
import axios from 'axios';

export const listCustomerOrders = (token, data) => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ORDER.LIST_CUSTOMER_ORDER_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("orders/by-customer-id", inputJSON)
      .then(function (response) {
        dispatch(listCustomerOrdersSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listCustomerOrdersError(error.message));
      });
  };
};

const listCustomerOrdersSuccess = (data) => ({
  type: CUSTOMER_ORDER.LIST_CUSTOMER_ORDER_SUCCESS,
  payload: data
});

const listCustomerOrdersError = errorMessage => ({
  type: CUSTOMER_ORDER.LIST_CUSTOMER_ORDER_FAIL,
  payload: errorMessage
});

export const searchOrderById = (data, token) => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ORDER.SEARCH_CUSTOMER_ORDER_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("orders/search-order", inputJSON)
      .then(function (response) {
        dispatch(searchOrderSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchOrderError(error.message));
      });
  };
};

const searchOrderSuccess = (data) => ({
  type: CUSTOMER_ORDER.SEARCH_CUSTOMER_ORDER_SUCCESS,
  payload: data
});

const searchOrderError = errorMessage => ({
  type: CUSTOMER_ORDER.SEARCH_CUSTOMER_ORDER_FAIL,
  payload: errorMessage
});

export const resetCustomerOrders = () => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ORDER.CUSTOMER_ORDER_RESET })
  }
}
