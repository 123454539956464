import { DEMO } from "../../constants/actionTypes";
import axios from 'axios';

export const sendDemo = (token, data) => {
    return (dispatch) => {
        dispatch({ type: DEMO.SEND_DEMO_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-demo/send", inputJSON)
            .then(function (response) {
                dispatch(sendDemoSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(sendDemoError(error.message));
            });
    };
};

const sendDemoSuccess= (data) => ({
    type: DEMO.SEND_DEMO_SUCCESS,
    payload: data
});

const sendDemoError = errorMessage => ({
    type: DEMO.SEND_DEMO_FAIL,
    payload: errorMessage
});

export const updateDemoStatus = (data) => {
    return (dispatch) => {
        dispatch({ type: DEMO.UPDATE_DEMO_REQUEST });
        const AuthStr = 'Bearer '.concat('GFYUFGTYGFTYTY64564545acvbvrttyFG@%#%#%#%#TTRR');
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-demo/update-status", inputJSON)
            .then(function (response) {
                dispatch(updateDemoSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateDemoError(error.message));
            });
    };
};

const updateDemoSuccess= (data) => ({
    type: DEMO.UPDATE_DEMO_SUCCESS,
    payload: data
});

const updateDemoError = errorMessage => ({
    type: DEMO.UPDATE_DEMO_FAIL,
    payload: errorMessage
});

export const getRecipientList = (token, data) => {
    return (dispatch) => {
        dispatch({ type: DEMO.LIST_RECIPIENT_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        let inputJSON = JSON.stringify(data);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        
        INSTANCE.post("game-demo/get-recipient-list", inputJSON)
            .then(function (response) {
                dispatch(getListSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(getListError(error.message));
            });
    };
};

const getListSuccess= (data) => ({
    type: DEMO.LIST_RECIPIENT_SUCCESS,
    payload: data
});

const getListError = errorMessage => ({
    type: DEMO.LIST_RECIPIENT_FAIL,
    payload: errorMessage
});

export const searchRecipient = (data, token) => {
    return (dispatch) => {
        dispatch({ type: DEMO.SEARCH_RECIPIENT_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("game-demo/search-recipient", inputJSON)
            .then(function (response) {
                dispatch(searchRecipientSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(searchRecipientError(error.message));
            });
    };
};

const searchRecipientSuccess= (data) => ({
    type: DEMO.SEARCH_RECIPIENT_SUCCESS,
    payload: data
});

const searchRecipientError = errorMessage => ({
    type: DEMO.SEARCH_RECIPIENT_FAIL,
    payload: errorMessage
});

export const resetSendDemo = () => {
    return (dispatch) => {
        dispatch({ type: DEMO.RESET_DEMO})
    }
}