import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AppUsersReport from "./appUsers";
import CustomBreadCrumb from 'components/Widgets/CustomBreadCrumb';

const Main = ({match}) => (
  <div>
  <CustomBreadCrumb home={true}/>
  <Switch>
    <Route path={`${match.url}/customers`} component={AppUsersReport}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/customers`} />
    </Route>
  </Switch>
  </div>
);

export default Main;
