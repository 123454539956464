import { GAME_SEARCH_TAG } from "../../../constants/actionTypes";
import axios from 'axios';

export const addGameSubCategory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-search-tag/create", inputJSON)
            .then(function (response) {
                dispatch(addGameSubCategorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addGameSubCategoryError(error.message));
            });
    };
};

const addGameSubCategorySuccess = (data) => ({
    type: GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_SUCCESS,
    payload: data
});

const addGameSubCategoryError = errorMessage => ({
    type: GAME_SEARCH_TAG.ADD_GAME_SEARCH_TAG_FAIL,
    payload: errorMessage
});

export const listGameSubCategory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_REQUEST });
      const AuthStr = 'Bearer '.concat(token);
      let inputJSON = JSON.stringify(data);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        
        INSTANCE.post("game-search-tag/lists", inputJSON)
            .then(function (response) {
                dispatch(listGameSubCategorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(listGameSubCategoryError(error.message));
            });
    };
};

const listGameSubCategorySuccess = (data) => ({
    type: GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_SUCCESS,
    payload: data
});

const listGameSubCategoryError = errorMessage => ({
    type: GAME_SEARCH_TAG.LIST_GAME_SEARCH_TAG_FAIL,
    payload: errorMessage
});

export const getAllGameSubCategory = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-search-tag/get-all-tags")
      .then(function (response) {
        dispatch(getAllGameSubCategorySuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGameSubCategoryError(error.message));
      });
  };
};

const getAllGameSubCategorySuccess = (data) => ({
  type: GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_SUCCESS,
  payload: data
});

const getAllGameSubCategoryError = errorMessage => ({
  type: GAME_SEARCH_TAG.GET_ALL_GAME_SEARCH_TAG_FAIL,
  payload: errorMessage
});

export const deleteGameSubCategory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-search-tag/delete", inputJSON)
            .then(function (response) {
                dispatch(deleteGameSubCategorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(deleteGameSubCategoryError(error.message));
            });
    };
};

const deleteGameSubCategorySuccess = (data) => ({
    type: GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_SUCCESS,
    payload: data
});

const deleteGameSubCategoryError = errorMessage => ({
    type: GAME_SEARCH_TAG.DELETE_GAME_SEARCH_TAG_FAIL,
    payload: errorMessage
});

export const updateGameSubCategory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-search-tag/update", inputJSON)
            .then(function (response) {
                dispatch(updateGameSubCategorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateGameSubCategoryError(error.message));
            });
    };
};

const updateGameSubCategorySuccess = (data) => ({
    type: GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_SUCCESS,
    payload: data
});

const updateGameSubCategoryError = errorMessage => ({
    type: GAME_SEARCH_TAG.UPDATE_GAME_SEARCH_TAG_FAIL,
    payload: errorMessage
});

export const searchGameSubCategory = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("game-search-tag/search-category-tag", inputJSON)
      .then(function (response) {
        dispatch(searchGameSubCategorySuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchGameSubCategoryError(error.message));
      });
  };
};

const searchGameSubCategorySuccess = (data) => ({
  type: GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_SUCCESS,
  payload: data
});

const searchGameSubCategoryError = errorMessage => ({
  type: GAME_SEARCH_TAG.SEARCH_GAME_SEARCH_TAG_FAIL,
  payload: errorMessage
});

export const resetGameSubCategory = () => {
    return (dispatch) => {
        dispatch({ type: GAME_SEARCH_TAG.RESET_GAME_SEARCH_TAG})
    }
}
