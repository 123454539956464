import React from "react";
import { Divider, Breadcrumb, notification, Button, Modal, Tag, Input } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { FaTrashAlt, FaRegEdit, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  listGameCategoryTags,
  deleteGameCategoryTag,
  searchGameCategoryTag,
  resetGameCategoryTag
} from "../../../../appRedux/actions/gameCatalog/gameCategoryTag";

const { Search } = Input;
const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListGameCategoryTag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      gameCategoryTagList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      width: 140,
      render: (color) => { return (<span>{color}</span>) }
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }, {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Deleted' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Deleted',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      sorter: (a, b) => a.createdBy?.firstName.length - b.createdBy?.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (createdBy) => {
        return (<span>{createdBy?.firstName} {createdBy?.lastName}</span>)
      }
    }, {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: 150,
      sorter: (a, b) => a.modifiedBy?.firstName.length - b.modifiedBy.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (modifiedBy) => {
        return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
      }
    }, {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => { return (<span>{moment(createdAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          <span className="gx-link">
            {<Link to={`/game-catalog/edit-game-category-tag/${record._id}`}><FaRegEdit className="icon-size gx-link" /></Link>}
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]

  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameCategoryTagData) {
      if (nextProps.gameCategoryTagData.messageId === 204) {
        return {
          gameCategoryTagList: nextProps.gameCategoryTagData.lists.data,
          pagination: {
            current: nextProps.gameCategoryTagData.lists.current,
            pageSize: nextProps.gameCategoryTagData.lists.pageSize,
            total: nextProps.gameCategoryTagData.lists.totalCount
          }
        }
      } else if (nextProps.gameCategoryTagData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameCategoryTagData.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetGameCategoryTag();
      } else if (nextProps.gameCategoryTagData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameCategoryTagData.alertMessage
        });
        nextProps.resetGameCategoryTag();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete ' + record.name + '?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteGameCategoryTag(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.name + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteGameCategoryTag(authUser.token, data);
  }


  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listGameCategoryTags(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: 1,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchGameCategoryTag(inputJson, authUser.token);
    }
  };

  onChange = e => {
    if (e.type === 'click') {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.gameCategoryTagData;
    const { pagination, gameCategoryTagList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Game Category Tags</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Game Category Tags" navigateTo="/game-catalog/add-game-category-tag" linkName="Add Game Category Tag" />
        <Row className="row-margin2">
          <Col>
            <Search
              placeholder="Search by Name or Description"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={this.onSearch}
              onChange={this.onChange}
            />
          </Col>
        </Row>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={gameCategoryTagList}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={loader}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameCategoryTagData: state.gameCategoryTagData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({   
    listGameCategoryTags,
    deleteGameCategoryTag,
    searchGameCategoryTag,
    resetGameCategoryTag 
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListGameCategoryTag);
