import React, { Component } from "react";
import { Button, Card } from "antd";
import { Row, Col, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import lodash from "lodash";

import CircularProgress from "components/CircularProgress";

class AdminGameTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      gameTemplate: null
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const id = this.props.match.params.id;
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      INSTANCE.get(`game-template/get-by-id/${id}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              loader: false
            })
          }
        })
        .catch((error) => {
        })
    } else {
      this.setState({ loader: false })
    }
  }

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onFinish = values => {
  };

  renderAssetRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.assets ? this.state.gameTemplate.assets.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
  renderSpritesRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Row className="row-margin2">
          <Col>
            <Table responsive bordered key={spriteGroup}>
              <thead>
                <tr className="asset-table-title">
                  <td>Sprite Group</td>
                  <td>Asset Name</td>
                  <td>Resolution</td>
                  <td>Type of File</td>
                  <td>Size of File</td>
                  <td>Asset Example</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {sprites ? sprites.map((asset, index) => (
                    <tr key={index}>
                      <td>{asset.assetType}</td>
                      <td>{asset.assetName}</td>
                      <td>{asset.resolution}</td>
                      <td>{asset.fileType}</td>
                      <td>{this.convertFileSize(asset.fileSize)}</td>
                      <td>{asset.fileName}</td>
                    </tr>
                  )) : null}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    })
  }

  renderAudioRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {this.state.gameTemplate && this.state.gameTemplate.audios ? this.state.gameTemplate.audios.map((asset, index) => (
                  <tr key={index}>
                    <td>{asset.assetType}</td>
                    <td>{asset.assetName}</td>
                    <td>{asset.fileType}</td>
                    <td>{this.convertFileSize(asset.fileSize)}</td>
                    <td>{asset.fileName}</td>
                  </tr>
                )) : null}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
  render() {
    const { gameTemplate, loader } = this.state;
    return (
      <div>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <div>
            <Card>
              <Row className="row-margin">
                <Col>
                  <span className="sumission-title">2D Templates Admin</span>
                </Col>
                <Col lg="3" className="submit-container">
                  {gameTemplate ?
                    <Button className="submit-button" htmlType="submit">
                      Submit
                    </Button>
                    : null}
                </Col>
              </Row>
            </Card>
            {gameTemplate ?
              <Form name="gameTemplateForm">
                <Row className="row-margin2">
                  <Col xs lg="4">
                    <Form.Control as="select" custom>
                      <option>Admin Name</option>
                      <option>Sherry Carani</option>
                      <option>David Kid</option>
                    </Form.Control>
                  </Col>
                  <Col xs lg="2"></Col>
                  <Col >
                    <h3> Payment Offers for Reskinning The Game</h3>
                  </Col>
                </Row>
                <Row className="row-margin2">
                  <Col xs lg="6">
                    <Form.Control placeholder="Asset Name" readOnly value={gameTemplate.title} />
                  </Col>
                  <Col>
                    <Form.Control placeholder="Asset Offer $$$" />
                  </Col>
                  <Col>
                    <Form.Control placeholder="Sprite Offer $$$" />
                  </Col>
                </Row>
                <Row className="row-margin2">
                  <Col>
                    <Form.Control placeholder="Template ID#" readOnly value={gameTemplate._id} />
                  </Col>
                  <Col>
                    <Form.Control placeholder="New Skin ID#" />
                  </Col>
                  <Col>
                    <Form.Control placeholder="Audio Offer $$$" />
                  </Col>
                  <Col>
                    <Form.Control placeholder="Game Teasers Offer $$$" />
                  </Col>
                </Row>
                <Row className="row-margin2">
                  <Col>
                    <span className="assets-title">ASSETS</span>
                  </Col>
                </Row>
                {this.renderAssetRow()}
                <Row className="row-margin2">
                  <Col>
                    <span className="assets-title">SPRITES</span>
                  </Col>
                </Row>
                {this.renderSpritesRow()}
                <Row className="row-margin2">
                  <Col>
                    <span className="assets-title">AUDIO</span>
                  </Col>
                </Row>
                {this.renderAudioRow()}
                <Row className="row-margin2">
                  <Col>
                    <span className="assets-title">Game Graphic Teasers (Optional)</span>
                  </Col>
                </Row>
                <Row className="row-margin2">
                  <Col xs lg="2">
                    <div style={{ height: "80px", borderStyle: "solid" }}>{gameTemplate.shortDescription}</div>
                  </Col>
                </Row>
              </Form>
              : null}
          </div>
        }
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(AdminGameTemplate);
