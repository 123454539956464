import { TOKKENZ_TYPE } from "../../constants/actionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  messageId: null,
  lists: [],
  active: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOKKENZ_TYPE.RESET_TOKKENZ_TYPE: {
      return {
        ...state,
        loader: false,
        alertMessage: "",
        messageId: null,
      };
    }

    case TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
      };
    }

    case TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload,
      };
    }

    case TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload,
      };
    }

    case TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data,
      };
    }

    case TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data,
      };
    }

    case TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    case TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 215,
        active: action.payload,
      };
    }

    case TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203,
      };
    }

    default:
      return state;
  }
};
