import { GAME_CATEGORY_TAG } from "../../../constants/actionTypes";
import axios from 'axios';

export const addGameCategoryTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_CATEGORY_TAG.ADD_GAME_CATEGORY_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-category-tag/create", inputJSON)
            .then(function (response) {
                dispatch(addGameCategoryTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addGameCategoryTagError(error.message));
            });
    };
};

const addGameCategoryTagSuccess = (data) => ({
    type: GAME_CATEGORY_TAG.ADD_GAME_CATEGORY_TAG_SUCCESS,
    payload: data
});

const addGameCategoryTagError = errorMessage => ({
    type: GAME_CATEGORY_TAG.ADD_GAME_CATEGORY_TAG_FAIL,
    payload: errorMessage
});

export const listGameCategoryTags = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_CATEGORY_TAG.LIST_GAME_CATEGORY_TAG_REQUEST });
      const AuthStr = 'Bearer '.concat(token);
      let inputJSON = JSON.stringify(data);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        
        INSTANCE.post("game-category-tag/lists", inputJSON)
            .then(function (response) {
                dispatch(listGameCategoryTagsSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(listGameCategoryTagsError(error.message));
            });
    };
};

const listGameCategoryTagsSuccess = (data) => ({
    type: GAME_CATEGORY_TAG.LIST_GAME_CATEGORY_TAG_SUCCESS,
    payload: data
});

const listGameCategoryTagsError = errorMessage => ({
    type: GAME_CATEGORY_TAG.LIST_GAME_CATEGORY_TAG_FAIL,
    payload: errorMessage
});

export const getALLGameCategoryTags = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_CATEGORY_TAG.GET_ALL_GAME_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-category-tag/get-all")
      .then(function (response) {
        dispatch(getAllGameCategoryTagsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGameCategoryTagsError(error.message));
      });
  };
};

const getAllGameCategoryTagsSuccess = (data) => ({
  type: GAME_CATEGORY_TAG.GET_ALL_GAME_CATEGORY_TAG_SUCCESS,
  payload: data
});

const getAllGameCategoryTagsError = errorMessage => ({
  type: GAME_CATEGORY_TAG.GET_ALL_GAME_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const deleteGameCategoryTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_CATEGORY_TAG.DELETE_GAME_CATEGORY_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-category-tag/delete", inputJSON)
            .then(function (response) {
                dispatch(deleteGameCategoryTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(deleteGameCategoryTagError(error.message));
            });
    };
};

const deleteGameCategoryTagSuccess = (data) => ({
    type: GAME_CATEGORY_TAG.DELETE_GAME_CATEGORY_TAG_SUCCESS,
    payload: data
});

const deleteGameCategoryTagError = errorMessage => ({
    type: GAME_CATEGORY_TAG.DELETE_GAME_CATEGORY_TAG_FAIL,
    payload: errorMessage
});

export const updateGameCategoryTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_CATEGORY_TAG.UPDATE_GAME_CATEGORY_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-category-tag/update", inputJSON)
            .then(function (response) {
                dispatch(updateGameCategoryTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateGameCategoryTagError(error.message));
            });
    };
};

const updateGameCategoryTagSuccess = (data) => ({
    type: GAME_CATEGORY_TAG.UPDATE_GAME_CATEGORY_TAG_SUCCESS,
    payload: data
});

const updateGameCategoryTagError = errorMessage => ({
    type: GAME_CATEGORY_TAG.UPDATE_GAME_CATEGORY_TAG_FAIL,
    payload: errorMessage
});

export const searchGameCategoryTag = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAME_CATEGORY_TAG.SEARCH_GAME_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("game-category-tag/search-type-tag", inputJSON)
      .then(function (response) {
        dispatch(searchGameCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchGameCategoryTagError(error.message));
      });
  };
};

const searchGameCategoryTagSuccess = (data) => ({
  type: GAME_CATEGORY_TAG.SEARCH_GAME_CATEGORY_TAG_SUCCESS,
  payload: data
});

const searchGameCategoryTagError = errorMessage => ({
  type: GAME_CATEGORY_TAG.SEARCH_GAME_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const resetGameCategoryTag = () => {
    return (dispatch) => {
        dispatch({ type: GAME_CATEGORY_TAG.RESET_GAME_CATEGORY_TAG})
    }
}
