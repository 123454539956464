import {
  GAME_SPECIAL_TAG
} from "../../../constants/actionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  messageId: null,
  lists: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GAME_SPECIAL_TAG.RESET_GAME_SPECIAL_TAG: {
      return {
        ...state,
        loader: false,
        alertMessage: '',
        messageId: null
      }
    }

    case GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: action.payload.messageId
      }
    }

    case GAME_SPECIAL_TAG.ADD_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SPECIAL_TAG.LIST_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SPECIAL_TAG.GET_ALL_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data
      }
    }

    case GAME_SPECIAL_TAG.DELETE_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data
      }
    }

    case GAME_SPECIAL_TAG.UPDATE_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }
    case GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case GAME_SPECIAL_TAG.SEARCH_GAME_SPECIAL_TAG_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    default:
      return state;
  }
}
