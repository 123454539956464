import React, { Component } from "react";
import { notification, Select, Breadcrumb } from "antd";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import GameSkinCard from "components/Widgets/GameSkinCard";
import Auxiliary from "util/auxiliary";
import axios from 'axios';

import {
  listGameTemplateSkin,
  resetGameTemplate
} from "../../../appRedux/actions/gameTemplate";

const { Option } = Select;

class GameSkins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameTemplates: [],
      templateId: null,
      templateName: null,
      tempId: null,
      selectedGameType: null
    }
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        templateId: this.props.match.params.id,
        templateName: this.props.match.params.name,
        tempId: this.props.match.params.tempId
      });
      const { authUser } = this.props.auth;
      this.props.listGameTemplateSkin(authUser.token, this.props.match.params.id);
    }
  }

  onTypeSelect(value) {
    this.setState({ selectedGameType: value });
    const { authUser } = this.props.auth;
    const { templateId } = this.state;
    this.props.listGameTemplateSkin(authUser.token, templateId, value);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        nextProps.resetGameTemplate();
        return {
          gameTemplates: nextProps.gameTemplate.skinLists
        }
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
      }
    }
    return null;
  }
  
  handleDelete = (id, isDeleted) => {
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: id, isDeleted: !isDeleted };
    INSTANCE.post(`game-template/delete-skin`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          const { templateId, selectedGameType } = this.state;
          this.props.listGameTemplateSkin(authUser.token, templateId, selectedGameType);
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'Alert!!',
          description: 'Unable To Delete At the moment!',
        });
      })
  }

  handleHoldUnhold = (id, status) => {
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: id, status: status };
    INSTANCE.post(`game-template/hold-skin`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          const { templateId, selectedGameType } = this.state;
          this.props.listGameTemplateSkin(authUser.token, templateId, selectedGameType);
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'Alert!!',
          description: 'Unable To Update At the moment!',
        });
      })
  }

  renderGameSkinCard() {
    const { gameTemplates, templateId, templateName, tempId } = this.state;
    if (gameTemplates?.length > 0) {
      return gameTemplates.map((item, index) => (
        <Col xl={3} lg={3} md={4} sm={12} xs={12} key={index}>
          <GameSkinCard
            editable={true}
            name={item.title}
            templateId={templateId}
            templateName={templateName}
            tempId={tempId}
            description={item.shortDescription}
            coverImage={item.coverImage ? item.coverImage.url : 'https://via.placeholder.com/576X330'}
            gigAccept={`/game-template/gamey-skin-gig-acceptance/${item._id}`}
            learnMore={`/game-template/prev-template-skin/${templateId}/${templateName}/${tempId}/${item._id}`}
            id={item._id}
            handleDelete={this.handleDelete}
            handleHoldUnhold={this.handleHoldUnhold}
            status={item.status}
            isDeleted={item.isDeleted}
          />
        </Col>
      ))
    }

    return (
      <Col>
        No templates found!!
      </Col>
    )
  }

  render() {
    const { loader } = this.props.gameTemplate;
    const { templateId, templateName, tempId } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/game-template/list" className="gx-link">Game Templates</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{templateName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Game Template Skins" navigateTo={`/game-template/add-template-skin/${templateId}/${templateName}/${tempId}`} linkName="Add Game Template Skin" />
        <Row className="row-margin2" >
          <Col>
            <Select style={{ width: 200 }} placeholder="Filter By Game Type" onSelect={(value) => this.onTypeSelect(value)}>
              <Option value={null}>Filter By Game Type</Option>
              <Option value="2D">2D</Option>
              <Option value="3D">3D</Option>
              <Option value="AR">AR</Option>
              <Option value="VR">VR</Option>
            </Select>
          </Col>
        </Row>
        <Row className="row-margin2">
          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> :
            this.renderGameSkinCard()
          }
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate,
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listGameTemplateSkin, resetGameTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(GameSkins);
