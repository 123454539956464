import { CUSTOMER_GAME_RECEIVED } from "../../constants/actionTypes";
import axios from 'axios';

export const listCustomerGameReceived = (token, data) => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("personalization/get-received-games-customer", inputJSON)
      .then(function (response) {
        dispatch(listCustomerGameReceivedSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listCustomerGameReceivedError(error.message));
      });
  };
};

const listCustomerGameReceivedSuccess = (data) => ({
  type: CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_SUCCESS,
  payload: data
});

const listCustomerGameReceivedError = errorMessage => ({
  type: CUSTOMER_GAME_RECEIVED.LIST_CUSTOMER_GAME_RECEIVED_FAIL,
  payload: errorMessage
});

export const searchGameById = (data, token) => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("personalization/search-game", inputJSON)
      .then(function (response) {
        dispatch(searchGameReceiveduccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchOrderError(error.message));
      });
  };
};

const searchGameReceiveduccess = (data) => ({
  type: CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_SUCCESS,
  payload: data
});

const searchOrderError = errorMessage => ({
  type: CUSTOMER_GAME_RECEIVED.SEARCH_CUSTOMER_GAME_RECEIVED_FAIL,
  payload: errorMessage
});

export const resetCustomerGameReceived = () => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_GAME_RECEIVED.CUSTOMER_GAME_RECEIVED_RESET })
  }
}
