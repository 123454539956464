import {
    GAME_ORDER
} from "../../constants/actionTypes";

const INIT_STATE = {
    isLoading: false,
    alertMessage: '',
    messageId: null,
    lists: [],
    gameOrdersCount: null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GAME_ORDER.GAME_ORDER_RESET: {
            return {
                ...state,
                isLoading: false,
                alertMessage: '',
                messageId: null
            }
        }

        case GAME_ORDER.LIST_GAME_ORDER_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }

        case GAME_ORDER.LIST_GAME_ORDER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 200,
                lists: action.payload
            }
        }

        case GAME_ORDER.LIST_GAME_ORDER_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case GAME_ORDER.GAME_ORDER_COUNT_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case GAME_ORDER.GAME_ORDER_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                gameOrdersCount: action.payload
            }
        }

        case GAME_ORDER.GAME_ORDER_COUNT_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message
            }
        }

        case GAME_ORDER.SEARCH_GAME_ORDER_REQUEST: {
            return {
              ...state,
              loader: true
            }
          }
      
          case GAME_ORDER.SEARCH_GAME_ORDER_SUCCESS: {
            return {
              ...state,
              loader: false,
              messageId: 200,
              lists: action.payload
            }
          }
      
          case GAME_ORDER.SEARCH_GAME_ORDER_FAIL: {
            return {
              ...state,
              loader: false,
              alertMessage: action.payload.message,
              messageId: 203
            }
          }

        default:
            return state;
    }
}
