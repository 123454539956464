import React, { Component } from "react";
import { Button, Breadcrumb, Card, Form, Input, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  addGreetingCategoryTag,
  updateGreetingCategoryTag,
  resetGreetingCategoryTag
} from "./../../../appRedux/actions/greetingCategoryTag";

const FormItem = Form.Item;

class AddGreetingCategoryTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: false
    }

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { authUser } = this.props.auth;

    if (this.props.match.params.id) {
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("greeting-category-tag/get-by-id", inputJSON).then((response) => {
        if (response.data.messageId === 200) {
          this.formRef.current.setFieldsValue({
            name: response.data.data.name,
            description: response.data.data.description
          });
          this.setState({
            editId: this.props.match.params.id
          })
        }
      })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.greetingCategoryTag) {
      if (nextProps.greetingCategoryTag.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.greetingCategoryTag.alertMessage
        });
        nextProps.resetGreetingCategoryTag();
      } else if (nextProps.greetingCategoryTag.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.greetingCategoryTag.alertMessage
        });
        nextProps.resetGreetingCategoryTag();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = {
      name: values.name,
      description: values.description
    }
    if (editId) {
      inputJSON._id = editId;
      this.props.updateGreetingCategoryTag(authUser.token, inputJSON);
    } else {
      this.props.addGreetingCategoryTag(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.greetingCategoryTag;
    const { editId } = this.state;

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/greeting-category-tag/list" className="gx-link">Greeting Category Tag</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Greeting Category Tag" : "Add Greeting Category Tag"} />
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Form
                  name="greetingCategoryTagForm"
                  onFinish={this.onFinish}
                  layout={'vertical'}
                  ref={this.formRef}
                >
                  <FormItem label="Tag Name" rules={[{ required: true, message: 'Please enter Tag name.' }]} name="name">
                    <Input placeholder="Tag Name" />
                  </FormItem>

                  <FormItem label="Description" name="description">
                    <Input.TextArea placeholder="Description" rows={4} />
                  </FormItem>

                  <FormItem>
                    <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    greetingCategoryTag: state.greetingCategoryTag
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    addGreetingCategoryTag,
    updateGreetingCategoryTag,
    resetGreetingCategoryTag
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddGreetingCategoryTag);
