import React, { Component } from "react";
import { Button, Card, Form, Input, Select, Checkbox, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  addInterest,
  updateInterest,
  resetInterest
} from "./../../../appRedux/actions/interest";

const FormItem = Form.Item;
const { Option } = Select;

class AddInterestTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      interestList: [{
        name: 'Hobbies'
      }, {
        name: 'Movies'
      }, {
        name: 'Music'
      }, {
        name: 'Sports'
      }]
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("interest/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              type: response.data.data.type,
              isPopular: response.data.data.isPopular ? response.data.data.isPopular : false 
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.interests) {
      if (nextProps.interests.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.interests.alertMessage
        });
        nextProps.resetInterest();
      } else if (nextProps.interests.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.interests.alertMessage
        });
        nextProps.resetInterest();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    if (editId) {
      let inputJSON = values;
      inputJSON._id = editId;
      this.props.updateInterest(authUser.token, inputJSON);
    } else {
      this.props.addInterest(authUser.token, values);
    }
  };

  render() {
    const { loader } = this.props.interests;
    const { editId, interestList } = this.state;
    return (
      <Auxiliary>
        <PageHeader title={editId ? "Edit Interest" : "Add Interest"} />
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Form
                  name="interestForm"
                  onFinish={this.onFinish}
                  layout="vertical"
                  ref={this.formRef}
                >
                  <FormItem label="Interest Type" name="type" rules={[{ required: true, message: 'Please select interest type.' }]}>
                    <Select placeholder="Select Interest Type">
                      {interestList.map(item => (
                        <Option key={item.name}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Interest Name" rules={[{ required: true, message: 'Please enter interest name.' }]} name="name">
                    <Input placeholder="Interest Name" />
                  </FormItem>

                  <Form.Item name="isPopular" valuePropName="checked">
                    <Checkbox>Popular</Checkbox>
                  </Form.Item>

                  <FormItem>
                    <Button className="gx-mb-0" type="primary" htmlType="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    interests: state.interests
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addInterest, updateInterest, resetInterest }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddInterestTitle);
