import React, { Component } from "react";
import { Button, Form, Input, notification } from "antd";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import { Link } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false
    }

    this.formRef = React.createRef();
  };

  onFinish = values => {

    this.setState({
      loader: true
    })

    fetch(`${process.env.REACT_APP_API_URL}admin-auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(values)
    }).then(res => res.json()).then((json) => {
      if (json.messageId === 200) {
        this.setState({
          loader: false
        })
        notification['success']({
          message: 'Success',
          description: json.message
        });
      } else {
        this.setState({
          loader: false
        })
        notification['error']({
          message: 'Alert!!',
          description: json.message,
        });
      }
    });
  };

  render() {
    const { loader } = this.state;
    return (
      <div className="gx-login-container">
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <img className="logo-size" src={require("assets/images/logo-purple.png")} alt="Tokkenz MoneyHunt Games" />
            </div>
            <div className="gx-mb-4">
              <h2>Forgot Your Password ?</h2>
              <p>Don't worry. Recovering the password is easy.Just tell us the email you have registered with us.</p>
            </div>

            <Form
              layout={'vertical'}
              onFinish={this.onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem label="Email" name="email" hasFeedback rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail.',
                },
                {
                  required: true,
                  message: 'Please input your E-mail.',
                },
              ]}>
                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
              </FormItem>

              <FormItem>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormItem>
            </Form>
            <div className="gx-flex-row">
              <span>Go to&nbsp;</span>
              <Link to="/signin">
                Sign In
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ForgotPassword;
