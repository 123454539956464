import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";

import About from "../../../components/Profile/About/index";
import Events from "../../../components/Profile/Events/index";
import Availability from "../../../components/Profile/Availability/index";
import ProfileHeader from "../../../components/Profile/ProfileHeader/index";
import Auxiliary from "util/auxiliary";
import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import {
  getUser,
  resetUser
} from "./../../../appRedux/actions/users";

class PreviewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {

      let inputJSON = {
        _id: authUser.user.userId
      }
      if (this.props.match.params.id) {
        inputJSON._id = this.props.match.params.id
      }
      this.props.getUser(authUser.token, inputJSON);
    }
  }
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.users) {
      if (nextProps.users.messageId === 202) {
        let userData = nextProps.users.userData;
        return {
          userData: userData
        };
      }
    };
    return null;
  }
  render() {
    const { userData } = this.state;
    const { loader } = this.props.users;
    return (
      <Auxiliary>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/users/list" className="gx-link">Users</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>User Details</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title="User Details" />
            <ProfileHeader userData={userData} mode="preview" />
            <div className="gx-profile-content">
              <Row>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <About userData={userData} mode="preview" />
                  {userData && userData.userRole[0].userRole === 8 ?
                    <Events userData={userData} />
                    : null
                  }
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Availability />
                </Col>
              </Row>
            </div>
          </>
        }
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    users: state.users
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ getUser, resetUser }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(PreviewUser);
