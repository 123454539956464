import React, { Component } from "react";
import { Button, Card, Modal } from "antd";
import { Row, Col, Form, Table } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      gameTemplate: {},
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      title:''
    }

    this.history = props.history;

    this.formRef = React.createRef();
  };

  componentDidMount() {

    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      this.setState({loading: true});
      INSTANCE.get(`game-template/get-gig-history-by-id/${this.props.match.params.id}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              loading: false
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  onFinish = values => {
  };

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }
  handleCancel = () => this.setState({ previewVisible: false });

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  renderAssetRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.assets ? this.state.gameTemplate.assets.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Asset")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  renderSpritesRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Row className="row-margin2">
          <Col>
            <Table responsive bordered key={spriteGroup}>
              <thead>
                <tr className="asset-table-title">
                  <td>Sprite Group</td>
                  <td>Asset Name</td>
                  <td>Resolution</td>
                  <td>Type of File</td>
                  <td>Size of File</td>
                  <td>Asset Example</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {sprites ? sprites.map((asset, index) => (
                  <tr key={index}>
                    <td>{asset.assetType}</td>
                    <td>{asset.assetName}</td>
                    <td>{asset.resolution}</td>
                    <td>{asset.fileType}</td>
                    <td>{this.convertFileSize(asset.fileSize)}</td>
                    <td>{asset.fileName}</td>
                    <td> <Button onClick={this.onPreviewClick.bind(this, asset, 'Sprite')}> Preview </Button></td>
                  </tr>
                )) : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    })
  }

  renderAudiosRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.audios ? this.state.gameTemplate.audios.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  render() {
    const {gameTemplate, previewVisible, previewImage, previewTitle} = this.state;
    return (
      gameTemplate === null || gameTemplate === {}  ?
        <Modal 
          visible={true}
          footer={null}
          title="Sorry Unable To Fetch Data"
          closable={false}
        >
          <p>Want To Go Back???</p>
          <div style={{ display:'flex', justifyContent:'flex-end', alignContent:'flex-end'}}>
            <Link className="btn btn-primary" to="/contributor/profile">Go back</Link>
          </div>
        </Modal>:
      <Card>
        <Row className="row-margin">
          <Col>
            <span className="sumission-title">GIG DETAILS</span>
          </Col>
        </Row>
        <Form name="gameTemplateForm">
          <Row className="row-margin2">
            <Col xs lg="6">
              <Table borderless>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Form.Label>Game Template Name</Form.Label>
                      <Form.Control placeholder="Game Template Name" readOnly value={gameTemplate.title || ''} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Label>Template ID#</Form.Label>
                      <Form.Control placeholder="Template ID#" readOnly value={gameTemplate.templateId || ''} />
                    </td>
                    <td>
                      <Form.Label>New Skin ID#</Form.Label>
                      <Form.Control placeholder="New Skin ID#" readOnly value={gameTemplate.skinId || ''} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">ASSETS</span>
            </Col>
          </Row>
          {this.renderAssetRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">SPRITES</span>
            </Col>
          </Row>
          {this.renderSpritesRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">AUDIO (Optional)</span>
            </Col>
          </Row>
          {this.renderAudiosRow()}
         
        </Form>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          {this.state.title === "Audio" ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
      </Card>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(Details));

