import React from "react";
import { Select, Input, Modal } from "antd";

class AddSkill extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      experience_level: '',
    }
  }

  render() {
    const { onAddSkill, onClose } = this.props;
    return (
      <Modal onCancel={onClose} visible={this.props.open}
        title="Add Skill"
        okText="Save"
        closable={false}
        onOk={() => {
          if (this.state.name === '')
            return;
          onClose();
          onAddSkill(this.state)
        }}
        style={{ zIndex: 2600 }}
      >
        <div className="gx-form-group">
          <Input
            placeholder="Example: Designer"
            onChange={(event) => this.setState({ name: event.target.value })}
            margin="normal"/>
        </div>
        <div className="gx-form-group">
          <Select placeholder="Experience level" onSelect={(value) => this.setState({ experience_level: value })} style={{width: '100%'}}>
            <Select.Option key="Beginner">Beginner</Select.Option>
            <Select.Option key="Intermediate">Intermediate</Select.Option>
            <Select.Option key="Expert">Expert</Select.Option>
          </Select>
        </div>
      </Modal>
    );
  }
}

export default AddSkill;
