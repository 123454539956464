import React, { Component } from "react";
import { notification, Breadcrumb } from "antd";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";
import { FaRegEdit } from 'react-icons/fa';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import StatsCard from "components/Widgets/StatsCard";
import Auxiliary from "util/auxiliary";

import {
  listGameTemplate,
  getAvailableGameTemplate,
  resetGameTemplate
} from "../../../appRedux/actions/gameTemplate";

class GameTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameTemplates: [],
      pageSize: 100
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    const { pageSize } = this.state;
    authUser.user.userRoles.some(x => x.name === "Contributor") ?
      this.props.getAvailableGameTemplate(authUser.token, pageSize) :
      this.props.listGameTemplate(authUser.token, pageSize);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        nextProps.resetGameTemplate();
        return {
          gameTemplates: nextProps.gameTemplate.lists
        }
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
      }
    }
    return null;
  }

  renderGameTemplates() {
    const { gameTemplates } = this.state;
    if (gameTemplates?.length > 0) {
      return gameTemplates.map((item, index) => (
        <Col key={index} xl={4} lg={4} md={4} sm={12} xs={12} className="gx-col-full">
          <StatsCard>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-mr-lg-4 gx-mr-3">
                <img src={item.coverImage?.url} alt={item.name} style={{ height: "45px" }} />
              </div>

              <div className="gx-media-body">
                <Link to={`/game-template/template-skins/${item._id}/${item.title}/${item.templateId}`}>
                  <h3 className="gx-fs-font gx-font-weight-medium gx-mb-1 template-link">{item.title}</h3>
                </Link>
                <p className="gx-text-grey gx-mb-0">{item.skinCount} - Games</p>
              </div>
              <span>
                <Link to={`/game-template/edit-template/${item._id}`} title="Edit"><FaRegEdit className="icon-size gx-link" /></Link>
              </span>
            </div>
          </StatsCard>
        </Col>
      ))
    }

    return (
      <Col>
        No templates found!!
      </Col>
    )
  }

  render() {
    const { loader } = this.props.gameTemplate;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Game Templates</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Game Templates" navigateTo="/game-template/add-template" linkName="Add Game Template" />
        <Row className="row-margin2">
          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> :
            this.renderGameTemplates()
          }
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate,
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listGameTemplate, resetGameTemplate, getAvailableGameTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(GameTemplates);
