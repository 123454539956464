import React from "react";
import { Tooltip } from 'antd';

const ToolTip = ({ text, child}) => {
    return (
        <Tooltip placement="top" title={text}>
            {child}
        </Tooltip>
    )
};

export default ToolTip;