import {
    REPORTS
} from "../../constants/actionTypes";
  
  const INIT_STATE = {
    loader: false,
    alertMessage: '',
    messageId: null,
    appUserStep1: null,
    appUserStep2: null,
    appUserStep3: null
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case REPORTS.APP_USER_STEP1_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }
  
        case REPORTS.APP_USER_STEP1_SUCCESS: {
            return {
                ...state,
                loader: false,
                appUserStep1: action.payload
            }
        }
  
        case REPORTS.APP_USER_STEP1_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case REPORTS.APP_USER_STEP2_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }
  
        case REPORTS.APP_USER_STEP2_SUCCESS: {
            return {
                ...state,
                loader: false,
                appUserStep2: action.payload
            }
        }
  
        case REPORTS.APP_USER_STEP2_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case REPORTS.APP_USER_STEP3_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }
  
        case REPORTS.APP_USER_STEP3_SUCCESS: {
            return {
                ...state,
                loader: false,
                appUserStep3: action.payload
            }
        }
  
        case REPORTS.APP_USER_STEP3_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        default:
            return state;
    }
  }
  