import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  InputNumber,
  Form,
  Modal,
  notification,
  Select,
} from "antd";
import { activeTokkenzType } from "../../../appRedux/actions/tokkenzType";

const FormItem = Form.Item;
const { Option } = Select;

class AddTokkenz extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      tokkenzTypeDataList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {
      this.props.activeTokkenzType(authUser.token);
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.tokkenzTypeData) {
      if (nextProps.tokkenzTypeData.messageId === 215) {
        return {
          tokkenzTypeDataList: nextProps.tokkenzTypeData.active.data,
        };
      }
      return true;
    }
    return null;
  }

  onFinish = (values) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const { authUser } = this.props.auth;

        const inputJSON = {
          _id: this.props.ecommerceUserId,
          tokenData: {
            type: values.type,
            count: values.count,
          },
        };

        fetch(`${process.env.REACT_APP_API_URL}app-user/update-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer ".concat(authUser.token),
          },
          body: JSON.stringify(inputJSON),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.messageId === 200) {
              this.setState({
                isLoading: false,
              });
              notification["success"]({
                message: "Success",
                description: json.message,
              });
              this.formRef.current.setFieldsValue({
                type: null,
                count: null,
              });
              this.props.onClose();
            } else {
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "Alert!!",
                description: json.message,
              });
            }
          });
      }
    );
  };

  render() {
    const { onClose, open } = this.props;
    const { isLoading, tokkenzTypeDataList } = this.state;
    return (
      <Modal
        onCancel={onClose}
        visible={open}
        title="Add Tokken"
        closable={true}
        style={{ zIndex: 2600 }}
        footer={null}
      >
        <Form
          layout={"vertical"}
          onFinish={this.onFinish}
          className="gx-signin-form gx-form-row0"
          ref={this.formRef}
          autoComplete="off"
        >
          <FormItem
            label="Select Tokken Type"
            name="type"
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Select placeholder="Select Tokken Type">
              {tokkenzTypeDataList?.map((item) => (
                <Option key={item._id}>{item.name}</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="Total Number Of Tokkenz"
            rules={[{ required: true, message: "This field is required." }]}
            name="count"
          >
            <InputNumber
              min={0}
              style={{ width: "100%" }}
            />
          </FormItem>
          <FormItem>
            <Button
              loading={isLoading}
              className="gx-mb-0"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tokkenzTypeData: state.tokkenzTypeData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      activeTokkenzType,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(AddTokkenz);
