import React, { Component } from "react";
import { Card, Breadcrumb, notification, Modal, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField";

import {
  addSlide,
  updateSlide,
  resetSlide,
} from "../../../../appRedux/actions/marketing/slides";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const MarketingSlideSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .max(50, "Name must be at most 50 characters"),
  url: Yup.string(),
  description: Yup.string()
    .required("Required")
    .max(500, "Description must be at most 500 characters"),
  slideImage: Yup.mixed()
    .test("required", "Required", (file) => {
      if (file) return true;
      return false;
    })
    .test("fileSize", "Slide image must be less than 1 MB.", (file) => {
      return file !== "SlideImage" ? file && file.size <= 1000000 : true;
    })
    .test("fileFormat", "Slide image has unsupported format.", (file) => {
      return file !== "SlideImage"
        ? file && SUPPORTED_FORMATS.includes(file.type)
        : true;
    }),
  status: Yup.boolean().required("Required"),
});

let self;

class AddMarketingSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialFormData: {
        name: "",
        url: "",
        description: "",
        slideImage: null,
        status: false,
      },
      editId: null,
      sliderFile: null,
      editSlideImage: null,
      uploadingFile: false,
      sliderImgError: null,
    };
  }

  componentDidMount() {
    self = this;
    const { authUser } = this.props.auth;
    if (this.props.match.params.id) {
      this.setState({
        uploadingFile: true
      });
      const INSTANCE = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ".concat(authUser.token),
        },
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id,
      });

      INSTANCE.post("marketing/slides/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              uploadingFile: false,
              editId: this.props.match.params.id,
              initialFormData: {
                name: response.data.data.name,
                url: response.data.data.url,
                description: response.data.data.description,
                slideImage: "SlideImage",
                status: response.data.data.status,
              },
              editSlideImage: response.data.data.sliderImg,
            });
          }
        })
        .catch((error) => {
          this.setState({
            uploadingFile: false,
          });
        });
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.slidesData) {
      if (nextProps.slidesData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.slidesData.alertMessage,
        });
        nextProps.resetSlide();
        nextProps.history.goBack();
      } else if (nextProps.slidesData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.slidesData.alertMessage,
        });
        nextProps.resetSlide();
      }
      return true;
    }
    return null;
  }

  onFinish = (values) => {
    const { sliderImgError } = this.state;
    if (!sliderImgError) {
      // Process Form
      let inputJSON = values;
      this.onUploadFile(inputJSON);
      this.setState({ uploadingFile: true });
    } else {
      let newErrors = [];
      if (sliderImgError) {
        newErrors.push(sliderImgError);
      }
      Modal.error({
        title: "Required Fields",
        centered: true,
        content: (
          <Row>
            <Col sm={12} className="error-message">
              {newErrors.map(function (item, i) {
                return (
                  <p key={i} className="error-message">
                    {i + 1 + ") " + item}
                  </p>
                );
              })}
            </Col>
          </Row>
        ),
      });
    }
  };

  async onUploadFile(inputJSON) {
    const { sliderFile } = this.state;
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ".concat(authUser.token),
      },
    });
    if (sliderFile) {
      let formData = new FormData();
      formData.append("path", "marketing/slides/");
      formData.append("image", sliderFile);
      const resp = await INSTANCE.post("upload-file/upload", formData);
      if (resp.data.messageId === 200) {
        inputJSON.sliderImg = {
          originalName: resp.data.data.originalname,
          mimeType: resp.data.data.mimetype,
          location: resp.data.data.location,
          key: resp.data.data.key,
        };
      } else {
        notification["error"]({
          message: "Alert!!",
          description: "Error uploading image. Please try again.",
        });
        this.setState({ uploadingFile: false });
        return;
      }
    }
    this.onFinishSubmit(authUser.token, inputJSON);
  }

  onFinishSubmit(token, inputJSON) {
    const { editId } = this.state;
    const { authUser } = this.props.auth;
    delete inputJSON.slideImage;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateSlide(token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addSlide(token, inputJSON);
    }
  }

  render() {
    const { isLoading } = this.props.slidesData;
    const {
      editId,
      initialFormData,
      uploadingFile,
      sliderFile,
      editSlideImage,
    } = this.state;

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/marketing/slides" className="gx-link">
                  Marketing Slides
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {editId ? "Edit Slide" : "Add Slide"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Slide" : "Add Slide"} />
        {isLoading || uploadingFile ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : (
          <Row className="row-margin2">
            <Col>
              <Card>
                <Formik
                  validationSchema={MarketingSlideSchema}
                  onSubmit={(values) => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={initialFormData}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>
                            Name
                            <RequiredField />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          {errors.name && touched.name ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>URL</Form.Label>
                          <Form.Control
                            type="text"
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>
                            Description
                            <RequiredField />{" "}
                            <small>(Max 500 characters)</small>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                            rows="5"
                          />
                          {errors.description && touched.description ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.description}
                            </Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>
                            Slide Image
                            <RequiredField />{" "}
                            <small>
                              (Only jpg, jpeg, png up to 1 MB & width 340px &
                              height 300px allowed.)
                            </small>
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="slideImage"
                            accept=".jpg,.jpeg,.png"
                            isInvalid={!!errors.slideImage}
                            onChange={(event) => {
                              let image = event.currentTarget.files[0];
                              setFieldValue("slideImage", image);
                              if (image) {
                                let reader = new FileReader();
                                reader.readAsDataURL(image);
                                reader.onload = function (e) {
                                  let imageObj = new Image();
                                  imageObj.src = e.target.result;
                                  imageObj.onload = function () {
                                    if (this.height > 310 || this.width > 350) {
                                      let newElement =
                                        "Slide image width and height are invalid. Allowed dimensions are width 340px & height 300px";
                                      self.setState({
                                        sliderImgError: newElement,
                                        sliderFile: image,
                                      });
                                    } else {
                                      self.setState({
                                        sliderFile: image,
                                        sliderImgError: null,
                                      });
                                    }
                                  };
                                };
                              }
                            }}
                          />
                          {errors.slideImage && touched.slideImage ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.slideImage}
                            </Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          {sliderFile && (
                            <img
                              width={"340px"}
                              height={"300px"}
                              src={URL.createObjectURL(sliderFile)}
                              alt="Slider"
                            />
                          )}
                          {!sliderFile && editSlideImage ? (
                            <img
                              width={"340px"}
                              height={"300px"}
                              src={editSlideImage.location}
                              alt="Slider"
                            />
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row
                        className="formrow"
                        style={{ marginTop: "20px" }}
                      >
                        <Col>
                          <Form.Label>Status</Form.Label>
                          <Switch
                            checkedChildren="RELEASED"
                            unCheckedChildren="DRAFT"
                            defaultChecked={values.status}
                            onChange={(checked) => {
                              setFieldValue("status", checked);
                            }}
                            style={{ marginLeft: "20px" }}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <Button type="submit">
                            {editId ? "Update" : "Submit"}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        )}
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    slidesData: state.slidesData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      addSlide,
      updateSlide,
      resetSlide,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(AddMarketingSlide);
