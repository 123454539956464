import React, { Component } from "react";
import { notification } from "antd";
import lodash from "lodash";
import { Button, Form, Col } from 'react-bootstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from "components/CircularProgress/index";

import {
  userSignUp,
  resetUserAuth
} from "../../appRedux/actions/auth";

import {
  getCountry, getStates, getCity
} from "../../appRedux/actions/location";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  mobilePhone: Yup.string().matches(/^\d{10}$/, 'Invalid mobile phone').required('Required').min(10, 'Invalid mobile phone').max(10, 'Invalid mobile phone'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(6, 'Too Short!').max(20, 'Too Long!'),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
});

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: null,
      countryList: [],
      stateList: [],
      cityList: []
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    this.props.getCountry();
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.auth) {
      if (nextProps.auth.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.auth.alertMessage
        });
        nextProps.resetUserAuth();
      } else if (nextProps.auth.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.auth.alertMessage
        });
        nextProps.resetUserAuth();
      }
    }

    if (nextProps.locationData.messageId === 200) {
      return {
        countryList: nextProps.locationData.countryList
      }
    }

    if (nextProps.locationData.messageId === 204) {
      return {
        stateList: nextProps.locationData.stateList
      }
    }

    if (nextProps.locationData.messageId === 205) {
      return {
        cityList: nextProps.locationData.cityList
      }
    }

    return null;
  }

  onFinish = values => {
    const { countryList, stateList } = this.state;
    let countryObj = lodash.find(countryList, function (o) { return o.isoCode === values.country; });
    let stateObj = lodash.find(stateList, function (o) { return o.isoCode === values.state; });

    values.country = countryObj.name;
    values.state = stateObj.name;
    values.dialCode = '+' +  countryObj.phonecode;
    values.userRole = 8; // Contributor
    this.props.userSignUp(values);
  };

  getStatesByCountry(countryCode) {
    let Input = {
      countryCode: countryCode
    }
    this.props.getStates(Input);
  }

  getCitiesByStates(stateCode) {
    let Input = {
      stateCode: stateCode
    }
    this.props.getCity(Input);
  }

  render() {
    const { loader } = this.props.auth;
    const { countryList, stateList, cityList } = this.state;
    return (
      <div className="gx-login-container">
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <div className="gx-login-content" style={{ maxWidth: "550px" }}>
            <div className="gx-login-header gx-text-center">
              <img className="logo-size" src={require("assets/images/logo-purple.png")} alt="Tokkenz MoneyHunt Games"/>
            </div>
            <div className="gx-mb-4">
              <h2>Sign Up</h2>
            </div>
            <Formik
              validationSchema={SignupSchema}
              onSubmit={values => {
                this.onFinish(values);
              }}
              initialValues={{
                firstName: '',
                lastName: '',
                mobilePhone: '',
                email: '',
                password: '',
                country: '',
                state: '',
                city: '',
                terms: false
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row className="formrow">
                    <Col>
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isInvalid={!!errors.firstName}
                      />
                      {errors.firstName && touched.firstName ? (
                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isInvalid={!!errors.lastName}
                      />
                      {errors.lastName && touched.lastName ? (
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Form.Row>
                  <Form.Row className="formrow">
                    <Col>
                      <Form.Label>Mobile Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        required
                        name="mobilePhone"
                        value={values.mobilePhone}
                        onChange={handleChange}
                        isInvalid={!!errors.mobilePhone}
                      />
                      {errors.mobilePhone && touched.mobilePhone ? (
                        <Form.Control.Feedback type="invalid">{errors.mobilePhone}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      {errors.email && touched.email ? (
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        required
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      {errors.password && touched.password ? (
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="country"
                        required
                        value={values.country}
                        onChange={e => {
                          const { value } = e.target;
                          this.getStatesByCountry(value);
                          setFieldValue("country", value);
                        }}
                        isInvalid={!!errors.country}>
                        <option value={null}>Select Country</option>
                        {countryList.map(item => (
                          <option key={item._id} value={item.isoCode}>{item.name}</option>
                        ))}
                      </Form.Control>
                      {errors.country && touched.country ? (
                        <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Form.Row>
                  <Form.Row className="formrow">
                    <Col>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="state"
                        required
                        value={values.state}
                        onChange={e => {
                          const { value } = e.target;
                          this.getCitiesByStates(value);
                          setFieldValue("state", value);
                        }}
                        isInvalid={!!errors.state}>
                        <option value={null}>Select State</option>
                        {stateList.map(item => (
                          <option key={item._id} value={item.isoCode}>{item.name}</option>
                        ))}
                      </Form.Control>
                      {errors.state && touched.state ? (
                        <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        name="city"
                        required
                        value={values.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}>
                        <option value={null}>Select City</option>
                        {cityList.map(item => (
                          <option key={item._id} value={item.name}>{item.name}</option>
                        ))}
                      </Form.Control>
                      {errors.city && touched.city ? (
                        <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Form.Row>
                  <Form.Row className="formrow">
                    <Col>
                      <Form.Check
                        name="terms"
                        required
                        onChange={handleChange}
                        isInvalid={!!errors.terms}
                        feedback={errors.terms}
                      />
                      By signing up, I accept &nbsp;
                      <span className="gx-signup-form-forgot gx-link">
                        Terms & Conditions
                      </span>
                      <span>&nbsp; and &nbsp;</span>
                      <span className="gx-signup-form-forgot gx-link">
                        Privacy Policy
                      </span>
                    </Col>
                  </Form.Row>
                  <Form.Row className="btnrow">
                    <Col>
                      <Button type="submit">
                        Sign Up
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              )}
            </Formik>
            <div className="gx-flex-row">
              <span>Already have an account?&nbsp;</span>
              <Link to="/signin">
                Sign In
              </Link>
            </div>
          </div>
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    locationData: state.locationData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ userSignUp, resetUserAuth, getCountry, getStates, getCity }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(SignUp);
