import React, { Component } from "react";
import { Button, Card, Upload, notification, Modal, message } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";

const confirm = Modal.confirm;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class TemplateSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s3Folder: 'game-template',
      loading: false,
      gameTemplate: {},
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      source: '',
      license: '',
      assetFile: '',
      title: '',
      loaderUI: false,
      showSubmitConfirm: false,
      showRejectConfirm: false,
      showUpdateConfirm: false
    }

    this.history = this.props.history;

    this.formRef = React.createRef();
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });

    INSTANCE.get(`game-template/get-by-userid/${authUser.user.userId}`)
      .then((response) => {
        if (response.data.messageId === 200) {
          this.setState({
            gameTemplate: response.data.data
          })
        }
      })
      .catch((error) => {
      })
  }

  onFinish = values => {
  };

  openNotificationWithIcon = (messageId, message) => {
    if (messageId === 200) {
      notification['success']({
        message: 'Success',
        description: message,
      });
    } else {
      notification['error']({
        message: 'Alert!!',
        description: message,
      });
    }
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }
  handleCancel = () => this.setState({ previewVisible: false });


  renderUploadBtn() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    )
  }

  onRejectGigClick = () => {
    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: gameTemplate._id, contributorId: authUser.user.userId };
    INSTANCE.post(`game-template/reject-gig/`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          this.history.push('/game-template/game-templates');
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
      })
  }

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onFileChange = (asset, title, fileType, event) => {
    let files = event.target.files;
    const { gameTemplate } = this.state;
    if (fileType === "License") {
      asset.license = {
        file: files[0],
        fileSize: files[0].size,
        fileType: files[0].type,
        fileName: files[0].name,
      }
      this.setState({ gameTemplate: gameTemplate });
      return false;
    }

    if (title !== "Audio" && ["image/png", "image/jpeg", "image/jpg"].includes(files[0].type)) {
      var img = new Image();
      img.src = window.URL.createObjectURL(files[0]);
      img.onload = () => {
        var width = img.naturalWidth, height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        const resolution = asset.resolution.split("*")
        if (width >= parseInt(resolution[0]) && height >= parseInt(resolution[1])) {
          asset.assetFile = files[0];
          asset.fileSize = files[0].size;
          asset.fileType = files[0].type;
          asset.fileName = files[0].name;
          this.setState({ assetFile: files[0].name });
          this.setState({ gameTemplate: gameTemplate });
        } else {
          if (width > parseInt(resolution[0])) {
            notification['error']({
              message: 'Alert!!',
              description: "The asset you added is too big.  Please re-submit",
            });
          }
          else {
            notification['error']({
              message: 'Alert!!',
              description: "The asset you added is too small.  Please re-submit",
            });
          }
        }

      }
    }
    else if (title === "Audio" && ["audio/mpeg"].includes(files[0].type)) {
      asset.assetFile = files[0];
      asset.fileSize = files[0].size;
      asset.fileType = files[0].type;
      asset.fileName = files[0].name;
      this.setState({ gameTemplate: gameTemplate });
    }
    else {
      notification['error']({
        message: 'Alert!!',
        description: "The asset you added is the wrong file type.  Please re-submit.",
      });
    }
    return false;
  }

  onSubmitClick = () => {
    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    INSTANCE.post(`game-template/submit-gig/`, JSON.stringify(gameTemplate))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          this.history.push('/game-template/approve-gigs');
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
      })
  }


  renderAssetRow() {
    const { authUser } = this.props.auth;

    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Name of Contributor</td>
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Source</td>
                <td>License</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>

            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.assets ? this.state.gameTemplate.assets.map((asset, index) => (
                <tr key={index}>
                  <td>{authUser.user.firstName}</td>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.source}</td>
                  <td>{asset.license?.fileName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, 'Asset')}>Preview</Button>
                    {this.state.gameTemplate.isAssetsAndSprites ? <Button onClick={this.updateConfirm.bind(this, asset, "Asset")}>Update</Button> : null}
                  </td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  renderSpriteRow() {
    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Row className="row-margin2">
          <Col>
            <Table responsive bordered key={spriteGroup}>
              <thead>
                <tr className="asset-table-title">
                  <td>Name of Contributor</td>
                  <td>Type of Asset</td>
                  <td>Asset Name</td>
                  <td>Source</td>
                  <td>License</td>
                  <td>Resolution</td>
                  <td>Type of File</td>
                  <td>Size of File</td>
                  <td>Asset Example</td>
                  <td style={{ width: '250px' }}>Action</td>
                </tr>
              </thead>

              <tbody>
                {sprites ? sprites?.map((asset, index) => (
                  <tr key={index}>
                    <td>{authUser.user.firstName}</td>
                    <td>{asset.assetType}</td>
                    <td>{asset.assetName}</td>
                    <td>{asset.source}</td>
                    <td>{asset.license?.fileName}</td>
                    <td>{asset.resolution}</td>
                    <td>{asset.fileType}</td>
                    <td>{this.convertFileSize(asset.fileSize)}</td>
                    <td>{asset.fileName}</td>
                    <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Sprite")}> Preview </Button>
                      {this.state.gameTemplate.isAssetsAndSprites ? <Button onClick={this.updateConfirm.bind(this, asset, "Sprite")}>Update</Button> : null}
                    </td>
                  </tr>
                )) : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    })
  }

  renderAudioRow() {
    const { authUser } = this.props.auth;

    return (
      <Row className="row-margin2" >
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Name of Contributor</td>
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Source</td>
                <td>License</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td style={{ width: '250px' }}>Action</td>
              </tr>
            </thead>

            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.audios ? this.state.gameTemplate.audios.map((asset, index) => (
                <tr key={index}>
                  <td>{authUser.user.firstName}</td>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.source}</td>
                  <td>{asset.license?.fileName}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}> Preview </Button>
                    {this.state.gameTemplate.isAudios ? <Button onClick={this.updateConfirm.bind(this, asset, "Audio")}>Update</Button> : null}
                  </td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  onDataChange = (asset, title) => {
    this.setState({ loaderUI: true });
    const { authUser } = this.props.auth;
    const { gameTemplate, source, s3Folder } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const formData = new FormData();
    formData.append('path', `${s3Folder}/${gameTemplate.templateId}/${gameTemplate.skinId}/`);
    formData.append('image', asset.assetFile);
    INSTANCE.post('game-template/upload-asset', formData)
      .then((response) => {
        if (response.data.messageId === 200) {
          asset.assetFile = response.data.data.location;
          asset.assetKey = response.data.data.key;
          asset.source = source;

          if (asset.license) {
            const formData = new FormData();
            formData.append('path', `${s3Folder}/${gameTemplate.templateId}/${gameTemplate.skinId}/`);
            formData.append('image', asset.license.file);
            INSTANCE.post('game-template/upload-asset', formData)
              .then((response) => {
                if (response.data.messageId === 200) {
                  asset.license.file = response.data.data.location;
                  asset.license.key = response.data.data.key;
                  this.setState(state => ({
                    gameTemplate: gameTemplate,
                    loaderUI: false
                  }));
                }
              })
              .catch((error) => {
                notification['error']({
                  message: 'Alert!!',
                  description: error
                });
              });
          }
          else {
            this.setState(state => ({
              gameTemplate: gameTemplate,
              loaderUI: false
            }));
          }
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'Alert!!',
          description: error
        });
      });
  }

  updateData = (asset, title) => {
    return (
      <Form>
        <Form.Row style={{ paddingBottom: '4px' }}>
          <Form.Label>Asset</Form.Label>
          <Form.File
            className="ant-btn"
            label={this.state.assetFile === '' ? asset.fileName : this.state.assetFile ?? asset.fileName}
            custom
            data-browse="Upload"
            onChange={this.onFileChange.bind(this, asset, title, "AssetFile")}
          />
        </Form.Row>
        <Form.Row style={{ paddingBottom: '4px' }}>
          <Form.Label>Source</Form.Label>
          <Form.Control
            type="text"
            required
            name="source"
            placeholder="Enter the source"
            onChange={(e) => {
              this.setState({ ...this.state, source: e.target.value })
            }}
            defaultValue={this.state.source}
          />
        </Form.Row>
        <Form.Row style={{ paddingBottom: '4px' }}>
          <Form.Label>License</Form.Label>
          <Form.File
            className="ant-btn"
            label={this.state.license}
            custom
            data-browse="Upload"
            onChange={this.onFileChange.bind(this, asset, title, "License")}
          />
        </Form.Row>
      </Form>
    )
  }

  updateConfirm(asset, title) {
    confirm({
      title: "Upload " + title,
      content: this.updateData(asset, title),
      okText: 'Update',
      cancelText: 'Cancel',
      icon: '',
      onOk: () => {
        this.onDataChange(asset, title);
      }
    });
  }

  // updateConfirm = () => {
  //   this.setState({ ...this.state, showUpdateConfirm: !this.state.showUpdateConfirm });
  // }
  submitConfirm = () => {
    this.setState({ ...this.state, showSubmitConfirm: !this.state.showSubmitConfirm });
  }
  deleteConfirm = () => {
    this.setState({ ...this.state, showRejectConfirm: !this.state.showRejectConfirm });
  }

  renderUI() {
    const { gameTemplate, previewVisible, previewImage, previewTitle, loaderUI } = this.state;
    if (loaderUI) {
      return (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )
    } else {
      return (
        <Card className="card-margin">
          <Form name="gameTemplateForm">
            <Row>
              <Col xs lg="6">
                <Form.Label>Game Template Name</Form.Label>
                <Form.Control placeholder="Game Template Name" readOnly value={gameTemplate.title} />
              </Col>
              <Col>
                <Form.Label>Manager's Name</Form.Label>
                <Form.Control as="select" custom>
                  <option>Sherry Carani</option>
                  <option>David Kid</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Label>Approved By</Form.Label>
                <Form.Control placeholder="Approved By" />
              </Col>
              <Col>
                <Form.Label>Approved Date</Form.Label>
                <Form.Control placeholder="Approved Date" />
              </Col>
            </Row>
            <Row className="row-margin2">
              <Col xs lg="3">
                <Form.Label>Template ID#</Form.Label>
                <Form.Control placeholder="Template ID#" readOnly value={gameTemplate.templateId} />
              </Col>
              <Col xs lg="3">
                <Form.Label>New Skin ID#</Form.Label>
                <Form.Control placeholder="New Skin ID#" readOnly value={gameTemplate.skinId} />
              </Col>
            </Row>
            <Row className="row-margin2">
              <Col xs lg="6">
                <Form.Label>Managing Contributor Name</Form.Label>
                <Form.Control placeholder="Managing Contributor Name" />
              </Col>
            </Row>
            <Row className="row-margin2">
              <Col>
                <span className="assets-title">ASSETS</span>
              </Col>
            </Row>
            {this.renderAssetRow()}
            <Row className="row-margin2">
              <Col>
                <span className="assets-title">SPRITES</span>
              </Col>
            </Row>
            {this.renderSpriteRow()}
            <Row className="row-margin2">
              <Col>
                <span className="assets-title">AUDIO</span>
              </Col>
            </Row>
            {this.renderAudioRow()}
            <Row className="row-margin2">
              <Col>
                <span className="assets-title">Game Graphic Teasers (Optional)</span>
              </Col>
            </Row>
            <Row className="row-margin2">

              {/* <Modal
              visible={this.state.showUpdateConfirm}
              title="Submit Template"
              okText="Submit"
              okType="No"
              closable={false}
              onOk={() => {

                this.updateConfirm();
              }}
              onCancel={() => this.updateConfirm()}
              style={{ zIndex: 2600 }}
            >
              <Form>
                <Form.Row style={{ paddingBottom: '4px' }}>
                  <Form.Label>Asset</Form.Label>
                  <Form.File
                    className="ant-btn"
                    label={this.state.assetFile ?? asset.fileName}
                    custom
                    data-browse="Upload"
                    // onChange={this.onFileChange.bind(this, asset, title, "AssetFile")}
                  />
                </Form.Row>
                <Form.Row style={{ paddingBottom: '4px' }}>
                  <Form.Label>Source</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="source"
                    placeholder="Enter the source"
                    onChange={(e) => {
                      this.setState({ ...this.state, source: e.target.value })
                    }}
                    defaultValue={this.state.source}
                />
                </Form.Row>
                <Form.Row style={{ paddingBottom: '4px' }}>
                  <Form.Label>License</Form.Label>
                  <Form.File
                    className="ant-btn"
                    label={this.state.license}
                    custom
                    data-browse="Upload"
                    // onChange={this.onFileChange.bind(this, asset, title, "License")}
                  />
                </Form.Row>
              </Form>
            </Modal> */}


              <Modal
                visible={this.state.showSubmitConfirm}
                title="Submit Template"
                okText="Submit"
                okType="primary"
                closable={false}
                onOk={() => {
                  this.onSubmitClick();
                  this.submitConfirm()
                }}
                onCancel={() => this.submitConfirm()}
                style={{ zIndex: 2600 }}
              >
                <h4>Are You Sure You Want To Submit?</h4>
              </Modal>

              <Modal
                visible={this.state.showRejectConfirm}
                title="Accept Approval"
                okText="Reject"
                okType="danger"
                closable={false}
                onOk={() => {
                  this.onRejectGigClick();
                  this.deleteConfirm()
                }}
                onCancel={() => this.deleteConfirm()}
                style={{ zIndex: 2600 }}
              >
                <h4>Are You Sure You Want To Reject?</h4>
              </Modal>

              <Col lg="6">
                {this.renderUploadBtn()}
              </Col>
              <Col lg="6" className="submit-container">
                <Button className="reject-button" onClick={() => { this.deleteConfirm(); }}>
                  Reject Gig
                </Button>
                <Button className="submit-button" onClick={() => { this.submitConfirm(); }}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
          >
            {this.state.title === "Audio" ?
              <AudioPlayer
                autoPlay
                src={previewImage}
              /> :
              <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
            }
          </Modal>
        </Card>
      )
    }
  }

  render() {
    const { gameTemplate } = this.state;
    return (
      <>
        <PageHeader title="Templates Submission" />
        {gameTemplate?.title ?
          this.renderUI() :
          <Card>
            No accepted gig found!!
          </Card>
        }
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(TemplateSubmission));
