import React, { Component } from "react";
import { Card, Space, Button, notification, Modal, Upload, Select } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Row, Col } from 'react-bootstrap';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  listEventImageType
} from "../../../appRedux/actions/eventImages";

const { Option } = Select;

class UploadEventImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      uploading: false,
      image_types: null,
      previewVisible: false,
      previewImage: '',
      previewTitle: ''
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    let inputJson = {
      current: 1,
      pageSize: 500
    }
    this.props.listEventImageType(authUser.token, inputJson);
  }

  handleUpload = () => {
    const { fileList, image_types } = this.state;
    if (image_types) {
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('image', file);
      });
      formData.append('path', 'event-images/');
      this.setState({
        uploading: true,
      });

      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      INSTANCE.post('upload-file/upload', formData)
        .then((response) => {
          if (response.data.messageId === 200) {
            let inputJSON = JSON.stringify({
              'image_types': image_types,
              'image': {
                originalName: response.data.data.originalname,
                mimeType: response.data.data.mimetype,
                location: response.data.data.location,
                key: response.data.data.key
              }
            })
            const INSTANCE1 = axios.create({
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(authUser.token)
              }
            });
            INSTANCE1.post('event-images/upload-image-data', inputJSON)
              .then((resp) => {
                if (resp.data.messageId === 200) {
                  this.setState({
                    fileList: [],
                    uploading: false,
                    image_types: null
                  }, () => {
                    notification['success']({
                      message: 'Success',
                      description: resp.data.message
                    });
                  });
                } else {
                  this.setState({
                    uploading: false
                  }, () => {
                    notification['error']({
                      message: 'Alert!!',
                      description: response.data.message
                    });
                  });
                }
              })
              .catch((error) => {
                notification['error']({
                  message: 'Alert!!',
                  description: error
                });
              })
          } else {
            this.setState({
              uploading: false
            }, () => {
              notification['error']({
                message: 'Alert!!',
                description: response.data.message
              });
            });
          }
        })
        .catch((error) => {
          notification['error']({
            message: 'Alert!!',
            description: error
          });
        })
    } else {
      notification['error']({
        message: 'Alert!!',
        description: 'Please select event image type.'
      });
    }
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  beforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  onRemove = file => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  }

  onSelectChange = (value) => {
    this.setState({
      image_types: value
    })
  }

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const event_image_types = this.props.eventImage.event_image_types.data;
    const { uploading, previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Auxiliary>
        <PageHeader title="Upload Event Image"/>
        <Row className="row-margin2">
          <Col>
            <Card>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Select
                  placeholder="Select Event Image Type"
                  onChange={this.onSelectChange}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                >
                  {event_image_types?.map(item => (
                    <Option key={item._id}>{item.name}</Option>
                  ))}
                </Select>

                <Upload
                  listType="picture-card"
                  onPreview={this.handlePreview}
                  beforeUpload={this.beforeUpload}
                  onRemove={this.onRemove}
                  accept="image/png, image/jpeg, image/jpg"
                >
                  {fileList.length > 0 ? null : uploadButton}
                </Upload>
                <Button
                  type="primary"
                  onClick={this.handleUpload}
                  disabled={fileList.length === 0}
                  loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Space>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Card>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    eventImage: state.eventImage
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listEventImageType }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(UploadEventImage);
