import {
    GAMES_COMPLETE
} from "../../constants/actionTypes";

const INIT_STATE = {
    isLoading: false,
    alertMessage: '',
    messageId: null,
    lists: [],
    gamesCompleteCount: null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GAMES_COMPLETE.GAMES_COMPLETE_RESET: {
            return {
                ...state,
                isLoading: false,
                alertMessage: '',
                messageId: null
            }
        }

        case GAMES_COMPLETE.LIST_GAMES_COMPLETE_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }

        case GAMES_COMPLETE.LIST_GAMES_COMPLETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 200,
                lists: action.payload
            }
        }

        case GAMES_COMPLETE.LIST_GAMES_COMPLETE_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case GAMES_COMPLETE.GAMES_COMPLETE_COUNT_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case GAMES_COMPLETE.GAMES_COMPLETE_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                gamesCompleteCount: action.payload
            }
        }

        case GAMES_COMPLETE.GAMES_COMPLETE_COUNT_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message
            }
        }

        case GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_REQUEST: {
            return {
              ...state,
              loader: true
            }
          }
      
          case GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_SUCCESS: {
            return {
              ...state,
              loader: false,
              messageId: 200,
              lists: action.payload
            }
          }
      
          case GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_FAIL: {
            return {
              ...state,
              loader: false,
              alertMessage: action.payload.message,
              messageId: 203
            }
          }

        default:
            return state;
    }
}
