import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Button, notification } from "antd";
import { bindActionCreators } from 'redux';

import CircularProgress from "components/CircularProgress";
import GameSkinCard from "components/Widgets/GameSkinCard";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  getSubmittedGameTemplate,
  resetGameTemplate
} from "../../../appRedux/actions/gameTemplate";

class ApproveGigs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      gameTemplates: [],
      pageSize: 8
    };
  }

  componentDidMount() {
    const { authUser } = this.props.auth;
    const { pageSize } = this.state;
    this.props.getSubmittedGameTemplate(authUser.token, pageSize);
  }

  changePageSize() {
    const { authUser } = this.props.auth;
    let { pageSize } = this.state;
    pageSize += 8;
    this.setState({ pageSize: pageSize });
    this.props.getSubmittedGameTemplate(authUser.token, pageSize);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        nextProps.resetGameTemplate();
        return {
          gameTemplates: nextProps.gameTemplate.lists
        }
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
      }
    }
    return null;
  }

  renderGigs() {
    const { gameTemplates } = this.state;
    if (gameTemplates.length > 0) {
      return gameTemplates.map((item, index) => (
        <Col xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
          <GameSkinCard
            name={item.title}
            description={item.shortDescription}
            coverImage={item.coverImage ? item.coverImage.url : 'https://via.placeholder.com/576X330'}
            details={`/game-template/gig-approve/${item._id}`}
          />
        </Col>
      ))
    }
    return (
      <Col>
        No templates found to approve!!
      </Col>
    )
  }

  render() {
    const loader = false;
    const { gameTemplates } = this.state;
    return (
      <Auxiliary>
        <PageHeader title="Approve Gigs" />
        <Row className="row-margin2">
          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> :
            this.renderGigs()
          }
        </Row>
        {gameTemplates.length > 7 ?
          <Row className="row-margin2">
            <Col></Col>
            <Col className="submit-container">
              <Button className="see-more-button" onClick={() => { this.changePageSize(); }}>
                See More
              </Button>
            </Col>
          </Row> : null
        }
      </Auxiliary>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate,
  }
}

// may have to remove this
function mapDispathToProps(dispatch) {
  return bindActionCreators({ getSubmittedGameTemplate, resetGameTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ApproveGigs);
