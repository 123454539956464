import React, { Component } from "react";
import { Breadcrumb, Card, notification } from "antd";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from 'yup';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField";
import Auxiliary from "util/auxiliary";

import {
  addCoupon,
  resetCoupon
} from "./../../../appRedux/actions/coupons";

const CouponSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  max_redemptions: Yup.number().required('Required'),
  duration: Yup.string().required('Required'),
  duration_in_months: Yup.number().when("duration", {
    is: (duration) => duration === "repeating",
    then: Yup.number().required("Required."),
    otherwise: Yup.number(),
  }),
  amount_off: Yup.number().when("percent_off", {
    is: (percent_off) => !percent_off || percent_off.length === 0,
    then: Yup.number().required("Required."),
    otherwise: Yup.number(),
  }),
  percent_off: Yup.number().when("amount_off", {
    is: (amount_off) => !amount_off || amount_off.length === 0,
    then: Yup.number().required("Required."),
    otherwise: Yup.number(),
  }),
}, ["amount_off", "percent_off"]);

class AddCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: false,
      initialData: {
        duration: '',
        amount_off: '',
        percent_off: ''
      },
      durations: [
        {
          name: "Forever",
          value: "forever"
        },
        {
          name: "Once",
          value: "once"
        },
        {
          name: "Repeating",
          value: "repeating"
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.couponsData) {
      if (nextProps.couponsData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.couponsData.alertMessage,
        });
        nextProps.resetCoupon();
        nextProps.history.goBack();
      } else if (nextProps.couponsData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.couponsData.alertMessage,
        });
        nextProps.resetCoupon();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    let inputJSON = {
      name: values.name,
      duration: values.duration,
      max_redemptions: values.max_redemptions,
      currency: 'USD',
      amount_off: values.amount_off * 100,
      percent_off: values.percent_off
    };
    if (values.amount_off !== '') {
      delete inputJSON.percent_off;
    }
    if (values.percent_off !== '') {
      delete inputJSON.amount_off;
    }
    if (values.duration === 'repeating') {
      inputJSON.duration_in_months = values.duration_in_months;
    }

    this.props.addCoupon(authUser.token, inputJSON);
  };

  render() {
    const { loader } = this.props.couponsData;
    const { durations, initialData } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/coupons/list" className="gx-link">
                  Coupons
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Add</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={"Add Coupon"} />
        <Row className="row-margin2">
          <Col>
            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              <Card>
                <Formik
                  validationSchema={CouponSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={initialData}>
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Coupon Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          {errors.name && touched.name ? (
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Max Redemptions <small>(Number of times the coupon can be redeemed before it’s no longer valid.)</small></Form.Label>
                          <Form.Control
                            type="number"
                            name="max_redemptions"
                            value={values.max_redemptions}
                            onChange={handleChange}
                            isInvalid={!!errors.max_redemptions}
                          />
                          {errors.max_redemptions && touched.max_redemptions ? (
                            <Form.Control.Feedback type="invalid">{errors.max_redemptions}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Duration<RequiredField /></Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="duration"
                            required
                            value={values.duration}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("duration", value);
                            }}
                            isInvalid={!!errors.duration}>
                            <option value={null}>Select Coupon Duration</option>
                            {durations.map(item => (
                              <option key={item.value} value={item.value}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.duration && touched.duration ? (
                            <Form.Control.Feedback type="invalid">{errors.duration}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        {values.duration === 'repeating' && (
                          <Col>
                            <Form.Label>Duration in months <small>(Required if duration is repeating.)</small></Form.Label>
                            <Form.Control
                              type="number"
                              name="duration_in_months"
                              value={values.duration_in_months}
                              onChange={handleChange}
                              isInvalid={!!errors.duration_in_months}
                            />
                            {errors.duration_in_months && touched.duration_in_months ? (
                              <Form.Control.Feedback type="invalid">{errors.duration_in_months}</Form.Control.Feedback>
                            ) : null}
                          </Col>
                        )}
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Amount Off <small>(Required if percent off is not added)</small></Form.Label>
                          <Form.Control
                            type="number"
                            name="amount_off"
                            value={values.amount_off}
                            onChange={handleChange}
                            isInvalid={!!errors.amount_off}
                          />
                          {errors.amount_off && touched.amount_off ? (
                            <Form.Control.Feedback type="invalid">{errors.amount_off}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Percent Off <small>(Required if amount off is not added)</small></Form.Label>
                          <Form.Control
                            type="number"
                            name="percent_off"
                            value={values.percent_off}
                            onChange={handleChange}
                            isInvalid={!!errors.percent_off}
                          />
                          {errors.percent_off && touched.percent_off ? (
                            <Form.Control.Feedback type="invalid">{errors.percent_off}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <button
                            type="submit"
                            style={{ maxWidth: "200px" }}
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            )}
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    couponsData: state.couponsData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      addCoupon,
      resetCoupon
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(AddCoupon);
