import { GAMES_COMPLETE } from "../../constants/actionTypes";
import axios from 'axios';

export const listGamesComplete = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAMES_COMPLETE.LIST_GAMES_COMPLETE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("gift-card/lists", inputJSON)
      .then(function (response) {
        dispatch(listGamesCompleteSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGamesCompleteError(error.message));
      });
  };
};

const listGamesCompleteSuccess = (data) => ({
  type: GAMES_COMPLETE.LIST_GAMES_COMPLETE_SUCCESS,
  payload: data
});

const listGamesCompleteError = errorMessage => ({
  type: GAMES_COMPLETE.LIST_GAMES_COMPLETE_FAIL,
  payload: errorMessage
});

export const getGamesCompleteCount = (token) => {
  return (dispatch) => {
    dispatch({ type: GAMES_COMPLETE.GAMES_COMPLETE_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("gift-card/get-count")
      .then(function (response) {
        dispatch(gamesCompleteCountSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(gamesCompleteCountError(error.message));
      });
  };
};

const gamesCompleteCountSuccess = (data) => ({
  type: GAMES_COMPLETE.GAMES_COMPLETE_COUNT_SUCCESS,
  payload: data
});

const gamesCompleteCountError = errorMessage => ({
  type: GAMES_COMPLETE.GAMES_COMPLETE_COUNT_FAIL,
  payload: errorMessage
});

export const searchGamesComplete = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("gift-card/search", inputJSON)
      .then(function (response) {
        dispatch(searchGamesCompleteSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchGamesCompleteError(error.message));
      });
  };
};

const searchGamesCompleteSuccess = (data) => ({
  type: GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_SUCCESS,
  payload: data
});

const searchGamesCompleteError = errorMessage => ({
  type: GAMES_COMPLETE.SEARCH_GAMES_COMPLETE_FAIL,
  payload: errorMessage
});

export const resetGamesComplete = () => {
  return (dispatch) => {
    dispatch({ type: GAMES_COMPLETE.GAMES_COMPLETE_RESET })
  }
}
