import React, { Component } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import CircularProgress from "components/CircularProgress/index";
import PageHeader from "components/PageHeader";
import RequiredField from "components/RequiredField/index";
import Auxiliary from "util/auxiliary";

import {
  addGameTemplate,
  editGameTemplate,
  resetGameTemplate
} from "../../../appRedux/actions/gameTemplate";


const GameTemplateSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  coverImage: Yup.string()
})

class AddGameTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      s3Folder: 'game-template',
      editId: null,
      isLoading: false,
      gameTemplate: {
        title: '',
        coverImage: null
      },
      file: null,
      fileName: 'Browse Image',
      templateId: null
    }
  };

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onFileChange = event => {
    let files = event.target.files;
    if (files[0]) {
      this.setState(state => ({
        file: files[0],
        fileName: files[0].name
      }));
    }
    return false;
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({
        editId: this.props.match.params.id,
        isLoading: true
      });
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      INSTANCE.get(`game-template/get-by-id/${this.props.match.params.id}`)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              isLoading: false,
              gameTemplate: response.data.data,
              templateId: response.data.data.templateId
            })
          }
        })
        .catch((error) => {
        })
    } else {
      this.setState({
        templateId: this.generateRandomId(5)
      });
    }
  }

  // generateRandomId
  generateRandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameTemplate.alertMessage
        });
        nextProps.resetGameTemplate();
        nextProps.history.goBack();
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
        nextProps.resetGameTemplate();
      }
    }
    return null;
  }

  onFinish = values => {
    const { file, editId, s3Folder, templateId } = this.state;
    const { authUser } = this.props.auth;
    let inputJson = {
      title: values.title
    }
    if (file) {
      const formData = new FormData();
      formData.append('path', `${s3Folder}/${templateId}/`);
      formData.append('image', file);
      if (editId) {
        inputJson._id = editId;
        formData.append('gameTemplate', JSON.stringify(inputJson));
        this.props.editGameTemplate(authUser.token, formData);
      } else {
        inputJson.templateId = templateId;
        formData.append('gameTemplate', JSON.stringify(inputJson));
        this.props.addGameTemplate(authUser.token, formData);
      }
    } else {
      notification['error']({
        message: 'Alert!!',
        description: "Please upload cover image"
      });
    }
  }

  render() {
    const { loader } = this.props;
    const { editId, fileName, isLoading, gameTemplate } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/game-template/list" className="gx-link">Game Templates</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit Game Template" : "Add Game Template"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Game Template" : "Add Game Template"} />
        <Row className="row-margin2">
          <Col>
            {loader || isLoading ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Formik
                  validationSchema={GameTemplateSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={{
                    title: gameTemplate.title,
                    coverImage: ""
                  }}>
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Title<RequiredField /></Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            isInvalid={!!errors.title}
                          />
                          {errors.title && touched.title ? (
                            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Cover Image</Form.Label>
                          <Form.File
                            id="custom-file"
                            label={fileName}
                            custom
                            onChange={this.onFileChange}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <Button type="submit">
                            {editId ? "Update" : "Submit"}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addGameTemplate, editGameTemplate, resetGameTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(withRouter(AddGameTemplate));

