import React, {Component} from "react";
import CircularProgress from "components/CircularProgress/index";
import {Link} from "react-router-dom";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      message: null
    }

  };

  componentDidMount(){
    if(this.props.match.params.token){
      let dataInput = {
        token: this.props.match.params.token
      }
      fetch(`${process.env.REACT_APP_API_URL}admin-auth/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(dataInput)
      }).then(res => res.json()).then((json) => {
        if(json.messageId === 200){
          this.setState({
            loader: false,
            message: json.message
          })
        } else {
          this.setState({
            loader: false,
            message: json.message
          })
        }
      });
    }
  }

  render (){
    const { loader, message } = this.state;
    return (
      <div className="gx-login-container">
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> : 
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <img className="logo-size" src={require("assets/images/logo-purple.png")} alt="Tokkenz MoneyHunt Games"/>
            </div>
            <div className="gx-mb-4">
              <h2>Email Verification</h2>
              <p>{message}</p>
            </div>
            <div className="gx-flex-row">
              <Link to="/signin">
                Go To Login
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default VerifyEmail;
