import React, { Component } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, notification, Breadcrumb } from "antd";
import * as Yup from 'yup';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";
import RequiredField from "components/RequiredField";

import {
    sendDemo,
    resetSendDemo
} from "../../../appRedux/actions/sendDemo";
import {
    getAllGreetingPackage
} from '../../../appRedux/actions/greetingPackage';
import {
    getAllGameInventories
} from "./../../../appRedux/actions/gameCatalog/gameInventory";

const DemoSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    mobileNumber: Yup.string().matches(/^\d{10}$/, 'Invalid mobile number.').min(10, 'Invalid mobile number.').max(10, 'Invalid mobile number.'),
    email: Yup.string().email('Invalid email'),
    gameInventory: Yup.string().required('Required'),
    greetingPackage: Yup.string().required('Required'),
    cashGift: Yup.number().required('Required').positive('Cash gift must be a positive number.').moreThan(4, 'Cash gift must be $5 or more.')
});

class SendDemo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailMobileError: false,
            user: {
                firstName: '',
                lastName: '',
                mobileNumber: '',
                email: '',
                gameInventory: '',
                greetingPackage: '',
                cashGift: ''
            }
        }
    };

  componentDidMount() {
    const { authUser } = this.props.auth;
    this.props.getAllGreetingPackage(authUser.token);
    this.props.getAllGameInventories(authUser.token);
  }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.sendDemoData) {
            if (nextProps.sendDemoData.messageId === 200) {
                notification['success']({
                    message: 'Success',
                    description: nextProps.sendDemoData.alertMessage
                });
                nextProps.resetSendDemo();
            } else if (nextProps.sendDemoData.messageId === 203) {
                notification['error']({
                    message: 'Alert!!',
                    description: nextProps.sendDemoData.alertMessage
                });
                nextProps.resetSendDemo();
            }
        }

        return null;
    }

    onFinish = values => {
        if (values.email === "" && values.mobileNumber === "") {
            this.setState({
                emailMobileError: true
            })
        } else {
            this.setState({
                emailMobileError: false
            }, () => {
                const { authUser } = this.props.auth;
                let inputJson = values;
                inputJson.sender = authUser.user.userId;
                inputJson.senderFirstName = authUser.user.firstName;
                inputJson.senderLastName = authUser.user.lastName;
                inputJson.gameNumber = this.generateRandomId(10);
                this.props.sendDemo(authUser.token, values);
            })
        }
    };

    // Generate Random Id
    generateRandomId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    render() {
        const { isLoading } = this.props.sendDemoData;
        const { user, emailMobileError } = this.state;
        const greetingPackages = this.props.greetingPackage.lists;
        let greetingPackageList = [];
        if (greetingPackages) {
            if (greetingPackages.data) {
                greetingPackageList = greetingPackages.data;
            }
        }
        const gameInventory = this.props.gameInventoryData.lists;
        let gameInventoryList = [];
        if (gameInventory) {
            if (gameInventory.data) {
                gameInventoryList = gameInventory.data.sort((a, b) => a.gameName.localeCompare(b.gameName));
            }
        }
        return (
            <Auxiliary>
                <Row className="margin-bottom">
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Send Demo</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <PageHeader title="Send Demo" navigateTo="/home/recipients" linkName="Demo Recipients" iconType="users" />
                <Row className="row-margin2">
                    <Col>
                        {isLoading ?
                            <div className="gx-loader-view">
                                <CircularProgress />
                            </div> :
                            <Card>
                                <h3>RECIPIENT INFO</h3>
                                <Formik
                                    validationSchema={DemoSchema}
                                    onSubmit={values => {
                                        this.onFinish(values);
                                    }}
                                    enableReinitialize={true}
                                    initialValues={user}>
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            {emailMobileError ?
                                                <Form.Row>
                                                    <Col className="error-div">
                                                        <span className="error-message">Please enter mobile number or email.</span>
                                                    </Col>
                                                </Form.Row> : null
                                            }
                                            <Form.Row className="formrow">
                                                <Col>
                                                    <Form.Label>First Name<RequiredField /></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        name="firstName"
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.firstName}
                                                    />
                                                    {errors.firstName && touched.firstName ? (
                                                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                                <Col>
                                                    <Form.Label>Last Name<RequiredField /></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        name="lastName"
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.lastName}
                                                    />
                                                    {errors.lastName && touched.lastName ? (
                                                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="formrow">
                                                <Col>
                                                    <Form.Label>Mobile Number <small>(10 digit number - no spaces)</small></Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        required
                                                        name="mobileNumber"
                                                        value={values.mobileNumber}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileNumber}
                                                    />
                                                    {errors.mobileNumber && touched.mobileNumber ? (
                                                        <Form.Control.Feedback type="invalid">{errors.mobileNumber}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                                <Col sm={2} className="or-div">
                                                    <span>OR</span>
                                                </Col>
                                                <Col>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        required
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.email}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="formrow">
                                                <Col xs={6}>
                                                    <Form.Label>Select Game<RequiredField /></Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        custom
                                                        name="gameInventory"
                                                        required
                                                        value={values.gameInventory}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            setFieldValue("gameInventory", value);
                                                        }}
                                                        isInvalid={!!errors.gameInventory}>
                                                        <option value={null}>Select Game</option>
                                                        {gameInventoryList.map(item => (
                                                            <option key={item._id} value={item._id}>{item.gameName} {item.isActive ? '✓' : null}</option>
                                                        ))}
                                                    </Form.Control>
                                                    {errors.gameInventory && touched.gameInventory ? (
                                                        <Form.Control.Feedback type="invalid">{errors.gameInventory}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Label>Select Greeting Package<RequiredField /></Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        custom
                                                        name="greetingPackage"
                                                        required
                                                        value={values.greetingPackage}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            setFieldValue("greetingPackage", value);
                                                        }}
                                                        isInvalid={!!errors.greetingPackage}>
                                                        <option value={null}>Select Greeting Package</option>
                                                        {greetingPackageList.map(item => (
                                                            <option key={item._id} value={item._id}>{item.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                    {errors.greetingPackage && touched.greetingPackage ? (
                                                        <Form.Control.Feedback type="invalid">{errors.greetingPackage}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="formrow">
                                                <Col>
                                                    <Form.Label>Cash Gift<RequiredField /> <small>(USD)</small></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        required
                                                        name="cashGift"
                                                        value={values.cashGift}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.cashGift}
                                                    />
                                                    {errors.cashGift && touched.cashGift ? (
                                                        <Form.Control.Feedback type="invalid">{errors.cashGift}</Form.Control.Feedback>
                                                    ) : null}
                                                </Col>
                                                <Col></Col>
                                            </Form.Row>
                                            <Form.Row className="btnrow">
                                                <Col>
                                                    <Button type="submit">
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Form.Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        }
                    </Col>
                </Row>
            </Auxiliary>
        );
    }
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        sendDemoData: state.sendDemoData,
        greetingPackage: state.greetingPackage,
        gameInventoryData: state.gameInventoryData
    }
}

function mapDispathToProps(dispatch) {
    return bindActionCreators({
        sendDemo,
        resetSendDemo,
        getAllGreetingPackage,
        getAllGameInventories
    }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(SendDemo);


