import {
    GAME_INVENTORY
} from "../../../constants/actionTypes";
  
  const INIT_STATE = {
    isLoading: false,
    alertMessage: '',
    messageId: null,
    lists: [],
    gameInventoryCount: null
  };
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GAME_INVENTORY.RESET_GAME_INVENTORY: {
            return {
                ...state,
                isLoading: false,
                alertMessage: '',
                messageId: null
            }
        }
  
        case GAME_INVENTORY.ADD_GAME_INVENTORY_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case GAME_INVENTORY.ADD_GAME_INVENTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: action.payload.messageId
            }
        }
  
        case GAME_INVENTORY.ADD_GAME_INVENTORY_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case GAME_INVENTORY.LIST_GAME_INVENTORY_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case GAME_INVENTORY.LIST_GAME_INVENTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                messageId: 204,
                lists: action.payload
            }
        }
  
        case GAME_INVENTORY.LIST_GAME_INVENTORY_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
      }

      case GAME_INVENTORY.GET_ALL_GAME_INVENTORY_REQUEST: {
        return {
          ...state,
          isLoading: true
        }
      }

      case GAME_INVENTORY.GET_ALL_GAME_INVENTORY_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          messageId: 204,
          lists: action.payload
        }
      }

      case GAME_INVENTORY.GET_ALL_GAME_INVENTORY_FAIL: {
        return {
          ...state,
          isLoading: false,
          alertMessage: action.payload.message,
          messageId: 203
        }
      }

        case GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }
  
        case GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case GAME_INVENTORY.UPDATE_GAME_INVENTORY_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
  
        case GAME_INVENTORY.UPDATE_GAME_INVENTORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }
  
        case GAME_INVENTORY.UPDATE_GAME_INVENTORY_FAIL: {
            return {
                ...state,
                isLoading: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case GAME_INVENTORY.GAME_INVENTORY_COUNT_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }
  
        case GAME_INVENTORY.GAME_INVENTORY_COUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                gameInventoryCount: action.payload
            }
        }
  
        case GAME_INVENTORY.GAME_INVENTORY_COUNT_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message
            }
        }
  
        default:
            return state;
    }
  }
