import React from "react";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaPlus, FaUsers } from 'react-icons/fa';

const PageHeader = ({title, navigateTo, linkName, iconType}) => {
  return (
    <div className="gx-page-heading">
      <Row>
        <Col sm={9}>
          <span className="sumission-title">{title}</span>
        </Col>
        <Col className="right-align">
          {navigateTo ? 
            <Link to={navigateTo} className="nav-btn">
              {iconType === 'users' ? <FaUsers className="link-icon-size" /> : <FaPlus className="link-icon-size" />}
              <span className="margin-left">{linkName}</span>
            </Link>
          : null}
        </Col>
      </Row>
    </div>
  )
};

export default PageHeader;

