import React, {Component} from "react";
import {Button, Input, Form, notification} from "antd";
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";

import CircularProgress from "components/CircularProgress/index";

import {
  userLogin,
  resetUserAuth
} from "../../appRedux/actions/auth";

const FormItem = Form.Item;

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.formRef = React.createRef();
  };

  static getDerivedStateFromProps(nextProps, state){
    if(nextProps.auth){
      if(nextProps.auth.messageId === 200){
        if(nextProps.auth.authUser.user){
          nextProps.history.push("/home/dashboard");
          notification['success']({
            message: 'Success',
            description: nextProps.auth.alertMessage
          });
        }
        nextProps.resetUserAuth();
      } else if(nextProps.auth.messageId === 203){
        notification['error']({
          message: 'Alert!!',
          description: nextProps.auth.alertMessage
        });
        nextProps.resetUserAuth();
      }
      return true;
    }
    return null;
  }
  
  onFinish = values => {
    this.props.userLogin(values);
  };

  render (){
    const { loader } = this.props.auth;
    return(
      <div className="gx-login-container">
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> : 
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <img className="logo-size" src={require("assets/images/logo-purple.png")} alt="Tokkenz MoneyHunt Games"/>
            </div>
            <div className="gx-mb-4">
              <h2>Sign In</h2>
            </div>
              <Form
                layout={'vertical'}
                onFinish={this.onFinish}
                className="gx-signin-form gx-form-row0">
    
                <FormItem label="Email"
                  rules={[{ required: true, type: 'email', message: 'Please enter valid E-mail' }]} name="email">
                  <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} type="email" placeholder="Email"/>
                </FormItem>
                <FormItem label="Password" rules= {[{required: true, message: 'Please enter your Password'}]} name="password">
                  <Input type="password" prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Password"/>
                </FormItem>
                <p className="forgotPassword">
                  <Link to="/forgot-password">
                    Forgot Password
                  </Link>
                </p>
                <FormItem>
                  <Button loading={loader} type="primary" className="gx-mb-0" htmlType="submit">
                    Sign In
                  </Button>
                </FormItem>
                {/* <div className="gx-flex-row">
                  <span>Don't have an account?&nbsp;</span> 
                  <Link to="/signup">
                    Sign Up
                  </Link>
                </div> */}
              </Form>
            </div>
          }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ userLogin, resetUserAuth }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(SignIn);
