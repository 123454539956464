import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AddInterestTitle from "./add";
import ListInterestTitle from "./list";
import CustomBreadCrumb from 'components/Widgets/CustomBreadCrumb';

const Main = ({match}) => (
  <div>
    <CustomBreadCrumb />
  <Switch>
    <Route path={`${match.url}/add`} component={AddInterestTitle}/>
    <Route path={`${match.url}/edit/:id`} component={AddInterestTitle}/>
    <Route path={`${match.url}/list`} component={ListInterestTitle}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
  </div>
);

export default Main;
