import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ListGreetingPackage from "./list";
import AddGreetingPackage from "./add";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/add`} component={AddGreetingPackage} />
    <Route path={`${match.url}/edit/:id`} component={AddGreetingPackage} />
    <Route path={`${match.url}/preview/:name/:id`} component={AddGreetingPackage} />
    <Route path={`${match.url}/list`} component={ListGreetingPackage} />
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
