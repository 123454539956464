import { GAME_INFO_TAG } from "../../../constants/actionTypes";
import axios from 'axios';

export const addGameInfoTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INFO_TAG.ADD_GAME_INFO_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-info-tag/create", inputJSON)
            .then(function (response) {
                dispatch(addGameInfoTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addGameInfoTagError(error.message));
            });
    };
};

const addGameInfoTagSuccess = (data) => ({
    type: GAME_INFO_TAG.ADD_GAME_INFO_TAG_SUCCESS,
    payload: data
});

const addGameInfoTagError = errorMessage => ({
    type: GAME_INFO_TAG.ADD_GAME_INFO_TAG_FAIL,
    payload: errorMessage
});

export const listGameInfoTags = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INFO_TAG.LIST_GAME_INFO_TAG_REQUEST });
      const AuthStr = 'Bearer '.concat(token);
      let inputJSON = JSON.stringify(data);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

      INSTANCE.post("game-info-tag/lists", inputJSON)
            .then(function (response) {
                dispatch(listGameInfoTagsSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(listGameInfoTagsError(error.message));
            });
    };
};

const listGameInfoTagsSuccess = (data) => ({
    type: GAME_INFO_TAG.LIST_GAME_INFO_TAG_SUCCESS,
    payload: data
});

const listGameInfoTagsError = errorMessage => ({
    type: GAME_INFO_TAG.LIST_GAME_INFO_TAG_FAIL,
    payload: errorMessage
});

export const getAllGameInfoTags = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_INFO_TAG.GET_ALL_GAME_INFO_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-info-tag/get-all")
      .then(function (response) {
        dispatch(getAllGameInfoTagsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGameInfoTagsError(error.message));
      });
  };
};

const getAllGameInfoTagsSuccess = (data) => ({
  type: GAME_INFO_TAG.GET_ALL_GAME_INFO_TAG_SUCCESS,
  payload: data
});

const getAllGameInfoTagsError = errorMessage => ({
  type: GAME_INFO_TAG.GET_ALL_GAME_INFO_TAG_FAIL,
  payload: errorMessage
});

export const deleteGameInfoTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INFO_TAG.DELETE_GAME_INFO_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-info-tag/delete", inputJSON)
            .then(function (response) {
                dispatch(deleteGameInfoTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(deleteGameInfoTagError(error.message));
            });
    };
};

const deleteGameInfoTagSuccess = (data) => ({
    type: GAME_INFO_TAG.DELETE_GAME_INFO_TAG_SUCCESS,
    payload: data
});

const deleteGameInfoTagError = errorMessage => ({
    type: GAME_INFO_TAG.DELETE_GAME_INFO_TAG_FAIL,
    payload: errorMessage
});

export const updateGameInfoTag = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INFO_TAG.UPDATE_GAME_INFO_TAG_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-info-tag/update", inputJSON)
            .then(function (response) {
                dispatch(updateGameInfoTagSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateGameInfoTagError(error.message));
            });
    };
};

const updateGameInfoTagSuccess = (data) => ({
    type: GAME_INFO_TAG.UPDATE_GAME_INFO_TAG_SUCCESS,
    payload: data
});

const updateGameInfoTagError = errorMessage => ({
    type: GAME_INFO_TAG.UPDATE_GAME_INFO_TAG_FAIL,
    payload: errorMessage
});

export const searchGameInfoTag = (data, token) => {
  return (dispatch) => {
    dispatch({ type: GAME_INFO_TAG.SEARCH_GAME_INFO_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("game-info-tag/search-Info-Tag", inputJSON)
      .then(function (response) {
        dispatch(searchGameInfoTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchGameInfoTagError(error.message));
      });
  };
};

const searchGameInfoTagSuccess = (data) => ({
  type: GAME_INFO_TAG.SEARCH_GAME_INFO_TAG_SUCCESS,
  payload: data
});

const searchGameInfoTagError = errorMessage => ({
  type: GAME_INFO_TAG.SEARCH_GAME_INFO_TAG_FAIL,
  payload: errorMessage
});

export const resetGameInfoTag = () => {
    return (dispatch) => {
        dispatch({ type: GAME_INFO_TAG.RESET_GAME_INFO_TAG})
    }
}
