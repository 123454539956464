import React, {Component} from "react";
import {Button, Form, Input, notification} from "antd";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import {Link} from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;


class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      token: null
    }

    this.formRef = React.createRef();
  };
  
  componentDidMount(){
    if(this.props.match.params.token){
      this.setState({
        token: this.props.match.params.token
      })
    }
  }

  onFinish = values => {
    values.token = this.state.token;
    this.setState({
      loader: true
    })

    fetch(`${process.env.REACT_APP_API_URL}admin-auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(values)
    }).then(res => res.json()).then((json) => {
      if(json.messageId === 200){
        this.setState({
          loader: false
        })
        notification['success']({
          message: 'Success',
          description: json.message,
        });
      } else {
        this.setState({
          loader: false
        })
        notification['error']({
          message: 'Alert!!',
          description: json.message,
        });
      }
    });
  };

  render (){
    const { loader } = this.state;
    return(
      <div className="gx-login-container">
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> : 
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <img className="logo-size" src={require("assets/images/logo-purple.png")} alt="Tokkenz MoneyHunt Games"/>
            </div>
            <div className="gx-mb-4">
              <h2>Reset Password</h2>
              <p>Choose a new password for your account.</p>
            </div>

            <Form
              layout={'vertical'}
              onFinish={this.onFinish}
              className="gx-signin-form gx-form-row0">

              <FormItem label="New Password" rules={ [{required: true, message: 'Please input your Password'}, {min: 6, message: 'Password must be minimum 6 characters.'}]} hasFeedback name="password">
              <Input.Password prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="New Password"/>
              </FormItem>

              <FormItem label="Confirm Password" 
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('New password and confirm password does not match.'));
                    },
                  }),
                ]} hasFeedback name="confirm">
                <Input.Password prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Confirm Password"/>
              </FormItem>
  
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormItem>
            </Form>
            <div className="gx-flex-row">
              <span>Go to&nbsp;</span>
              <Link to="/signin">
                Sign In
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
};

export default ResetPassword;
