import { GAME_TEMPLATE } from "../../constants/actionTypes";
import axios from 'axios';

export const addGameTemplate = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.ADD_GAME_TEMPLATE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': data.length > 0 ? 'application/json' : undefined,
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("game-template/add", data)
      .then(function (response) {
        dispatch(addGameTemplateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addGameTemplateError(error.message));
      });
  };
};

const addGameTemplateSuccess = (data) => ({
  type: GAME_TEMPLATE.ADD_GAME_TEMPLATE_SUCCESS,
  payload: data
});

const addGameTemplateError = errorMessage => ({
  type: GAME_TEMPLATE.ADD_GAME_TEMPLATE_FAIL,
  payload: errorMessage
});

export const listGameTemplate = (token, pageSize) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.LIST_GAME_TEMPLATE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get(`game-template/list/${pageSize}`)
      .then(function (response) {
        dispatch(listGameTemplateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGameTemplateError(error.message));
      });
  };
};

export const getAvailableGameTemplate = (token, pageSize, gameType) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.LIST_GAME_TEMPLATE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get(`game-template/availableGig/${pageSize}/${gameType}`)
      .then(function (response) {
        dispatch(listGameTemplateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGameTemplateError(error.message));
      });
  };
};

export const getSubmittedGameTemplate = (token, pageSize, gameType) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.LIST_GAME_TEMPLATE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get(`game-template/submittedGig/${pageSize}/${gameType}`)
      .then(function (response) {
        dispatch(listGameTemplateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGameTemplateError(error.message));
      });
  };
};

const listGameTemplateSuccess = (data) => ({
  type: GAME_TEMPLATE.LIST_GAME_TEMPLATE_SUCCESS,
  payload: data.data
});

const listGameTemplateError = errorMessage => ({
  type: GAME_TEMPLATE.LIST_GAME_TEMPLATE_FAIL,
  payload: errorMessage
});

export const editGameTemplate = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.EDIT_GAME_TEMPLATE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': data.length > 0 ? 'application/json' : undefined,
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("game-template/edit", data)
      .then(function (response) {
        dispatch(editGameTemplateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(editGameTemplateError(error.message));
      });
  };
};

const editGameTemplateSuccess = (data) => ({
  type: GAME_TEMPLATE.EDIT_GAME_TEMPLATE_SUCCESS,
  payload: data
});

const editGameTemplateError = errorMessage => ({
  type: GAME_TEMPLATE.EDIT_GAME_TEMPLATE_FAIL,
  payload: errorMessage
});


export const resetGameTemplate = () => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.RESET_GAME_TEMPLATE })
  }
}

export const assetGallery = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.ASSET_GALLERY_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-template/asset-gallery")
      .then(function (response) {
        dispatch(assetGallerySuccess(response.data));
      })
      .catch(function (error) {
        dispatch(assetGalleryFail(error.message));
      });
  }
};

const assetGallerySuccess = (data) => ({
  type: GAME_TEMPLATE.ASSET_GALLERY_SUCCESS,
  payload: data
});

const assetGalleryFail = errorMessage => ({
  type: GAME_TEMPLATE.ASSET_GALLERY_FAIL,
  payload: errorMessage
});

export const getGameTemplateCount = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.GAME_TEMPLATE_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-template/get-count")
      .then(function (response) {
        dispatch(gameTemplateCountSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(gameTemplateCountError(error.message));
      });
  };
};

const gameTemplateCountSuccess = (data) => ({
  type: GAME_TEMPLATE.GAME_TEMPLATE_COUNT_SUCCESS,
  payload: data
});

const gameTemplateCountError = errorMessage => ({
  type: GAME_TEMPLATE.GAME_TEMPLATE_COUNT_FAIL,
  payload: errorMessage
});


// Template Skins 

export const listGameTemplateSkin = (token, templateId, gameType) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.LIST_TEMPLATE_SKIN_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get(`game-template/template-skins/${templateId}/${gameType}`)
      .then(function (response) {
        dispatch(listGameTemplateSkinSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGameTemplateSkinError(error.message));
      });
  };
};

const listGameTemplateSkinSuccess = (data) => ({
  type: GAME_TEMPLATE.LIST_TEMPLATE_SKIN_SUCCESS,
  payload: data.data
});

const listGameTemplateSkinError = errorMessage => ({
  type: GAME_TEMPLATE.LIST_TEMPLATE_SKIN_FAIL,
  payload: errorMessage
});

export const addGameTemplateSkin = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.ADD_TEMPLATE_SKIN_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': data.length > 0 ? 'application/json' : undefined,
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("game-template/add-template-skin", data)
      .then(function (response) {
        dispatch(addGameTemplateSkinSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addGameTemplateSkinError(error.message));
      });
  };
};

const addGameTemplateSkinSuccess = (data) => ({
  type: GAME_TEMPLATE.ADD_TEMPLATE_SKIN_SUCCESS,
  payload: data
});

const addGameTemplateSkinError = errorMessage => ({
  type: GAME_TEMPLATE.ADD_TEMPLATE_SKIN_FAIL,
  payload: errorMessage
});

export const editGameTemplateSkin = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': data.length > 0 ? 'application/json' : undefined,
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("game-template/edit-skin", data)
      .then(function (response) {
        dispatch(editGameSkinSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(editGameSkinError(error.message));
      });
  };
};

const editGameSkinSuccess = (data) => ({
  type: GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_SUCCESS,
  payload: data
});

const editGameSkinError = errorMessage => ({
  type: GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_FAIL,
  payload: errorMessage
});

export const getAllGameSkins = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_TEMPLATE.GET_ALL_GAME_SKIN_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get(`game-template/get-all-skin`)
      .then(function (response) {
        dispatch(getAllGameSkinSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGameSkinError(error.message));
      });
  };
};

const getAllGameSkinSuccess = (data) => ({
  type: GAME_TEMPLATE.GET_ALL_GAME_SKIN_SUCCESS,
  payload: data.data
});

const getAllGameSkinError = errorMessage => ({
  type: GAME_TEMPLATE.GET_ALL_GAME_SKIN_FAIL,
  payload: errorMessage
});