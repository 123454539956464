import { EVENT_TITLE } from "../../constants/actionTypes";
import axios from 'axios';

export const addEventTitle = (token, data) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.ADD_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("event-title/create", inputJSON)
      .then(function (response) {
        dispatch(addEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addEventTitleError(error.message));
      });
  };
};

const addEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.ADD_EVENT_TITLE_SUCCESS,
  payload: data
});

const addEventTitleError = errorMessage => ({
  type: EVENT_TITLE.ADD_EVENT_TITLE_FAIL,
  payload: errorMessage
});

export const listEventTitle = (token, data) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.LIST_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("event-title/lists", inputJSON)
      .then(function (response) {
        dispatch(listEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listEventTitleError(error.message));
      });
  };
};

const listEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.LIST_EVENT_TITLE_SUCCESS,
  payload: data
});

const listEventTitleError = errorMessage => ({
  type: EVENT_TITLE.LIST_EVENT_TITLE_FAIL,
  payload: errorMessage
});

export const getAllEventTitles = (token, data) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.GET_ALL_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("event-title/get-all")
      .then(function (response) {
        dispatch(getAllEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllEventTitleError(error.message));
      });
  };
};

const getAllEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.GET_ALL_EVENT_TITLE_SUCCESS,
  payload: data
});

const getAllEventTitleError = errorMessage => ({
  type: EVENT_TITLE.GET_ALL_EVENT_TITLE_FAIL,
  payload: errorMessage
});

export const deleteEventTitle = (token, data) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.DELETE_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("event-title/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteEventTitleError(error.message));
      });
  };
};

const deleteEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.DELETE_EVENT_TITLE_SUCCESS,
  payload: data
});

const deleteEventTitleError = errorMessage => ({
  type: EVENT_TITLE.DELETE_EVENT_TITLE_FAIL,
  payload: errorMessage
});

export const updateEventTitle = (token, data) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.UPDATE_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("event-title/update", inputJSON)
      .then(function (response) {
        dispatch(updateEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateEventTitleError(error.message));
      });
  };
};

const updateEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.UPDATE_EVENT_TITLE_SUCCESS,
  payload: data
});

const updateEventTitleError = errorMessage => ({
  type: EVENT_TITLE.UPDATE_EVENT_TITLE_FAIL,
  payload: errorMessage
});

export const resetEventTitle = () => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.RESET_EVENT_TITLE })
  }
}

export const searchEventTitle = (data, token) => {
  return (dispatch) => {
    dispatch({ type: EVENT_TITLE.SEARCH_EVENT_TITLE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("event-title/search-event-type", inputJSON)
      .then(function (response) {
        dispatch(searchEventTitleSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchEventTitleError(error.message));
      });
  };
};

const searchEventTitleSuccess = (data) => ({
  type: EVENT_TITLE.SEARCH_EVENT_TITLE_SUCCESS,
  payload: data
});

const searchEventTitleError = errorMessage => ({
  type: EVENT_TITLE.SEARCH_EVENT_TITLE_FAIL,
  payload: errorMessage
});
