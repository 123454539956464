import React, { Component } from "react";
import { Button, Card, Form, Input, Breadcrumb, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  addGameInfoTag,
  updateGameInfoTag,
  resetGameInfoTag
} from "../../../../appRedux/actions/gameCatalog/infoTag";

const FormItem = Form.Item;

class AddInfoTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("game-info-tag/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              description: response.data.data.description ? response.data.data.description : ''
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameInfoTagData) {
      if (nextProps.gameInfoTagData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameInfoTagData.alertMessage
        });
        nextProps.resetGameInfoTag();
        nextProps.history.goBack();
      } else if (nextProps.gameInfoTagData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameInfoTagData.alertMessage
        });
        nextProps.resetGameInfoTag();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = values;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateGameInfoTag(authUser.token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addGameInfoTag(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.gameInfoTagData;
    const { editId } = this.state;
    return (
      <>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Auxiliary>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/game-catalog/info-tags" className="gx-link">Game Info Tags</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title={editId ? "Edit Info Tag" : "Add Info Tag"} />
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Form
                    name="gameTagForm"
                    onFinish={this.onFinish}
                    layout="vertical"
                    ref={this.formRef}
                  >
                    <FormItem label="Tag Name" rules={[{ required: true, message: 'Please enter tag name.' }]} name="name">
                      <Input placeholder="Tag Name" />
                    </FormItem>

                    <FormItem label="Description" name="description">
                      <Input.TextArea placeholder="Description" rows={4} />
                    </FormItem>

                    <FormItem>
                      <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                        {editId ? "Update" : "Submit"}
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Auxiliary>
        }
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameInfoTagData: state.gameInfoTagData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    addGameInfoTag,
    updateGameInfoTag,
    resetGameInfoTag
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddInfoTag);
