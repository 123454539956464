import { INIT_URL, USER_AUTH } from "../../constants/actionTypes";
import axios from "axios";
import Cookies from "js-cookie";
import { notification } from "antd";
import { getUser } from "./users";

const CryptoJS = require("crypto-js");

export const userLogin = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_AUTH.SIGNIN_REQUEST });
    const INSTANCE = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    let inputJSON = JSON.stringify(data);
    INSTANCE.post("admin-auth/login", inputJSON)
      .then(function (response) {
        if (response.data.messageId === 200) {
          let result = {
            data: {
              user: response.data.user,
              token: response.data.token,
            },
            message: response.data.message,
          };
          // Encrypt
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(result.data),
            "GFYUFGTYGFTYTY64564545acvbvrttyFG@%#%#%#%#TTRR"
          ).toString();
          // _tok_u = JWT Token & user details
          Cookies.set("_tok_u", ciphertext, { expires: 7 });
          dispatch(userSignInSuccess(result));
        } else {
          dispatch(userSignInFail(response.data));
        }
      })
      .catch(function (error) {
        dispatch(userSignInFail(error.message));
      });
  };
};

const userSignInSuccess = (data) => ({
  type: USER_AUTH.SIGNIN_REQUEST_SUCCESS,
  payload: data,
});

const userSignInFail = (errorMessage) => ({
  type: USER_AUTH.SIGNIN_REQUEST_FAIL,
  payload: errorMessage,
});

export const userSignUp = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_AUTH.SIGNUP_REQUEST });
    const INSTANCE = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("admin-auth/signup", inputJSON)
      .then(function (response) {
        if (response.data.messageId === 200) {
          dispatch(userSignUpSuccess(response.data));
        } else {
          dispatch(userSignUpFail(response.data));
        }
      })
      .catch(function (error) {
        dispatch(userSignUpFail(error.message));
      });
  };
};

const userSignUpSuccess = (data) => ({
  type: USER_AUTH.SIGNUP_REQUEST_SUCCESS,
  payload: data,
});

const userSignUpFail = (errorMessage) => ({
  type: USER_AUTH.SIGNUP_REQUEST_FAIL,
  payload: errorMessage,
});

export const logOutUser = () => {
  Cookies.remove("_tok_u");
  return (dispatch) => {
    dispatch({ type: USER_AUTH.SIGNOUT_USER });
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const resetUserAuth = () => {
  return (dispatch) => {
    dispatch({ type: USER_AUTH.RESET_AUTH });
  };
};

export const addSkill = (data) => {
  return (dispatch, getState) => {
    const { authUser } = getState().auth;
    dispatch({ type: USER_AUTH.ADD_SKILL_REQUEST });
    axios
      .post("admin-auth/add-skill", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ".concat(authUser?.token),
        },
      })
      .then((response) => {
        dispatch(addSkillSuccess(response.data));
        dispatch(getUser(authUser?.token, { _id: authUser.user.userId }));
        notification["success"]({
          message: "Success",
          description: response.data.message,
        });
      })
      .catch(function (error) {
        dispatch(addSkillError(error.message));
      });
  };
};

export const removeSkill = (data) => {
  return (dispatch, getState) => {
    const { authUser } = getState().auth;
    axios
      .delete("users/delete-skill", {
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ".concat(authUser?.token),
        },
      })
      .then((resp) => {
        dispatch(getUser(authUser?.token, { _id: authUser.user.userId }));
        notification["success"]({
          message: "Success",
          description: resp.data.message,
        });
      });
  };
};

const addSkillSuccess = (data) => ({
  type: USER_AUTH.ADD_SKILL_SUCCESS,
  payload: data,
});

const addSkillError = (errorMessage) => ({
  type: USER_AUTH.ADD_SKILL_FAIL,
  payload: errorMessage,
});

export const getUserProfile = (token, data) => {
  return (dispatch) => {
    dispatch({ type: USER_AUTH.GET_PROFILE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);
    INSTANCE.post("admin-auth/get-profile", inputJSON)
      .then(function (response) {
        let result = {
          data: {
            user: response.data.user,
            token: response.data.token,
          },
          message: response.data.message,
        };
        // Encrypt
        const ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(result.data),
          "GFYUFGTYGFTYTY64564545acvbvrttyFG@%#%#%#%#TTRR"
        ).toString();
        // _tok_u = JWT Token & user details
        Cookies.set("_tok_u", ciphertext, { expires: 7 });
        dispatch(getUserProfileSuccess(response));
      })
      .catch(function (error) {
        dispatch(getUserProfileError(error.message));
      });
  };
};

const getUserProfileSuccess = (data) => ({
  type: USER_AUTH.GET_PROFILE_SUCCESS,
  payload: data
});

const getUserProfileError = errorMessage => ({
  type: USER_AUTH.GET_PROFILE_FAIL,
  payload: errorMessage
});
