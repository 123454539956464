import React, { Component } from "react";
import { Card, notification, Breadcrumb } from "antd";
import { Button, Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

const LegalSchema = Yup.object().shape({
  description: Yup.string().required('Required')
})

class AddTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      description: null,
      isLoading: false
    }
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    }, () => {
      const AuthStr = 'Bearer '.concat(process.env.REACT_APP_API_TOKEN);
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthStr
        }
      });

      let inputJSON = JSON.stringify({
        type: 'Terms'
      })

      INSTANCE.post("legal/get-legal", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            if (response.data.data) {
              this.setState({
                isLoading: false,
                editId: response.data.data._id,
                description: response.data.data.description
              })
            } else {
              this.setState({
                isLoading: false
              })
            }
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })
    })
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    const inputJSON = {
      type: 'Terms',
      description: values.description
    }
    if (editId) {
      inputJSON._id = editId;
      this.updateForm(JSON.stringify(inputJSON), authUser);
    } else {
      this.submitForm(JSON.stringify(inputJSON), authUser);
    }
    this.setState({
      description: values.description
    })

  };

  submitForm(inputJSON, authUser) {
    this.setState({
      isLoading: true
    })
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    INSTANCE.post("legal/add", inputJSON)
      .then((response) => {
        if (response.data.messageId === 200) {
          this.setState({
            isLoading: false
          })
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
        } else {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })
  }

  updateForm(inputJSON, authUser) {
    this.setState({
      isLoading: true
    })
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    INSTANCE.post("legal/update", inputJSON)
      .then((response) => {
        if (response.data.messageId === 200) {
          this.setState({
            isLoading: false
          })
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
        } else {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })
  }

  render() {
    const { isLoading, editId, description } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/legal/terms" className="gx-link">Terms and Conditions</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit" : "Add"}/>
        {isLoading ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Card className="card-margin">
            <Formik
              validationSchema={LegalSchema}
              onSubmit={values => {
                this.onFinish(values);
              }}
              enableReinitialize={true}
              initialValues={{
                description: description
              }}>
              {({
                handleSubmit,
                setFieldValue,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <CKEditor
                          name="description"
                          isInvalid={!!errors.description}
                          editor={ClassicEditor}
                          data={values.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue("description", data);
                          }}
                        />
                        {errors.description && touched.description ? (
                          <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Button type="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          </Card>
        }
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(AddTerms);
