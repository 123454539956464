import { COUPONS } from "../../constants/actionTypes";
import axios from 'axios';

export const addCoupon = (token, data) => {
  return (dispatch) => {
    dispatch({ type: COUPONS.ADD_COUPONS_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("stripe/coupons/create", inputJSON)
      .then(function (response) {
        dispatch(addCouponSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addCouponError(error.message));
      });
  };
};

const addCouponSuccess = (data) => ({
  type: COUPONS.ADD_COUPONS_SUCCESS,
  payload: data
});

const addCouponError = errorMessage => ({
  type: COUPONS.ADD_COUPONS_FAIL,
  payload: errorMessage
});

export const listCoupon = (token) => {
  return (dispatch) => {
    dispatch({ type: COUPONS.LIST_COUPONS_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("stripe/coupons/lists")
      .then(function (response) {
        dispatch(listCouponSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listCouponError(error.message));
      });
  };
};

const listCouponSuccess = (data) => ({
  type: COUPONS.LIST_COUPONS_SUCCESS,
  payload: data
});

const listCouponError = errorMessage => ({
  type: COUPONS.LIST_COUPONS_FAIL,
  payload: errorMessage
});

export const deleteCoupon = (token, data) => {
  return (dispatch) => {
    dispatch({ type: COUPONS.DELETE_COUPONS_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("stripe/coupons/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteCouponSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteCouponError(error.message));
      });
  };
};

const deleteCouponSuccess = (data) => ({
  type: COUPONS.DELETE_COUPONS_SUCCESS,
  payload: data
});

const deleteCouponError = errorMessage => ({
  type: COUPONS.DELETE_COUPONS_FAIL,
  payload: errorMessage
});

export const resetCoupon = () => {
  return (dispatch) => {
    dispatch({ type:COUPONS.RESET_COUPONS })
  }
}
