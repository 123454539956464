import React, { useState } from "react";
import { Link } from "react-router-dom";
import Widget from "components/Widget/index";
import { Modal, Divider } from "antd";
import { FaTrashAlt, FaTrashRestoreAlt, FaEdit, FaRegCopy, FaRegHandPaper, FaRedoAlt } from 'react-icons/fa';

import HasPermission from '../../shared/routePermission/hasPermissions';
import { Roles } from "../../shared/enums/roles";

const GameSkinCard = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showHoldModal, setShowHoldModal] = useState(false);
  return (
    <Widget styleName="gx-card-full gx-text-center">
      {HasPermission([Roles.SuperAdmin]) && props.editable ?
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignContent: 'center', marginTop: '10px', marginRight: '10px' }}>
          <span title="Edit" className="gx-link">
            <Link to={`/game-template/edit-template-skin/${props.templateId}/${props.templateName}/${props.tempId}/${props.id}`}>
              <FaEdit className="icon-size" />
            </Link>
          </span>
          <Divider type="vertical" />
          <span title="Copy" className="gx-link">
            <Link to={`/game-template/copy-template-skin/${props.templateId}/${props.templateName}/${props.tempId}/${props.id}`}>
              <FaRegCopy className="icon-size" />
            </Link>
          </span>
          <Divider type="vertical" />
          <span title={props.status === "Hold" ? "Unhold" : "Hold"} className="gx-link delete-color" onClick={() => { setShowHoldModal(!showHoldModal) }}>
            {props.status === "Hold" ? <FaRedoAlt className="icon-size" /> : <FaRegHandPaper className="icon-size" />}
          </span>
          <Divider type="vertical" />
          {props.isDeleted ?
             <span title="Restore" className="gx-link delete-color" onClick={() => { setShowDeleteModal(!showDeleteModal) }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
            :
            <span title="Delete" className="gx-link delete-color" onClick={() => { setShowDeleteModal(!showDeleteModal) }}>
              <FaTrashAlt className="icon-size" />
            </span>
          }
        </div>
        : null}
      <Modal
        visible={showDeleteModal}
        title={props.isDeleted ? "Restore Template" : "Delete Template"}
        okText={props.isDeleted ? "Restore" : "Delete"}
        okType="danger"
        closable={false}
        onOk={() => {
          props.handleDelete(props.id, props.isDeleted);
          setShowDeleteModal(!showDeleteModal);
        }}
        onCancel={() => setShowDeleteModal(!showDeleteModal)}
        style={{ zIndex: 2600 }}
      >
        <h3>Are you sure you want to {props.isDeleted ? "restore" : "delete" } template skin {props.name}?</h3>
      </Modal>
      <Modal
        visible={showHoldModal}
        title={props.status === "Hold" ? "UnHold Template" : "Hold Template"}
        okText={props.status === "Hold" ? "UnHold" : "Hold"}
        okType="danger"
        closable={false}
        onOk={() => {
          props.handleHoldUnhold(props.id, props.status === "Hold" ? "Active" : "Hold");
          setShowHoldModal(!showHoldModal);
        }}
        onCancel={() => setShowHoldModal(!showHoldModal)}
        style={{ zIndex: 2600 }}
      >
        {props.status === "Hold" ? <h3>Are you sure you want to unhold this template?</h3> : <h3>Are you sure you want to hold this template?</h3>}
      </Modal>
      <div className="gx-pt-4 gx-px-3">
        <div className="gx-bg-success" />
        <div>
          <h2 className="gx-mb-4 display-2-lines" style={{ height: '46px' }}>{props.name}</h2>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center' }}>
           {/* {props.gigAccept ?
            <Link to={props.gigAccept}>
              <span className="gx-text-primary gx-pointer gx-text-uppercase gx-mb-3 gx-mb-xxl-2 gx-d-block">GIG Details</span>
            </Link> : null
          }  */}
          {props.learnMore ?
            <Link to={props.learnMore}>
              <span className="gx-text-primary gx-pointer gx-text-uppercase gx-mb-3 gx-mb-xxl-2 gx-d-block">More Details</span>
            </Link>
            : null}
        </div>
      </div>
      <div className="gx-mt-xxl-3 gx-ayurveda-thumb">
        <img className="gx-img-fluid gx-w-100" src={props.coverImage} alt={props.name} />
      </div>
    </Widget>
  );
};

export default GameSkinCard;
