import { GREETING_CATEGORY_TAG } from "../../constants/actionTypes";
import axios from 'axios';

export const addGreetingCategoryTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_CATEGORY_TAG.ADD_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-category-tag/create", inputJSON)
      .then(function (response) {
        dispatch(addGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addGreetingCategoryTagError(error.message));
      });
  };
};

const addGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.ADD_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const addGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.ADD_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const listGreetingCategoryTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_CATEGORY_TAG.LIST_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("greeting-category-tag/lists", inputJSON)
      .then(function (response) {
        dispatch(listGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGreetingCategoryTagError(error.message));
      });
  };
};

const listGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.LIST_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const listGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.LIST_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const getAllGreetingCategoryTag = (token) => {
  return (dispatch) => {
    dispatch({ type: GREETING_CATEGORY_TAG.GET_ALL_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("greeting-category-tag/get-all")
      .then(function (response) {
        dispatch(getAllGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGreetingCategoryTagError(error.message));
      });
  };
};

const getAllGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.GET_ALL_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const getAllGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.GET_ALL_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const deleteGreetingCategoryTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_CATEGORY_TAG.DELETE_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-category-tag/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteGreetingCategoryTagError(error.message));
      });
  };
};

const deleteGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.DELETE_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const deleteGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.DELETE_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const updateGreetingCategoryTag = (token, data) => {
  return (dispatch) => {
    dispatch({ type:GREETING_CATEGORY_TAG.UPDATE_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-category-tag/update", inputJSON)
      .then(function (response) {
        dispatch(updateGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateGreetingCategoryTagError(error.message));
      });
  };
};

const updateGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.UPDATE_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const updateGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.UPDATE_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const searchGreetingCategoryTag = (data, token) => {
  debugger
  return (dispatch) => {
    dispatch({ type: GREETING_CATEGORY_TAG.SEARCH_GREETING_CATEGORY_TAG_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("greeting-category-tag/search-greeting-category-Tag", inputJSON)
      .then(function (response) {
        dispatch(searchGreetingCategoryTagSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchGreetingCategoryTagError(error.message));
      });
  };
};

const searchGreetingCategoryTagSuccess = (data) => ({
  type: GREETING_CATEGORY_TAG.SEARCH_GREETING_CATEGORY_TAG_SUCCESS,
  payload: data
});

const searchGreetingCategoryTagError = errorMessage => ({
  type: GREETING_CATEGORY_TAG.SEARCH_GREETING_CATEGORY_TAG_FAIL,
  payload: errorMessage
});

export const resetGreetingCategoryTag = () => {
  return (dispatch) => {
    dispatch({ type:GREETING_CATEGORY_TAG.RESET_GREETING_CATEGORY_TAG })
  }
}
