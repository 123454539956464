import { PERSONALIZATION, TOKEN } from "../../constants/actionTypes";
import axios from 'axios';

export const listPersonalizations = (token, data) => {
  return (dispatch) => {
    dispatch({ type: PERSONALIZATION.LIST_PERSONALIZATION_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("personalization/lists", inputJSON)
      .then(function (response) {
        dispatch(listPersonalizationsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listPersonalizationsError(error.message));
      });
  };
};

const listPersonalizationsSuccess = (data) => ({
  type: PERSONALIZATION.LIST_PERSONALIZATION_SUCCESS,
  payload: data
});

const listPersonalizationsError = errorMessage => ({
  type: PERSONALIZATION.LIST_PERSONALIZATION_FAIL,
  payload: errorMessage
});

export const getPersonalizationsCount = (token) => {
  return (dispatch) => {
    dispatch({ type: PERSONALIZATION.PERSONALIZATION_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("personalization/get-count")
      .then(function (response) {
        dispatch(personalizationCountSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(personalizationCountError(error.message));
      });
  };
};

const personalizationCountSuccess = (data) => ({
  type: PERSONALIZATION.PERSONALIZATION_COUNT_SUCCESS,
  payload: data
});

const personalizationCountError = errorMessage => ({
  type: PERSONALIZATION.PERSONALIZATION_COUNT_FAIL,
  payload: errorMessage
});

export const searchPersonalization = (data, token) => {
  return (dispatch) => {
    dispatch({ type: PERSONALIZATION.SEARCH_PERSONALIZATION_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });
    let inputJSON = JSON.stringify(data);
    INSTANCE.post("personalization/search", inputJSON)
      .then(function (response) {
        dispatch(searchPersonalizationSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(searchPersonalizationError(error.message));
      });
  };
};

const searchPersonalizationSuccess = (data) => ({
  type: PERSONALIZATION.SEARCH_PERSONALIZATION_SUCCESS,
  payload: data
});

const searchPersonalizationError = errorMessage => ({
  type: PERSONALIZATION.SEARCH_PERSONALIZATION_FAIL,
  payload: errorMessage
});

export const giftItemCalculations = (data) => {
  return (dispatch) => {
    dispatch({ type: PERSONALIZATION.GIFT_ITEM_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(TOKEN);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("app-user/get-tokkenz", inputJSON)
      .then(function (response) {
        dispatch(giftItemCalculationsSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(giftItemCalculationsError(error.message));
      });
  };
};

const giftItemCalculationsSuccess = (data) => ({
  type: PERSONALIZATION.GIFT_ITEM_COUNT_SUCCESS,
  payload: data
});

const giftItemCalculationsError = errorMessage => ({
  type: PERSONALIZATION.GIFT_ITEM_COUNT_FAIL,
  payload: errorMessage
});

export const resetGamePersonalizations = () => {
  return (dispatch) => {
    dispatch({ type: PERSONALIZATION.PERSONALIZATION_RESET })
  }
}
