// Customizer const
export const THEME_TYPE = 'THEME_TYPE';
export const THEME_TYPE_LITE = 'THEME_TYPE_LITE';
export const THEME_TYPE_SEMI_DARK = 'THEME_TYPE_SEMI_DARK';

export const THEME_COLOR = 'light_purple';

export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';

export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT_TYPE_FULL = 'LAYOUT_TYPE_FULL';


export const NAV_STYLE = 'NAV_STYLE';
export const NAV_STYLE_MINI = 'NAV_STYLE_MINI';
export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR = 'NAV_STYLE_NO_HEADER_MINI_SIDEBAR';

export const TAB_SIZE = 992;
