import React from "react";
import { Modal, notification } from "antd";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RequiredField from "../../RequiredField/index";

const UserSchema = Yup.object().shape({
  assetType: Yup.string().required('Required'),
  audioAsset: Yup.boolean(),
  resolution: Yup.string().when("audioAsset", {
    is: false,
    then: Yup.string().required("Required")
  }),
  assetName: Yup.string().required('Required')
})

class AddAsset extends React.Component {
  constructor() {
    super();
    this.state = {
      assetType: '',
      resolution: '',
      assetName: '',
      fileType: '',
      fileSize: '',
      fileName: 'Browse File',
      assetFile: null,
    }
  }

  onFileChange = event => {
    let files = event.target.files;
    if (files[0]) {
      if (this.props.title !== "Audio" && ["image/png", "image/jpeg", "image/jpg"].includes(files[0].type)) {
        this.setState(state => ({
          assetFile: files[0],
          fileName: files[0].name,
          fileSize: files[0].size,
          fileType: files[0].type
        }));
      }
      else if (this.props.title === "Audio" && ["audio/mpeg", "audio/wav", "audio/ogg"].includes(files[0].type)) {
        this.setState(state => ({
          assetFile: files[0],
          fileName: files[0].name,
          fileSize: files[0].size,
          fileType: files[0].type
        }));
      }
      else {
        notification['error']({
          message: 'Alert!!',
          description: "The asset you added is the wrong file type.  Please re-submit.",
        });
      }
    }
    return false;
  }

  onFinish = values => {
    const { assetFile, fileName, fileSize, fileType } = this.state;
    if (this.props.title === "Audio") {
      if (!assetFile) {
        notification['error']({
          message: 'Alert!!',
          description: "Unable to Find Asset.  Please re-submit",
        });
      } else {
        values.assetFile = assetFile;
        values.fileName = fileName;
        values.fileSize = fileSize;
        values.fileType = fileType;
        this.props.onAddAsset(values);
        this.props.onClose();
      }
    }
    else {
      if (!assetFile) {
        notification['error']({
          message: 'Alert!!',
          description: "Unable to Find Asset.  Please re-submit",
        });
      } else {
        let files = assetFile;
        var img = new Image();
        img.src = window.URL.createObjectURL(files);

        img.onload = () => {
          var width = img.naturalWidth, height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          const resolution = values.resolution.split("*")
          if (width >= parseInt(resolution[0]) && height >= parseInt(resolution[1])) {
            values.assetFile = assetFile;
            values.fileName = fileName;
            values.fileSize = fileSize;
            values.fileType = fileType;
            this.props.onAddAsset(values);
            this.props.onClose();
          } else {
            if (width > parseInt(resolution[0])) {
              notification['error']({
                message: 'Alert!!',
                description: "The asset you added is too big.  Please re-submit",
              });
            }
            else {
              notification['error']({
                message: 'Alert!!',
                description: "The asset you added is too small.  Please re-submit",
              });
            }
          }
        }
      }
    }
  };

  render() {
    const { onClose, title } = this.props;
    const { fileName } = this.state;
    return (
      <Modal onCancel={onClose} visible={this.props.open}
        title={"Add " + title}
        closable={false}
        footer={null}
        style={{ zIndex: 2600 }}
      >
        <Row>
          <Col>
            <Formik
              validationSchema={UserSchema}
              onSubmit={values => {
                this.onFinish(values);
              }}
              enableReinitialize={true}
              initialValues={{
                assetType: '',
                resolution: '',
                assetName: '',
                fileSize: '',
                fileName: 'Browse File',
                assetFile: null,
                audioAsset: title === 'Audio'
              }}>
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row className="formrow">
                    <Col>
                      <Form.Label>Asset Type<RequiredField /></Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="assetType"
                        value={values.assetType}
                        onChange={handleChange}
                        isInvalid={!!errors.assetType}
                      />
                      {errors.assetType && touched.assetType ? (
                        <Form.Control.Feedback type="invalid">{errors.assetType}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>Asset Name<RequiredField /></Form.Label>
                      <Form.Control
                        type="text"
                        required
                        name="assetName"
                        value={values.assetName}
                        onChange={handleChange}
                        isInvalid={!!errors.assetName}
                      />
                      {errors.assetName && touched.assetName ? (
                        <Form.Control.Feedback type="invalid">{errors.assetName}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Form.Row>

                  <Form.Row className="formrow">
                    <Col>
                      <Form.Label>Resolution {title !== 'Audio' ? <RequiredField /> : ''}</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="1024*500"
                        name="resolution"
                        value={values.resolution}
                        onChange={handleChange}
                        isInvalid={!!errors.resolution}
                      />
                      {errors.resolution && touched.resolution ? (
                        <Form.Control.Feedback type="invalid">{errors.resolution}</Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col>
                      <Form.Label>Asset File</Form.Label>
                      <Form.File
                        id="custom-file"
                        label={fileName}
                        custom
                        onChange={this.onFileChange}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="btnrow">
                    <Col>
                      <Button type="button" variant="outline-danger" onClick={() => { onClose() }}>
                        Cancel
                        </Button>
                      <Button type="submit">
                        Add
                        </Button>
                    </Col>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default AddAsset;
