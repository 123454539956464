import { MARKETING } from "../../../constants/actionTypes";
import axios from 'axios';

export const addSlide = (token, data) => {
    return (dispatch) => {
        dispatch({ type: MARKETING.ADD_SLIDE_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("marketing/slides/create", inputJSON)
            .then(function (response) {
                dispatch(addSlideSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addSlideError(error.message));
            });
    };
};

const addSlideSuccess = (data) => ({
    type: MARKETING.ADD_SLIDE_SUCCESS,
    payload: data
});

const addSlideError = errorMessage => ({
    type: MARKETING.ADD_SLIDE_FAIL,
    payload: errorMessage
});

export const listSlides = (token, data) => {
    return (dispatch) => {
        dispatch({ type: MARKETING.LIST_SLIDE_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("marketing/slides/lists", inputJSON)
            .then(function (response) {
                dispatch(listSlideSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(listSlideError(error.message));
            });
    };
};

const listSlideSuccess = (data) => ({
    type: MARKETING.LIST_SLIDE_SUCCESS,
    payload: data
});

const listSlideError = errorMessage => ({
    type: MARKETING.LIST_SLIDE_FAIL,
    payload: errorMessage
});

export const updateSlide = (token, data) => {
    return (dispatch) => {
        dispatch({ type: MARKETING.UPDATE_SLIDE_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("marketing/slides/update", inputJSON)
            .then(function (response) {
                dispatch(updateSlideSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateSlideError(error.message));
            });
    };
};

const updateSlideSuccess = (data) => ({
    type: MARKETING.UPDATE_SLIDE_SUCCESS,
    payload: data
});

const updateSlideError = errorMessage => ({
    type: MARKETING.UPDATE_SLIDE_FAIL,
    payload: errorMessage
});

export const resetSlide = () => {
    return (dispatch) => {
        dispatch({ type: MARKETING.RESET_SLIDE})
    }
}
