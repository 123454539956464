import React, { Component } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, notification, Switch, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import lodash from "lodash";
import * as Yup from 'yup';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";

import Auxiliary from "util/auxiliary";

import {
  addUser,
  getUser,
  resetUser,
  updateUser
} from "./../../../appRedux/actions/users";

import {
  getCountry, getStates, getCity
} from "./../../../appRedux/actions/location";

import {
  listRoles, resetRoles
} from "./../../../appRedux/actions/roles";

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  mobilePhone: Yup.string().matches(/^\d{10}$/, 'Invalid mobile phone').required('Required').min(10, 'Invalid mobile phone').max(10, 'Invalid mobile phone'),
  email: Yup.string().email('Invalid email').required('Required'),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  userRole: Yup.string().required('Required'),
  gameCompleteNotification: Yup.boolean().required('Required')
});

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      editId: null,
      countryList: [],
      stateList: [],
      cityList: [],
      roleList: [],
      user: {
        firstName: '',
        lastName: '',
        mobilePhone: '',
        email: '',
        country: '',
        state: '',
        userRole: '',
        city: '',
        gameCompleteNotification: false
      }
    }
  };

  componentDidMount() {
    this.props.getCountry();
    const { authUser } = this.props.auth;
    this.props.listRoles(authUser.token);
    if (this.props.match.params.id) {
      let inputJSON = {
        _id: this.props.match.params.id
      }
      this.props.getUser(authUser.token, inputJSON);
      this.setState({
        editId: this.props.match.params.id
      })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.users) {
      if (nextProps.users.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.users.alertMessage
        });
        nextProps.resetUser();
        nextProps.history.goBack();
      } else if (nextProps.users.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.users.alertMessage
        });
        nextProps.resetUser();
      } else if (nextProps.users.messageId === 202) {
        let userData = nextProps.users.userData;
        const user = {
          firstName: userData.firstName,
          lastName: userData.lastName,
          mobilePhone: userData.mobilePhone,
          email: userData.email,
          country: userData.country,
          state: userData.state,
          city: userData.city,
          userRole: userData.userRole[0]._id,
          gameCompleteNotification: userData.gameCompleteNotification ? userData.gameCompleteNotification : false,
        };
        let Input1 = {
          countryCode: userData.country
        }
        nextProps.getStates(Input1);
        let Input = {
          stateCode: userData.state
        }
        nextProps.getCity(Input);
        nextProps.resetUser();
        return {
          user: user
        }
      }
    }

    if (nextProps.roles.messageId === 200 && nextProps.roles.lists.length > 0) {
      nextProps.resetRoles();
      return {
        roleList: nextProps.roles.lists
      }
    }

    if (nextProps.locationData.messageId === 200) {
      return {
        countryList: nextProps.locationData.countryList
      }
    }

    if (nextProps.locationData.messageId === 204) {
      return {
        stateList: nextProps.locationData.stateList
      }
    }

    if (nextProps.locationData.messageId === 205) {
      return {
        cityList: nextProps.locationData.cityList
      }
    }

    return null;
  }

  generatePassword(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  onFinish = values => {
    const { countryList, stateList, editId } = this.state;
    let countryObj = lodash.find(countryList, function (o) { return o.isoCode === values.country; });
    let stateObj = lodash.find(stateList, function (o) { return o.isoCode === values.state; });

    values.generatePassword = this.generatePassword(8);
    values.country = countryObj.name;
    values.state = stateObj.name;
    values.dialCode = '+' + countryObj.phonecode;
    const { authUser } = this.props.auth;

    if (editId) {
      let inputJSON = values;
      inputJSON._id = editId;
      this.props.updateUser(authUser.token, inputJSON);
    } else {
      this.props.addUser(authUser.token, values);
    }
  };

  getStatesByCountry(countryCode) {
    let Input = {
      countryCode: countryCode
    }
    this.props.getStates(Input);
  }

  getCitiesByStates(stateCode) {
    let Input = {
      stateCode: stateCode
    }
    this.props.getCity(Input);
  }

  render() {
    const { loader } = this.props.users;
    const { editId, countryList, stateList, cityList, roleList, user, isLoading } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/users/list" className="gx-link">Users</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit User" : "Add User"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit User" : "Add User"} />
        <Row className="row-margin2">
          <Col>
            {loader || isLoading ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Formik
                  validationSchema={UserSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={user}>
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                          />
                          {errors.firstName && touched.firstName ? (
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          {errors.lastName && touched.lastName ? (
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Mobile Phone</Form.Label>
                          <Form.Control
                            type="tel"
                            required
                            name="mobilePhone"
                            value={values.mobilePhone}
                            onChange={handleChange}
                            isInvalid={!!errors.mobilePhone}
                          />
                          {errors.mobilePhone && touched.mobilePhone ? (
                            <Form.Control.Feedback type="invalid">{errors.mobilePhone}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            required
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          {errors.email && touched.email ? (
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <Form.Label>Role</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="userRole"
                            required
                            value={values.userRole}
                            onChange={e => {
                              const { value } = e.target;
                              setFieldValue("userRole", value);
                            }}
                            isInvalid={!!errors.userRole}>
                            <option value={null}>Select Role</option>
                            {roleList.map(item => (
                              <option key={item._id} value={item._id}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.userRole && touched.userRole ? (
                            <Form.Control.Feedback type="invalid">{errors.userRole}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="country"
                            required
                            value={values.country}
                            onChange={e => {
                              const { value } = e.target;
                              this.getStatesByCountry(value);
                              setFieldValue("country", value);
                            }}
                            isInvalid={!!errors.country}>
                            <option value={null}>Select Country</option>
                            {countryList.map(item => (
                              <option key={item._id} value={item.isoCode}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.country && touched.country ? (
                            <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="state"
                            required
                            value={values.state}
                            onChange={e => {
                              const { value } = e.target;
                              this.getCitiesByStates(value);
                              setFieldValue("state", value);
                            }}
                            isInvalid={!!errors.state}>
                            <option value={null}>Select State</option>
                            {stateList.map(item => (
                              <option key={item._id} value={item.isoCode}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.state && touched.state ? (
                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            name="city"
                            required
                            value={values.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}>
                            <option value={null}>Select City</option>
                            {cityList.map(item => (
                              <option key={item._id} value={item.name}>{item.name}</option>
                            ))}
                          </Form.Control>
                          {errors.city && touched.city ? (
                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow" style={{ marginTop: '20px' }}>
                        <Col>
                          <Form.Label>Game Complete Notification</Form.Label>
                          <Switch
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            defaultChecked={values.gameCompleteNotification}
                            onChange={checked => {
                              setFieldValue("gameCompleteNotification", checked);
                            }}
                            style={{ marginLeft: '20px' }}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <Button type="submit">
                            {editId ? "Update" : "Submit"}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    locationData: state.locationData,
    users: state.users,
    roles: state.roles
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addUser, getUser, updateUser, resetUser, getCountry, getStates, getCity, listRoles, resetRoles }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddUser);

