import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import GameTemplates from "./gameTemplates";
import AddGameTemplate from "./addGameTemplate";
import AddGameSkin from "./addGameSkin";
import GameSkins from "./gameSkins";
import PreviewGameSkin from "./previewGameSkin";

import GigAcceptance from "./gigAcceptance";
import TemplateSubmission from "./submission";
import AdminGameTemplate from "./adminGameTemplate/index";
import ApproveGigs from "./approveGigs/index";
import GigApproveForm from './gigApproveForm/index';
import Details from './details/index';
import AssetGallery from './assetGallery/index';

const Main = ({ match }) => (
  <div>
    <Switch>
      <Route path={`${match.url}/list`} component={GameTemplates} />
      <Route path={`${match.url}/add-template`} component={AddGameTemplate} />
      <Route path={`${match.url}/edit-template/:id`} component={AddGameTemplate} />

      <Route path={`${match.url}/template-skins/:id/:name/:tempId`} component={GameSkins} />
      <Route path={`${match.url}/add-template-skin/:id/:name/:tempId`} component={AddGameSkin} />
      <Route path={`${match.url}/prev-template-skin/:id/:name/:tempId/:edit`} component={PreviewGameSkin} />
      <Route path={`${match.url}/edit-template-skin/:id/:name/:tempId/:edit`} component={AddGameSkin} />
      <Route path={`${match.url}/copy-template-skin/:id/:name/:tempId/:copy`} component={AddGameSkin} />

      <Route path={`${match.url}/admin-game-template`} component={AdminGameTemplate} />
      <Route path={`${match.url}/submission`} component={TemplateSubmission} />
      <Route path={`${match.url}/gamey-skin-gig-acceptance/:id`} component={GigAcceptance} />
      <Route path={`${match.url}/approve-gigs`} component={ApproveGigs} />
      <Route path={`${match.url}/gig-approve/:id`} component={GigApproveForm} />
      <Route path={`${match.url}/details/:id`} component={Details} />
      <Route path={`${match.url}/asset-gallery`} component={AssetGallery} />

      <Route exact path={`*`}>
        <Redirect to={`${match.url}/list`} />
      </Route>
    </Switch>
  </div>
);

export default Main;
