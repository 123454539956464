import {
    MESSAGE
} from "../../constants/actionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    messageId: null,
    lists: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MESSAGE.RESET_MESSAGE: {
            return {
                ...state,
                loader: false,
                alertMessage: '',
                messageId: null
            }
        }

        case MESSAGE.LIST_MESSAGE_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case MESSAGE.LIST_MESSAGE_SUCCESS: {
            return {
                ...state,
                loader: false,
                messageId: 204,
                lists: action.payload
            }
        }

        case MESSAGE.LIST_MESSAGE_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message
            }
        }

        case MESSAGE.DELETE_MESSAGE_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case MESSAGE.DELETE_MESSAGE_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }

        case MESSAGE.DELETE_MESSAGE_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case MESSAGE.UPDATE_MESSAGE_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case MESSAGE.UPDATE_MESSAGE_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }

        case MESSAGE.UPDATE_MESSAGE_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        default:
            return state;
    }
}
