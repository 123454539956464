import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomScrollbars from "util/customScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  THEME_TYPE_LITE,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/themeSetting";
import HasPermission from "../../shared/routePermission/hasPermissions";
import { Roles } from "../../shared/enums/roles";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  // let { pathname } = useSelector(({ common }) => common);

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  // const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            // defaultOpenKeys={['home.dashboard', 'contributor.dashboard', 'game-factory', 'game-skin', 'personalization', 'personalization.greeting-package-list', 'personalization.event', 'event.event-image', 'game-catalog', 'legal', 'reports', 'marketing']}
            // selectedKeys={[defaultOpenKeys]}
            inlineCollapsed={true}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup key="home" className="gx-menu-group">
              <Menu.Item key="home.dashboard">
                <Link to="/home/dashboard">
                  <i className="icon icon-widgets" />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>

              {HasPermission([Roles.SuperAdmin, Roles.DevOpsSuperAdmin]) ? (
                <Menu.Item key="home.senddemo">
                  <Link to="/home/send-demo">
                    <i className="icon icon-contacts" />
                    <span>Send Demo</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {/* {HasPermission([Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.ContentAdmin, Roles.DevOpsAdmin]) ?
                <SubMenu key="game-factory" popupClassName={getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                    <i className="icon icon-dasbhoard" />
                    <span>Gamey Factory</span>
                  </span>
                }>
                  <Menu.Item key="game-template.admin-game-template">
                      <Link to="/game-template/admin-game-template">
                        <span>Admin Game Template</span>
                      </Link>
                    </Menu.Item>
                    {HasPermission([Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.ContentAdmin, Roles.DevOpsAdmin, Roles.Contributor]) ?
                      <Menu.Item key="game-skin.submission">
                        <Link to="/game-template/submission">
                          <span>Template Submission</span>
                        </Link>
                      </Menu.Item> : ''}
                      <Menu.Item key="game-skin.approveGigs">
                        <Link to="/game-template/approve-gigs">
                          <span>Accept Gigs</span>
                        </Link>
                      </Menu.Item>
                </SubMenu>
                : ''} */}
              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.ContentAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <SubMenu
                  key="game-skin"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-icon" />
                      <span>Game Factory</span>
                    </span>
                  }
                >
                  <Menu.Item key="game-skin.game-template">
                    <Link to="/game-template/list">
                      <span>Game Templates</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="game-skin.assetGallery">
                    <Link to="/game-template/asset-gallery">
                      <span>Asset Gallery</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                ""
              )}
              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.ContentAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <SubMenu
                  key="personalization"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-data-display" />
                      <span>Personalization</span>
                    </span>
                  }
                >
                  <SubMenu
                    key="personalization.greeting-package-list"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={<span>Greeting Packages</span>}
                  >
                    <Menu.Item key="greeting-package-list.greeting-package.list">
                      <Link to="/greeting-package/list">
                        <span>Greeting Package</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="greeting-package-list.greeting-category-tag.list">
                      <Link to="/greeting-category-tag/list">
                        <span>Greeting Category Tag</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="personalization.event"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={<span>Event</span>}
                  >
                    <Menu.Item key="event.event-title.list">
                      <Link to="/event-title/list">
                        <span>Event Titles</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="event.event-subcategory.list">
                      <Link to="/event-subcategory/list">
                        <span>Event Subcategories</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="personalization.interest.list">
                      <Link to="/interest/list">
                        <span>Interests</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="personalization.event-image"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={<span>Event Images</span>}
                  >
                    <Menu.Item key="event.event-image.list">
                      <Link to="/event-image/list">
                        <span>Event Image Types</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="event.event-image.listings">
                      <Link to="/event-image/listings">
                        <span>List Images</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
              ) : (
                ""
              )}
              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.ContentAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <SubMenu
                  key="game-catalog"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-card" />
                      <span>Game Catalog</span>
                    </span>
                  }
                >
                  <Menu.Item key="game-catalog.inventory">
                    <Link to="/game-catalog/inventory">
                      <span>Game Inventory</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="game-catalog.game-category-tags">
                    <Link to="/game-catalog/game-category-tags">
                      <span>Game Category Tags</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="game-catalog.game-sub-categories">
                    <Link to="/game-catalog/game-sub-categories">
                      <span>Game Sub Categories</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="game-catalog.info-tags">
                    <Link to="/game-catalog/info-tags">
                      <span>Info Tags</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="game-catalog.special-tags">
                    <Link to="/game-catalog/special-tags">
                      <span>Special Tags</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                ""
              )}

              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="home.tokkenz-types">
                  <Link to="/tokkenz-types/list">
                    <i className="icon icon-contacts" />
                    <span>Tokkenz Types</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="home.coupons">
                  <Link to="/coupons/list">
                    <i className="icon icon-contacts" />
                    <span>Coupons</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {/* {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="home.promotion-code">
                  <Link to="/promotion-code/list">
                    <i className="icon icon-contacts" />
                    <span>Promotion Codes</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )} */}

              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="video-tutorial.list">
                  <Link to="/video-tutorial/list">
                    <i className="icon icon-camera" />
                    <span>Video Tutorials</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {HasPermission([Roles.SuperAdmin, Roles.DevOpsSuperAdmin]) ? (
                <SubMenu
                  key="legal"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-editor" />
                      <span>Legal</span>
                    </span>
                  }
                >
                  <Menu.Item key="legal.terms">
                    <Link to="/legal/terms">
                      <span>Terms and Conditions</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="legal.privacy-policy">
                    <Link to="/legal/privacy-policy">
                      <span>Privacy Policy</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="legal.cookie-policy">
                    <Link to="/legal/cookie-policy">
                      <span>Cookie Policy</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="legal.acceptable-use-policy">
                    <Link to="/legal/acceptable-use-policy">
                      <span>Acceptable Use Policy</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                ""
              )}

              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="faqs">
                  <Link to="/faqs">
                    {" "}
                    <i className="icon icon-sweet-alert" />
                    <span>FAQs</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}
              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <Menu.Item key="messages">
                  <Link to="/messages">
                    {" "}
                    <i className="icon icon-email" />
                    <span>Messages</span>
                  </Link>
                </Menu.Item>
              ) : (
                ""
              )}

              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <SubMenu
                  key="reports"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-chart" />
                      <span>Reports</span>
                    </span>
                  }
                >
                  <Menu.Item key="reports.asset-library">
                    <Link to="/reports/customers">
                      <span>App Users</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                ""
              )}

              {HasPermission([
                Roles.SuperAdmin,
                Roles.DevOpsSuperAdmin,
                Roles.MarketingAdmin,
                Roles.DevOpsAdmin,
              ]) ? (
                <SubMenu
                  key="marketing"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-copy" />
                      <span>Marketing</span>
                    </span>
                  }
                >
                  <Menu.Item key="marketing.slides">
                    <Link to="/marketing/slides">
                      <span>Slides</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                ""
              )}
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
