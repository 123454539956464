import React, { Component } from "react";
import { Button, Card, Form, Input, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  addEventImageType,
  updateEventImageType,
  resetEventImageType
} from "./../../../appRedux/actions/eventImages";

const FormItem = Form.Item;

class AddEventImageType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("event-images/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              description: response.data.data.description ? response.data.data.description : ''
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.eventImage) {
      if (nextProps.eventImage.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.eventImage.alertMessage
        });
        nextProps.resetEventImageType();
      } else if (nextProps.eventImage.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.eventImage.alertMessage
        });
        nextProps.resetEventImageType();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    if (editId) {
      let inputJSON = values;
      inputJSON._id = editId;
      this.props.updateEventImageType(authUser.token, inputJSON);
    } else {
      this.props.addEventImageType(authUser.token, values);
    }
  };

  render() {
    const { loader } = this.props.eventImage;
    const { editId } = this.state;

    return (
      <Auxiliary>
        <PageHeader title={editId ? "Edit Event Image Type" : "Add Event Image Type"}/>
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Form
                  name="imageTypeForm"
                  onFinish={this.onFinish}
                  layout="vertical"
                  requiredMark={'optional'}
                  ref={this.formRef}
                >
                  <FormItem label="Name" rules={[{ required: true, message: 'Please enter name.' }]} name="name">
                    <Input placeholder="Name" />
                  </FormItem>

                  <FormItem label="Description" name="description">
                    <Input.TextArea placeholder="Description" rows={4} />
                  </FormItem>

                  <FormItem>
                    <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    eventImage: state.eventImage
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addEventImageType, updateEventImageType, resetEventImageType }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddEventImageType);
