import React, { Component } from "react";
import { Button, Card, Form, Input, Select, DatePicker, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  getAllEventTitles
} from "./../../../appRedux/actions/eventTitle";

import {
  getAllGreetingCategoryTag
} from "../../../appRedux/actions/greetingCategoryTag";
import {
  addEventSubCategory,
  updateEventSubCategory,
  resetEventSubCategory
} from "./../../../appRedux/actions/eventSubCategory";

const FormItem = Form.Item;
const { Option } = Select;

class AddEventSubCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: false
    }

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { authUser } = this.props.auth;
    this.props.getAllEventTitles(authUser.token);
    this.props.getAllGreetingCategoryTag(authUser.token);
    if (this.props.match.params.id) {
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("event-subcategory/get-by-id", inputJSON).then((response) => {
        if (response.data.messageId === 200) {
          this.formRef.current.setFieldsValue({
            eventTitle: response.data.data.eventTitle,     
            greetingCategoryTag : response.data.data.greetingCategoryTag,
            name: response.data.data.name,
            event_date: [moment(response.data.data.startDate), moment(response.data.data.endDate)]
          });
          this.setState({
            editId: this.props.match.params.id
          })
        }
      })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.eventSubCategory) {
      if (nextProps.eventSubCategory.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.eventSubCategory.alertMessage
        });
        nextProps.resetEventSubCategory();
      } else if (nextProps.eventSubCategory.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.eventSubCategory.alertMessage
        });
        nextProps.resetEventSubCategory();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = {
      eventTitle: values.eventTitle,      
      greetingCategoryTag : values.greetingCategoryTag,
      name: values.name,
      startDate: values.event_date[0]._d,
      endDate: values.event_date[1]._d
    }
    if (editId) {
      inputJSON._id = editId;
      this.props.updateEventSubCategory(authUser.token, inputJSON);
    } else {
      this.props.addEventSubCategory(authUser.token, inputJSON);
    }
  };

  render() {
    const  lists  = this.props.eventTitle;
    const { loader } = this.props.eventSubCategory;
    const { editId } = this.state;

    return (
      <Auxiliary>
        <PageHeader title={editId ? "Edit Event Subcategory" : "Add Event Subcategory"}/>
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Form
                  name="eventSubcategoryForm"
                  onFinish={this.onFinish}
                  layout={'vertical'}
                  ref={this.formRef}
                >
                  <FormItem name="eventTitle" label="Select Event Title" rules={[{ required: true, message: 'Please select event title.' }]}>
                    <Select placeholder="Select Event Title">
                      {lists?.map(item => (
                        <Option key={item._id}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem name="greetingCategoryTag" label="Select Greeting Tag" rules={[{ required: true, message: 'Please select greeting Tag.' }]}>
                    <Select placeholder="Select Greeting Tag" mode="multiple">
                      {this.props.greetingCategoryTag?.map(item => (
                        <Option key={item._id}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Event Subcategory Name" rules={[{ required: true, message: 'Please enter event subcategory name.' }]} name="name">
                    <Input placeholder="Event Subcategory Name" />
                  </FormItem>

                  <FormItem label="Start and End Date" rules={[{ required: true, message: 'Please select event start & end date.' }]} name="event_date">
                    <DatePicker.RangePicker style={{ width: '100%' }} format={'MM-DD-YYYY'} />
                  </FormItem>

                  <FormItem>
                    <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    eventTitle: state.eventTitle?.lists?.data,
    greetingCategoryTag: state.greetingCategoryTag?.lists?.data,
    eventSubCategory: state.eventSubCategory
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    getAllEventTitles,
    getAllGreetingCategoryTag,
    addEventSubCategory,
    updateEventSubCategory,
    resetEventSubCategory
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddEventSubCategory);
