import {
  GAME_TEMPLATE
} from "../../constants/actionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  messageId: null,
  lists: [],
  skinLists: [],
  gameTemplateCount: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GAME_TEMPLATE.RESET_GAME_TEMPLATE: {
      return {
        ...state,
        loader: false,
        alertMessage: '',
        messageId: null
      }
    }

    case GAME_TEMPLATE.ADD_GAME_TEMPLATE_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.ADD_GAME_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.ADD_GAME_TEMPLATE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.EDIT_GAME_TEMPLATE_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.EDIT_GAME_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.EDIT_GAME_TEMPLATE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.LIST_GAME_TEMPLATE_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.LIST_GAME_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        lists: action.payload,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.LIST_GAME_TEMPLATE_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.ASSET_GALLERY_REQUEST: {
      return {
        ...state,
        loader: false
      }
    }

    case GAME_TEMPLATE.ASSET_GALLERY_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: false,
        lists: action.payload,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.ASSET_GALLERY_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload
      }
    }

    case GAME_TEMPLATE.GAME_TEMPLATE_COUNT_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.GAME_TEMPLATE_COUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
        gameTemplateCount: action.payload
      }
    }

    case GAME_TEMPLATE.GAME_TEMPLATE_COUNT_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message
      }
    }

    // Template Skins

    case GAME_TEMPLATE.LIST_TEMPLATE_SKIN_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.LIST_TEMPLATE_SKIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        skinLists: action.payload,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.LIST_TEMPLATE_SKIN_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.ADD_TEMPLATE_SKIN_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.ADD_TEMPLATE_SKIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.ADD_TEMPLATE_SKIN_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.EDIT_TEMPLATE_SKIN_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case GAME_TEMPLATE.GET_ALL_GAME_SKIN_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case GAME_TEMPLATE.GET_ALL_GAME_SKIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        skinLists: action.payload,
        messageId: 200
      }
    }

    case GAME_TEMPLATE.GET_ALL_GAME_SKIN_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }
    
    default:
      return state;
  }
}
