import React, { Component } from "react";
import {
  Divider,
  Breadcrumb,
  notification,
  Button,
  Modal,
  Tag,
} from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import { FaTrashAlt, FaRegEdit, FaTrashRestoreAlt } from "react-icons/fa";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  listTokkenzType,
  deleteTokkenzType,
  resetTokkenzType
} from "../../../appRedux/actions/tokkenzType";

const getRandomuserParams = (params) => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return (
      <Button className="ant-paginatio-button">
        <MdNavigateBefore className="icon-size" /> Previous
      </Button>
    );
  }
  if (type === "next") {
    return (
      <Button className="ant-paginatio-button">
        Next <MdNavigateNext className="icon-size" />
      </Button>
    );
  }
  return originalElement;
};
class ListTokkenzType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      tokkenzTypeDataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };

    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Value (Tokkenz)",
        dataIndex: "value",
        key: "value",
        width: 130,
        sorter: (a, b) => a.value.length - b.value.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 300,
        sorter: (a, b) => a.description.length - b.description.length,
        sortDirections: ["descend", "ascend"],
        render: (description) => {
          return <span>{description}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "isDeleted",
        key: "isDeleted",
        sorter: (a, b) => a.isDeleted - b.isDeleted,
        sortDirections: ["descend", "ascend"],
        render: (isDeleted) => {
          return (
            <Tag color={isDeleted ? "warning" : "processing"}>
              {isDeleted ? "Deleted" : "Active"}
            </Tag>
          );
        }
      },
      {
        title: "Last Updated",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: (a, b) =>
          moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        sortDirections: ["descend", "ascend"],
        render: (updatedAt) => {
          return <span>{moment(updatedAt).format("MM-DD-YYYY hh:mm a")}</span>;
        },
      }, {
        title: 'Last Updated By',
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        width: 150,
        sorter: (a, b) => a.modifiedBy?.firstName.length - b.modifiedBy.firstName?.length,
        sortDirections: ['descend', 'ascend'],
        render: (modifiedBy) => {
          return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
        }
      },
      {
        title: "Action",
        key: "action",
        width: 100,
        render: (text, record) => (
          <span>
            <span className="gx-link">
              {
                <Link to={`/tokkenz-types/edit/${record._id}`}>
                  <FaRegEdit className="icon-size gx-link" />
                </Link>
              }
            </span>
            <Divider type="vertical" />
            {!record.isDeleted ? (
              <span
                title="Delete"
                className="gx-link delete-color"
                onClick={(e) => {
                  this.onConfirmDelete(record);
                }}
              >
                <FaTrashAlt className="icon-size" />
              </span>
            ) : (
              <span
                title="Restore"
                className="gx-link delete-color"
                onClick={(e) => {
                  this.onConfirmRestore(record);
                }}
              >
                <FaTrashRestoreAlt className="icon-size" />
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.tokkenzTypeData) {
      if (nextProps.tokkenzTypeData.messageId === 204) {
        return {
          tokkenzTypeDataList: nextProps.tokkenzTypeData.lists.data,
          pagination: {
            current: nextProps.tokkenzTypeData.lists.current,
            pageSize: nextProps.tokkenzTypeData.lists.pageSize,
            total: nextProps.tokkenzTypeData.lists.totalCount,
          },
        };
      } else if (nextProps.tokkenzTypeData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.tokkenzTypeData.alertMessage,
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetTokkenzType();
      } else if (nextProps.tokkenzTypeData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.tokkenzTypeData.alertMessage,
        });
        nextProps.resetTokkenzType();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState(
      {
        record: record,
      },
      () => {
        Modal.confirm({
          title: "Delete",
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: "Are you sure you want to delete " + record.name + "?",
          okText: "Delete",
          okType: "danger",
          cancelText: "Cancel",
          onOk: this.onDeleteRecord,
        });
      }
    );
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true,
    };
    this.props.deleteTokkenzType(authUser.token, data);
  };

  onConfirmRestore(record) {
    this.setState(
      {
        record: record,
      },
      () => {
        Modal.confirm({
          title: "Restore",
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: "Are you sure you want to restore " + record.name + "?",
          okText: "Restore",
          okType: "danger",
          cancelText: "Cancel",
          onOk: this.onRestoreRecord,
        });
      }
    );
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false,
    };
    this.props.deleteTokkenzType(authUser.token, data);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize,
      };
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? "TRUE" : "FALSE";
      }
      this.props.listTokkenzType(authUser.token, inputJson);
    }
  };

  onChange = (e) => {
    if (e.type === "click") {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.tokkenzTypeData;
    const { pagination, tokkenzTypeDataList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Tokkenz Types</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader
          title="Tokkenz Types"
          navigateTo="/tokkenz-types/add"
          linkName="Add Tokkenz Type"
        />
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={tokkenzTypeDataList}
              rowKey={(record) => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: "small",
              }}
              loading={loader}
              size={"middle"}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tokkenzTypeData: state.tokkenzTypeData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      listTokkenzType,
      deleteTokkenzType,
      resetTokkenzType
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(ListTokkenzType);
