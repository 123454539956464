import React, { Component } from "react";
import { Breadcrumb, notification, Divider, Select, Tag, Button, Modal } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FaTrashAlt, FaRegEdit, FaEye, FaTrashRestoreAlt } from 'react-icons/fa';

import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";
import {
  listGreetingCategoryTag
} from "../../../appRedux/actions/greetingCategoryTag";

import {
  listGreetingPackage,
  deleteGreetingPackage,
  resetGreetingPackage
} from '../../../appRedux/actions/greetingPackage';

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListGreetingPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      selected: [],
      greetingPackageList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Greeting Package Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ['descend', 'ascend'],
      render: (title) => { return (<span>{title}</span>) }
    }, {
      title: '#Tag',
      dataIndex: 'greetingCategoryTags',
      key: 'greetingCategoryTags',
      locale: { filterConfirm: 'Ok' },
      defaultFilteredValue: ['tag1'],
      onFilter: (value, record) => {
        if (this.state.selected.length === 0)
          return true;
        for (let i = 0; i < this.state.selected.length; i++) {
          const val = this.state.selected[i];
          if (record.greetingCategoryTags && record.greetingCategoryTags.some(x => x.name === val))
            return true;
        }
        return false;
      },
      render: tags => (
        <>
          {tags
            ? tags.map(tag => (
              <Tag color="blue" key={tag._id}>
                {tag.name}
              </Tag>
            ))
            : null
          }
        </>
      )
    }, {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Deleted' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Deleted',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => a.createdBy.firstName?.length - b.createdBy?.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (createdBy) => {
        return (<span>{createdBy?.firstName} {createdBy?.lastName}</span>)
      }
    }, {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: (a, b) => a.modifiedBy.firstName?.length - b.modifiedBy.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (modifiedBy) => {
        return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
      }
    }, {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <span className="gx-link">
            <Link to={`/greeting-package/preview/${record.name}/${record._id}`} title="Preview"><FaEye className="icon-size gx-link" /></Link>
          </span>
          <Divider type="vertical" />
          <span className="gx-link">
            <Link to={`/greeting-package/edit/${record._id}`} title="Edit"><FaRegEdit className="icon-size gx-link" /></Link>
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
    const { authUser } = this.props.auth;
    let inputJson = {
      current: 1,
      pageSize: 500
    }
    this.props.listGreetingCategoryTag(authUser.token, inputJson);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.greetingPackage) {
      if (nextProps.greetingPackage.messageId === 204) {
        return {
          greetingPackageList: nextProps.greetingPackage.lists.data,
          pagination: {
            current: nextProps.greetingPackage.lists.current,
            pageSize: nextProps.greetingPackage.lists.pageSize,
            total: nextProps.greetingPackage.lists.totalCount
          }
        }
      } else if (nextProps.greetingPackage.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.greetingPackage.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetGreetingPackage();
      } else if (nextProps.greetingPackage.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.greetingPackage.alertMessage
        });
        nextProps.resetGreetingPackage();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete ' + record.name + '?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteGreetingPackage(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.name + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteGreetingPackage(authUser.token, data);
  }

  handleSelectTag = (tag) => {
    if (!this.state.selected.includes(tag)) {
      this.setState({
        ...this.state,
        selected: [...this.state.selected, tag]
      });
    }
  }

  handleRemoveTag = (tag) => {
    const newSelectedArr = this.state.selected.filter(item => item !== tag);
    this.setState({
      ...this.state,
      selected: [...newSelectedArr]
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listGreetingPackage(authUser.token, inputJson);
    }
  }

  render() {
    const { loader } = this.props.greetingPackage;
    const { pagination, greetingPackageList, selected } = this.state;
    const tagList = this.props.greetingCategoryTag;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Greeting Packages</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Greeting Packages" navigateTo="/greeting-package/add" linkName="Add Greeting Package" />
        <div className="bg-white mx-auto py-3 px-4 div-select">
          <Select className="select-width" placeholder="Filter By Tags">
            {tagList?.map(tag =>
              <Select.Option
                className="p-0"
                key={tag.name}
              >
                {selected.includes(tag.name)
                  ? <button type="button" disabled className="btn text-secondary m-0 border-0">{tag.name}</button>
                  : <button type="button" className="btn btn-white text-primary m-0 border-0" onClick={() => this.handleSelectTag(tag.name)}>{tag.name}</button>
                }
              </Select.Option>
            )}
          </Select>
          <div className="mt-3 tags-row-height">
            {selected.map((tag, index) =>
              <Tag key={index} color="geekblue" closable onClose={() => this.handleRemoveTag(tag)}>
                {tag}
              </Tag>
            )}
          </div>
        </div>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={greetingPackageList}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={loader}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    greetingCategoryTag: state.greetingCategoryTag?.lists?.data,
    greetingPackage: state.greetingPackage
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    listGreetingCategoryTag,
    listGreetingPackage,
    deleteGreetingPackage,
    resetGreetingPackage
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListGreetingPackage);
