import { TOKKENZ_TYPE } from "../../constants/actionTypes";
import axios from 'axios';

export const addTokkenzType = (token, data) => {
  return (dispatch) => {
    dispatch({ type: TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("tokkenz-type/create", inputJSON)
      .then(function (response) {
        dispatch(addTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addTokkenzTypeError(error.message));
      });
  };
};

const addTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const addTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.ADD_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const listTokkenzType = (token, data) => {
  return (dispatch) => {
    dispatch({ type: TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("tokkenz-type/lists", inputJSON)
      .then(function (response) {
        dispatch(listTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listTokkenzTypeError(error.message));
      });
  };
};

const listTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const listTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.LIST_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const getAllTokkenzType = (token) => {
  return (dispatch) => {
    dispatch({ type: TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("tokkenz-type/get-all")
      .then(function (response) {
        dispatch(getAllTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllTokkenzTypeError(error.message));
      });
  };
};

const getAllTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const getAllTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.GET_ALL_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const deleteTokkenzType = (token, data) => {
  return (dispatch) => {
    dispatch({ type: TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("tokkenz-type/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteTokkenzTypeError(error.message));
      });
  };
};

const deleteTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const deleteTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.DELETE_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const updateTokkenzType = (token, data) => {
  return (dispatch) => {
    dispatch({ type:TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("tokkenz-type/update", inputJSON)
      .then(function (response) {
        dispatch(updateTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateTokkenzTypeError(error.message));
      });
  };
};

const updateTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const updateTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.UPDATE_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const activeTokkenzType = (token) => {
  return (dispatch) => {
    dispatch({ type: TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("tokkenz-type/active")
      .then(function (response) {
        dispatch(activeTokkenzTypeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(activeTokkenzTypeError(error.message));
      });
  };
};

const activeTokkenzTypeSuccess = (data) => ({
  type: TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_SUCCESS,
  payload: data
});

const activeTokkenzTypeError = errorMessage => ({
  type: TOKKENZ_TYPE.ACTIVE_TOKKENZ_TYPE_FAIL,
  payload: errorMessage
});

export const resetTokkenzType = () => {
  return (dispatch) => {
    dispatch({ type:TOKKENZ_TYPE.RESET_TOKKENZ_TYPE })
  }
}
