import { GAME_INVENTORY } from "../../../constants/actionTypes";
import axios from 'axios';

export const addGameInventory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INVENTORY.ADD_GAME_INVENTORY_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-inventory/create", inputJSON)
            .then(function (response) {
                dispatch(addGameInventorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addGameInventoryError(error.message));
            });
    };
};

const addGameInventorySuccess = (data) => ({
    type: GAME_INVENTORY.ADD_GAME_INVENTORY_SUCCESS,
    payload: data
});

const addGameInventoryError = errorMessage => ({
    type: GAME_INVENTORY.ADD_GAME_INVENTORY_FAIL,
    payload: errorMessage
});

export const listGameInventory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INVENTORY.LIST_GAME_INVENTORY_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-inventory/lists", inputJSON)
            .then(function (response) {
                dispatch(listGameInventorysSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(listGameInventorysError(error.message));
            });
    };
};

const listGameInventorysSuccess = (data) => ({
    type: GAME_INVENTORY.LIST_GAME_INVENTORY_SUCCESS,
    payload: data
});

const listGameInventorysError = errorMessage => ({
    type: GAME_INVENTORY.LIST_GAME_INVENTORY_FAIL,
    payload: errorMessage
});

export const updateSelectedGameInventory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-inventory/update-selected", inputJSON)
            .then(function (response) {
                dispatch(updateSelectedGameSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateSelectedGameError(error.message));
            });
    };
};

const updateSelectedGameSuccess = (data) => ({
    type: GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_SUCCESS,
    payload: data
});

const updateSelectedGameError = errorMessage => ({
    type: GAME_INVENTORY.UPDATE_SELECTED_GAME_INVENTORY_FAIL,
    payload: errorMessage
});

export const updateGameInventory = (token, data) => {
    return (dispatch) => {
        dispatch({ type: GAME_INVENTORY.UPDATE_GAME_INVENTORY_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("game-inventory/update", inputJSON)
            .then(function (response) {
                dispatch(updateGameInventorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateGameInventoryError(error.message));
            });
    };
};

const updateGameInventorySuccess = (data) => ({
    type: GAME_INVENTORY.UPDATE_GAME_INVENTORY_SUCCESS,
    payload: data
});

const updateGameInventoryError = errorMessage => ({
    type: GAME_INVENTORY.UPDATE_GAME_INVENTORY_FAIL,
    payload: errorMessage
});

export const getAllGameInventories = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_INVENTORY.GET_ALL_GAME_INVENTORY_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-inventory/get-all")
      .then(function (response) {
        dispatch(getAllGameInventorysSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGameInventorysError(error.message));
      });
  };
};

const getAllGameInventorysSuccess = (data) => ({
  type: GAME_INVENTORY.GET_ALL_GAME_INVENTORY_SUCCESS,
  payload: data
});

const getAllGameInventorysError = errorMessage => ({
  type: GAME_INVENTORY.GET_ALL_GAME_INVENTORY_FAIL,
  payload: errorMessage
});

export const getGameInventoryCount = (token) => {
  return (dispatch) => {
    dispatch({ type: GAME_INVENTORY.GAME_INVENTORY_COUNT_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("game-inventory/get-count")
      .then(function (response) {
        dispatch(gameInventoryCountSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(gameInventoryCountError(error.message));
      });
  };
};

const gameInventoryCountSuccess = (data) => ({
  type: GAME_INVENTORY.GAME_INVENTORY_COUNT_SUCCESS,
  payload: data
});

const gameInventoryCountError = errorMessage => ({
  type: GAME_INVENTORY.GAME_INVENTORY_COUNT_FAIL,
  payload: errorMessage
});

export const resetGameInventory = () => {
    return (dispatch) => {
        dispatch({ type: GAME_INVENTORY.RESET_GAME_INVENTORY})
    }
}
