import React from 'react';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import 'assets/vendors/style';
import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";
/**
 * Axios HTTP Request Defaults
 * 
 */
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>;

export default NextApp;
