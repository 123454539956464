import React, { Component } from "react";
import { notification, Select, Card, Divider } from "antd";
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaFolder, FaEye, FaCloudDownloadAlt, FaMusic } from 'react-icons/fa';
import { Modal } from "antd";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import { assetGallery } from "../../../appRedux/actions/gameTemplate";
const { Option } = Select;

class AssetGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameTemplates: [],
      isRenderTemplates: true,
      isRenderSkins: false,
      isRenderFiles: false,
      templateId: null,
      skinId: null,
      skinName: null,
      skins: [],
      files: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      previewType: '',
      selectedView: 'Grid'
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    this.props.assetGallery(authUser.token);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameTemplate) {
      if (nextProps.gameTemplate.messageId === 200) {
        return {
          gameTemplates: nextProps.gameTemplate.lists.data
        }
      } else if (nextProps.gameTemplate.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameTemplate.alertMessage,
        });
      }
    }
    return null;
  }

  onFolderClick = (template, e) => {
    this.setState({
      isRenderTemplates: false,
      isRenderSkins: true,
      templateId: template.title ?? template.templateId,
      skins: template.skins
    });
  }

  onSkinClick = (skin, e) => {
    this.setState({
      isRenderSkins: false,
      isRenderFiles: true,
      skinId: skin.skinId,
      skinName: skin.skinName,
      files: skin.assets
    });
  }

  onTemplateIdClick = (e) => {
    this.setState({
      isRenderFiles: false,
      isRenderSkins: true,
      skinId: null,
      skinName: null,
      files: [],
    });
  }

  onAllTemplateClick = (e) => {
    this.setState({
      isRenderSkins: false,
      isRenderFiles: false,
      isRenderTemplates: true,
      files: [],
      skins: [],
      templateId: null,
      skinId: null,
      skinName: null
    });
  }

  onPreviewClick = (image, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      previewType: image.fileType
    });
  }

  onDownloadClick = (image, e) => {
    window.open(image.assetFile, '_self', false);
  }

  handleCancel = () => this.setState({ previewVisible: false });

  onViewChange(value) {
    this.setState({ selectedView: value });
  }

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  renderTemplates() {
    const { gameTemplates } = this.state;
    if (gameTemplates?.length > 0) {
      return gameTemplates.map((item, index) => (
        <Col xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
          <div className="folder-div" onClick={this.onFolderClick.bind(this, item)}>
            <FaFolder className="icon-size gx-link" />
            <span className="folder-btn" > {item.title ?? item.templateId} </span>
          </div>
        </Col>
      ))
    }
    return (
      <Col>
        No templates found!!
      </Col>
    )
  }

  renderSkins(skins) {
    if (skins?.length > 0) {
      return skins.map((item, index) => (
        <Col xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
          <div className="folder-div" onClick={this.onSkinClick.bind(this, item)}>
            <FaFolder className="icon-size gx-link" />
            <span className="folder-btn"> {item?.skinName} </span>
          </div>
        </Col>
      ))
    }
    return (
      <Col>
        No Skin found!!
      </Col>
    )
  }

  renderFiles(files) {
    const { selectedView } = this.state;
    if (files?.length > 0) {
      return (
        selectedView === "List" ?
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>File Name</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {files?.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td>
                    <FaEye onClick={this.onPreviewClick.bind(this, asset)} className="icon-size gx-link"/>
                    <Divider type="vertical" />
                    <FaCloudDownloadAlt className="margin-left icon-size gx-link" onClick={this.onDownloadClick.bind(this, asset)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          :
          files?.map((asset, index) => (
            <Col xl={3} lg={3} md={3} sm={6} xs={12} key={index} className="margin-bottom">
              {asset.fileType.includes("audio") ?
                <div className="asset-div">
                  <FaMusic onClick={this.onPreviewClick.bind(this, asset)} className="music-icon" title="Click to play"/>
                  <FaCloudDownloadAlt className="icon-position icon-size gx-link" onClick={this.onDownloadClick.bind(this, asset)} title="Download"/>
                </div>
                : 
                <div className="asset-div">
                  <img onClick={this.onPreviewClick.bind(this, asset)} alt={asset.fileName} src={asset.assetFile} className="asset-thumbnail" title="Click to preview"/>
                  <FaCloudDownloadAlt className="icon-position icon-size gx-link" onClick={this.onDownloadClick.bind(this, asset)} title="Download"/>
                </div>
              }
            </Col>
          ))
      )
    }
    return (
      <Col>
        No Asset found!!
      </Col>
    )
  }

  render() {
    const { loader } = this.props.gameTemplate;
    const { isRenderTemplates, isRenderSkins, isRenderFiles, skins, files, templateId, skinId, skinName,
      previewVisible, previewImage, previewTitle, previewType, selectedView } = this.state;
    return (
      <Auxiliary>
        <PageHeader title="Assets Gallery"/>
        <Card className="card-margin">
          {templateId && !skinId ?
            <Row>
              <Col>
                <span className="gx-link" onClick={this.onAllTemplateClick.bind(this)}>All</span>
                {' / '}
                <span>{templateId}</span>
              </Col>
            </Row>
            : null}
          {skinId ?
            <Row>
              <Col>
                <span className="gx-link" onClick={this.onAllTemplateClick.bind(this)}>All</span>
                {' / '}
                <span className="gx-link" onClick={this.onTemplateIdClick.bind(this)}>{templateId}</span>
                {skinId ? <span>
                  {' / '}
                  <span>{skinName}</span>
                </span>
                  : null
                }
              </Col>
            </Row>
            : null}
          {isRenderFiles ?
            <Row className="row-margin">
              <Col>
                <Select value={selectedView} style={{ width: 220 }} onSelect={(value) => this.onViewChange(value)}>
                  <Option value="List">List View</Option>
                  <Option value="Grid">Grid View</Option>
                </Select>
              </Col>
            </Row> : null}
          <Row className="row-margin">
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : isRenderTemplates ?
                this.renderTemplates() : isRenderSkins ? this.renderSkins(skins) : isRenderFiles ? this.renderFiles(files) : null
            }
          </Row>
        </Card>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >

          {previewType.includes("audio") ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameTemplate: state.gameTemplate,
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ assetGallery }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AssetGallery);
