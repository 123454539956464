import {
    FAQ
} from "../../constants/actionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    messageId: null,
    lists: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FAQ.RESET_FAQ: {
            return {
                ...state,
                loader: false,
                alertMessage: '',
                messageId: null
            }
        }

        case FAQ.ADD_FAQ_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case FAQ.ADD_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 200
            }
        }

        case FAQ.ADD_FAQ_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case FAQ.LIST_FAQ_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case FAQ.LIST_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                messageId: 204,
                lists: action.payload
            }
        }

        case FAQ.LIST_FAQ_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message
            }
        }

        case FAQ.DELETE_FAQ_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case FAQ.DELETE_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }

        case FAQ.DELETE_FAQ_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case FAQ.UPDATE_FAQ_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case FAQ.UPDATE_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 200,
                lists: action.payload.data
            }
        }

        case FAQ.UPDATE_FAQ_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        case FAQ.SEARCH_FAQ_REQUEST: {
            return {
                ...state,
                loader: true
            }
        }

        case FAQ.SEARCH_FAQ_SUCCESS: {
            return {
                ...state,
                loader: false,
                messageId: 204,
                lists: action.payload
            }
        }

        case FAQ.SEARCH_FAQ_FAIL: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload.message,
                messageId: 203
            }
        }

        default:
            return state;
    }
}
