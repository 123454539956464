import { GREETING_PACKAGE } from "../../constants/actionTypes";
import axios from 'axios';

export const addGreetingPackage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.ADD_GREETING_PACKAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-package/create", inputJSON)
      .then(function (response) {
        dispatch(addGreetingPackageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(addGreetingPackageError(error.message));
      });
  };
};

const addGreetingPackageSuccess = (data) => ({
  type: GREETING_PACKAGE.ADD_GREETING_PACKAGE_SUCCESS,
  payload: data
});

const addGreetingPackageError = errorMessage => ({
  type: GREETING_PACKAGE.ADD_GREETING_PACKAGE_FAIL,
  payload: errorMessage
});

export const listGreetingPackage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.LIST_GREETING_PACKAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("greeting-package/lists", inputJSON)
      .then(function (response) {
        dispatch(listGreetingPackageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listGreetingPackageError(error.message));
      });
  };
};

const listGreetingPackageSuccess = (data) => ({
  type: GREETING_PACKAGE.LIST_GREETING_PACKAGE_SUCCESS,
  payload: data
});

const listGreetingPackageError = errorMessage => ({
  type: GREETING_PACKAGE.LIST_GREETING_PACKAGE_FAIL,
  payload: errorMessage
});

export const getAllGreetingPackage = (token) => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.get("greeting-package/get-all")
      .then(function (response) {
        dispatch(getAllGreetingPackageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(getAllGreetingPackageError(error.message));
      });
  };
};

const getAllGreetingPackageSuccess = (data) => ({
  type: GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_SUCCESS,
  payload: data
});

const getAllGreetingPackageError = errorMessage => ({
  type: GREETING_PACKAGE.GET_ALL_GREETING_PACKAGE_FAIL,
  payload: errorMessage
});

export const deleteGreetingPackage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.DELETE_GREETING_PACKAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-package/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteGreetingPackageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteGreetingPackageError(error.message));
      });
  };
};

const deleteGreetingPackageSuccess = (data) => ({
  type: GREETING_PACKAGE.DELETE_GREETING_PACKAGE_SUCCESS,
  payload: data
});

const deleteGreetingPackageError = errorMessage => ({
  type: GREETING_PACKAGE.DELETE_GREETING_PACKAGE_FAIL,
  payload: errorMessage
});

export const updateGreetingPackage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("greeting-package/update", inputJSON)
      .then(function (response) {
        dispatch(updateGreetingPackageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateGreetingPackageError(error.message));
      });
  };
};

const updateGreetingPackageSuccess = (data) => ({
  type: GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_SUCCESS,
  payload: data
});

const updateGreetingPackageError = errorMessage => ({
  type: GREETING_PACKAGE.UPDATE_GREETING_PACKAGE_FAIL,
  payload: errorMessage
});

export const resetGreetingPackage = () => {
  return (dispatch) => {
    dispatch({ type: GREETING_PACKAGE.RESET_GREETING_PACKAGE })
  }
}
