import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AddEventImageType from "./add";
import ListEventImageType from "./list";
import UploadEventImage from "./upload";
import ImageList from "./imageList";
import CustomBreadCrumb from 'components/Widgets/CustomBreadCrumb';

const Main = ({match}) => (
  <div>
    <CustomBreadCrumb />
  <Switch>
    <Route path={`${match.url}/add`} component={AddEventImageType}/>
    <Route path={`${match.url}/edit/:id`} component={AddEventImageType}/>
    <Route path={`${match.url}/list`} component={ListEventImageType}/>
    <Route path={`${match.url}/upload`} component={UploadEventImage}/>
    <Route path={`${match.url}/listings`} component={ImageList}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
  </div>
);

export default Main;
