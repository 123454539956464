import React from "react";
import Widget from "components/Widget";
import {availabilityTime} from '../data';
import { IoIosMail } from "react-icons/io";
const Availability = () => {
  return (
    <Widget title="Availability" styleName="gx-card-profile-sm">
      {availabilityTime.map((data, index) =>
        <div key={index} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <IoIosMail className="icon-size gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">{data.title}</span>
            <p className="gx-mb-0">{data.desc}</p>
          </div>
        </div>
      )}
    </Widget>
  )
}

export default Availability;
