import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import SendDemo from "./sendDemo";
import RecipientList from "./recipientList";
import Profile from "./profile";
import PrivateRoute from "../../shared/routePermission/privateRoute";
import { Roles } from "../../shared/enums/roles";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/profile`} component={Profile} />
    <PrivateRoute path={`${match.url}/send-demo`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin]} component={SendDemo} />
    <PrivateRoute path={`${match.url}/recipients`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin]} component={RecipientList} />
  </Switch>
);

export default Main;
