// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";

//Auth const
export const INIT_URL = "INIT_URL";

export const TOKEN = "GFYUFGTYGFTYTY64564545acvbvrttyFG@%#%#%#%#TTRR";

export const USER_AUTH = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_REQUEST_SUCCESS: "SIGNIN_REQUEST_SUCCESS",
  SIGNIN_REQUEST_FAIL: "SIGNIN_REQUEST_FAIL",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_REQUEST_SUCCESS: "SIGNUP_REQUEST_SUCCESS",
  SIGNUP_REQUEST_FAIL: "SIGNUP_REQUEST_FAIL",
  SIGNOUT_USER: "SIGNOUT_USER",
  RESET_AUTH: "RESET_AUTH",
  ADD_SKILL_REQUEST: "ADD_SKILL_REQUEST",
  ADD_SKILL_SUCCESS: "ADD_SKILL_SUCCESS",
  ADD_SKILL_FAIL: "ADD_SKILL_FAIL",
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",
};

export const LOCATION = {
  COUNTRY_REQUEST: "COUNTRY_REQUEST",
  COUNTRY_SUCCESS: "COUNTRY_SUCCESS",
  COUNTRY_FAIL: "COUNTRY_FAIL",
  STATE_REQUEST: "STATE_REQUEST",
  STATE_SUCCESS: "STATE_SUCCESS",
  STATE_FAIL: "STATE_FAIL",
  CITY_REQUEST: "CITY_REQUEST",
  CITY_SUCCESS: "CITY_SUCCESS",
  CITY_FAIL: "CITY_FAIL",
};

export const EVENT_TITLE = {
  ADD_EVENT_TITLE_REQUEST: "ADD_EVENT_TITLE_REQUEST",
  ADD_EVENT_TITLE_SUCCESS: "ADD_EVENT_TITLE_SUCCESS",
  ADD_EVENT_TITLE_FAIL: "ADD_EVENT_TITLE_FAIL",
  LIST_EVENT_TITLE_REQUEST: "LIST_EVENT_TITLE_REQUEST",
  LIST_EVENT_TITLE_SUCCESS: "LIST_EVENT_TITLE_SUCCESS",
  LIST_EVENT_TITLE_FAIL: "LIST_EVENT_TITLE_FAIL",
  GET_ALL_EVENT_TITLE_REQUEST: "GET_ALL_EVENT_TITLE_REQUEST",
  GET_ALL_EVENT_TITLE_SUCCESS: "GET_ALL_EVENT_TITLE_SUCCESS",
  GET_ALL_EVENT_TITLE_FAIL: "GET_ALL_EVENT_TITLE_FAIL",
  DELETE_EVENT_TITLE_REQUEST: "DELETE_EVENT_TITLE_REQUEST",
  DELETE_EVENT_TITLE_SUCCESS: "DELETE_EVENT_TITLE_SUCCESS",
  DELETE_EVENT_TITLE_FAIL: "DELETE_EVENT_TITLE_FAIL",
  UPDATE_EVENT_TITLE_REQUEST: "UPDATE_EVENT_TITLE_REQUEST",
  UPDATE_EVENT_TITLE_SUCCESS: "UPDATE_EVENT_TITLE_SUCCESS",
  UPDATE_EVENT_TITLE_FAIL: "UPDATE_EVENT_TITLE_FAIL",
  SEARCH_EVENT_TITLE_REQUEST: "SEARCH_EVENT_TITLE_REQUEST",
  SEARCH_EVENT_TITLE_SUCCESS: "SEARCH_EVENT_TITLE_SUCCESS",
  SEARCH_EVENT_TITLE_FAIL: "SEARCH_EVENT_TITLE_FAIL",
  RESET_EVENT_TITLE: "RESET_EVENT_TITLE",
};

export const EVENT_SUBCAT = {
  ADD_EVENT_SUBCAT_REQUEST: "ADD_EVENT_SUBCAT_REQUEST",
  ADD_EVENT_SUBCAT_SUCCESS: "ADD_EVENT_SUBCAT_SUCCESS",
  ADD_EVENT_SUBCAT_FAIL: "ADD_EVENT_SUBCAT_FAIL",
  LIST_EVENT_SUBCAT_REQUEST: "LIST_EVENT_SUBCAT_REQUEST",
  LIST_EVENT_SUBCAT_SUCCESS: "LIST_EVENT_SUBCAT_SUCCESS",
  LIST_EVENT_SUBCAT_FAIL: "LIST_EVENT_SUBCAT_FAIL",
  DELETE_EVENT_SUBCAT_REQUEST: "DELETE_EVENT_SUBCAT_REQUEST",
  DELETE_EVENT_SUBCAT_SUCCESS: "DELETE_EVENT_SUBCAT_SUCCESS",
  DELETE_EVENT_SUBCAT_FAIL: "DELETE_EVENT_SUBCAT_FAIL",
  UPDATE_EVENT_SUBCAT_REQUEST: "UPDATE_EVENT_SUBCAT_REQUEST",
  UPDATE_EVENT_SUBCAT_SUCCESS: "UPDATE_EVENT_SUBCAT_SUCCESS",
  UPDATE_EVENT_SUBCAT_FAIL: "UPDATE_EVENT_SUBCAT_FAIL",
  SEARCH_EVENT_SUBCAT_REQUEST: "SEARCH_EVENT_SUBCAT_REQUEST",
  SEARCH_EVENT_SUBCAT_SUCCESS: "SEARCH_EVENT_SUBCAT_SUCCESS",
  SEARCH_EVENT_SUBCAT_FAIL: "SEARCH_EVENT_SUBCAT_FAIL",
  RESET_EVENT_SUBCAT: "RESET_EVENT_SUBCAT",
};

export const EVENT_IMAGE = {
  ADD_EVENT_IMAGE_REQUEST: "ADD_EVENT_IMAGE_REQUEST",
  ADD_EVENT_IMAGE_SUCCESS: "ADD_EVENT_IMAGE_SUCCESS",
  ADD_EVENT_IMAGE_FAIL: "ADD_EVENT_IMAGE_FAIL",
  LIST_EVENT_IMAGE_REQUEST: "LIST_EVENT_IMAGE_REQUEST",
  LIST_EVENT_IMAGE_SUCCESS: "LIST_EVENT_IMAGE_SUCCESS",
  LIST_EVENT_IMAGE_FAIL: "LIST_EVENT_IMAGE_FAIL",
  DELETE_EVENT_IMAGE_REQUEST: "DELETE_EVENT_IMAGE_REQUEST",
  DELETE_EVENT_IMAGE_SUCCESS: "DELETE_EVENT_IMAGE_SUCCESS",
  DELETE_EVENT_IMAGE_FAIL: "DELETE_EVENT_IMAGE_FAIL",
  DELETE_EVENT_IMAGE_TYPE_REQUEST: "DELETE_EVENT_IMAGE_TYPE_REQUEST",
  DELETE_EVENT_IMAGE_TYPE_SUCCESS: "DELETE_EVENT_IMAGE_TYPE_SUCCESS",
  DELETE_EVENT_IMAGE_TYPE_FAIL: "DELETE_EVENT_IMAGE_TYPE_FAIL",
  UPDATE_EVENT_IMAGE_REQUEST: "UPDATE_EVENT_IMAGE_REQUEST",
  UPDATE_EVENT_IMAGE_SUCCESS: "UPDATE_EVENT_IMAGE_SUCCESS",
  UPDATE_EVENT_IMAGE_FAIL: "UPDATE_EVENT_IMAGE_FAIL",
  LIST_IMAGE_REQUEST: "LIST_IMAGE_REQUEST",
  LIST_IMAGE_SUCCESS: "LIST_IMAGE_SUCCESS",
  LIST_IMAGE_FAIL: "LIST_IMAGE_FAIL",
  SEARCH_EVENT_IMAGE_REQUEST: "SEARCH_EVENT_IMAGE_REQUEST",
  SEARCH_EVENT_IMAGE_SUCCESS: "SEARCH_EVENT_IMAGE_SUCCESS",
  SEARCH_EVENT_IMAGE_FAIL: "SEARCH_EVENT_IMAGE_FAIL",
  SEARCH_EVENT_IMAGE_TYPE_REQUEST: "SEARCH_EVENT_IMAGE_TYPE_REQUEST",
  SEARCH_EVENT_IMAGE_TYPE_SUCCESS: "SEARCH_EVENT_IMAGE_TYPE_SUCCESS",
  SEARCH_EVENT_IMAGE_TYPE_FAIL: "SEARCH_EVENT_IMAGE_TYPE_FAIL",
  RESET_EVENT_IMAGE: "RESET_EVENT_IMAGE",
};

export const INTEREST = {
  ADD_INTEREST_REQUEST: "ADD_INTEREST_REQUEST",
  ADD_INTEREST_SUCCESS: "ADD_INTEREST_SUCCESS",
  ADD_INTEREST_FAIL: "ADD_INTEREST_FAIL",
  LIST_INTEREST_REQUEST: "LIST_INTEREST_REQUEST",
  LIST_INTEREST_SUCCESS: "LIST_INTEREST_SUCCESS",
  LIST_INTEREST_FAIL: "LIST_INTEREST_FAIL",
  DELETE_INTEREST_REQUEST: "DELETE_INTEREST_REQUEST",
  DELETE_INTEREST_SUCCESS: "DELETE_INTEREST_SUCCESS",
  DELETE_INTEREST_FAIL: "DELETE_INTEREST_FAIL",
  UPDATE_INTEREST_REQUEST: "UPDATE_INTEREST_REQUEST",
  UPDATE_INTEREST_SUCCESS: "UPDATE_INTEREST_SUCCESS",
  UPDATE_INTEREST_FAIL: "UPDATE_INTEREST_FAIL",
  SEARCH_INTEREST_REQUEST: "SEARCH_INTEREST_REQUEST",
  SEARCH_INTEREST_SUCCESS: "SEARCH_INTEREST_SUCCESS",
  SEARCH_INTEREST_FAIL: "SEARCH_INTEREST_FAIL",
  RESET_INTEREST: "RESET_INTEREST",
};

export const VIDEO_TUTORIAL = {
  ADD_VIDEO_TUTORIAL_REQUEST: "ADD_VIDEO_TUTORIAL_REQUEST",
  ADD_VIDEO_TUTORIAL_SUCCESS: "ADD_VIDEO_TUTORIAL_SUCCESS",
  ADD_VIDEO_TUTORIAL_FAIL: "ADD_VIDEO_TUTORIAL_FAIL",
  LIST_VIDEO_TUTORIAL_REQUEST: "LIST_VIDEO_TUTORIAL_REQUEST",
  LIST_VIDEO_TUTORIAL_SUCCESS: "LIST_VIDEO_TUTORIAL_SUCCESS",
  LIST_VIDEO_TUTORIAL_FAIL: "LIST_VIDEO_TUTORIAL_FAIL",
  DELETE_VIDEO_TUTORIAL_REQUEST: "DELETE_VIDEO_TUTORIAL_REQUEST",
  DELETE_VIDEO_TUTORIAL_SUCCESS: "DELETE_VIDEO_TUTORIAL_SUCCESS",
  DELETE_VIDEO_TUTORIAL_FAIL: "DELETE_VIDEO_TUTORIAL_FAIL",
  UPDATE_VIDEO_TUTORIAL_REQUEST: "UPDATE_VIDEO_TUTORIAL_REQUEST",
  UPDATE_VIDEO_TUTORIAL_SUCCESS: "UPDATE_VIDEO_TUTORIAL_SUCCESS",
  UPDATE_VIDEO_TUTORIAL_FAIL: "UPDATE_VIDEO_TUTORIAL_FAIL",
  RESET_VIDEO_TUTORIAL: "RESET_VIDEO_TUTORIAL",
};

export const USERS = {
  ADD_USERS_REQUEST: "ADD_USERS_REQUEST",
  ADD_USERS_SUCCESS: "ADD_USERS_SUCCESS",
  ADD_USERS_FAIL: "ADD_USERS_FAIL",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",
  LIST_USERS_REQUEST: "LIST_USERS_REQUEST",
  LIST_USERS_SUCCESS: "LIST_USERS_SUCCESS",
  LIST_USERS_FAIL: "LIST_USERS_FAIL",
  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAIL: "DELETE_USERS_FAIL",
  UPDATE_USERS_REQUEST: "UPDATE_USERS_REQUEST",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_USERS_FAIL: "UPDATE_USERS_FAIL",
  RESET_USERS: "RESET_USERS",
};

export const ROLES = {
  LIST_ROLES_REQUEST: "LIST_ROLES_REQUEST",
  LIST_ROLES_SUCCESS: "LIST_ROLES_SUCCESS",
  LIST_ROLES_FAIL: "LIST_ROLES_FAIL",
  RESET_ROLES: "RESET_ROLES",
};

export const DASHBOARD = {
  USERS_COUNT_REQUEST: "USERS_COUNT_REQUEST",
  USERS_COUNT_SUCCESS: "USERS_COUNT_SUCCESS",
  USERS_COUNT_FAIL: "USERS_COUNT_FAIL",
  APP_USER_COUNT_REQUEST: "APP_USER_COUNT_REQUEST",
  APP_USER_COUNT_SUCCESS: "APP_USER_COUNT_SUCCESS",
  APP_USER_COUNT_FAIL: "APP_USER_COUNT_FAIL",
  RESET_DASHBOARD: "RESET_DASHBOARD",
};

export const GAME_TEMPLATE = {
  ADD_GAME_TEMPLATE_REQUEST: "ADD_GAME_TEMPLATE_REQUEST",
  ADD_GAME_TEMPLATE_SUCCESS: "ADD_GAME_TEMPLATE_SUCCESS",
  ADD_GAME_TEMPLATE_FAIL: "ADD_GAME_TEMPLATE_FAIL",
  EDIT_GAME_TEMPLATE_REQUEST: "EDIT_GAME_TEMPLATE_REQUEST",
  EDIT_GAME_TEMPLATE_SUCCESS: "EDIT_GAME_TEMPLATE_SUCCESS",
  EDIT_GAME_TEMPLATE_FAIL: "EDIT_GAME_TEMPLATE_FAIL",
  LIST_GAME_TEMPLATE_REQUEST: "LIST_GAME_TEMPLATE_REQUEST",
  LIST_GAME_TEMPLATE_SUCCESS: "LIST_GAME_TEMPLATE_SUCCESS",
  LIST_GAME_TEMPLATE_FAIL: "LIST_GAME_TEMPLATE_FAIL",
  GET_ALL_GAME_SKIN_REQUEST: "GET_ALL_GAME_SKIN_REQUEST",
  GET_ALL_GAME_SKIN_SUCCESS: "GET_ALL_GAME_SKIN_SUCCESS",
  GET_ALL_GAME_SKIN_FAIL: "GET_ALL_GAME_SKIN_FAIL",
  RESET_GAME_TEMPLATE: "RESET_GAME_TEMPLATE",
  GAME_TEMPLATE_COUNT_REQUEST: "GAME_TEMPLATE_COUNT_REQUEST",
  GAME_TEMPLATE_COUNT_SUCCESS: "GAME_TEMPLATE_COUNT_SUCCESS",
  GAME_TEMPLATE_COUNT_FAIL: "GAME_TEMPLATE_COUNT_FAIL",
  ASSET_GALLERY_REQUEST: "ASSET_GALLERY_REQUEST",
  ASSET_GALLERY_SUCCESS: "ASSET_GALLERY_SUCCESS",
  ASSET_GALLERY_FAIL: "ASSET_GALLERY_FAIL",

  // Template Skin
  ADD_TEMPLATE_SKIN_REQUEST: "ADD_TEMPLATE_SKIN_REQUEST",
  ADD_TEMPLATE_SKIN_SUCCESS: "ADD_TEMPLATE_SKIN_SUCCESS",
  ADD_TEMPLATE_SKIN_FAIL: "ADD_TEMPLATE_SKIN_FAIL",
  EDIT_TEMPLATE_SKIN_REQUEST: "EDIT_TEMPLATE_SKIN_REQUEST",
  EDIT_TEMPLATE_SKIN_SUCCESS: "EDIT_TEMPLATE_SKIN_SUCCESS",
  EDIT_TEMPLATE_SKIN_FAIL: "EDIT_TEMPLATE_SKIN_FAIL",
  LIST_TEMPLATE_SKIN_REQUEST: "LIST_TEMPLATE_SKIN_REQUEST",
  LIST_TEMPLATE_SKIN_SUCCESS: "LIST_TEMPLATE_SKIN_SUCCESS",
  LIST_TEMPLATE_SKIN_FAIL: "LIST_TEMPLATE_SKIN_FAIL",
};

export const APP_USER = {
  LIST_APP_USER_REQUEST: 'LIST_APP_USER_REQUEST',
  LIST_APP_USER_SUCCESS: 'LIST_APP_USER_SUCCESS',
  LIST_APP_USER_FAIL: 'LIST_APP_USER_FAIL',
  VERIFY_APP_USER_REQUEST: 'VERIFY_APP_USER_REQUEST',
  VERIFY_APP_USER_SUCCESS: 'VERIFY_APP_USER_SUCCESS',
  VERIFY_APP_USER_FAIL: 'VERIFY_APP_USER_FAIL',
  DELETE_APP_USER_REQUEST: 'DELETE_APP_USER_REQUEST',
  DELETE_APP_USER_SUCCESS: 'DELETE_APP_USER_SUCCESS',
  DELETE_APP_USER_FAIL: 'DELETE_APP_USER_FAIL',
  SEARCH_APP_USER_REQUEST: 'SEARCH_APP_USER_REQUEST',
  SEARCH_APP_USER_SUCCESS: 'SEARCH_APP_USER_SUCCESS',
  SEARCH_APP_USER_FAIL: 'SEARCH_APP_USER_FAIL',
  RESET_APP_USERS: 'RESET_APP_USERS'
}

export const GREETING_PACKAGE = {
  ADD_GREETING_PACKAGE_REQUEST: "ADD_GREETING_PACKAGE_REQUEST",
  ADD_GREETING_PACKAGE_SUCCESS: "ADD_GREETING_PACKAGE_SUCCESS",
  ADD_GREETING_PACKAGE_FAIL: "ADD_GREETING_PACKAGE_FAIL",
  LIST_GREETING_PACKAGE_REQUEST: "LIST_GREETING_PACKAGE_REQUEST",
  LIST_GREETING_PACKAGE_SUCCESS: "LIST_GREETING_PACKAGE_SUCCESS",
  LIST_GREETING_PACKAGE_FAIL: "LIST_GREETING_PACKAGE_FAIL",
  GET_ALL_GREETING_PACKAGE_REQUEST: "GET_ALL_GREETING_PACKAGE_REQUEST",
  GET_ALL_GREETING_PACKAGE_SUCCESS: "GET_ALL_GREETING_PACKAGE_SUCCESS",
  GET_ALL_GREETING_PACKAGE_FAIL: "GET_ALL_GREETING_PACKAGE_FAIL",
  DELETE_GREETING_PACKAGE_REQUEST: "DELETE_GREETING_PACKAGE_REQUEST",
  DELETE_GREETING_PACKAGE_SUCCESS: "DELETE_GREETING_PACKAGE_SUCCESS",
  DELETE_GREETING_PACKAGE_FAIL: "DELETE_GREETING_PACKAGE_FAIL",
  UPDATE_GREETING_PACKAGE_REQUEST: "UPDATE_GREETING_PACKAGE_REQUEST",
  UPDATE_GREETING_PACKAGE_SUCCESS: "UPDATE_GREETING_PACKAGE_SUCCESS",
  UPDATE_GREETING_PACKAGE_FAIL: "UPDATE_GREETING_PACKAGE_FAIL",
  RESET_GREETING_PACKAGE: "RESET_GREETING_PACKAGE",
};

export const GREETING_CATEGORY_TAG = {
  ADD_GREETING_CATEGORY_TAG_REQUEST: "ADD_GREETING_CATEGORY_TAG_REQUEST",
  ADD_GREETING_CATEGORY_TAG_SUCCESS: "ADD_GREETING_CATEGORY_TAG_SUCCESS",
  ADD_GREETING_CATEGORY_TAG_FAIL: "ADD_GREETING_CATEGORY_TAG_FAIL",
  LIST_GREETING_CATEGORY_TAG_REQUEST: "LIST_GREETING_CATEGORY_TAG_REQUEST",
  LIST_GREETING_CATEGORY_TAG_SUCCESS: "LIST_GREETING_CATEGORY_TAG_SUCCESS",
  LIST_GREETING_CATEGORY_TAG_FAIL: "LIST_GREETING_CATEGORY_TAG_FAIL",
  GET_ALL_GREETING_CATEGORY_TAG_REQUEST:
    "GET_ALL_GREETING_CATEGORY_TAG_REQUEST",
  GET_ALL_GREETING_CATEGORY_TAG_SUCCESS:
    "GET_ALL_GREETING_CATEGORY_TAG_SUCCESS",
  GET_ALL_GREETING_CATEGORY_TAG_FAIL: "GET_ALL_GREETING_CATEGORY_TAG_FAIL",
  DELETE_GREETING_CATEGORY_TAG_REQUEST: "DELETE_GREETING_CATEGORY_TAG_REQUEST",
  DELETE_GREETING_CATEGORY_TAG_SUCCESS: "DELETE_GREETING_CATEGORY_TAG_SUCCESS",
  DELETE_GREETING_CATEGORY_TAG_FAIL: "DELETE_GREETING_CATEGORY_TAG_FAIL",
  UPDATE_GREETING_CATEGORY_TAG_REQUEST: "UPDATE_GREETING_CATEGORY_TAG_REQUEST",
  UPDATE_GREETING_CATEGORY_TAG_SUCCESS: "UPDATE_GREETING_CATEGORY_TAG_SUCCESS",
  UPDATE_GREETING_CATEGORY_TAG_FAIL: "UPDATE_GREETING_CATEGORY_TAG_FAIL",
  SEARCH_GREETING_CATEGORY_TAG_REQUEST: "SEARCH_GREETING_CATEGORY_TAG_REQUEST",
  SEARCH_GREETING_CATEGORY_TAG_SUCCESS: "SEARCH_GREETING_CATEGORY_TAG_SUCCESS",
  SEARCH_GREETING_CATEGORY_TAG_FAIL: "SEARCH_GREETING_CATEGORY_TAG_FAIL",
  RESET_GREETING_CATEGORY_TAG: "RESET_GREETING_CATEGORY_TAG",
};

export const REPORTS = {
  APP_USER_STEP1_REQUEST: "APP_USER_STEP1_REQUEST",
  APP_USER_STEP1_SUCCESS: "APP_USER_STEP1_SUCCESS",
  APP_USER_STEP1_FAIL: "APP_USER_STEP1_FAIL",
  APP_USER_STEP2_REQUEST: "APP_USER_STEP2_REQUEST",
  APP_USER_STEP2_SUCCESS: "APP_USER_STEP2_SUCCESS",
  APP_USER_STEP2_FAIL: "APP_USER_STEP2_FAIL",
  APP_USER_STEP3_REQUEST: "APP_USER_STEP3_REQUEST",
  APP_USER_STEP3_SUCCESS: "APP_USER_STEP3_SUCCESS",
  APP_USER_STEP3_FAIL: "APP_USER_STEP3_FAIL",
};

export const DEMO = {
  SEND_DEMO_REQUEST: "SEND_DEMO_REQUEST",
  SEND_DEMO_SUCCESS: "SEND_DEMO_SUCCESS",
  SEND_DEMO_FAIL: "SEND_DEMO_FAIL",
  LIST_RECIPIENT_REQUEST: "LIST_RECIPIENT_REQUEST",
  LIST_RECIPIENT_SUCCESS: "LIST_RECIPIENT_SUCCESS",
  LIST_RECIPIENT_FAIL: "LIST_RECIPIENT_FAIL",
  UPDATE_DEMO_REQUEST: "UPDATE_DEMO_REQUEST",
  UPDATE_DEMO_SUCCESS: "UPDATE_DEMO_SUCCESS",
  UPDATE_DEMO_FAIL: "UPDATE_DEMO_FAIL",
  SEARCH_RECIPIENT_REQUEST: "SEARCH_RECIPIENT_REQUEST",
  SEARCH_RECIPIENT_SUCCESS: "SEARCH_RECIPIENT_SUCCESS",
  SEARCH_RECIPIENT_FAIL: "SEARCH_RECIPIENT_FAIL",
  RESET_DEMO: "RESET_DEMO",
};

export const GAME_INVENTORY = {
  ADD_GAME_INVENTORY_REQUEST: "ADD_GAME_INVENTORY_REQUEST",
  ADD_GAME_INVENTORY_SUCCESS: "ADD_GAME_INVENTORY_SUCCESS",
  ADD_GAME_INVENTORY_FAIL: "ADD_GAME_INVENTORY_FAIL",
  LIST_GAME_INVENTORY_REQUEST: "LIST_GAME_INVENTORY_REQUEST",
  LIST_GAME_INVENTORY_SUCCESS: "LIST_GAME_INVENTORY_SUCCESS",
  LIST_GAME_INVENTORY_FAIL: "LIST_GAME_INVENTORY_FAIL",
  UPDATE_SELECTED_GAME_INVENTORY_REQUEST:
    "UPDATE_SELECTED_GAME_INVENTORY_REQUEST",
  UPDATE_SELECTED_GAME_INVENTORY_SUCCESS:
    "UPDATE_SELECTED_GAME_INVENTORY_SUCCESS",
  UPDATE_SELECTED_GAME_INVENTORY_FAIL: "UPDATE_SELECTED_GAME_INVENTORY_FAIL",
  UPDATE_GAME_INVENTORY_REQUEST: "UPDATE_GAME_INVENTORY_REQUEST",
  UPDATE_GAME_INVENTORY_SUCCESS: "UPDATE_GAME_INVENTORY_SUCCESS",
  UPDATE_GAME_INVENTORY_FAIL: "UPDATE_GAME_INVENTORY_FAIL",
  SEARCH_GAME_INVENTORY_FAIL: "SEARCH_GAME_INVENTORY_FAIL",
  SEARCH_GAME_INVENTORY_REQUEST: "SEARCH_GAME_INVENTORY_REQUEST",
  SEARCH_GAME_INVENTORY_SUCCESS: "SEARCH_GAME_INVENTORY_SUCCESS",
  GET_ALL_GAME_INVENTORY_REQUEST: "GET_ALL_GAME_INVENTORY_REQUEST",
  GET_ALL_GAME_INVENTORY_SUCCESS: "GET_ALL_GAME_INVENTORY_SUCCESS",
  GET_ALL_GAME_INVENTORY_FAIL: "GET_ALL_GAME_INVENTORY_FAIL",
  GAME_INVENTORY_COUNT_REQUEST: "GAME_INVENTORY_COUNT_REQUEST",
  GAME_INVENTORY_COUNT_SUCCESS: "GAME_INVENTORY_COUNT_SUCCESS",
  GAME_INVENTORY_COUNT_FAIL: "GAME_INVENTORY_COUNT_FAIL",
  RESET_GAME_INVENTORY: "RESET_GAME_INVENTORY",
};

export const GAME_SEARCH_TAG = {
  ADD_GAME_SEARCH_TAG_REQUEST: "ADD_GAME_SEARCH_TAG_REQUEST",
  ADD_GAME_SEARCH_TAG_SUCCESS: "ADD_GAME_SEARCH_TAG_SUCCESS",
  ADD_GAME_SEARCH_TAG_FAIL: "ADD_GAME_SEARCH_TAG_FAIL",
  LIST_GAME_SEARCH_TAG_REQUEST: "LIST_GAME_SEARCH_TAG_REQUEST",
  LIST_GAME_SEARCH_TAG_SUCCESS: "LIST_GAME_SEARCH_TAG_SUCCESS",
  LIST_GAME_SEARCH_TAG_FAIL: "LIST_GAME_SEARCH_TAG_FAIL",
  GET_ALL_GAME_SEARCH_TAG_REQUEST: "GET_ALL_GAME_SEARCH_TAG_REQUEST",
  GET_ALL_GAME_SEARCH_TAG_SUCCESS: "GET_ALL_GAME_SEARCH_TAG_SUCCESS",
  GET_ALL_GAME_SEARCH_TAG_FAIL: "GET_ALL_GAME_SEARCH_TAG_FAIL",
  DELETE_GAME_SEARCH_TAG_REQUEST: "DELETE_GAME_SEARCH_TAG_REQUEST",
  DELETE_GAME_SEARCH_TAG_SUCCESS: "DELETE_GAME_SEARCH_TAG_SUCCESS",
  DELETE_GAME_SEARCH_TAG_FAIL: "DELETE_GAME_SEARCH_TAG_FAIL",
  UPDATE_GAME_SEARCH_TAG_REQUEST: "UPDATE_GAME_SEARCH_TAG_REQUEST",
  UPDATE_GAME_SEARCH_TAG_SUCCESS: "UPDATE_GAME_SEARCH_TAG_SUCCESS",
  UPDATE_GAME_SEARCH_TAG_FAIL: "UPDATE_GAME_SEARCH_TAG_FAIL",
  SEARCH_GAME_SEARCH_TAG_REQUEST: "SEARCH_GAME_SEARCH_TAG_REQUEST",
  SEARCH_GAME_SEARCH_TAG_SUCCESS: "SEARCH_GAME_SEARCH_TAG_SUCCESS",
  SEARCH_GAME_SEARCH_TAG_FAIL: "SEARCH_GAME_SEARCH_TAG_FAIL",
  RESET_GAME_SEARCH_TAG: "RESET_GAME_SEARCH_TAG",
};

export const GAME_CATEGORY_TAG = {
  ADD_GAME_CATEGORY_TAG_REQUEST: "ADD_GAME_CATEGORY_TAG_REQUEST",
  ADD_GAME_CATEGORY_TAG_SUCCESS: "ADD_GAME_CATEGORY_TAG_SUCCESS",
  ADD_GAME_CATEGORY_TAG_FAIL: "ADD_GAME_CATEGORY_TAG_FAIL",
  LIST_GAME_CATEGORY_TAG_REQUEST: "LIST_GAME_CATEGORY_TAG_REQUEST",
  LIST_GAME_CATEGORY_TAG_SUCCESS: "LIST_GAME_CATEGORY_TAG_SUCCESS",
  LIST_GAME_CATEGORY_TAG_FAIL: "LIST_GAME_CATEGORY_TAG_FAIL",
  GET_ALL_GAME_CATEGORY_TAG_REQUEST: "GET_ALL_GAME_CATEGORY_TAG_REQUEST",
  GET_ALL_GAME_CATEGORY_TAG_SUCCESS: "GET_ALL_GAME_CATEGORY_TAG_SUCCESS",
  GET_ALL_GAME_CATEGORY_TAG_FAIL: "GET_ALL_GAME_CATEGORY_TAG_FAIL",
  DELETE_GAME_CATEGORY_TAG_REQUEST: "DELETE_GAME_CATEGORY_TAG_REQUEST",
  DELETE_GAME_CATEGORY_TAG_SUCCESS: "DELETE_GAME_CATEGORY_TAG_SUCCESS",
  DELETE_GAME_CATEGORY_TAG_FAIL: "DELETE_GAME_CATEGORY_TAG_FAIL",
  UPDATE_GAME_CATEGORY_TAG_REQUEST: "UPDATE_GAME_CATEGORY_TAG_REQUEST",
  UPDATE_GAME_CATEGORY_TAG_SUCCESS: "UPDATE_GAME_CATEGORY_TAG_SUCCESS",
  UPDATE_GAME_CATEGORY_TAG_FAIL: "UPDATE_GAME_CATEGORY_TAG_FAIL",
  SEARCH_GAME_CATEGORY_TAG_REQUEST: "SEARCH_GAME_CATEGORY_TAG_REQUEST",
  SEARCH_GAME_CATEGORY_TAG_SUCCESS: "SEARCH_GAME_CATEGORY_TAG_SUCCESS",
  SEARCH_GAME_CATEGORY_TAG_FAIL: "SEARCH_GAME_CATEGORY_TAG_FAIL",
  RESET_GAME_CATEGORY_TAG: "RESET_GAME_CATEGORY_TAG",
};

export const GAME_SPECIAL_TAG = {
  ADD_GAME_SPECIAL_TAG_REQUEST: "ADD_GAME_SPECIAL_TAG_REQUEST",
  ADD_GAME_SPECIAL_TAG_SUCCESS: "ADD_GAME_SPECIAL_TAG_SUCCESS",
  ADD_GAME_SPECIAL_TAG_FAIL: "ADD_GAME_SPECIAL_TAG_FAIL",
  LIST_GAME_SPECIAL_TAG_REQUEST: "LIST_GAME_SPECIAL_TAG_REQUEST",
  LIST_GAME_SPECIAL_TAG_SUCCESS: "LIST_GAME_SPECIAL_TAG_SUCCESS",
  LIST_GAME_SPECIAL_TAG_FAIL: "LIST_GAME_SPECIAL_TAG_FAIL",
  GET_ALL_GAME_SPECIAL_TAG_REQUEST: "GET_ALL_GAME_SPECIAL_TAG_REQUEST",
  GET_ALL_GAME_SPECIAL_TAG_SUCCESS: "GET_ALL_GAME_SPECIAL_TAG_SUCCESS",
  GET_ALL_GAME_SPECIAL_TAG_FAIL: "GET_ALL_GAME_SPECIAL_TAG_FAIL",
  DELETE_GAME_SPECIAL_TAG_REQUEST: "DELETE_GAME_SPECIAL_TAG_REQUEST",
  DELETE_GAME_SPECIAL_TAG_SUCCESS: "DELETE_GAME_SPECIAL_TAG_SUCCESS",
  DELETE_GAME_SPECIAL_TAG_FAIL: "DELETE_GAME_SPECIAL_TAG_FAIL",
  UPDATE_GAME_SPECIAL_TAG_REQUEST: "UPDATE_GAME_SPECIAL_TAG_REQUEST",
  UPDATE_GAME_SPECIAL_TAG_SUCCESS: "UPDATE_GAME_SPECIAL_TAG_SUCCESS",
  UPDATE_GAME_SPECIAL_TAG_FAIL: "UPDATE_GAME_SPECIAL_TAG_FAIL",
  SEARCH_GAME_SPECIAL_TAG_REQUEST: "SEARCH_GAME_SPECIAL_TAG_REQUEST",
  SEARCH_GAME_SPECIAL_TAG_SUCCESS: "SEARCH_GAME_SPECIAL_TAG_SUCCESS",
  SEARCH_GAME_SPECIAL_TAG_FAIL: "SEARCH_GAME_SPECIAL_TAG_FAIL",
  RESET_GAME_SPECIAL_TAG: "RESET_GAME_SPECIAL_TAG",
};

export const GAME_INFO_TAG = {
  ADD_GAME_INFO_TAG_REQUEST: "ADD_GAME_INFO_TAG_REQUEST",
  ADD_GAME_INFO_TAG_SUCCESS: "ADD_GAME_INFO_TAG_SUCCESS",
  ADD_GAME_INFO_TAG_FAIL: "ADD_GAME_INFO_TAG_FAIL",
  LIST_GAME_INFO_TAG_REQUEST: "LIST_GAME_INFO_TAG_REQUEST",
  LIST_GAME_INFO_TAG_SUCCESS: "LIST_GAME_INFO_TAG_SUCCESS",
  LIST_GAME_INFO_TAG_FAIL: "LIST_GAME_INFO_TAG_FAIL",
  GET_ALL_GAME_INFO_TAG_REQUEST: "GET_ALL_GAME_INFO_TAG_REQUEST",
  GET_ALL_GAME_INFO_TAG_SUCCESS: "GET_ALL_GAME_INFO_TAG_SUCCESS",
  GET_ALL_GAME_INFO_TAG_FAIL: "GET_ALL_GAME_INFO_TAG_FAIL",
  DELETE_GAME_INFO_TAG_REQUEST: "DELETE_GAME_INFO_TAG_REQUEST",
  DELETE_GAME_INFO_TAG_SUCCESS: "DELETE_GAME_INFO_TAG_SUCCESS",
  DELETE_GAME_INFO_TAG_FAIL: "DELETE_GAME_INFO_TAG_FAIL",
  UPDATE_GAME_INFO_TAG_REQUEST: "UPDATE_GAME_INFO_TAG_REQUEST",
  UPDATE_GAME_INFO_TAG_SUCCESS: "UPDATE_GAME_INFO_TAG_SUCCESS",
  UPDATE_GAME_INFO_TAG_FAIL: "UPDATE_GAME_INFO_TAG_FAIL",
  SEARCH_GAME_INFO_TAG_REQUEST: "SEARCH_GAME_INFO_TAG_REQUEST",
  SEARCH_GAME_INFO_TAG_SUCCESS: "SEARCH_GAME_INFO_TAG_SUCCESS",
  SEARCH_GAME_INFO_TAG_FAIL: "SEARCH_GAME_INFO_TAG_FAIL",
  RESET_GAME_INFO_TAG: "RESET_GAME_INFO_TAG",
};

export const FAQ = {
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_FAIL: "ADD_FAQ_FAIL",
  LIST_FAQ_REQUEST: "LIST_FAQ_REQUEST",
  LIST_FAQ_SUCCESS: "LIST_FAQ_SUCCESS",
  LIST_FAQ_FAIL: "LIST_FAQ_FAIL",
  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAIL: "DELETE_FAQ_FAIL",
  UPDATE_FAQ_REQUEST: "UPDATE_FAQ_REQUEST",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",
  UPDATE_FAQ_FAIL: "UPDATE_FAQ_FAIL",
  SEARCH_FAQ_REQUEST: "SEARCH_FAQ_REQUEST",
  SEARCH_FAQ_SUCCESS: "SEARCH_FAQ_SUCCESS",
  SEARCH_FAQ_FAIL: "SEARCH_FAQ_FAIL",
  RESET_FAQ: "RESET_FAQ",
};

export const MESSAGE = {
  LIST_MESSAGE_REQUEST: "LIST_MESSAGE_REQUEST",
  LIST_MESSAGE_SUCCESS: "LIST_MESSAGE_SUCCESS",
  LIST_MESSAGE_FAIL: "LIST_MESSAGE_FAIL",
  DELETE_MESSAGE_REQUEST: "DELETE_MESSAGE_REQUEST",
  DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAIL: "DELETE_MESSAGE_FAIL",
  UPDATE_MESSAGE_REQUEST: "UPDATE_MESSAGE_REQUEST",
  UPDATE_MESSAGE_SUCCESS: "UPDATE_MESSAGE_SUCCESS",
  UPDATE_MESSAGE_FAIL: "UPDATE_MESSAGE_FAIL",
  RESET_MESSAGE: "RESET_MESSAGE",
};

export const GAME_ORDER = {
  LIST_GAME_ORDER_REQUEST: "LIST_GAME_ORDER_REQUEST",
  LIST_GAME_ORDER_SUCCESS: "LIST_GAME_ORDER_SUCCESS",
  LIST_GAME_ORDER_FAIL: "LIST_GAME_ORDER_FAIL",
  SEARCH_GAME_ORDER_FAIL: "SEARCH_GAME_ORDER_FAIL",
  SEARCH_GAME_ORDER_REQUEST: "SEARCH_GAME_ORDER_REQUEST",
  SEARCH_GAME_ORDER_SUCCESS: "SEARCH_GAME_ORDER_SUCCESS",
  GAME_ORDER_COUNT_REQUEST: "GAME_ORDER_COUNT_REQUEST",
  GAME_ORDER_COUNT_SUCCESS: "GAME_ORDER_COUNT_SUCCESS",
  GAME_ORDER_COUNT_FAIL: "GAME_ORDER_COUNT_FAIL",
  GAME_ORDER_RESET: "GAME_ORDER_RESET",
};

export const CUSTOMER_ORDER = {
  LIST_CUSTOMER_ORDER_REQUEST: "LIST_CUSTOMER_ORDER_REQUEST",
  LIST_CUSTOMER_ORDER_SUCCESS: "LIST_CUSTOMER_ORDER_SUCCESS",
  LIST_CUSTOMER_ORDER_FAIL: "LIST_CUSTOMER_ORDER_FAIL",
  SEARCH_CUSTOMER_ORDER_FAIL: "SEARCH_CUSTOMER_ORDER_FAIL",
  SEARCH_CUSTOMER_ORDER_REQUEST: "SEARCH_CUSTOMER_ORDER_REQUEST",
  SEARCH_CUSTOMER_ORDER_SUCCESS: "SEARCH_CUSTOMER_ORDER_SUCCESS",
  CUSTOMER_ORDER_RESET: "CUSTOMER_ORDER_RESET",
};

export const CUSTOMER_GAME_RECEIVED = {
  LIST_CUSTOMER_GAME_RECEIVED_REQUEST: "LIST_CUSTOMER_GAME_RECEIVED_REQUEST",
  LIST_CUSTOMER_GAME_RECEIVED_SUCCESS: "LIST_CUSTOMER_GAME_RECEIVED_SUCCESS",
  LIST_CUSTOMER_GAME_RECEIVED_FAIL: "LIST_CUSTOMER_GAME_RECEIVED_FAIL",
  SEARCH_CUSTOMER_GAME_RECEIVED_FAIL: "SEARCH_CUSTOMER_GAME_RECEIVED_FAIL",
  SEARCH_CUSTOMER_GAME_RECEIVED_REQUEST:
    "SEARCH_CUSTOMER_GAME_RECEIVED_REQUEST",
  SEARCH_CUSTOMER_GAME_RECEIVED_SUCCESS:
    "SEARCH_CUSTOMER_GAME_RECEIVED_SUCCESS",
  CUSTOMER_GAME_RECEIVED_RESET: "CUSTOMER_GAME_RECEIVED_RESET",
};

export const PERSONALIZATION = {
  LIST_PERSONALIZATION_REQUEST: "LIST_PERSONALIZATION_REQUEST",
  LIST_PERSONALIZATION_SUCCESS: "LIST_PERSONALIZATION_SUCCESS",
  LIST_PERSONALIZATION_FAIL: "LIST_PERSONALIZATION_FAIL",
  SEARCH_PERSONALIZATION_FAIL: "SEARCH_PERSONALIZATION_FAIL",
  SEARCH_PERSONALIZATION_REQUEST: "SEARCH_PERSONALIZATION_REQUEST",
  SEARCH_PERSONALIZATION_SUCCESS: "SEARCH_PERSONALIZATION_SUCCESS",
  PERSONALIZATION_COUNT_REQUEST: "PERSONALIZATION_COUNT_REQUEST",
  PERSONALIZATION_COUNT_SUCCESS: "PERSONALIZATION_COUNT_SUCCESS",
  PERSONALIZATION_COUNT_FAIL: "PERSONALIZATION_COUNT_FAIL",
  GIFT_ITEM_COUNT_REQUEST: "GIFT_ITEM_COUNT_REQUEST",
  GIFT_ITEM_COUNT_SUCCESS: "GIFT_ITEM_COUNT_SUCCESS",
  GIFT_ITEM_COUNT_FAIL: "GIFT_ITEM_COUNT_FAIL",
  PERSONALIZATION_RESET: "PERSONALIZATION_RESET",
};

export const GAMES_COMPLETE = {
  LIST_GAMES_COMPLETE_REQUEST: "LIST_GAMES_COMPLETE_REQUEST",
  LIST_GAMES_COMPLETE_SUCCESS: "LIST_GAMES_COMPLETE_SUCCESS",
  LIST_GAMES_COMPLETE_FAIL: "LIST_GAMES_COMPLETE_FAIL",
  SEARCH_GAMES_COMPLETE_FAIL: "SEARCH_GAMES_COMPLETE_FAIL",
  SEARCH_GAMES_COMPLETE_REQUEST: "SEARCH_GAMES_COMPLETE_REQUEST",
  SEARCH_GAMES_COMPLETE_SUCCESS: "SEARCH_GAMES_COMPLETE_SUCCESS",
  GAMES_COMPLETE_COUNT_REQUEST: "GAMES_COMPLETE_COUNT_REQUEST",
  GAMES_COMPLETE_COUNT_SUCCESS: "GAMES_COMPLETE_COUNT_SUCCESS",
  GAMES_COMPLETE_COUNT_FAIL: "GAMES_COMPLETE_COUNT_FAIL",
  GAMES_COMPLETE_RESET: "GAMES_COMPLETE_RESET",
};

export const MARKETING = {
  ADD_SLIDE_REQUEST: "ADD_SLIDE_REQUEST",
  ADD_SLIDE_SUCCESS: "ADD_SLIDE_SUCCESS",
  ADD_SLIDE_FAIL: "ADD_SLIDE_FAIL",
  LIST_SLIDE_REQUEST: "LIST_SLIDE_REQUEST",
  LIST_SLIDE_SUCCESS: "LIST_SLIDE_SUCCESS",
  LIST_SLIDE_FAIL: "LIST_SLIDE_FAIL",
  UPDATE_SLIDE_REQUEST: "UPDATE_SLIDE_REQUEST",
  UPDATE_SLIDE_SUCCESS: "UPDATE_SLIDE_SUCCESS",
  UPDATE_SLIDE_FAIL: "UPDATE_SLIDE_FAIL",
  GET_ALL_SLIDE_REQUEST: "GET_ALL_SLIDE_REQUEST",
  GET_ALL_SLIDE_SUCCESS: "GET_ALL_SLIDE_SUCCESS",
  GET_ALL_SLIDE_FAIL: "GET_ALL_SLIDE_FAIL",
  RESET_SLIDE: "RESET_SLIDE",
};

export const TOKKENZ_TYPE = {
  ADD_TOKKENZ_TYPE_REQUEST: "ADD_TOKKENZ_TYPE_REQUEST",
  ADD_TOKKENZ_TYPE_SUCCESS: "ADD_TOKKENZ_TYPE_SUCCESS",
  ADD_TOKKENZ_TYPE_FAIL: "ADD_TOKKENZ_TYPE_FAIL",
  LIST_TOKKENZ_TYPE_REQUEST: "LIST_TOKKENZ_TYPE_REQUEST",
  LIST_TOKKENZ_TYPE_SUCCESS: "LIST_TOKKENZ_TYPE_SUCCESS",
  LIST_TOKKENZ_TYPE_FAIL: "LIST_TOKKENZ_TYPE_FAIL",
  ACTIVE_TOKKENZ_TYPE_REQUEST: "ACTIVE_TOKKENZ_TYPE_REQUEST",
  ACTIVE_TOKKENZ_TYPE_SUCCESS: "ACTIVE_TOKKENZ_TYPE_SUCCESS",
  ACTIVE_TOKKENZ_TYPE_FAIL: "ACTIVE_TOKKENZ_TYPE_FAIL",
  GET_ALL_TOKKENZ_TYPE_REQUEST: "GET_ALL_TOKKENZ_TYPE_REQUEST",
  GET_ALL_TOKKENZ_TYPE_SUCCESS: "GET_ALL_TOKKENZ_TYPE_SUCCESS",
  GET_ALL_TOKKENZ_TYPE_FAIL: "GET_ALL_TOKKENZ_TYPE_FAIL",
  DELETE_TOKKENZ_TYPE_REQUEST: "DELETE_TOKKENZ_TYPE_REQUEST",
  DELETE_TOKKENZ_TYPE_SUCCESS: "DELETE_TOKKENZ_TYPE_SUCCESS",
  DELETE_TOKKENZ_TYPE_FAIL: "DELETE_TOKKENZ_TYPE_FAIL",
  UPDATE_TOKKENZ_TYPE_REQUEST: "UPDATE_TOKKENZ_TYPE_REQUEST",
  UPDATE_TOKKENZ_TYPE_SUCCESS: "UPDATE_TOKKENZ_TYPE_SUCCESS",
  UPDATE_TOKKENZ_TYPE_FAIL: "UPDATE_TOKKENZ_TYPE_FAIL",
  RESET_TOKKENZ_TYPE: "RESET_TOKKENZ_TYPE"
};

export const COUPONS = {
  ADD_COUPONS_REQUEST: "ADD_COUPONS_REQUEST",
  ADD_COUPONS_SUCCESS: "ADD_COUPONS_SUCCESS",
  ADD_COUPONS_FAIL: "ADD_COUPONS_FAIL",
  LIST_COUPONS_REQUEST: "LIST_COUPONS_REQUEST",
  LIST_COUPONS_SUCCESS: "LIST_COUPONS_SUCCESS",
  LIST_COUPONS_FAIL: "LIST_COUPONS_FAIL",
  DELETE_COUPONS_REQUEST: "DELETE_COUPONS_REQUEST",
  DELETE_COUPONS_SUCCESS: "DELETE_COUPONS_SUCCESS",
  DELETE_COUPONS_FAIL: "DELETE_COUPONS_FAIL",
  RESET_COUPONS: "RESET_COUPONS"
};

export const PROMOTION_CODE = {
  ADD_PROMOTION_CODE_REQUEST: "ADD_PROMOTION_CODE_REQUEST",
  ADD_PROMOTION_CODE_SUCCESS: "ADD_PROMOTION_CODE_SUCCESS",
  ADD_PROMOTION_CODE_FAIL: "ADD_PROMOTION_CODE_FAIL",
  LIST_PROMOTION_CODE_REQUEST: "LIST_PROMOTION_CODE_REQUEST",
  LIST_PROMOTION_CODE_SUCCESS: "LIST_PROMOTION_CODE_SUCCESS",
  LIST_PROMOTION_CODE_FAIL: "LIST_PROMOTION_CODE_FAIL",
  ACTIVE_PROMOTION_CODE_REQUEST: "ACTIVE_PROMOTION_CODE_REQUEST",
  ACTIVE_PROMOTION_CODE_SUCCESS: "ACTIVE_PROMOTION_CODE_SUCCESS",
  ACTIVE_PROMOTION_CODE_FAIL: "ACTIVE_PROMOTION_CODE_FAIL",
  GET_ALL_PROMOTION_CODE_REQUEST: "GET_ALL_PROMOTION_CODE_REQUEST",
  GET_ALL_PROMOTION_CODE_SUCCESS: "GET_ALL_PROMOTION_CODE_SUCCESS",
  GET_ALL_PROMOTION_CODE_FAIL: "GET_ALL_PROMOTION_CODE_FAIL",
  DELETE_PROMOTION_CODE_REQUEST: "DELETE_PROMOTION_CODE_REQUEST",
  DELETE_PROMOTION_CODE_SUCCESS: "DELETE_PROMOTION_CODE_SUCCESS",
  DELETE_PROMOTION_CODE_FAIL: "DELETE_PROMOTION_CODE_FAIL",
  UPDATE_PROMOTION_CODE_REQUEST: "UPDATE_PROMOTION_CODE_REQUEST",
  UPDATE_PROMOTION_CODE_SUCCESS: "UPDATE_PROMOTION_CODE_SUCCESS",
  UPDATE_PROMOTION_CODE_FAIL: "UPDATE_PROMOTION_CODE_FAIL",
  RESET_PROMOTION_CODE: "RESET_PROMOTION_CODE"
};