import { MESSAGE } from "../../constants/actionTypes";
import axios from 'axios';

export const listMessage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE.LIST_MESSAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("contact-us/lists", inputJSON)
      .then(function (response) {
        dispatch(listMessageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listMessageError(error.message));
      });
  };
};

const listMessageSuccess = (data) => ({
  type: MESSAGE.LIST_MESSAGE_SUCCESS,
  payload: data
});

const listMessageError = errorMessage => ({
  type: MESSAGE.LIST_MESSAGE_FAIL,
  payload: errorMessage
});

export const deleteMessage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE.DELETE_MESSAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("contact-us/delete", inputJSON)
      .then(function (response) {
        dispatch(deleteMessageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(deleteMessageError(error.message));
      });
  };
};

const deleteMessageSuccess = (data) => ({
  type: MESSAGE.DELETE_MESSAGE_SUCCESS,
  payload: data
});

const deleteMessageError = errorMessage => ({
  type: MESSAGE.DELETE_MESSAGE_FAIL,
  payload: errorMessage
});

export const updateMessage = (token, data) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE.UPDATE_MESSAGE_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    let inputJSON = JSON.stringify(data);

    INSTANCE.post("contact-us/update", inputJSON)
      .then(function (response) {
        dispatch(updateMessageSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(updateMessageError(error.message));
      });
  };
};

const updateMessageSuccess = (data) => ({
  type: MESSAGE.UPDATE_MESSAGE_SUCCESS,
  payload: data
});

const updateMessageError = errorMessage => ({
  type: MESSAGE.UPDATE_MESSAGE_FAIL,
  payload: errorMessage
});

export const resetMessage = () => {
  return (dispatch) => {
    dispatch({ type: MESSAGE.RESET_MESSAGE })
  }
}
