import React from "react";
import { Divider, notification, Input, Button, Modal, Tag, Breadcrumb } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { Row, Col } from 'react-bootstrap';
import { FaTrashAlt, FaRegEdit, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  listFaq,
  deleteFaq,
  searchFaq,
  resetFaq
} from "./../../../appRedux/actions/faq";


const { Search } = Input;
const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListFaq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      faqList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: 200,
      sorter: (a, b) => a.question.length - b.question.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      width: 300,
      sorter: (a, b) => a.answer.length - b.answer.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Inactive' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Inactive',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: (a, b) => a.modifiedBy.firstName?.length - b.modifiedBy.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (modifiedBy) => {
        return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
      }
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          <span className="gx-link">
            {<Link to={`/faqs/edit/${record._id}`}><FaRegEdit className="icon-size gx-link" /></Link>}
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.faqs) {
      if (nextProps.faqs.messageId === 204) {
        return {
          faqList: nextProps.faqs.lists.data,
          pagination: {
            current: nextProps.faqs.lists.current,
            pageSize: nextProps.faqs.lists.pageSize,
            total: nextProps.faqs.lists.totalCount
          }
        }
      } else if (nextProps.faqs.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.faqs.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetFaq();
      } else if (nextProps.faqs.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.faqs.alertMessage
        });
        nextProps.resetFaq();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete "' + record.question + '"?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteFaq(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.question + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isSuggested: false,
      isDeleted: false
    }
    this.props.deleteFaq(authUser.token, data);
  }


  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listFaq(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: 1,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchFaq(inputJson, authUser.token);
    }
  };

  onChange = e => {
    if (e.type === 'click') {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.faqs;
    const { pagination, faqList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>FAQs</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="FAQs" navigateTo="/faqs/add" linkName="Add FAQs" />
        <>
          <Row className="row-margin2">
            <Col>
              <Search
                placeholder="Search by Name"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={this.onSearch}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              <Table
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={faqList}
                rowKey={record => record._id}
                pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  itemRender: itemRender,
                  size: 'small'
                }}
                loading={loader}
                size={'middle'}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    faqs: state.faqs
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listFaq, deleteFaq, searchFaq, resetFaq }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListFaq);
