import React from "react";
import {Card} from "antd";
import PropTypes from "prop-types";

const StatsCard = ({children, styleName}) => {

  return (
    <Card className={`gx-card-widget ${styleName}`}>
      {children}
    </Card>
  )
};

export default StatsCard;
StatsCard.defaultProps = {
  styleName: '',
};

StatsCard.propTypes = {
  children: PropTypes.node.isRequired
};
