import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';

import MainApp from "./mainApp";
import SignIn from "./../UserAuth/signIn";
import SignUp from "./../UserAuth/signUp";
import ForgotPassword from "./../UserAuth/forgotPassword";
import ResetPassword from "./../UserAuth/resetPassword";
import VerifyEmail from "./../UserAuth/verifyEmail";
import { setInitUrl } from "../../appRedux/actions/auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "../../appRedux/actions/setting";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;


const App = (props) => {

  const dispatch = useDispatch();
  const { themeColor } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `/css/${themeColor}.css`;

    link.className = 'gx-style';
    document.body.appendChild(link);
  }, [themeColor]);


  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType();
    setNavStyle();
  });


  const setLayoutType = () => {
    document.body.classList.add('full-layout');
  };

  const setNavStyle = () => {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (authUser.user) {
        history.push('/home/dashboard');
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  return (
    <ConfigProvider>
      <Switch>
        <Route exact path='/signin' component={SignIn} />
        <Route exact path='/signup' component={SignUp} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset-password/:token' component={ResetPassword} />
        <Route exact path='/verify-email/:token' component={VerifyEmail} />
        <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
      </Switch>
    </ConfigProvider>
  )
};

export default memo(App);
