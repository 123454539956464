import React, { Component } from "react";
import { Button, Card, notification, Modal } from "antd";
import { Row, Col, Form, Table } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import lodash from "lodash";

import PageHeader from "components/PageHeader";

class GigAcceptance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      gameTemplate: {},
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      title:'',
      isSprite:false,
      isAudio:false,
      isGame:false,
      acceptConfirm:false
    }

    this.history = props.history;

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });
      this.setState({loading: true});
      INSTANCE.get(`game-template/get-by-id/${this.props.match.params.id}`) // change id
        .then((response) => { 
          if (response.data.messageId === 200) {
            this.setState({
              gameTemplate: response.data.data,
              loading: false
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  onFinish = values => {
  };

  convertFileSize = (fileSize) => {
    if (!fileSize) {
      return;
    }
    let size = (fileSize / 1024).toFixed(2);
    if (size >= 1024) {
      return (size / 1024).toFixed(2) + "Mb";
    }
    return size + "Kb";
  }

  onPreviewClick = (image, title, e) => {
    this.setState({
      previewImage: image.assetFile,
      previewVisible: true,
      previewTitle: image.fileName,
      title: title
    });
  }
  handleCancel = () => this.setState({ previewVisible: false });
  handleAcceptConfirm = () => this.setState({acceptConfirm: !this.state.acceptConfirm});

  onAcceptGigClick = () => {

    const { authUser } = this.props.auth;
    const { gameTemplate } = this.state;
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '.concat(authUser.token)
      }
    });
    const data = { _id: gameTemplate._id, contributorId: authUser.user.userId, isAssetsAndSprites: this.state.isSprite, isAudios: this.state.isAudio, isGameTeasers: this.state.isGame };
    INSTANCE.post(`game-template/accept-gig/`, JSON.stringify(data))
      .then((response) => {
        if (response.data.messageId === 200) {
          notification['success']({
            message: 'Success',
            description: response.data.message,
          });
          this.history.push('/game-template/submission')
        }
        if (response.data.messageId === 203) {
          notification['error']({
            message: 'Alert!!',
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
      })
  }

  renderAssetRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Resolution</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.assets ? this.state.gameTemplate.assets.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.resolution}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Asset")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  renderSpritesRow() {
    const { gameTemplate } = this.state;
    const spriteGroups = lodash.groupBy(gameTemplate.sprites, 'assetType');
    return Object.entries(spriteGroups).map(([spriteGroup, sprites], i) => {
      return (
        <Row className="row-margin2">
          <Col>
            <Table responsive bordered key={spriteGroup}>
              <thead>
                <tr className="asset-table-title">
                  <td>Sprite Group</td>
                  <td>Asset Name</td>
                  <td>Resolution</td>
                  <td>Type of File</td>
                  <td>Size of File</td>
                  <td>Asset Example</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {sprites ? sprites.map((asset, index) => (
                  <tr key={index}>
                    <td>{asset.assetType}</td>
                    <td>{asset.assetName}</td>
                    <td>{asset.resolution}</td>
                    <td>{asset.fileType}</td>
                    <td>{this.convertFileSize(asset.fileSize)}</td>
                    <td>{asset.fileName}</td>
                    <td> <Button onClick={this.onPreviewClick.bind(this, asset, 'Sprite')}> Preview </Button></td>
                  </tr>
                )) : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      )
    })
  }

  renderAudiosRow() {
    return (
      <Row className="row-margin2">
        <Col>
          <Table responsive bordered>
            <thead>
              <tr className="asset-table-title">
                <td>Type of Asset</td>
                <td>Asset Name</td>
                <td>Type of File</td>
                <td>Size of File</td>
                <td>Asset Example</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.gameTemplate && this.state.gameTemplate.audios ? this.state.gameTemplate.audios.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.assetType}</td>
                  <td>{asset.assetName}</td>
                  <td>{asset.fileType}</td>
                  <td>{this.convertFileSize(asset.fileSize)}</td>
                  <td>{asset.fileName}</td>
                  <td> <Button onClick={this.onPreviewClick.bind(this, asset, "Audio")}> Preview </Button></td>
                </tr>
              )) : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  render() {
    const {gameTemplate, previewVisible, previewImage, previewTitle, isGame, isAudio, isSprite } = this.state;
    return (
      gameTemplate === null || gameTemplate === {}  ?
        <Modal 
          visible={true}
          footer={null}
          title="Sorry Unable To Fetch Data"
          closable={false}
        >
          <p>Want To Go Back???</p>
          <div style={{ display:'flex', justifyContent:'flex-end', alignContent:'flex-end'}}>
            <Link className="btn btn-primary" to="/contributor/profile">Go back</Link>
          </div>
        </Modal>:
      <Card>
        <PageHeader title="2D Templates GIG Acceptance" />
        <Form name="gameTemplateForm">
          <Row className="row-margin2">
            <Col xs lg="6">
              <Table borderless>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <Form.Label>Game Template Name</Form.Label>
                      <Form.Control placeholder="Game Template Name" readOnly value={gameTemplate.title || ''} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Label>Template ID#</Form.Label>
                      <Form.Control placeholder="Template ID#" readOnly value={gameTemplate.templateId || ''} />
                    </td>
                    <td>
                      <Form.Label>New Skin ID#</Form.Label>
                      <Form.Control placeholder="New Skin ID#" readOnly value={gameTemplate.skinId || ''} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="accept-gig-incentives">
                        <p style={{ textAlign: 'center' }}>INCENTIVES</p>
                        <p style={{ textAlign: 'center' }}>Or Revenue share and get a % of each one of this game sold</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
                {(gameTemplate && !gameTemplate.isGameTeasers) || !gameTemplate.isAudios || !gameTemplate.isAssetsAndSprites ?
              <Table borderless className="accept-gig-table">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <p className="pay-title">We Will Pay You To Reskin This Game</p>
                    </td>
                      </tr>
                      {!gameTemplate.isAssetsAndSprites?
                        <tr>
                          <td style={{ display:'flex', justifyContent:'space-around' }}>
                            <span>Assets & Sprites $50</span>
                          </td>
                          <td>
                            <div style={{display:'flex', alignContent:'center'}}>
                              <label style={{marginRight:'5px'}}>Yes</label>
                              <input style={{marginRight:'15px', marginTop:'2px'}} type="radio" value="true" name="isSprite" onClick={()=>{this.setState({...this.state, isSprite:true})}} />
                              <label style={{marginRight:'5px'}}>No</label>
                              <input style={{marginRight:'15px', marginTop:'2px'}} type="radio" value="false" name="isSprite" onClick={()=>{this.setState({...this.state, isSprite:false})}} />
                            </div>
                          </td>
                        </tr>
                        : null}
                      {!gameTemplate.isAudios ?
                        <tr>
                          <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <span>Audio $50</span>
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                              <label style={{ marginRight: '5px' }}>Yes</label>
                              <input style={{ marginRight: '15px', marginTop: '2px' }} type="radio" value="true" name="isAudio" onClick={() => { this.setState({ ...this.state, isAudio: true }) }} />
                              <label style={{ marginRight: '5px' }}>No</label>
                              <input style={{ marginRight: '15px', marginTop: '2px' }} type="radio" value="false" name="isAudio" onClick={() => { this.setState({ ...this.state, isAudio: false }) }} />
                            </div>
                          </td>
                        </tr>
                        : null}
                      {!gameTemplate.isGameTeasers ?
                        <tr>
                          <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <span>Game Teasers $10</span>
                          </td>
                          <td>
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                              <label style={{ marginRight: '5px' }}>Yes</label>
                              <input style={{ marginRight: '15px', marginTop: '2px' }} type="radio" value="true" name="isGame" onClick={() => { this.setState({ ...this.state, isGame: true }) }} />
                              <label style={{ marginRight: '5px' }}>No</label>
                              <input style={{ marginRight: '15px', marginTop: '2px' }} type="radio" value="false" name="isGame" onClick={() => { this.setState({ ...this.state, isGame: false }) }} />
                            </div>
                          </td>
                        </tr>
                        : null}
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      <Button className="accept-gig-button" onClick={() => { 
                          if(isSprite === true || isAudio === true || isGame === true) {
                            this.handleAcceptConfirm();
                          } else {
                            notification['error']({
                              message: 'Alert!!',
                              description: 'Select Atleast 1 Category',
                            });
                          }
                        }}>
                        Accept This GameySkin GIG
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              :null}
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">ASSETS</span>
            </Col>
          </Row>
          {this.renderAssetRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">SPRITES</span>
            </Col>
          </Row>
          {this.renderSpritesRow()}
          <Row className="row-margin2">
            <Col>
              <span className="assets-title">AUDIO (Optional)</span>
            </Col>
          </Row>
          {this.renderAudiosRow()}
         
        </Form>
        <Modal
          visible={this.state.acceptConfirm}
          title={"Acceptance Form"}
          onCancel={this.handleAcceptConfirm}
          onOk={()=>{
            this.onAcceptGigClick();
            this.handleAcceptConfirm();
          }}
        >
          <p>Are You Sure You Want To Accept This Gamey Skin GIG?</p>
        </Modal>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          {this.state.title === "Audio" ?
            <AudioPlayer
              autoPlay
              src={previewImage}
            /> :
            <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          }
        </Modal>
      </Card>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(GigAcceptance));

