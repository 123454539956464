import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AddFaq from "./add";
import ListFaq from "./list";

const Main = ({match}) => (
  <Switch>
    <Route path={`${match.url}/add`} component={AddFaq}/>
    <Route path={`${match.url}/edit/:id`} component={AddFaq}/>
    <Route path={`${match.url}/list`} component={ListFaq}/>
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
