import React from "react";
import { Layout } from "antd";
// import { Layout, Popover } from "antd";
// import { Link } from "react-router-dom";

import { toggleCollapsedSideNav } from "../../appRedux/actions/setting";
import UserInfo from "components/UserInfo";
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
import Auxiliary from "util/auxiliary";

import { NAV_STYLE_DRAWER, NAV_STYLE_MINI_SIDEBAR, NAV_STYLE_FIXED, TAB_SIZE } from "../../constants/themeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {

  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="Tokkenz MoneyHunt Games" src={require("assets/images/logo-purple.png")} style={{ width: 130 }} />
      </Link> */}
      <ul className="gx-header-notifications gx-ml-auto">
        {/* <Auxiliary>
          <li className="gx-notify">
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification />}
              trigger="click">
              <span className="gx-pointer gx-d-block"><i className="icon icon-notification" /></span>
            </Popover>
          </li>

          <li className="gx-msg">
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
              content={<MailNotification />} trigger="click">
              <span className="gx-pointer gx-status-pos gx-d-block">
                <i className="icon icon-chat-new" />
                <span className="gx-status gx-status-rtl gx-small gx-orange" />
              </span>
            </Popover>
          </li>
        </Auxiliary> */}
        <Auxiliary>
          <li className="gx-user-nav"><UserInfo /></li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
