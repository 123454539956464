import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddEventTitle from "./add";
import ListEventTitle from "./list";
import CustomBreadCrumb from 'components/Widgets/CustomBreadCrumb';

const Main = ({ match }) => (
  <div>
    <CustomBreadCrumb />
    <Switch>
      <Route path={`${match.url}/add`} component={AddEventTitle} />
      <Route path={`${match.url}/edit/:id`} component={AddEventTitle} />
      <Route path={`${match.url}/list`} component={ListEventTitle} />
      <Route exact path={`${match.url}/`}>
        <Redirect to={`${match.url}/list`} />
      </Route>
    </Switch>
  </div>
);

export default Main;
