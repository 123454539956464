import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
  NAV_STYLE_FIXED,
  THEME_TYPE_LITE
} from "../../constants/themeSetting";

const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {themeType} = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  return (
    <div className="gx-layout-sider-header">
      {(navStyle === NAV_STYLE_FIXED) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            setSidebarCollapsed(!sidebarCollapsed)
          }}
        />
      </div> : null}

      <Link to="/" className="gx-site-logo">
        <img alt="Tokkenz MoneyHunt Games" className="logo1" src={require("assets/images/logo-gold-white.png")}/>
      </Link>
    </div>
  );
};

export default SidebarLogo;