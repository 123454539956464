import React from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

const UserProfileTab = (props) => {
  const { profileData } = props;

  if (!profileData) {
    return;
  }

  return (
    <>
      <Row>
        <Col>
          <span className="lable-style">First Name: </span>
          {profileData.firstName}
        </Col>
        <Col>
          <span className="lable-style">Last Name: </span>
          {profileData.lastName}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col>
          <span className="lable-style">Email: </span>
          {profileData.email}
        </Col>
        <Col>
          <span className="lable-style">Mobile Phone: </span>
          {profileData.phone
            ? profileData.dialCode + " " + profileData.phone
            : null}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col>
          <span className="lable-style">Gender: </span>
          {profileData.gender}
        </Col>
        <Col>
          <span className="lable-style">DOB: </span>
          {profileData.dob
            ? moment(profileData.dob).format("MM-DD-YYYY")
            : null}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">Address Line1: </span>
          {profileData.address ? profileData.address.line1 : null}
        </Col>
        <Col md="6">
          <span className="lable-style">Address Line2: </span>
          {profileData.address && profileData.address.line2
            ? profileData.address.line2
            : null}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">City: </span>
          {profileData.address ? profileData.address.city : null}
        </Col>
        <Col md="6">
          <span className="lable-style">State: </span>
          {profileData.address ? profileData.address.state : null}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">Zip Code: </span>
          {profileData.address ? profileData.address.zipCode : null}
        </Col>
        <Col md="6">
          <span className="lable-style">Country: </span>
          {profileData.address ? profileData.address.country : null}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">Email Verified: </span>
          {profileData.isEmailVerified ? "Yes" : "No"}
        </Col>
        <Col md="6">
          <span className="lable-style">Mobile Verified: </span>
          {profileData.isMobileVerified ? "Yes" : "No"}
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="lable-style">IP Address: </span>
          {profileData.ip}
        </Col>
        <Col>
          <span className="lable-style">Web Brower, OS: </span>
          {profileData.browserName}, {profileData.osName}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">Stripe Customer ID: </span>
          {profileData.customerId ? profileData.customerId : null}
        </Col>
        <Col md="6">
          <span className="lable-style">Personalization Tutorial: </span>
          {profileData.settings && profileData.settings.personalizationTutorial
            ? "Yes"
            : "No"}
        </Col>
      </Row>
      <Row className="row-margin">
        <Col md="6">
          <span className="lable-style">Created At: </span>
          {moment(profileData.createdAt).format("MM-DD-YYYY hh:mm a")}
        </Col>
        <Col md="6">
          <span className="lable-style">Updated At: </span>
          {moment(profileData.updatedAt).format("MM-DD-YYYY hh:mm a")}
        </Col>
      </Row>
    </>
  );
};

export default UserProfileTab;
