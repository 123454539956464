import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddTokkenzType from "./add";
import ListTokkenzType from "./list";

const Main = ({ match }) => (
<Switch>
      <Route path={`${match.url}/add`} component={AddTokkenzType} />
      <Route path={`${match.url}/edit/:id`} component={AddTokkenzType} />
      <Route path={`${match.url}/list`} component={ListTokkenzType} />      
      <Route exact path={`${match.url}/`}>
        <Redirect to={`${match.url}/list`} />
      </Route>
    </Switch>
);

export default Main;
