import { connect } from 'react-redux';
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    let userExist = false;
    if (rest.userRoles.length > 0) {
        userExist = true;
    }
    const couldShow = rest.userRoles.some(x => rest.permission && rest.permission.includes(x.userRole));
    return <Route
        {...rest}
        render={props =>
            couldShow
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: userExist ? rest.redirectPath ? rest.redirectPath : "/home/dashboard" : "/signIn",
                        state: { from: location }
                    }}
                />}
    />
};

const mapStateToProps = state => ({
    userRoles: state.auth.authUser ? state.auth.authUser.user.userRoles : []
});

export default connect(mapStateToProps)(PrivateRoute);