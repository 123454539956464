import React, { Component } from "react";
import { Button, Card, Form, Input, Breadcrumb, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  addGameSubCategory,
  updateGameSubCategory,
  resetGameSubCategory
} from "../../../../appRedux/actions/gameCatalog/gameSubCategory";

const FormItem = Form.Item;

class AddGameSubCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("game-search-tag/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              description: response.data.data.description ? response.data.data.description : ''
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.gameSubCategoryData) {
      if (nextProps.gameSubCategoryData.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.gameSubCategoryData.alertMessage
        });
        nextProps.resetGameSubCategory();
        nextProps.history.goBack();
      } else if (nextProps.gameSubCategoryData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.gameSubCategoryData.alertMessage
        });
        nextProps.resetGameSubCategory();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = values;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateGameSubCategory(authUser.token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addGameSubCategory(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.gameSubCategoryData;
    const { editId } = this.state;
    return (
      <>
        {loader ?
          <div className="gx-loader-view">
            <CircularProgress />
          </div> :
          <Auxiliary>
            <Row className="margin-bottom">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/game-catalog/game-sub-categories" className="gx-link">Game Sub Categories</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <PageHeader title={editId ? "Edit Game Sub Category" : "Add Game Sub Category"} />
            <Row className="row-margin2">
              <Col>
                <Card>
                  <Form
                    name="gameTagForm"
                    onFinish={this.onFinish}
                    layout="vertical"
                    ref={this.formRef}
                  >
                    <FormItem label="Name" rules={[{ required: true, message: 'Please enter name.' }]} name="name">
                      <Input placeholder="Name" />
                    </FormItem>

                    <FormItem label="Description" name="description">
                      <Input.TextArea placeholder="Description" rows={4} />
                    </FormItem>

                    <FormItem>
                      <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                        {editId ? "Update" : "Submit"}
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Auxiliary>
        }
      </>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    gameSubCategoryData: state.gameSubCategoryData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    addGameSubCategory,
    updateGameSubCategory,
    resetGameSubCategory
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddGameSubCategory);
